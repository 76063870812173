<template>
  <div class="main-content sys-user-manage">
    <div class="btn-area">
      <div class="left">
        <el-row :gutter="20">
          <el-col :span="16">
            <el-input
              v-model="listParams.username"
              placeholder="请输入用户名或手机号"
              size="small"
              clearable
              @change="searchChange"
            ></el-input>
          </el-col>
          <el-col :span="8">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-search"
              @click="()=>(listParams.pageNumber=1,getSystemUserList())"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
          <el-button type="default" size="small" @click="enableClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-bofang" />
            </svg>
            <span>启用</span>
          </el-button>
          <el-button type="default" size="small" @click="disableClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-bofang-copy" />
            </svg>
            <span>禁用</span>
          </el-button>
          <el-button type="default" size="small" @click="resetPwClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-mima2" />
            </svg>
            <span>重置密码</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="userList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        ref="userListTable"
        height="100%"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        @row-click="userItemClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="userId"
          label="编号"
          width="80"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="username"
          label="账号"
          width="160"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="真实姓名"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="campusAddress"
          label="学段"
          align="center"
          :formatter="dealSectionId"
        ></el-table-column>
        <el-table-column
          prop="campusAddress"
          label="校区地址"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          label="手机号"
          align="center"
        ></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-button type="success" size="mini" v-show="scope.row.status == 1"
              >正常</el-button
            >
            <el-button type="danger" size="mini" v-show="scope.row.status == 0"
              >禁用</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="gmtCreate"
          label="创建时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="inviteCode"
          label="邀请码"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          align="center"
        ></el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="saveDialog.title"
      :visible.sync="saveDialogVisible"
      width="700px"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="90px"
          size="small"
        >
        <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="头像" prop="photo">
                <el-upload
                  accept="image/jpeg, image/png/, image/gif"
                  class="avatar-uploader"
                  :action="fileUpload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="saveRuleForm.photo"
                    :src="saveRuleForm.photo"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="用户名" prop="username">
                <el-input
                  v-model="saveRuleForm.username"
                  autocomplete="off"
                  :disabled="saveDialog.type == 'edit'"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input
                  type="password"
                  v-model="saveRuleForm.password"
                  autocomplete="off"
                  :disabled="saveDialog.type == 'edit'"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="真实姓名" prop="name">
                <el-input
                  v-model="saveRuleForm.name"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" prop="mobile">
                <el-input
                  v-model="saveRuleForm.mobile"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="邮箱" prop="email">
                <el-input
                  v-model="saveRuleForm.email"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态">
                <el-radio-group v-model="saveRuleForm.status">
                  <el-radio label="0">禁用</el-radio>
                  <el-radio label="1">正常</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="saveRuleForm.remark"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="校区地址">
                 <el-input
                  v-model="saveRuleForm.campusAddress"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="学段" prop="email">
                <el-checkbox-group v-model="saveRuleForm.sectionId" >
                    <el-checkbox :label="107">高中</el-checkbox>
                    <el-checkbox :label="106">初中</el-checkbox>
                    <el-checkbox :label="105">小学</el-checkbox>
                    <el-checkbox :label="108">艺术培训</el-checkbox>
                    <el-checkbox :label="109">小语种</el-checkbox>
                  </el-checkbox-group>
              </el-form-item>
            </el-col>
            
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="APP展示" prop="appIfShow">
              <el-radio-group v-model="saveRuleForm.appIfShow">
                  <el-radio label="0">展示</el-radio>
                  <el-radio label="1">不展示</el-radio>
                </el-radio-group>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSave('saveRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  systemUserList,
  systemUserRoleList,
  systemUserSave,
  systemUserInfo,
  systemUserInfoUpdate,
  systemUserDelete,
  systemUserDisable,
  systemUserEnable,
  systemUserResetPw,
} from "@/api/system/systemManage";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  followTags,
  fileUpload,
  reg_Num
} from "@/api/public";
export default {
  data() {
    let checkPhone = (rule, value, callback) => {
      // 当数据为空时，不进行校验
      if (!value) {
        return callback()
      }
     const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/
       setTimeout(() => {
        if (phoneReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号码'))
        }
      }, 100)
    }
    return {
      fileUpload,
      userList: [],
      listParams: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        username: "",
        //notSelf: "true",
        roleSign: "studentAdmin",
        roleSignAdmin: "studentAdminAdmin",
      },
      total: 0,
      saveDialogVisible: false,
      sectionList:[],
      saveRuleForm: {
        photo:"",
        username: "",
        mobile: "",
        password: "",
        name: "",
        status: "1",
        remark: "",
        email: "",
        roleIdList: [],
        roleSign: "studentAdmin",
        appIfShow:'0'
      },
      saveRules: {
        photo: [
          { required: true, message: "头像不能为空", trigger: "change" },
        ],
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        name: [
          { required: true, message: "真实姓名不能为空", trigger: "blur" },
        ],
        mobile : [
         {
             validator: checkPhone, message: '请输入正确的手机号码',trigger: "blur"
          }
        ],
      },
      roleList: [],
      selectUserList: [],
      saveDialog: {
        title: "新增教务",
        type: "add",
      },
    };
  },
  inject: ["reload"],
   watch: {
    saveDialogVisible: function (val,oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    }
  },
  methods: {
    searchChange(){
      this.getSystemUserList()
    },
    handleSelectionChange(val) {
      this.selectUserList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectUserList.push(val[i].userId);
        }
      }
    },
    getSystemUserList() {
      systemUserList(this.listParams).then((res) => {
        this.userList = res.rows;
        this.total = res.total;
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getSystemUserList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getSystemUserList();
    },
    getSystemUserRoleList() {
      systemUserRoleList().then((res) => {
        if (res.code == 0) {
          this.roleList = res.rows;
          for (let i = 0, len = this.roleList.length; i < len; i++) {
            if (this.roleList[i].roleSign == this.saveRuleForm.roleSign) {
              this.saveRuleForm.roleIdList = [this.roleList[i].roleId];
            }
          }
        }
      });
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            username: this.saveRuleForm.username,
            mobile: this.saveRuleForm.mobile,
            password: this.saveRuleForm.password,
            name: this.saveRuleForm.name,
            status: this.saveRuleForm.status,
            remark: this.saveRuleForm.remark,
            email: this.saveRuleForm.email,
            roleIdList: this.saveRuleForm.roleIdList,
            roleSign: "studentAdmin",
            inviteCodeType:"jw",
            campusAddress:this.saveRuleForm.campusAddress,
            photo:this.saveRuleForm.photo,
            appIfShow:this.saveRuleForm.appIfShow
          };
          data.sectionId=''
          if(this.saveRuleForm.sectionId.length>=1&&this.saveRuleForm.sectionId!=null){
            data.sectionId=this.saveRuleForm.sectionId?this.saveRuleForm.sectionId.join():'';
          }
          if (this.saveDialog.type == "add") {
            systemUserSave(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemUserList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            data.userId = this.selectUserList[0];
            systemUserInfoUpdate(data).then((res) => {
              if (res.code == 0) {
                this.saveDialogVisible = false;
                this.getSystemUserList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    resetPwClick() {
      if (this.selectUserList.length > 1) {
        this.msgWarn("请选择单个用户进行密码重置操作！");
        return;
      }
      if (this.selectUserList.length == 0) {
        this.msgWarn("请选择一个用户进行密码重置操作！");
        return;
      }
      this.$prompt("请输入新的密码", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        inputPattern: /\S+/,
        inputErrorMessage: "密码不能为空",
      })
        .then(({ value }) => {
          systemUserResetPw({
            password: value,
            userId: this.selectUserList[0],
          }).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("密码修改成功！");
            }
          });
        })
        .catch(() => {});
    },
    handleAvatarSuccess(res){
      this.saveRuleForm.photo = res[0].url;
    },
     beforeAvatarUpload(file) {
      let reg = /\.(png|jpg|gif|jpeg)$/;
      if (!reg.test(file.name)) {
        this.msgError(
          "支持上传的图片格式为png，jpg，gif，jpeg；请检查格式是否正确！"
        );
      }
      return reg.test(file.name);
    },
    editClick() {
      if (this.selectUserList.length > 1) {
        this.msgWarn("请选择单个用户进行编辑操作！");
        return;
      }
      if (this.selectUserList.length == 0) {
        this.msgWarn("请选择一个用户进行编辑操作！");
        return;
      }
      this.saveDialog.title = "编辑教务";
      this.saveDialog.type = "edit";
      this.saveRuleForm = {
        username: "",
        mobile: "",
        password: "",
        name: "",
        status: "1",
        remark: "",
        email: "",
        photo:"",
        roleIdList: this.saveRuleForm.roleIdList,
        roleSign: "studentAdmin",
        sectionId:[],
        appIfShow:""
      };
      
      this.saveDialogVisible = true;
      systemUserInfo(this.selectUserList[0]).then((res) => {
        if (res.code == 0) {
          this.saveRuleForm = {
            username: res.rows.username,
            mobile: res.rows.mobile,
            password: res.rows.password,
            name: res.rows.name,
            status: String(res.rows.status),
            remark: res.rows.remark,
            email: res.rows.email,
            roleIdList: res.rows.roleIdList,
            roleSign: "studentAdmin",
            sectionId:String(res.rows.sectionId),
            campusAddress:res.rows.campusAddress,
            photo:res.rows.photo,
            appIfShow:String(res.rows.appIfShow)
          };
          var sectionIdArr=[];
          sectionIdArr=res.rows.sectionId?res.rows.sectionId.split(","):[];
          this.saveRuleForm.sectionId=sectionIdArr.map(Number);
        }
      });
    },
    addClick() {
      this.saveDialog.title = "新增教务";
      this.saveDialog.type = "add";
      this.saveRuleForm = {
        username: "",
        mobile: "",
        password: "",
        name: "",
        status: "1",
        remark: "",
        email: "",
        photo:"",
        sectionId:[],
        roleIdList: this.saveRuleForm.roleIdList,
        roleSign: "studentAdmin",
        appIfShow:'0'
      };
      this.saveDialogVisible = true;
    },
    refreshClick() {
      this.reload();
    },
    delClick() {
      if (this.selectUserList.length == 0) {
        this.msgWarn("请至少选择一个用户！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          systemUserDelete(this.selectUserList).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getSystemUserList();
            }
          });
        })
        .catch(() => {});
    },
    enableClick() {
      if (this.selectUserList.length == 0) {
        this.msgWarn("请至少选择一个用户！");
        return;
      }
      systemUserEnable(this.selectUserList).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.getSystemUserList();
        }
      });
    },
    dealSectionId(row) {
      var str = "";
      var arr2 = new Array();
      if (row.sectionId) {
        arr2 = row.sectionId.split(",");
      }
      for (var i = 0; i < arr2.length; i++) {
        if (arr2[i] == 107) {
          str += "高中,";
        } else if (arr2[i] == 106) {
          str += "初中,";
        } else if (arr2[i] == 105) {
          str += "小学,";
        } else if (arr2[i] == 108) {
          str += "艺术培训,";
        } else if (arr2[i] == 109) {
          str += "小语种,";
        } else if (arr2[i] == 235) {
          str += "全学段,";
        }
      }
      return str.substring(0, str.length - 1);
    },
    disableClick() {
      if (this.selectUserList.length == 0) {
        this.msgWarn("请至少选择一个用户！");
        return;
      }
      systemUserDisable(this.selectUserList).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.getSystemUserList();
        }
      });
    },
    userItemClick(row) {
      this.$refs.userListTable.toggleRowSelection(row);
    },
    getSectionList(){
       return getSelectList({
        parentCode: sectionCode
      }).then(res => {
        if (res.code == 0) {
          this.sectionList = res.rows;
        }
      });
    },

  },
  mounted() {
    this.getSystemUserList();
    this.getSystemUserRoleList();
    this.getSectionList();//获取学段
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .center {
    text-align: center;
    min-height: 30px;
  }
  .line {
    height: 0;
    border-bottom: 1px solid #f1f1f1;
    margin-left: 30px;
    margin: 10px 0 10px 30px;
  }
  .save-dialog-content {
    padding-right: 20px;
  }
  .row_detail {
      padding: 10px 0;
    }
}
</style>