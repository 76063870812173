<template>
  <div class="main-content sys-user-manage">
    <div class="btn-area">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="输入学员姓名/联系方式/订单编号/产品名称"
            size="small"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="listParams.sectionId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in sectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">订单状态：</span>
          <el-select
            v-model="listParams.orderStatus"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">下单时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
           :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <el-button type="primary" size="small" icon="el-icon-search"
          @click="searchFn()"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="tableList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
      >
        <el-table-column
          prop="studentName"
          label="学生姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="studentSectionName"
          label="年级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="orderNo"
          :formatter="formatterProductType"
          label="产品类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称"
          align="center"
        ></el-table-column>
        <el-table-column
          label="课程类型"
          align="center"
          :formatter="formatterCourseType"
        ></el-table-column>
        <el-table-column
          prop="classHour"
          label="购课时长"
          align="center"
        ></el-table-column>
        <el-table-column
          label="归属人"
          prop="teachManagerName"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{scope.row.teachManagerName}}（{{scope.row.teachManagerRealName}}）</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="moneyType"
          :formatter="formatterOrderStatus"
          label="订单状态"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
             <el-button
              type="text"
              size="small"
              v-show="scope.row.teachManagerName"
              @click.stop="dealApportion(scope.row)"
              >重新分配</el-button
            >
            <el-button
              type="text"
              size="small"
              v-show="!scope.row.teachManagerName"
              @click.stop="dealApportion(scope.row)"
              >分配</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="dealSeeDetail(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
     <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="apportionDialog.title"
      :visible.sync="apportionVisible"
      :close-on-click-modal="false"
      width="500px"
      center
    >
      <div class="dialog-content">
        <div class="item_select">
          <span>请选择教务：</span>
          <el-select
            v-model="saleData.teachManagerId"
            placeholder="请选择"
            size="small"
            @change="saleChange"
          >
            <el-option
              v-for="item in saleList"
              :key="item.userId"
               :label="`${item.username}（${item.name}）`"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div class="record">
          <el-checkbox v-model="saleData.record">可查看跟踪记录</el-checkbox>
        </div> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="apportionVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="confirmApportion()" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="订单详情"
      :visible.sync="detailDialogVisible"
      width="800px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="detail_type">学生概况</div>
        <div>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>学生姓名：</span>
              <span>{{ studentDetail.name }}</span>
            </el-col>
            <el-col :span="8">
              <span>联系电话：</span>
              <span>{{
                studentDetail.phoneNo ? studentDetail.phoneNo : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>年级：</span>
              <span>{{
                studentDetail.sectionName
                  ? studentDetail.sectionName
                  : "暂无数据"
              }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>性别：</span>
              <span>{{
                studentDetail.sexName ? studentDetail.sexName : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>地址：</span>
              <span>{{
                studentDetail.address ? studentDetail.address : "暂无数据"
              }}</span>
            </el-col>
          </el-row>
        </div>
        <div class="detail_type">订单信息</div>
        <div>
          <el-row
            :gutter="20"
            class="row_detail row_detail2"
            type="flex"
            align="bottom"
            v-show="orderDetail.totalPrice"
          >
            <el-col :span="8">
              <span>总价：</span>
              <span>{{
                orderDetail.zdyActualPrice
                  ? orderDetail.zdyActualPrice
                  : orderDetail.totalPrice
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>单价：</span>
              <span>{{
                orderDetail.zdySinglePrice
                  ? orderDetail.zdySinglePrice
                  : orderDetail.singlePrice
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>课时数：</span>
              <span>{{
                orderDetail.zdyClassHour
                  ? orderDetail.zdyClassHour
                  : orderDetail.classHour
              }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail row_detail2" type="flex" align="bottom" v-show="orderDetail.zdyClassHour">
            <el-col :span="8">
              <span>单价：</span>
              <span>{{orderDetail.zdySinglePrice?orderDetail.zdySinglePrice:0}}</span>
            </el-col>
            <el-col :span="8">
              <span>课时数：</span>
              <span>{{orderDetail.zdyClassHour?orderDetail.zdyClassHour:0}}</span>
            </el-col>
            <el-col :span="8">
              <span>总金额：</span>
              <span>{{orderDetail.zdyTotalPrice?orderDetail.zdyTotalPrice:0}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail row_detail2" type="flex" align="bottom" v-show="orderDetail.zdyClassHour">
            <el-col :span="8">
              <span>优惠金额：</span>
              <span>{{orderDetail.zdyDiscountAmount?orderDetail.zdyDiscountAmount:0}}</span>
            </el-col>
            <el-col :span="8">
              <span>赠送课时：</span>
              <span>{{orderDetail.zdyGiveClassHour?orderDetail.zdyGiveClassHour:0}}</span>
            </el-col>
            <el-col :span="8">
              <span>实际支付：</span>
              <span>{{orderDetail.zdyActualPrice?orderDetail.zdyActualPrice:0}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>支付方式：</span>
              <span>{{
                orderDetail.payMentName ? orderDetail.payMentName : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>付款方式：</span>
              <span>{{
                orderDetail.moneyTypeName
                  ? orderDetail.moneyTypeName
                  : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>产品类型：</span>
              <span>{{
                orderDetail.productTypeName
                  ? orderDetail.productTypeName
                  : "暂无数据"
              }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>产品名称：</span>
              <span>{{ orderDetail.productName }}</span>
            </el-col>
            <el-col :span="8">
              <span>年级：</span>
              <span>{{
                orderDetail.sectionName ? orderDetail.sectionName : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>教师职称：</span>
              <span>{{
                orderDetail.teacherTitleName
                  ? orderDetail.teacherTitleName
                  : "暂无数据"
              }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>订单编号：</span>
              <span>{{ orderDetail.orderNo }}</span>
            </el-col>
            <el-col :span="8">
              <span>下单时间：</span>
              <span>{{ orderDetail.createTime }}</span>
            </el-col>
            <el-col :span="8">
              <span>订单状态：</span>
              <span>{{ orderDetail.orderStatusName }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>付款凭证：</span>
              <!-- <img
                :src="orderDetail.certificate"
                alt=""
                class="certificate"
                v-if="orderDetail.certificate"
              /> -->
               <el-image 
              style="width: 100px; height: 100px"
              :src="orderDetail.certificate" 
               v-if="orderDetail.certificate"
                :preview-src-list="srcList"
            >
            </el-image>
              <span v-else>暂无数据</span>
            </el-col>
            <el-col :span="8">
              <span>辅导协议：</span>
              <span v-if="orderDetail.coachAgreement">点击查看</span>
              <span v-else>暂无数据</span>
            </el-col>
            <el-col :span="8">
              <span>归属部门：</span>
              <span>{{
                orderDetail.orgName ? orderDetail.orgName : "暂无数据"
              }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>订单提交人：</span>
              <span>{{
                orderDetail.orderSourceName ? orderDetail.orderSourceName+'（'+ orderDetail.orderSourceRealName+'）': "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>备注：</span>
              <span>{{
                orderDetail.remark ? orderDetail.remark : "暂无数据"
              }}</span>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- <span
        slot="footer"
        class="dialog-footer"
        v-show="orderDetail.orderStatus == 1"
      >
        <el-button @click="conformOrder" type="primary" size="small"
          >通 过</el-button
        >
        <el-button type="primary" @click="refuseOrder" size="small"
          >驳 回</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>
<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  constellation,
  teachStyle,
  teachYear,
  teachSpeed
} from "@/api/public";
import {
  distributeMore,
  listNoPage,
  updateTeachManagerIdYDD,
  orderDetail
} from "@/api/administration/tubeTeachBusiness/studentManage.js";
import { studentInfo } from "@/api/sales/saleManage.js";
export default {
  data() {
    return {
      srcList:[],
      listParams: {
        name: null,
        sectionId: "",
        searchBeginDate:null,
        searchEndDate:null,
        pageNumber: 1,
        pageSize: 10,
        dataAuthority:"dept",
        orderStatus:"",
      },
      sectionList: [
        {
          macroId:null,
          name: "全部"
        }
      ],
      orderStatusList: [
        {
          value: 0,
          label: "全部"
        },
        {
          value: 1,
          label: "待财务审核"
        },
        {
          value: 2,
          label: "财务审核拒绝"
        },
        {
          value: 3,
          label: "教务主管分配中"
        },
        {
          value: 4,
          label: "待排课"
        },
        {
          value: 5,
          label: "排课中"
        },
        {
          value: 6,
          label: "已退款"
        },
        {
          value: 7,
          label: "订单已完成"
        },
        {
          value: 8,
          label: "订单作废"
        },
        {
          value: 9,
          label: "退款中"
        }
      ],
      timePickerValue: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs()
                  .subtract(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(7, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(30, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(90, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      tableList:[],
      total:0,
      apportionVisible:false,
      saleList:[],
      apportionDialog: {
        title: "分配",
        type: "1"
      },
       saleParams: {
        roleSign: "studentAdmin",
        status: 1,
        roleSignAdmin:"studentAdminAdmin"
      },
      saleData: {
        teachManagerName:"",
        teachManagerId:"",
        allocationRecordType:2,
        reservationsStatus:3,
        studentId:"",
        orderNo:"",
        id:"",
      },
      detailDialogVisible:false,
      orderDetail: {},
      studentDetail: {},
    };
  },
  created() {

  },
  mounted() {
    this.getSectionList();
    this.distributeMoreFn();
  },
  methods: {
    getSectionList() {
      getSelectList({
        parentCode: sectionCode
      }).then(res => {
        if (res.code == 0) {
          this.sectionList = res.rows;
          this.sectionList.unshift({
            macroId: null,
            name: "全部"
          });
        }
      });
    },
    datePickerChange() {
        if (!this.timePickerValue) {
          this.listParams.searchBeginDate = null;
          this.listParams.searchEndDate = null;
          return;
        }
        this.listParams.searchBeginDate = this.timePickerValue[0];
        this.listParams.searchEndDate = this.timePickerValue[1];
    },
    dealSeeDetail(item){
        this.detailDialogVisible=true;
        orderDetail(item.id).then(res => {
        if (res.code == 0) {
          this.orderDetail = res.rows;
           this.srcList.push(res.rows.certificate);
          switch (this.orderDetail.payMent) {
            case 1:
              this.orderDetail.payMentName = "支付宝";
              break;
            case 2:
              this.orderDetail.payMentName = "微信";
              break;
            case 3:
              this.orderDetail.payMentName = "对公账户";
              break;
            case 4:
              this.orderDetail.payMentName = "POS";
              break;
            default:
              this.orderDetail.payMentName = "";
          }
          switch (this.orderDetail.moneyType) {
            case 1:
              this.orderDetail.moneyTypeName = "全款";
              break;
            case 2:
              this.orderDetail.moneyTypeName = "定金";
              break;
            case 3:
              this.orderDetail.moneyTypeName = "尾款";
              break;
            default:
              this.orderDetail.moneyTypeName = "";
          }
          switch (this.orderDetail.orderStatus) {
            case 1:
              this.orderDetail.orderStatusName = "待财务审核";
              break;
            case 2:
              this.orderDetail.orderStatusName = "财务审核拒绝";
              break;
            case 3:
              this.orderDetail.orderStatusName = "教务主管分配中";
              break;
            case 4:
              this.orderDetail.orderStatusName = "待排课";
              break;
            case 5:
              this.orderDetail.orderStatusName = "排课中";
              break;
            case 6:
              this.orderDetail.orderStatusName = "已退款";
              break;
            case 7:
              this.orderDetail.orderStatusName = "订单已完成";
              break;
            case 8:
              this.orderDetail.orderStatusName = "订单作废";
              break;
            case 9:
              this.orderDetail.orderStatusName = "退款中";
              break;
            default:
              this.orderDetail.orderStatusName = "";
          }
          switch (this.orderDetail.productType) {
            case 1:
            case 3:
              this.orderDetail.productTypeName =
                this.orderDetail.isSubscribe == 1 ? "试听课" : "一对一";
              break;
            case 2:
            case 5:
              this.orderDetail.productTypeName = "一对多";
              break;
            case 4:
              this.orderDetail.productTypeName = "公开课";
              break;
            default:
              this.orderDetail.productTypeName = "";
          }
        }
      });
      studentInfo(item.studentId).then(res => {
        if (res.code == 0) {
          this.studentDetail = res.rows;
          switch (this.studentDetail.sex) {
            case 1:
              this.studentDetail.sexName = "男";
              break;
            case 2:
              this.studentDetail.sexName = "女";
            default:
              this.studentDetail.sexName = "";
          }
          if (this.studentDetail.provinceName) {
            this.studentDetail.address =
              this.studentDetail.provinceName +
              "" +
              this.studentDetail.cityName +
              "" +
              this.studentDetail.countyName;
          }
        }
      });
    },
    distributeMoreFn(){
      distributeMore(this.listParams).then(res => {
        this.tableList = res.rows;
        this.total = res.total;
      });
    },
    formatterProductType(item){
         switch (item.orderType) {
          case 1:
            return "一对一";
          case 2:
          case 5:
            return "一对多";
          case 3:
            return "试听课";
        }
    },
    formatterCourseType(item){
      switch (item.courseTypeId) {
        case 1:
          return "大班课";
        case 2:
          return "小班课";
        case 3:
          return "答疑课";
        default:
          return "";
      }
    },
    formatterOrderStatus(item) {
      if (item.orderStatus) {
        for (let i = 0, len = this.orderStatusList.length; i < len; i++) {
          if (this.orderStatusList[i].value == item.orderStatus) {
            return this.orderStatusList[i].label;
          }
        }
      }
    },
    dealApportion(row){
      this.saleData.studentId=row.studentId;
      this.saleData.id=row.id;
      this.saleData.orderNo=row.orderNo;
      if (row.saleId) {
        this.apportionDialog = {
          title: "重新分配",
          type: "2"
        };
      } else {
        this.apportionDialog = {
          title: "分配",
          type: "1"
        };
      }
      this.getSaleList();
      this.apportionVisible = true;
    },
    confirmApportion(){
      if (!this.saleData.teachManagerId) {
        this.msgWarn("请选择需要分配的销售！");
        return;
      }
      this.apportionVisible = false;
      let arr = [];
      updateTeachManagerIdYDD(this.saleData).then(res => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.distributeMoreFn();
        }
      });
    },
    saleChange(){
      for (let i = 0, len = this.saleList.length; i < len; i++) {
        if (this.saleList[i].userId == this.saleData.teachManagerId) {
          this.saleData.teachManagerName = this.saleList[i].username;
        }
      }
    },
    getSaleList() {
      listNoPage(this.saleParams).then(res => {
        this.saleList = res;
      });
    },
     handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.distributeMoreFn();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.distributeMoreFn();
    },
    searchFn(){
      this.listParams.pageNumber=1;
      this.distributeMoreFn();
    }
  }
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      color:#000;
    }
    .row_detail {
      padding: 6px 0;
    }
    .row_detail2{
      span{
        font-weight: bold;
        color:#ff3f3f;
      }
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
}
</style>
