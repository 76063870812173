<template>
  <div class="main-content sys-role-manage">
    <dialog-file-view-private
      :dialogFileViewPrivateInfo="dialogFileViewPrivateInfo"
      @closeFileView="closeFileView"
    ></dialog-file-view-private>
    <div class="btn-area select-condition" >
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="课程标题/授课老师/学生姓名"
            size="small"
            style="width: 200px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">上课日期：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="listParams.sectionId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in sectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">学科：</span>
          <el-select
            v-model="listParams.subjectId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in subjectIdList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="()=>(listParams.pageNumber=1,findProductItemPage())"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="btn-area select-condition" >
      <div class="left">
        <div class="select-condition-item">
          <div class="select-condition-item">
            <span class="item-lable">上课状态：</span>
            <el-select
              v-model="listParams.attendClassStatus"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in attendClassStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="select-condition-item">
            <span class="item-lable">上课模式：</span>
            <el-select
              v-model="listParams.classMode"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in classModeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="select-condition-item">
            <span class="item-lable">请选择班主任：</span>
            <el-select
              v-model="listParams.teachManagerId"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in saleList"
                :key="item.userId"
                :label="`${item.username}（${item.name}）`"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="table-area" >
            <el-table
              size="small"
              :data="oneTooneData"
              style="width: 100%; margin-bottom: 20px"
              row-key="userId"
              height="100%"
              border
              tooltip-effect="dark"
            >
              <el-table-column
                prop="title"
                label="课程标题"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="title"
                label="上课形式"
                align="center"
              >
                <template slot-scope="scope">
                  <span v-show="scope.row.classMode == 1">线上</span>
                  <span v-show="scope.row.classMode == 2">线下</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="title"
                label="首次课"
                align="center"
              >
                <template slot-scope="scope">
                  <span v-show="scope.row.isFirstClass == 1">是</span>
                  <span v-show="scope.row.isFirstClass == 0">否</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="teacherRealName"
                label="授课老师"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="studentName"
                label="学生姓名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="sectionName"
                label="年级"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="beginTime"
                label="上课时间"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop=""
                label="上课状态"
                align="center"
              >
                <template slot-scope="scope">
                  <p>{{dealAttendClassStatus(scope.row)}}</p>
                  <p>课时数：{{scope.row.usedClassHour}}</p>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="课程状态"
                align="center"
              >
                <template slot-scope="scope">
                 <span v-if="scope.row.abnormalStatus==0">正常</span>
                <el-popover
                  v-else
                  placement="top-start"
                  title="异常原因"
                  trigger="hover"
                  :content="scope.row.abnormalCause">
                  <span slot="reference"  style="color:red;">异常(查看原因)</span>
                </el-popover>
              </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="反馈状态"
                align="center"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.learnReportStatus == 0">待反馈</span>
                  <span v-if="scope.row.learnReportStatus == 1">已反馈</span>
                  <span v-if="scope.row.learnReportStatus == 2">已发布</span>
                </template>
              </el-table-column>
               <el-table-column
              label="教务姓名"
              align="center"
              prop="teachManagerName"
            >
              <template slot-scope="scope">
                  <span>{{scope.row.teachManagerName}}（{{scope.row.teachManagerRealName}}）</span>
              </template>
            </el-table-column>
            <el-table-column
                prop=""
                label="补课时"
                align="center"
                width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.classHourType == 7" @click="yesHover(scope.row)" style="color:red;cursor:pointer">是</span>
                  <span v-else >否</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="afterClassRun(scope.row)"
                    >课后管理</el-button
                  > 
                   <el-button
                    type="text"
                    size="small"
                    @click="sendJob(scope.row)"
                    >发送作业</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    v-show="scope.row.attendClassStatus==1"
                    @click="makeUpClick(scope.row)"
                    >补课时</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
    </div>
    <div class="page-area" >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      title="课后管理"
      :visible.sync="afterClassRunDialog"
      :close-on-click-modal="false"
      width="1000px"
      center
    >
    <!-- 海报html元素 -->
      <div id="posterHtml" :style="{backgroundImage: 'url('+posterHtmlBg()+')'}" style="display:none;background-repeat: no-repeat;background-size:100% 100%;width: 750px;height: 1334px;">
        <div class="posterInfo">
          <p>{{getParams.studentName}}</p>
          <div>
            <p>上课科目：{{getParams.subjectName}}</p>
            <p>上课时间：{{getParams.beginTime}}~{{getParams.endTime}}</p>
            <p>授课老师：{{getParams.teacherName}}</p>
          </div>
        </div>
        <!-- 二维码 -->
        <div class="qrcode"><div id="qrcodeImg"></div><p style="text-align:center;color:#222;font-size:26px;margin-top:17px;">长按识别二维码</p></div>
      </div>
      <div class="dialog-content">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="课程评价" name="first">
            <el-form
          :model="infoFormOrder"
          status-icon
          ref="infoFormOrder"
          label-width="160px"
          size="small"
        >
          <el-form-item label="老师的优点" >
            <el-checkbox-group v-model="evaluationGood"  @click.native.prevent>
                  <el-checkbox-button label="课堂有激情" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="形象气质佳" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="沟通表达强" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="风趣幽默" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="细致负责有耐心" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="声音洪亮" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="干脆利索" name="type" ></el-checkbox-button>
                </el-checkbox-group>
          </el-form-item>
          <el-form-item label="希望老师改进的地方" >
                <el-checkbox-group v-model="evaluationGood" @click.native.prevent>
                  <el-checkbox-button label="有口音" name="type"  ></el-checkbox-button>
                  <el-checkbox-button label="上课迟到" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="课上互动少" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="亲和力差" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="没有耐心" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="讲课古板" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="不太负责" name="type" ></el-checkbox-button>
                </el-checkbox-group>
          </el-form-item>
          <el-form-item label="详细描述" prop="evaluationContent">
              <p>{{infoFormOrder.evaluationContent?infoFormOrder.evaluationContent:'暂无描述'}}</p>
          </el-form-item>
        </el-form>
          </el-tab-pane>
          <el-tab-pane label="课程反馈" name="second">
            <div class="part_text" style="margin-left:20px;">学生信息</div>
            <ul class="feedback_ul">
              <li>学生姓名：{{ getParams.studentName }}</li>
              <li>学生性别：{{ getParams.sex == 1 ? "男" : getParams.sex == 2 ? "女" : '未知' }}</li>
              <li>学生年级：{{ getParams.sectionName }}</li>
              <li>出生日期：{{ getParams.birthday ? getParams.birthday.slice(0,11) : "未知" }}</li>
              <li>星座：{{ getParams.constellationName ? getParams.constellationName :"未知"}}</li>
            </ul>
            <div class="cousre_info">
              <div class="part_text">上课内容</div>
             <div class="part_content">
                <div class="phase_area">
                  <div class="p_label">选择学段：</div>
                  <div class="p_select">
                    <el-select
                      size="small"
                      v-model="selectPhaseId"
                      placeholder="请选择学段"
                      @change="phaseSelectChange"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="item in phaseList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        v-show="item.id != 0"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="chapter_container">
                  <span class="chapter_label">章节：</span>
                  <span class="chapter_list">
                    <span
                      class="chapter_item"
                      v-for="item in chapterList"
                      :key="item.id"
                      :class="{ active_chapter: activeChapterId == item.id }"
                      @click="chapterClick(item)"
                      >{{ item.name }}</span
                    >
                  </span>
                </div>
                <div class="section_container" v-show="activeChapterId">
                  <span class="section_label">知识点：</span>
                  <span class="section_list">
                    <el-checkbox
                      class="section_item"
                      v-model="item.checked"
                      v-for="item in knowledgeList"
                      :key="item.id"
                      @change="knowSelectChange"
                      >{{ item.name }}</el-checkbox
                    >
                  </span>
                </div>
                <div
                  class="select_knowledge"
                  v-show="selectKnoewledgeList.length > 0"
                >
                  <span class="select_label">所选知识点</span>
                  <span class="select_list">
                    <span
                      class="select_item"
                      v-for="item in selectKnoewledgeList"
                      :key="item"
                    >
                      <span class="select_item_text">{{ item }}</span>
                      <span
                        class="iconfont icon-error icon_del"
                        @click="deleteKnowledgeClick(item)"
                      ></span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="detail_content">
              <div class="detail_content_title">上课内容及学生相关问题</div>
              <div class="detail_content_editer">
                <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="您可以通过课堂内容、掌握情况、课堂表现等多方面描述"
                  v-model="teaDetailedContent"
                >
                </el-input>
              </div>
              <div class="detail_content_title">图片备注</div>
              <div class="img_list">
                <div
                  class="img_item img_item_true"
                  v-for="(item, index) in teaDetailedPictureList"
                  :key="index"
                >
                  <div class="img_hover">
                    <span
                      class="iconfont icon-yulan1 icon_preview"
                      title="预览"
                      @click="imgItemSeeClick(item, index)"
                    ></span>
                    <span

                      class="iconfont icon-shanchu4 icon_del"
                      title="删除"
                      @click="imgItemDelClick(index)"
                    ></span>
                  </div>
                  <img :src="item.url" alt="" />
                </div>
                <div class="img_item" @click="imgSelectClick">
                  <span class="iconfont icon-danyehuaban icon_new"></span>
                  <input
                    type="file"
                    ref="uploadImg"
                    @change="imgSelectChange"
                    style="display: none"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                  />
                </div>
              </div>
            </div>
            <div class="course_evaluate">
              <div class="part_text">评价学生</div>
              <div class="course_evaluate_item">
                <div class="evaluate_title">
                  1. 学生在本堂课听课的认真程度？
                </div>
                <div class="evaluate_option">
                  <el-radio-group v-model="teaEarnestId">
                    <el-radio
                      class="evaluate_option_item"
                      :label="item.id"
                      v-for="item in teaEarnestList"
                      :key="item.id"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                </div>
              </div>
              <div class="course_evaluate_item">
                <div class="evaluate_title">2. 上次课作业的完成程度？</div>
                <div class="evaluate_option">
                  <el-radio-group v-model="teaFinishId">
                    <el-radio
                      class="evaluate_option_item"
                      :label="item.id"
                      v-for="item in teaFinishList"
                      :key="item.id"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                </div>
              </div>
              <div class="course_evaluate_item">
                <div class="evaluate_title">3. 学生与老师的互动效果？</div>
                <div class="evaluate_option">
                  <el-radio-group v-model="teaInteractionId">
                    <el-radio
                      class="evaluate_option_item"
                      :label="item.id"
                      v-for="item in teaInteractionList"
                      :key="item.id"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                </div>
              </div>
              <div class="course_evaluate_item">
                <div class="evaluate_title">
                  4. 学生对本堂课内容的掌握程度？
                </div>
                <div class="evaluate_option">
                  <el-radio-group v-model="teaUnderstandId">
                    <el-radio
                      class="evaluate_option_item"
                      :label="item.id"
                      v-for="item in teaUnderstandList"
                      :key="item.id"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                </div>
              </div>
            </div>
            <div>
              <div class="part_text">作业内容</div>
              <ul class="sendJob_ul">
                <li v-for="(item,index) in workList" :key="index">
                  <div>
                    <p>{{item.homeworkTitle}}</p>
                    <p>{{item.createTime}}</p>
                  </div>
                  <div>
                    <span @click="seeWork(item)">预览</span>
                    <span @click="removeJobData(item)">删除</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="homework">
              <div class="part_text part_text_homework">
                <el-button type="primary" size="small" @click="workShowFn()">新增作业</el-button>
              </div>
              <div class="homework_container" v-show="workShow">
                <div class="homework_title homework_item">
                  <div class="homewok_item_label">作业标题</div>
                  <div class="homework_item_value">
                    <el-input
                      v-model="homeworkTitle"
                      placeholder="请输入作业标题"
                    ></el-input>
                  </div>
                </div>
                <div class="homework_content homework_item">
                  <div class="homewok_item_label">作业内容</div>
                  <div class="homework_item_value">
                    <el-input
                      type="textarea"
                      :rows="4"
                      placeholder="请输入作业内容"
                      v-model="homeworkContent"
                      resize="none"
                    >
                    </el-input>
                  </div>
                </div>
                <div class="homework_file homework_item">
                  <div class="homewok_item_label">上传文件</div>
                  <div class="homework_item_value">
                    <span class="file_btn" @click="btnClick()">
                      <span class="iconfont icon-shangchuan2"></span>
                      <span>本地上传</span>
                      <input
                        type="file"
                        ref="fileUpload"
                        @change="fileUploadChange"
                        style="display: none"
                      />
                    </span>
                  </div>
                </div>
                <div class="homework_item homework_select">
                  <div class="homewok_item_label">文件列表</div>
                  <div class="homework_item_value">
                    <div
                      class="file_list"
                      v-show="xmtHomeworkEntityList.length > 0"
                    >
                      <span
                        v-for="(item, index) in xmtHomeworkEntityList"
                        :key="index"
                        class="file_item"
                        @click="imgItemSeeClick(item, undefined)"
                      >
                        <span class="file_icon" :class="[item.eatname]">
                          <template
                            v-if="
                              item.extname == 'jpg' ||
                              item.extname == 'jpeg' ||
                              item.extname == 'png' ||
                              item.extname == 'bmp'
                            "
                          >
                            <svg-icon icon-class="img_2"></svg-icon>
                          </template>
                          <template
                            v-else-if="
                              item.extname == 'doc' || item.extname == 'docx'
                            "
                          >
                            <svg-icon icon-class="word"></svg-icon>
                          </template>
                          <template
                            v-else-if="
                              item.extname == 'ppt' || item.extname == 'pptx'
                            "
                          >
                            <svg-icon icon-class="ppt"></svg-icon>
                          </template>
                          <template v-else-if="item.extname == 'pdf'">
                            <svg-icon icon-class="pdf"></svg-icon>
                          </template>
                          <template
                            v-else-if="
                              item.extname == 'xlsx' || item.extname == 'xls'
                            "
                          >
                            <svg-icon icon-class="excel"></svg-icon>
                          </template>
                          <template v-else-if="item.extname == 'txt'">
                            <svg-icon icon-class="txt"></svg-icon>
                          </template>
                          <template v-else>
                            <svg-icon
                              icon-class="unknown"
                              class-name="unknown"
                            ></svg-icon>
                          </template>
                        </span>
                        <span class="file_name">{{ item.name }}</span>
                        <span
                          class="iconfont icon-guanbi6 file_del"
                          @click.stop="delListItem(index)"
                        ></span>
                      </span>
                    </div>
                    <div v-show="xmtHomeworkEntityList.length == 0">
                      暂无上传文件
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer" v-show="activeName=='second'">
        <el-button @click="afterClassRunDialog = false" size="small"
          >取 消</el-button
        >
         <el-button
          type="primary"
          @click="confirmSaveOrder('save')"
          size="small"
          >保 存</el-button
        >
        <el-button
          type="primary"
          @click="confirmSaveOrder('publish')"
          size="small"
          >发 布</el-button
        >
         <el-button
          type="primary"
          @click="confirmSeeOrder()"
          size="small"
          >预 览</el-button
        >
        <el-button
          type="primary"
          @click="createLink()"
          size="small"
          >生成海报</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="课后评价"
      :visible.sync="evaluationPopup"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <el-table
          size="small"
          :data="evaluationList"
          style="width: 100%"
          row-key="userId"
          border
          tooltip-effect="dark"
        >
          <el-table-column prop="name" label="">
            <template slot-scope="scope">
              <div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <p style="display: flex; align-items: center">
                    <span style="font-size: 16px">张三</span>
                    <el-tag
                      style="margin-left: 15px"
                      v-for="(item, index) in scope.row
                        .xmtTeacherEvaluateLabelList"
                      :key="index"
                      type="warning"
                      >{{ item.evaluateLabelName }}</el-tag
                    >
                  </p>
                  <p>评价于{{ scope.row.createTime }}</p>
                </div>
                <p style="margin-top: 15px">
                  {{ scope.row.evaluationContent }}
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page-area">
          <el-pagination
            @size-change="evaluatedSizeChange"
            @current-change="evaluatedCurrentChange"
            :current-page="evaluationParams.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="evaluationParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="evaluationTotal"
            background
          ></el-pagination>
        </div>
      </div>
    </el-dialog>
     <el-dialog
      title="发布作业"
      :visible.sync="sendJobPopup"
      :close-on-click-modal="false"
      width="750px"
      center
    >
      <div class="dialog-content">
        <p style="font-size:18px;font-weight:bold;">已发布作业列表</p>
        <ul class="sendJob_ul">
          <li v-for="(item,index) in workList" :key="index">
            <div>
              <p>{{item.homeworkTitle}}</p>
              <p>{{item.createTime}}</p>
            </div>
            <div>
              <span @click="seeWork(item)">预览</span>
              <span @click="removeJobData(item)">删除</span>
            </div>
          </li>
        </ul>
        <div class="homework" style="margin-top:50px;">
          <div class="homework_container">
                <div class="homework_title homework_item">
                  <div class="homewok_item_label">作业标题</div>
                  <div class="homework_item_value">
                    <el-input
                      v-model="sendJobTitle"
                      :maxLength="20"
                      placeholder="请输入作业标题,最多20个字"
                    ></el-input>
                  </div>
                </div>
                <div class="homework_content homework_item">
                  <div class="homewok_item_label">作业详情</div>
                  <div class="homework_item_value">
                    <el-input
                      type="textarea"
                      :rows="4"
                      maxlength="500"
                      show-word-limit
                      placeholder="请输入作业内容"
                      v-model="sendJobContent"
                      resize="none"
                    >
                    </el-input>
                  </div>
                </div>
                <div class="homework_file homework_item">
                  <div class="homewok_item_label">上传文件</div>
                  <div class="homework_item_value">
                    <span class="file_btn" @click="sendJobBtnClick()">
                      <span class="iconfont icon-shangchuan2"></span>
                      <span>本地上传</span>
                      <input
                        type="file"
                        ref="sendJobUpload"
                        @change="sendJobfileChange"
                        style="display: none"
                      />
                    </span>
                  </div>
                </div>
                <div class="homework_item homework_select">
                  <div class="homewok_item_label">文件列表</div>
                  <div class="homework_item_value">
                    <div
                      class="file_list"
                      v-show="sendJobHomeworkList.length > 0"
                    >
                      <span
                        v-for="(item, index) in sendJobHomeworkList"
                        :key="index"
                        class="file_item"
                        @click="imgItemSeeClick(item, undefined)"
                      >
                        <span class="file_icon" :class="[item.eatname]">
                          <template
                            v-if="
                              item.extname == 'jpg' ||
                              item.extname == 'jpeg' ||
                              item.extname == 'png' ||
                              item.extname == 'bmp'
                            "
                          >
                            <svg-icon icon-class="img_2"></svg-icon>
                          </template>
                          <template
                            v-else-if="
                              item.extname == 'doc' || item.extname == 'docx'
                            "
                          >
                            <svg-icon icon-class="word"></svg-icon>
                          </template>
                          <template
                            v-else-if="
                              item.extname == 'ppt' || item.extname == 'pptx'
                            "
                          >
                            <svg-icon icon-class="ppt"></svg-icon>
                          </template>
                          <template v-else-if="item.extname == 'pdf'">
                            <svg-icon icon-class="pdf"></svg-icon>
                          </template>
                          <template
                            v-else-if="
                              item.extname == 'xlsx' || item.extname == 'xls'
                            "
                          >
                            <svg-icon icon-class="excel"></svg-icon>
                          </template>
                          <template v-else-if="item.extname == 'txt'">
                            <svg-icon icon-class="txt"></svg-icon>
                          </template>
                          <template v-else>
                            <svg-icon
                              icon-class="unknown"
                              class-name="unknown"
                            ></svg-icon>
                          </template>
                        </span>
                        <span class="file_name">{{ item.name }}</span>
                        <span
                          class="iconfont icon-guanbi6 file_del"
                          @click.stop="delListItem(index)"
                        ></span>
                      </span>
                    </div>
                    <div v-show="xmtHomeworkEntityList.length == 0">
                      暂无上传文件
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" >
        <el-button @click="sendJobPopup = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="publishJob()"
          >发布作业</el-button
        >
      </span>
     </el-dialog>
    <el-dialog
      title="作业预览"
      :visible.sync="seeWorkPopup"
      :close-on-click-modal="false"
      width="750px"
      center
    >
      <div class="dialog-content workDiv">
        <p><span>作业标题</span><span style="margin-left:30px;">{{seeWorkTitle}}</span></p>
        <p style="margin-top:20px;"><span>作业内容</span><span style="margin-left:30px;line-height:1.5">{{seeWorkContent?seeWorkContent:'暂无作业内容'}}</span></p>
        <div class="homework_item homework_select">
                  <div class="homewok_item_label">文件列表</div>
                  <div class="homework_item_value">
                    <div
                      class="file_list"
                      v-show="seeWorkEntityList.length > 0"
                    >
                      <span
                        v-for="(item, index) in seeWorkEntityList"
                        :key="index"
                        class="file_item"
                        @click="imgItemSeeClick(item, undefined)"
                      >
                        <span class="file_icon" :class="[item.eatname]">
                          <template
                            v-if="
                              item.extname == 'jpg' ||
                              item.extname == 'jpeg' ||
                              item.extname == 'png' ||
                              item.extname == 'bmp'
                            "
                          >
                            <svg-icon icon-class="img_2"></svg-icon>
                          </template>
                          <template
                            v-else-if="
                              item.extname == 'doc' || item.extname == 'docx'
                            "
                          >
                            <svg-icon icon-class="word"></svg-icon>
                          </template>
                          <template
                            v-else-if="
                              item.extname == 'ppt' || item.extname == 'pptx'
                            "
                          >
                            <svg-icon icon-class="ppt"></svg-icon>
                          </template>
                          <template v-else-if="item.extname == 'pdf'">
                            <svg-icon icon-class="pdf"></svg-icon>
                          </template>
                          <template
                            v-else-if="
                              item.extname == 'xlsx' || item.extname == 'xls'
                            "
                          >
                            <svg-icon icon-class="excel"></svg-icon>
                          </template>
                          <template v-else-if="item.extname == 'txt'">
                            <svg-icon icon-class="txt"></svg-icon>
                          </template>
                          <template v-else>
                            <svg-icon
                              icon-class="unknown"
                              class-name="unknown"
                            ></svg-icon>
                          </template>
                        </span>
                        <span class="file_name">{{ item.name }}</span>
                      </span>
                    </div>
                    <div v-show="seeWorkEntityList.length == 0">
                      暂无上传文件
                    </div>
                  </div>
                </div>
      </div>
    </el-dialog>
    <el-dialog
      title="课堂反馈预览"
      :visible.sync="seeOrderPopup"
      :close-on-click-modal="false"
      width="600px"
      center
    >
      <div class="dialog-content">
        <dl class="feedback_dl">
          <dt><img :src="studentParams.photo" alt="" style="width:100%;height:100%;border-radius:50%"></dt>
          <dd>
            <p>{{getParams.studentName}}</p>
            <p><span>{{getParams.sectionName}}</span><span>{{getParams.birthday ? getParams.birthday.slice(0,11) : "未知"}}</span><span>{{getParams.constellationName ? getParams.constellationName :"未知"}}</span></p>
          </dd>
        </dl>
        <div class="course_news">
          <p><span></span><span>课程信息</span></p>
          <ul>
            <li><span>上课时间</span>
                <span>{{studentParams.beginTime}}</span>
            </li>
            <li><span>科目</span><span>{{studentParams.subjectName}}</span></li>
            <li><span>授课老师</span><span>{{studentParams.teacherName}}</span></li>
          </ul>
        </div>
        <div class="class_news">
          <p><span></span><span>课程信息</span></p>
          <div>
            <p>主要知识点</p>
            <p><span v-for="(item,index) in selectKnoewledgeList" :key="index">#{{item}}</span></p>
            <p>上课内容及学生相关问题</p>
            <dl>
              <dt>{{studentParams.teaDetailedContent}}</dt>
              <dd>
                <div class="img_list">
                <div
                  class="img_item img_item_true" style="border:1px solid #fff;"
                  v-for="(item, index) in teaDetailedPictureList"
                  :key="index"
                >
                  <div class="img_hover">
                    <span
                      class="iconfont icon-yulan1 icon_preview"
                      title="预览"
                      @click="imgItemSeeClick(item, index)"
                    ></span>
                  </div>
                  <img :src="item.url" alt="" />
                </div>
              </div>
              </dd>
            </dl>
          </div>
        </div>
        <div class="class_assess">
          <p><span></span><span>课堂评价</span></p>
          <div v-for="(item,index) in speedArray" :key="index">
            <p>{{item.tit}}</p>
            <p>{{item.name}}</p>
            <el-progress style="margin-top:5px;" :text-inside="true" :stroke-width="15" :percentage="item.num" status="exception"></el-progress>
          </div>
        </div>
        <div class="work_content">
          <p><span></span><span>作业内容</span></p>
            <dl>
              <dt>
                <img  :src="imageFn()" style="width:60px;height:auto;" alt="">
              </dt>
              <dd>
                <p>{{getParams.title}}</p>
                <p>任课老师：{{getParams.teacherName}}</p>
              </dd>
            </dl>
            <div class="work_div" v-for="(item,index) in workList" :key="index">
              <p>{{item.homeworkTitle}}</p>
              <p>{{item.createTime}}</p>
              <p>{{item.homeworkContent?item.homeworkContent:'暂无数据'}}</p>
              <div>
                <p v-for="(items,indexs) in item.xmtHomeworkEntityList" :key="indexs" @click="imgItemSeeClick(items, undefined)">
                  <img style="width:30px;height:auto;" :src="homeworkImage(items.extname)" alt=""> <span>{{items.name}}</span>
                </p>
              </div>
            </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        title="问题课程"
        center
        width="350px"
        :close-on-click-modal="false"
        :visible="infoShow"
    >
    <div style="margin-bottom:20px;">
      <p style="text-align:center;"><span>{{infoParams.beginTime}}-{{infoParams.endTime}}</span><span style="margin:0 10px;">{{infoParams.subjectName}}</span><span>{{infoParams.teacherRealName}}</span></p>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="infoShow=false"
          >返回</el-button
        >
      </span>
    </el-dialog>
    <selectAddress ref="selectAddress" :addressStatus="addressStatus" :addressParams="addressParams" @CB_addressStatus="CB_addressStatus"></selectAddress>
    <makeUpClass ref="makeUpClass" :makeType="makeType" :addressData="addressData" :makeUpStatus="makeUpStatus" :makeInfo="makeInfo" @CB_makeStatus="CB_makeStatus" @selectAddressFn="selectAddressFn"></makeUpClass>
  </div>
</template>
<script>
import TIM from "tim-js-sdk";
import dayjs from "dayjs";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  followTags,
  reg_Num,
} from "@/api/public";
import { studentInfo } from "@/api/sales/saleManage.js";
import {
  getSyllabusPage,
  findProductItemPage,
  findMoreProductItemPage,
  confirmCourse,
  getXmtStudentAccessClassByItemId,
  listSyncTree,
  productItemLearnReportInfo, //详情
  saveOrUpdate, //保存
  xmtProductItemUpdate,
  setAbnormalStatus,
  teacherEvaluatedContent,
  confirmClassHour,
  knowledgeTree,
  fileUpload,
  fileUploadUrl,
  getInfoByItemId,
  productItemWorkList,
  productItemWorkSave,
  productItemWorkRemove,
  productItemWorkSaveInfo,
  xmtProductItemInfo,
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import { uploadFileTask, computeFileSize } from "@/utils/tool";
import dialogFileViewPrivate from "../expertTeachBusiness/chat/dialog-file-view-private.vue";
import Cookies from "js-cookie";
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'
import {
  updateLearnReport,
} from "@/api/chat";
import {
  listNoPage,
} from "@/api/administration/tubeTeachBusiness/studentManage.js";
import makeUpClass from '../publicFile/makeUpClass.vue'
import selectAddress from '../publicFile/address.vue'
const teaEarnestList = [
  {
    id: 205,
    name: "走神情况严重",
    num:20,
  },
  {
    id: 206,
    name: "注意力集中时间较短",
    num:40
  },
  {
    id: 207,
    name: "正常水平",
    num:60
  },
  {
    id: 208,
    name: "能够跟上老师的节奏",
    num:80
  },
  {
    id: 209,
    name: "全神贯注",
    num:100
  },
];
const teaFinishList = [
  {
    id: 192,
    name: "上次课未留作业",
    num:25

  },
  {
    id: 191,
    name: "完成的太少，没有主动去完成老师布置的作业",
    num:50
  },
  {
    id: 190,
    name: "部分完成，个别难度大的题目等待老师讲解",
    num:75
  },
  {
    id: 189,
    name: "无论题目难度大小，全部完成",
    num:100
  },
];
const teaInteractionList = [
  {
    id: 217,
    name: "交流少，不主动，不顺畅",
    num:25
  },
  {
    id: 218,
    name: "只被动回答老师的提问",
    num:50
  },
  {
    id: 219,
    name: "善于思考，积极回答",
    num:75
  },
  {
    id: 220,
    name: "思维清晰，主动提问",
    num:100
  },
];
const teaUnderstandList = [
  {
    id: 213,
    name: "记忆层面，只记住知识点",
    num:35
  },
  {
    id: 214,
    name: "理解层面，可以熟练做题",
    num:70
  },
  {
    id: 215,
    name: "完全掌握，能够举一反三",
    num:100
  },
];

export default {
   components: {
    dialogFileViewPrivate,
    makeUpClass,
    selectAddress
  },
  data() {
    return {
      infoParams:{},
      infoShow:false,
      posterHtmlBg1: require('../../../assets/image/背景-语文.png'), 
      posterHtmlBg2: require('../../../assets/image/背景-数学地理.png'), 
      posterHtmlBg3: require('../../../assets/image/背景-物理.png'), 
      posterHtmlBg4: require('../../../assets/image/背景-化学生物.png'), 
      posterHtmlBg5: require('../../../assets/image/背景-政治.png'), 
      posterHtmlBg6: require('../../../assets/image/背景-英语.png'),
      posterHtmlBg7: require('../../../assets/image/背景-历史.png'),
      posterHtmlBg8: require('../../../assets/image/背景-其他.png'),
      posterImg: '', // 最终生成的海报图片
      workShow:false,
      speedArray:[],
      getParams:{subjectName:'',itemId:'',sectionName:''},
      selectPhaseId:"",
      subjectName:"",
      chapterList: [],
      activeChapterId:"",
      knowledgeList:[],
      selectKnoewledgeList:[],
      teaDetailedPictureList:[],
      teaDetailedContent:"",
      phaseList:[],
      exanTitle: [],
      teaEarnestId:"",
      teaFinishId:"",
      teaInteractionId:"",
      teaUnderstandId:"",
      teaEarnestList,
      teaInteractionList,
      teaUnderstandList,
      teaFinishList,
      homeworkTitle:"",
      homeworkContent:"",
      xmtHomeworkEntityList:[],
      uploadListParams: {
        show: false,
        list: [],
      },
      sendJobListParams: {
        show: false,
        list: [],
      },
      dialogFileViewPrivateInfo: {
        show: false,
        type: "img",
        src: "",
      },
      id:"",
      status:"",
      examList: [],
      examRightList: [],
      examRightCheck: "",
      evaluationParams: {
        pageSize: 10,
        pageNumber: 1,
        evaluationSource: 1,
        itemId: "",
      },
      img2:require("@/assets/image/img_2.svg"),//图片
      word:require("@/assets/image/word.svg"),//word
      ppt:require("@/assets/image/ppt.svg"),//ppt
      pdf:require("@/assets/image/pdf.svg"),//pdf
      excel:require("@/assets/image/excel.svg"),//excel
      txt:require("@/assets/image/txt.svg"),//txt
      unknown:require("@/assets/image/unknown.svg"),//qita
      yuwenImg:require("@/assets/image/yuwen.png"),//语文
      shuxueImg:require("@/assets/image/shuxue.png"),//数学
      yingyuImg:require("@/assets/image/yingyu.png"),//英语
      huaxueImg:require("@/assets/image/huaxue.png"),//化学
      lishiImg:require("@/assets/image/lishi.png"),//历史
      qitaImg:require("@/assets/image/qita.png"),//其他
      wuliImg:require("@/assets/image/wuli.png"),//物理
      zhengzhiImg:require("@/assets/image/zhengzhi.png"),//政治
      evaluationList: [],
      evaluationTotal: 0,
      evaluationPopup: false,
      subjectIdList: [],
      getid: "",
      studentId: "",
      afterClassRunDialog: false,
      activeName: "first",
      accessStar: 0,
      accessContent: "", //文字描述
      sectionList: [],
      timePickerValue: "",
      timePickerValue2: "",
      listParams: {
        orderType: "1",
        dataAuthority: "dept",
        name: "",
        sectionId: "",
        attendClassStatus: 2,
        classMode: "",
        pageNumber: 1,
        pageSize: 10,
        searchBeginDate: null,
        searchEndDate: null,
        subjectId: "",
        teachManagerId:""
      },
     
      studentParams: {
        name: "",
      },
      saleList:[],
      seeWorkPopup:false,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs().subtract(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(90, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      attendClassStatusList: [
        { value: "", label: "全部" },
        { value: 0, label: "未上课" },
        { value: 1, label: "已上课" },
        { value: 2, label: "上课中" },
      ],
      classModeList: [
        { value: "", label: "全部" },
        { value: 1, label: "线上" },
        { value: 2, label: "线下" },
      ],
      seeWorkTitle:"",
      seeWorkContent:"",
      seeWorkEntityList:[],
      smallActiveName: "first",
      oneTooneData: [],
      oneToMoreData: [],
      total: 0,
      total2: 0,
      evaluationGood:[],
      evaluationTagNotGood:[],
      infoFormOrder:{

      },
      seeOrderPopup:false,
      sendJobPopup:false,
      sendJobTitle:"",
      sendJobContent:"",
      sendJobparams:{
        pageSize:100,
        pageNumber:1,
        itemId:""
      },
      workList:[],
      sendJobHomeworkList:[],
      saleParams: {
        roleSign: "studentAdmin",
        status: 1,
        roleSignAdmin:"studentAdminAdmin"
      },
      addressStatus:false,
      addressParams:{},
      makeUpStatus:false,
      makeInfo:{},
      makeType:{},
      addressData:""
    };
  },
  inject: ["timChat"],
  methods: {
    CB_makeStatus(){
      this.makeUpStatus=false;
      this.findProductItemPage();
    },
    CB_addressStatus(data){
      this.addressStatus=false;
      this.addressData=data;
    },
    selectAddressFn(data){
      this.addressParams=data;
      this.addressStatus=true;
    },
    makeUpClick(row){
      var endtime=Number(row.beginTime.split(' ')[1].split(':')[0]) +Number(row.usedClassHour);
      this.makeInfo=row;
      this.makeInfo.jiesuTime=row.beginTime+"-"+endtime+":"+row.beginTime.split(' ')[1].split(':')[1]
      this.makeType={
        title:"新增课程",
        type:"add"
      }
      this.makeUpStatus=true;
    },
    getSaleList() {
        listNoPage(this.saleParams).then(res => {
          this.saleList = res;
          this.saleList.unshift({
            userId: "",
            name:"全部",
            username: "全部"
          });
        });
      },
    yesHover(row){
      xmtProductItemInfo(row.supplementItemId).then(res => {
        if(res.code==0){
          this.infoShow=true;
          this.infoParams={
            beginTime:res.rows.beginTime,
            subjectName:res.rows.subjectName,
            teacherRealName:res.rows.teacherRealName,
            endTime:Number(res.rows.beginTime.split(' ')[1].split(':')[0]) +Number(res.rows.usedClassHour)+":"+res.rows.beginTime.split(' ')[1].split(':')[1]
          }
        }
      })
    },
    sendJob(row){
      this.sendJobTitle="";
      this.sendJobContent="";
      this.sendJobHomeworkList=[];
      this.sendJobPopup=true;
      this.sendJobparams.itemId=row.id;
      this.homeworkTitle="";
      this.homeworkContent="";
      this.xmtHomeworkEntityList=[];
      this.sendJobHomeworkList=[];
      this.productItemWorkFn();
    },
    productItemWorkFn(){
      productItemWorkList(this.sendJobparams).then((res) => {
          this.workList=res.rows;
   
      });
    },
    publishJob(){//发布作业接口
      if(this.sendJobTitle==""){
        this.msgWarn("作业标题不能为空！");
        return false;
      }
      let sendJobHomeworkList = [];
      this.sendJobHomeworkList.forEach((item) => {
        sendJobHomeworkList.push({
          url: item.url,
          name: item.name,
        });
      });
      let xmtHomeworkEntityList = {
        itemId: this.sendJobparams.itemId,
        homeworkTitle: this.sendJobTitle,
        homeworkContent: this.sendJobContent,
        xmtHomeworkEntityList:sendJobHomeworkList,
      };
      productItemWorkSave(xmtHomeworkEntityList)
        .then((res) => {
          if (res.code == 0&&res.msg=="操作成功！") {
            this.msgSuccess("操作成功！");
            this.productItemWorkFn();
            this.homeworkTitle="";
            this.homeworkContent="";
            this.sendJobTitle="";
            this.sendJobContent="";
            this.xmtHomeworkEntityList=[];

          }else{
            this.msgWarn(res.msg);
            
          }
        })
        .catch((err) => {
         
        });
    },
    workShowFn(){
      this.workShow=!this.workShow;
    },
    removeJobData(item){
      console.log(item)
      this.$confirm("是否删除该作业?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          productItemWorkRemove([item.id]).then((res) => {
            if (res.code == 0) {
              this.productItemWorkFn();
              this.msgSuccess("删除成功！");
            }
          });
        })
        .catch(() => {});
    },
     posterHtmlBg(){
      let subjectId=this.getParams.subjectId;
      if(subjectId==23){
        return this.posterHtmlBg1;
      }else if(subjectId==24||subjectId==87){
        return this.posterHtmlBg2;
      }else if(subjectId==101){
        return this.posterHtmlBg3;
      }else if(subjectId==100||subjectId==102){
        return this.posterHtmlBg4;
      }else if(subjectId==99){
        return this.posterHtmlBg5;
      }else if(subjectId==25){
        return this.posterHtmlBg6;
      }else if(subjectId==88){
        return this.posterHtmlBg7;
      }else {
        return this.posterHtmlBg8;
      }
    },
    createLink(){
      //生成二维码
       const text=`${this.$myStore.mobileType}`+'id='+`${this.getid}`+'&studentId='+`${this.studentId}`;
       const qrcodeImgEl = document.getElementById('qrcodeImg')
       qrcodeImgEl.innerHTML = ''
       let qrcode = new QRCode(qrcodeImgEl, {
        width: 194,
        height: 194,
        colorDark: '#000000',
        colorLight: '#ffffff',
        
        correctLevel: QRCode.CorrectLevel.H
      })
      qrcode.makeCode(text);
      // 生成海报
      const vm = this
      const domObj = document.getElementById('posterHtml');
      const scaleBy = window.devicePixelRatio;
      html2canvas(domObj, {
          useCORS: true,
          allowTaint: false,
          logging: false,
          letterRendering: true,
          scale: scaleBy,
          backgroundColor: null,
          onclone (doc) {
              let e = doc.querySelector('#posterHtml')
              e.style.display = 'block'
          }
      }).then(canvas => {
          // 在微信里,可长按保存或转发
          vm.posterImg = canvas.toDataURL('image/png',1.0)
          vm.imgDown(vm.posterImg)
          this.msgSuccess("海报已生成！");
      })
    },
     // 保存图片到本地
    imgDown (img) {
        var alink = document.createElement('a')
        alink.href = img
        alink.download = '课堂反馈'// 图片名
        alink.click()
    },
    // createLink(){
    //   var oInput = document.createElement('textarea');
    //   oInput.value = 'http://192.168.1.9:8080/#/login?id='+this.getid;
    //   document.body.appendChild(oInput);
    //   oInput.select(); // 选择对象
    //   document.execCommand("Copy"); // 执行浏览器复制命令
    //   oInput.className = 'oInput';
    //   oInput.style.display='none';
    // },
    seeWork(item){
      this.seeWorkPopup=true;
      productItemWorkSaveInfo(item.id).then((res) => {
        if (res.code == 0) {
          const {homeworkContent,homeworkTitle,xmtHomeworkEntityList} = res.rows
            this.seeWorkTitle=homeworkTitle;
            this.seeWorkContent = homeworkContent;
           this.seeWorkEntityList =xmtHomeworkEntityList?
          xmtHomeworkEntityList.map((item) => {
            let index = item.name.lastIndexOf(".");
            item.extname = item.name.substring(index + 1).toLocaleLowerCase();
            return item;
          }):[];
          
        }else if (typeof res.msg == "string") {
          this.msgWarn(`${res.msg}`);
          return;
        }
      });
    },
    afterClassRun(row) {
      if(row.classMode==1&&(row.attendClassStatus==0 || row.attendClassStatus==2)){//线上  未上课  不能点击
        this.msgWarn("只有课程结束才能进行课后反馈！");
        return false;
      } 
      this.evaluationGood=[];
      this.workList=[];
      this.examList = [];
      this.exanTitle = [];
      this.examRightList = [];
      this.selectPhaseId="";
      this.teaDetailedContent="";
      this.teaDetailedPictureList=[];
      this.teaEarnestId="";
      this.teaFinishId="";
      this.teaInteractionId="";
      this.teaUnderstandId="";
      this.homeworkTitle="";
      this.homeworkContent="";
      this.xmtHomeworkEntityList=[];
      this.selectKnoewledgeList = [];
      this.activeChapterId="";
      for(let i=0;i<this.knowledgeList.length;i++){
        this.knowledgeList[i].checked = false;
      }
        (this.activeName = "first");
      this.getid = row.id;
       this.getParams={
        studentId:row.studentId,
        subjectName:row.subjectName,
        itemId:row.id,
        sectionName:row.sectionName,
        beginTime:row.beginTime,
        sex:row.sex,
        constellationName:row.constellationName,
        studentName:row.studentName,
        classMode:row.classMode,
        subjectId:row.subjectId,
        title:row.title,
        teacherName:row.teacherName,
        birthday:row.birthday,
        teachManagerName:row.teachManagerName,
        usedClassHour:row.usedClassHour,
        endTime:Number(row.beginTime.split(' ')[1].split(':')[0])+Number(row.usedClassHour)+":"+row.beginTime.split(' ')[1].split(':')[1]
      }
      this.afterClassRunDialog = true;
      this.studentId = row.studentId;
      this.sendJobparams.itemId=row.id;
      this.productItemWorkFn();
      getInfoByItemId({itemId:row.id,studentId:row.studentId}).then((res) => {
        if (res.code == 0) {
          this.evaluationGood=res.rows.evaluationTag.split(',');
          // if(res.rows.xmtTeacherEvaluateLabelList){
          //   for(var i=0;i<res.rows.xmtTeacherEvaluateLabelList.length;i++){
          //     this.evaluationGood.push(res.rows.xmtTeacherEvaluateLabelList[i].evaluateLabelName)
          //   }
          // }
          this.infoFormOrder.evaluationContent=res.rows.evaluationContent;
        }
      });
      getXmtStudentAccessClassByItemId(row.id).then((res) => {
        if (res.code == 0) {
          this.accessStar = Number(res.rows.accessStar);
          this.accessContent = res.rows.accessContent;
        }
      });
    },
    imgSelectClick() {
      this.$refs.uploadImg.value = "";
      this.$refs.uploadImg.click();
    },
    imgSelectChange() {
      if (
        !/\.(png|jpg|gif|jpeg|webp)$/.test(this.$refs.uploadImg.files[0].name)
      ) {
        this.msgWarn("只能是图片格式的文件！");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.$refs.uploadImg.files[0]);
      fileUpload(formData).then((uploadRes) => {
        if (uploadRes[0].url) {
          let index = uploadRes[0].originalName.lastIndexOf(".");
          let extname = uploadRes[0].originalName.substring(index + 1);
          this.teaDetailedPictureList.push({
            name: uploadRes[0].originalName,
            url: uploadRes[0].url,
            extname,
          });
        }
      });
    },
    chapterClick(item) {
      if (item.id == this.activeChapterId) {
        return;
      }
      this.activeChapterId = item.id;
      this.getSubTree(this.activeChapterId);
    },
    async getSubTree(parentId) {
      let res;
      res = await knowledgeTree({
        parentId,
      });
      let list = res.rows;
      list.forEach((item) => {
        if (this.selectKnoewledgeList.includes(item.name)) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
      this.knowledgeList = res.rows;
    },
    deleteKnowledgeClick(name) {
      let index = this.selectKnoewledgeList.indexOf(name);
      this.selectKnoewledgeList.splice(index, 1);
      this.knowledgeList.forEach((item) => {
        if (item.name == name) {
          item.checked = false;
        }
      });
    },
    knowSelectChange() {
      this.knowledgeList.forEach((item) => {
        let index = this.selectKnoewledgeList.indexOf(item.name);
        if (index != -1 && !item.checked) {
          this.selectKnoewledgeList.splice(index, 1);
        } else if (index == -1 && item.checked) {
          this.selectKnoewledgeList.push(item.name);
        }
      });
    },
    evaluatedSizeChange(pageSize) {
      this.evaluationParams.pageSize = pageSize;
      this.teacherEvaluatedContentFn();
    },
    evaluatedCurrentChange(currentPage) {
      this.evaluationParams.pageNumber = currentPage;
      this.teacherEvaluatedContentFn();
    },
   async handleClick(tab, event) {
      if (tab.index == 1) {
       await this.getFeedbackDetail();
       this.getKonwledgeTree(0);
      }
    },
    async getKonwledgeTree(parentId) {
      console.log(this.getParams,'666666....')
      let phaseName = "";
      let { subjectName, sectionName, itemId} = this.getParams;
      this.subjectName = subjectName;
      if (sectionName.includes("初")) {
        phaseName = "初中";
      } else if (sectionName.includes("高")) {
        phaseName = "高中";
      } else {
        phaseName = "小学";
      }
      let phaseList = [],
        res,
        subjectList = [];
      res = await knowledgeTree({
        parentId,
      });
      this.phaseList = phaseList = res.rows;
      for (let i = 0, len = phaseList.length; i < len; i++) {
        if (phaseList[i].name == phaseName) {
          this.selectPhaseId = parentId = phaseList[i].id;
          break;
        }
      }
      res = await knowledgeTree({
        parentId,
      });
      subjectList = res.rows;
      for (let i = 0, len = subjectList.length; i < len; i++) {
        if (subjectList[i].name == subjectName) {
          parentId = subjectList[i].id;
          break;
        }
      }
      res = await knowledgeTree({
        parentId,
      });
      this.chapterList = res.rows;
    },
    attendClassClick(id) {
      this.examRightList = [];
      listSyncTree({ parentId: id }).then((res) => {
        if (res.code == 0) {
          for (var i = 0; i < res.rows.length; i++) {
            this.examRightList.push({
              examRightCheck: "",
              name: res.rows[i].name,
              parentId: res.rows[i].parentId,
            });
          }
        }
      });
    },
    dealAttendClassStatus(row) {
      if (row.attendClassStatus == 0) {
        return "未上课";
      } else if (row.attendClassStatus == 1) {
        return "已上课";
      } else if (row.attendClassStatus == 2) {
        return "上课中";
      } else {
        return "";
      }
    },
    lichange() {
      for (var i = 0; i < this.examRightList.length; i++) {
        if (this.examRightList[i].examRightCheck == true) {
          this.exanTitle.push(this.examRightList[i].name);
        }
      }
    },
    classEvaluation(row) {
      this.evaluationParams.itemId = row.id;
      this.evaluationPopup = true;
      this.teacherEvaluatedContentFn();
    },
    teacherEvaluatedContentFn() {
      teacherEvaluatedContent(this.evaluationParams).then((res) => {
        this.evaluationList = res.rows;
        this.evaluationTotal = res.total;
      });
    },
    sendLearnReport() {
      if (!this.mainGroupId) {
        this.msgWarn("主群还未创建不能发送课堂反馈！");
      }
      const message = this.timChat.tim.createCustomMessage({
        to: this.mainGroupId,
        conversationType: TIM.TYPES.CONV_GROUP,
        payload: {
          data: "learn-report",
          description: JSON.stringify(this.chatParams),
          extension: "",
        },
      });
      this.timChat.tim
        .sendMessage(message)
        .then(() => {
          this.msgSuccess("课堂反馈消息发送成功！");
          console.log("课堂反馈消息发送成功！");
        })
        .catch((error) => {
          setTimeout(() => {
            this.msgError('课堂反馈消息发送失败！');
          }, 6000)
        });
    },
     imgItemDelClick(index) {
      this.teaDetailedPictureList.splice(index, 1);
    },
    getFeedbackDetail() {
      productItemLearnReportInfo(this.getid).then((res) => {
        if (res.code == 0) {
          const {beginTime,teacherName,usedClassHour,photo,id,status,xmtProductItemWorkEntity,teaEarnestId,teaFinishId,teaInteractionId,teaUnderstandId,teaDetailedPictureList,teaDetailedContent,sectionName, studentName, itemId, subjectName, mainGroupId,sexName,birthday,constellationName,teaContent} = res.rows
            this.mainGroupId = mainGroupId
            this.chatParams = {
                sectionName,
                studentName,
                itemId,
                subjectName,
            }
            this.studentParams ={
              studentName,
              sexName,
              sectionName,
              birthday,
              constellationName,
              itemId,
              photo,
              beginTime,
              teacherName,
              usedClassHour,
              subjectName,
              teaDetailedContent

            }
            this.teaContent = teaContent;
            this.status = status;
            this.id = id;
            this.teaDetailedPictureList = teaDetailedPictureList?teaDetailedPictureList.map((item) => {
              let index = item.name.lastIndexOf(".");
              item.extname = item.name.substring(index + 1);
              return item;
            }):[]
            this.teaDetailedContent = teaDetailedContent?teaDetailedContent:'';
            if (teaContent) {
              this.selectKnoewledgeList = teaContent.split(";");
            } else {
              this.selectKnoewledgeList = [];
            }
          this.teaEarnestId = teaEarnestId;
          this.teaFinishId = teaFinishId;
          this.teaInteractionId = teaInteractionId;
          this.teaUnderstandId = teaUnderstandId;
          if(JSON.stringify(this.teaEarnestList).indexOf(teaEarnestId)!=-1){
            this.teaEarnestList.map((value,i)=>{
              if(teaEarnestId==value.id){
                this.speedArray[0]={num:value.num,name:value.name,tit:'1.学生在本堂课听课认真程度',status:'success'}
              }
            })
          }else{
            this.speedArray[0]={num:0,name:'',tit:'1.学生在本堂课听课认真程度',status:'success'}
          }
          if(JSON.stringify(this.teaFinishList).indexOf(teaFinishId)!=-1){
            this.teaFinishList.map((value,i)=>{
              if(teaFinishId==value.id){
                this.speedArray[1]={num:value.num,name:value.name,tit:"2.上次课作业的完成程度",status:'warning'}
              }
            })
          }else{
            this.speedArray[1]={num:0,name:'',tit:"2.上次课作业的完成程度",status:'warning'}
          }
          if(JSON.stringify(this.teaInteractionList).indexOf(teaInteractionId)!=-1){
            this.teaInteractionList.map((value,i)=>{
              if(teaInteractionId==value.id){
                this.speedArray[2]={num:value.num,name:value.name,tit:"3.学生与老师的互动情况",status:'exception'}
              }
            })
          }else{
            this.speedArray[2]={num:0,name:'',tit:"3.学生与老师的互动情况",status:'exception'}
          }
          if(JSON.stringify(this.teaUnderstandList).indexOf(teaUnderstandId)!=-1){
            this.teaUnderstandList.map((value,i)=>{
              if(teaUnderstandId==value.id){
                this.speedArray[3]={num:value.num,name:value.name,tit:"4.学生对本堂课的掌握程度"}
              }
            })
          }else{
            this.speedArray[3]={num:0,name:'',tit:"4.学生对本堂课的掌握程度"}
          }
        }else if (typeof res.msg == "string") {
          this.msgWarn(`${res.msg}`);
          return;
        }
      });
    },
    async phaseSelectChange() {
      let subjectList = [],
        res,
        parentId;
      res = await knowledgeTree({
        parentId: this.selectPhaseId,
      });
      subjectList = res.rows;
      for (let i = 0, len = subjectList.length; i < len; i++) {
        if (subjectList[i].name == this.subjectName) {
          parentId = subjectList[i].id;
          break;
        }
      }
      res = await knowledgeTree({
        parentId,
      });
      this.chapterList = res.rows;
      this.activeChapterId = null;
      this.knowledgeList = [];
    },
    btnClick(){
      this.$refs.fileUpload.click();
      this.$refs.fileUpload.value = "";
    },
    sendJobBtnClick(){
      this.$refs.sendJobUpload.click();
      this.$refs.sendJobUpload.value = "";
    },
    async fileUploadChange() {
      let file = this.$refs.fileUpload.files[0];
      let index = file.name.lastIndexOf(".");
      let extname = file.name.substring(index + 1);
      let data = {
        fileName: file.name,
        extname,
        fileProcess: 0,
        showProcess: true,
        size: computeFileSize(file.size),
        time: new Date().getTime(),
        error: null,
        cancel: null,
      };
      this.uploadListParams.show = true;
      this.uploadListParams.list.push(data);
      let formData = new FormData();
      formData.append("file", file);
      uploadFileTask(
        fileUploadUrl,
        formData,
        (process) => {
          data.fileProcess = process;
          if (data.fileProcess == 100) {
            setTimeout(() => {
              data.fileProcess = "处理中";
            }, 300);
          }
        },
        (cancel) => {
          data.cancel = cancel;
        }
      )
        .then(({ data: uploadRes }) => {
          data.showProcess = false;
          if (this.uploadListParams.list.indexOf(data) != -1) {
            this.uploadListParams.list.splice(
              this.uploadListParams.list.indexOf(data),
              1
            );
            if (this.uploadListParams.list.length == 0) {
              this.uploadListParams.show = false;
            }
          }
          if (uploadRes[0].url) {
            let index = uploadRes[0].originalName.lastIndexOf(".");
            this.xmtHomeworkEntityList.push({
              url: uploadRes[0].url,
              name: uploadRes[0].originalName,
              fileSource: 3,
              extname: uploadRes[0].originalName
                .substring(index + 1)
                .toLocaleLowerCase(),
              createUserId: Cookies.get("sysChatId").split("sys_chat_")[1],
            });
          }
        })
        .catch((err) => {
          data.showProcess = false;
        });
    },
    async sendJobfileChange() {
      let file = this.$refs.sendJobUpload.files[0];
      let index = file.name.lastIndexOf(".");
      let extname = file.name.substring(index + 1);
      let data = {
        fileName: file.name,
        extname,
        fileProcess: 0,
        showProcess: true,
        size: computeFileSize(file.size),
        time: new Date().getTime(),
        error: null,
        cancel: null,
      };
      this.sendJobListParams.show = true;
      this.sendJobListParams.list.push(data);
      let formData = new FormData();
      formData.append("file", file);
      uploadFileTask(
        fileUploadUrl,
        formData,
        (process) => {
          data.fileProcess = process;
          if (data.fileProcess == 100) {
            setTimeout(() => {
              data.fileProcess = "处理中";
            }, 300);
          }
        },
        (cancel) => {
          data.cancel = cancel;
        }
      )
        .then(({ data: uploadRes }) => {
          data.showProcess = false;
          if (this.sendJobListParams.list.indexOf(data) != -1) {
            this.sendJobListParams.list.splice(
              this.sendJobListParams.list.indexOf(data),
              1
            );
            if (this.sendJobListParams.list.length == 0) {
              this.sendJobListParams.show = false;
            }
          }
          if (uploadRes[0].url) {
            let index = uploadRes[0].originalName.lastIndexOf(".");
            this.sendJobHomeworkList.push({
              url: uploadRes[0].url,
              name: uploadRes[0].originalName,
              extname: uploadRes[0].originalName
                .substring(index + 1)
                .toLocaleLowerCase(),
            });
          }
        })
        .catch((err) => {
          data.showProcess = false;
        });
    },
    imgItemSeeClick(item, index){
      if (
        ["doc", "docx", "ppt", "pptx", "xlsx", "xls"].includes(
          item.extname.toLowerCase()
        )
      ) {
        this.dialogFileViewPrivateInfo = {
          show: true,
          type: "file",
          src: `https://view.officeapps.live.com/op/view.aspx?src=${
            item.fileUrl ? item.fileUrl : item.url
          }`,
        };
      } else if (["pdf"].includes(item.extname.toLowerCase())) {
        this.dialogFileViewPrivateInfo = {
          show: true,
          type: "file",
          src: `${this.webUrl}/pdf/web/viewer.html?file=${
            item.fileUrl ? item.fileUrl : item.url
          }`,
        };
      } else if (
        ["png", "jpg", "bmp", "jpeg", "gif"].includes(
          item.extname.toLowerCase()
        )
      ) {
        this.dialogFileViewPrivateInfo = {
          show: true,
          type: "img",
          src: item.fileUrl ? item.fileUrl : item.url,
          curIndex: index ? index : 0,
          imgList:
            index != undefined
              ? this.teaDetailedPictureList.map((item) => {
                  return item.fileUrl ? item.fileUrl : item.url;
                })
              : [item.fileUrl ? item.fileUrl : item.url],
        };
      } else {
        this.msgWarn("该文件格式不支持预览！");
        return;
      }
    },
    delListItem(index){
      this.xmtHomeworkEntityList.splice(index, 1);
    },
    closeFileView() {
      this.dialogFileViewPrivateInfo.show = false;
    },
    datePickerChange() {
      if (!this.timePickerValue) {
        this.listParams.searchBeginDate = null;
        this.listParams.searchEndDate = null;
        return;
      }
      this.listParams.searchBeginDate = this.timePickerValue[0];
      this.listParams.searchEndDate = this.timePickerValue[1];
    },
   
    //获取年级
    getSectionIdList() {
      getSelectList({
        parentCode: sectionCode,
      }).then((res) => {
        if (res.code == 0) {
          this.sectionList = res.rows;
          this.sectionList.unshift({
            macroId: "",
            name: "全部",
          });
        }
      });
    },
    //获取学科
    getSubjectList() {
      getSelectList({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          this.subjectIdList = res.rows;
          this.subjectIdList.unshift({
            macroId: "",
            name: "全部",
          });
        }
      });
    },
    sureClass(num, row) {
      let curTime = new Date(row.beginTime);
      let addHour = curTime.setHours(
        curTime.getHours() + Number(row.usedClassHour)
      );
      let newDate = Date.parse(new Date()); //现在的时间
      if (newDate < addHour) {
        this.msgWarn("请在课程结束后确认课时！");
        return;
      }
      if (num == 1) {
        //确认课时
        confirmClassHour({
          id: row.id,
          usedStatus: "1",
          attendClassStatus: "1",
        }).then((res) => {
          this.findProductItemPage();
          if (res.code == 0) {
          }
        });
      } else {
      }
    },
    isAbnormal(num, row) {
      if (num == 1) {
        //设为异常
        setAbnormalStatus({ id: row.id, abnormalStatus: "1" }).then((res) => {
          if (res.code == 0) {
            this.findProductItemPage();
            this.msgSuccess("操作成功！");
          }
        });
      } else {
        setAbnormalStatus({ id: row.id, abnormalStatus: "0" }).then((res) => {
          if (res.code == 0) {
            this.findProductItemPage();
            this.msgSuccess("操作成功！");
          }
        });
      }
    },
   
    confirmSaveOrder(type) {
      switch (type) {
        case "publish":
          this.status = 2;
          this.saveFeedback();
          break;
        case "save":
          this.status = 1;
          this.saveFeedback();
          break;
      }
    },
    imageFn(){
      var subjectId=this.getParams.subjectId;
      let str="";
      if(subjectId==23){ str=this.yuwenImg}
      if(subjectId==24){ str=this.shuxueImg}
      if(subjectId==25){ str=this.yingyuImg}
      if(subjectId==101){ str=this.wuliImg}
      if(subjectId==102){ str=this.huaxueImg}
      if(subjectId==99){ str=this.zhengzhiImg}
      // if(subjectId==100){ str=this.zhengzhiImg}
      if(subjectId==87){ str=this.zhengzhiImg}
      if(subjectId==88){ str=this.lishiImg}
     return str;
    },
    homeworkImage(img){
      var str=""
      if(img=='jpg'||img=='jpeg'||img=='png'||img=='bmp'){
        str=this.img2
      }else if(img=='doc'||img=='docx'){
        str=this.word
      }else if(img=='ppt'||img=='pptx'){
        str=this.ppt
      }else if(img=='pdf'){
        str=this.pdf
      }else if(img=='xlsx'||img=='xls'){
        str=this.excel
      }else if(img=='txt'){
        str=this.txt
      }
      else{
        str=this.unknown
      }
      return str;
    },
    confirmSeeOrder(){
      this.seeOrderPopup=true;
      for(var i=0;i<this.workList.length;i++){
        for(var k=0;k<this.workList[i].xmtHomeworkEntityList.length;k++){
          let index = this.workList[i].xmtHomeworkEntityList[k].name.lastIndexOf(".");
          this.workList[i].xmtHomeworkEntityList[k].extname=this.workList[i].xmtHomeworkEntityList[k].name.substring(index + 1).toLocaleLowerCase();
        }
      }
      console.log(this.workList)
    },
    saveFeedback() {
      this.saveFlag = true;
      this.teaContent = this.selectKnoewledgeList.join(";");
      let xmtHomeworkEntityList = [];
      this.xmtHomeworkEntityList.forEach((item) => {
        xmtHomeworkEntityList.push({
          url: item.url,
          name: item.name,
          fileSource: item.fileSource,
          createUserId: item.createUserId,
        });
      });
      if(this.workShow){
        var  xmtProductItemWorkEntity = {
          id: "",
          homeworkTitle: this.homeworkTitle,
          homeworkContent: this.homeworkContent,
          xmtHomeworkEntityList,
        };
      }else{
          var xmtProductItemWorkEntity=null;
      }
         
      // 作业可以不布置。都为空，但是布置作业，作业标题是必传
      if (this.homeworkContent === null) {
        this.homeworkContent = "";
      }
      if (this.homeworkTitle === null) {
        this.homeworkTitle = "";
      }
      if (
        (this.homeworkContent.trim() || xmtHomeworkEntityList.length > 0) &&
        !this.homeworkTitle.trim()
      ) {
        this.msgWarn("作业标题不能为空！");
        this.saveFlag = false;
        return;
      }
      if (
        !this.teaInteractionId &&
        !this.teaUnderstandId &&
        !this.teaFinishId &&
        !this.teaEarnestId &&
        this.selectKnoewledgeList.length == 0 &&
        !this.teaDetailedContent
      ) {
        this.msgWarn("不能保存空的课堂评价！");
        this.saveFlag = false;
        return;
      }
      let teaDetailedPicture = [];
      this.teaDetailedPictureList.forEach((item) => {
        teaDetailedPicture.push({
          name: item.name,
          url: item.url,
        });
      });
      updateLearnReport({
        id: this.id,
        itemId: this.getParams.itemId,
        teaContent: this.teaContent,
        teaDetailedContent: this.teaDetailedContent,
        teaEarnestId: this.teaEarnestId,
        teaFinishId: this.teaFinishId,
        teaUnderstandId: this.teaUnderstandId,
        teaInteractionId: this.teaInteractionId,
        xmtProductItemWorkEntity, //布置作业先不用，不删除，防止以后用
        status: this.status,
        teaDetailedPicture,
        classMode:this.getParams.classMode
      })
        .then((res) => {
          if (res.code == 0&&res.msg=="操作成功！") {
            this.msgSuccess("反馈成功！");
            this.productItemWorkFn();
            this.homeworkTitle="";
            this.homeworkContent="";
            this.xmtHomeworkEntityList=[];
            // this.afterClassRunDialog = false;
            if(this.status == 2){
              this.sendLearnReport()
            }
          }else{
            this.msgWarn(res.rows);
            
          }
        })
        .catch((err) => {
          // this.afterClassRunDialog = false;
        });
    },
    coachAgreementSuccess(response, file, fileList) {
      if (response[0].url) {
        this.saveRuleForm.xmtProductItemWorkEntity.url = response[0].url;
      }
    },
    coachAgreementError(err, file, fileList) {
      this.msgError("辅导协议上传失败！");
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.findProductItemPage();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.findProductItemPage();
    },
  
    findProductItemPage() {
        this.listParams.orderType = "1";
        findProductItemPage(this.listParams).then((res) => {
          this.oneTooneData = res.rows;
          this.total = res.total;
        });
    },
  },
  mounted() {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    if (month < 10) {
        month = "0" + month;
    }
    if (day < 10) {
        day = "0" + day;
    }
    this.timePickerValue=[year + "-" + month + "-" + day+' 00:00:00',year + "-" + month + "-" + day+' 23:59:59'];
    this.timePickerValue2=[year + "-" + month + "-" + day+' 00:00:00',year + "-" + month + "-" + day+' 23:59:59']
    this.listParams.searchBeginDate = this.timePickerValue[0];
    this.listParams.searchEndDate = this.timePickerValue[1];
    this.getSectionIdList(); //获取搜索年级
    this.findProductItemPage();
    this.getSubjectList();
    this.getSaleList();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }

  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .classInfo_ul {
    display: flex;
    flex-wrap: wrap;
  }
  .classInfo_ul li {
    width: 25%;
    margin-top: 20px;
    font-size: 16px;
  }
  .subject_ul {
    display: flex;
    flex-wrap: wrap;
  }
  .subject_ul li {
    text-align: center;
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    margin-top: 10px;
  }
  .feedback_ul {
    display: flex;
    margin-top: 20px;
    li {
      flex: 1;
      text-align: center;
    }
  }
  .attendClass {
    display: flex;
    .left {
    }
    .right {
      margin-left: 40px;
      border-left: 1px solid #dcdfe6;
      padding-left: 30px;
      li {
        text-align: left;
      }
    }
  }
  .cousre_info {
        background-color: #ffffff;
        padding: 10px 20px 20px;
        .part_content {
          border: 1px solid #dddddd;
          padding: 10px;
          .phase_area {
            display: flex;
            align-items: center;
            .p_label {
              font-weight: bolder;
            }
            .p_select {
              width: 180px;
            }
          }
          .chapter_container {
            display: flex;
            .chapter_label {
              line-height: 32px;
              font-weight: bold;
            }
            .chapter_list {
              flex: 1;
              .chapter_item {
                cursor: pointer;
                font-size: 14px;
                line-height: 32px;
                margin-right: 20px;
              }
              .active_chapter {
                color: #1890ff;
                font-weight: bold;
              }
            }
          }
          .section_container {
            margin-top: 10px;
            display: flex;
            .section_label {
              line-height: 32px;
              font-weight: bold;
            }
            .section_list {
              flex: 1;
              .section_item {
                line-height: 32px;
                margin-right: 20px;
              }
            }
          }
          .select_knowledge {
            margin-top: 10px;
            display: flex;
            .select_label {
              line-height: 40px;
              color: #888888;
            }
            .select_list {
              flex: 1;
              line-height: 40px;
              .select_item {
                margin-left: 20px;
                padding: 0px 8px;
                height: 30px;
                line-height: 26px;
                border: 1px solid #1890ff;
                font-size: 12px;
                display: inline-block;
                .select_item_text {
                  display: inline-block;
                }
                .icon_del {
                  margin-left: 10px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .part_text {
        height: 50px;
        font-weight: bold;
        font-size: 18px;
        color: #2f271d;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .sub_title {
          font-size: 18px;
        }
      }
      .detail_content {
        padding: 20px;
        background-color: #ffffff;
        .detail_content_title {
          margin-top: 10px;
          line-height: 30px;
          font-weight: 400;
        }
        .detail_content_editer {
          margin-top: 10px;
        }
      }
      .img_list {
        width: 100%;
        min-height: 120px;
        overflow: auto;
        .img_item {
          position: relative;
          margin: 10px 10px 10px 0px;
          float: left;
          cursor: pointer;
          height: 100px;
          width: 100px;
          border: 1px solid #dddddd;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          .img_hover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
            z-index: 6;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.7);
            .icon_del {
              margin-left: 10px;
              cursor: pointer;
              color: #ffffff;
            }
            .icon_preview {
              cursor: pointer;
              color: #ffffff;
              font-size: 14px;
            }
          }
          &:hover {
            .img_hover {
              display: flex;
            }
          }
          img {
            width: 100px;
            height: 100px;
          }
          .icon_new {
            font-size: 40px;
          }
        }
      }
      .course_evaluate {
        background-color: #ffffff;
        padding: 20px;
        .course_evaluate_item {
          .evaluate_title {
            line-height: 40px;
            font-size: 16px;
            font-weight: 400;
          }
          .evaluate_option {
            line-height: 40px;
            .evaluate_option_item {
              line-height: 40px;
            }
          }
        }
      }
      .homework {
        background-color: #ffffff;
        padding: 20px;
        .homework_container {
          .homework_item {
            display: flex;
            margin-top: 20px;
            .homewok_item_label {
              margin-right: 20px;
              line-height: 40px;
            }
            .homework_item_value {
              flex: 1;
              display: flex;
              align-items: center;
              .file_btn {
                cursor: pointer;
                border: 1px solid #dddddd;
                border-radius: 5px;
                padding: 6px 12px;
                margin-right: 20px;
                .iconfont {
                  color: #024df8;
                  margin-right: 6px;
                }
              }
            }
          }
        }
      }
      .homework_select {
        padding: 20px 0;
        .file_list {
          .file_item {
            padding: 4px 10px;
            line-height: 32px;
            display: inline-block;
            word-break: keep-all;
            box-shadow: 0px 0px 10px 0px rgba(34, 34, 34, 0.15);
            margin-right: 28px;
            margin-bottom: 10px;
            cursor: pointer;
            .file_icon {
              display: inline-block;
              height: 16px;
              margin-right: 10px;
            }
            .unknown {
              font-size: 20px;
              position: relative;
              top: 14px;
            }
            .file_name {
              color: #222222;
            }
            .file_del {
              font-size: 14px;
              margin-left: 20px;
              cursor: pointer;
              color: #666666;
            }
          }
        }
      }
}
.sendJob_ul{
  width:100%;
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
  margin-top:10px;
  li{
    width:48%;
    height:70px;
    // border:1px solid #ccc;
    border-radius:6px;
    margin-top:15px;
    padding:12px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    box-shadow:0 0 4px 2px #ccc;
    div:nth-child(1){
      width:75%;
      p:nth-child(1){
        font-size:16px;
        color:#000;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      p:nth-child(2){
        margin-top:12px;
        font-size:13px;
      }
    }
    div:nth-child(2){
      flex:1;
      text-align: right;
      span{
        cursor:pointer;
      }
      span:nth-child(2){
        margin-left:6px;
        font-size:12px;
      }
    }
  }
}
.workDiv{
  .homework_item_value {
              
              .file_btn {
                cursor: pointer;
                border: 1px solid #dddddd;
                border-radius: 5px;
                padding: 6px 12px;
                margin-right: 20px;
                .iconfont {
                  color: #024df8;
                  margin-right: 6px;
                }
              }
            }
            .homework_item {
            display: flex;
            .homewok_item_label {
              margin-right: 20px;
              line-height: 40px;
            }
            .homework_item_value {
              flex: 1;
              display: flex;
              align-items: center;
              .file_btn {
                cursor: pointer;
                border: 1px solid #dddddd;
                border-radius: 5px;
                padding: 6px 12px;
                margin-right: 20px;
                .iconfont {
                  color: #024df8;
                  margin-right: 6px;
                }
              }
            }
          }
  .homework_select {
        padding: 20px 0;
        .file_list {
          .file_item {
            padding: 4px 10px;
            line-height: 32px;
            display: inline-block;
            word-break: keep-all;
            box-shadow: 0px 0px 10px 0px rgba(34, 34, 34, 0.15);
            margin-right: 28px;
            margin-bottom: 10px;
            cursor: pointer;
            .file_icon {
              display: inline-block;
              height: 16px;
              margin-right: 10px;
            }
            .unknown {
              font-size: 20px;
              position: relative;
              top: 14px;
            }
            .file_name {
              color: #222222;
            }
            .file_del {
              font-size: 14px;
              margin-left: 20px;
              cursor: pointer;
              color: #666666;
            }
          }
        }
      }
}
.feedback_dl{
  height:115px;
  padding:15px 20px;
  display:flex;
  dt{
    width:88px;
    height:88px;
    background:yellow;
    border-radius:50%;
  }
  dd{
    margin-left:20px;
    margin-top:15px;
    p:nth-child(1){
      font-size:18px;
      color:#222;
    }
    p:nth-child(2){
      font-size:14px;
      color:#787878;
      margin-top:15px;
      span{
        margin-right:10px;
      }
    }
  }
}
.course_news{
  height:auto;
  border-top:12px solid #eee;
  padding:20px;
  p{
    display: flex;
    align-items: center;
    span:nth-child(1){
      background:#EB5D57;
      width:4px;
      height:20px;
      display: inline-block;
    }
    span:nth-child(2){
      font-size:16px;
      display: inline-block;
      margin-left:15px;
      color:#222;
      font-weight: bold;

    }
  }
  ul{
    li{
      margin-top:15px;
      display: flex;
      span:nth-child(1){
        color:#222;
        width:15%;
      }
      span:nth-child(2){
        color:#787878;
        margin-left:20px;
      }
    }
  }
}
.class_news{
   padding:20px;
  >p{
    display: flex;
    align-items: center;
    span:nth-child(1){
      background:#EB5D57;
      width:4px;
      height:20px;
      display: inline-block;
    }
    span:nth-child(2){
      font-size:16px;
      display: inline-block;
      margin-left:15px;
      color:#222;
      font-weight: bold;

    }
  }
  div{
    p:nth-child(1),p:nth-child(3){
      color:#787878;
      font-size:14px;
      font-weight: 500;
      margin-top:30px;
    }
    p:nth-child(2){
      margin-top:10px;
    }
    span{
      color:#476F9B;
      margin-right:15px;
    }
    dl{
      margin-top:10px;
      dt{
        color:#222;
        line-height:1.6;
      }
    }
    
  }

}
.class_assess{
  padding:20px;
  >p{
    display: flex;
    align-items: center;
    span:nth-child(1){
      background:#EB5D57;
      width:4px;
      height:20px;
      display: inline-block;
    }
    span:nth-child(2){
      font-size:16px;
      display: inline-block;
      margin-left:15px;
      color:#222;
      font-weight: bold;

    }
  }
  div{
    p{
      color:#222;
    }
    p:nth-child(1){
      font-size:16px;
      margin-top:30px;
    }
    p:nth-child(2){
      margin-top:15px;
    }
  }

}
.work_content{
  padding:20px;
  >p{
    display: flex;
    align-items: center;
    span:nth-child(1){
      background:#EB5D57;
      width:4px;
      height:20px;
      display: inline-block;
    }
    span:nth-child(2){
      font-size:16px;
      display: inline-block;
      margin-left:15px;
      color:#222;
      font-weight: bold;

    }
  }
  dl{
    display:flex;
    align-items: center;
    border-bottom:1px solid #E0E0E0;
    margin-top:20px;
    padding-bottom:20px;
    dd{
      margin-left:20px;
      p:nth-child(1){
        font-size:16px;
        color:#222;
      }
      p:nth-child(2){
        color:#787878;
        margin-top:10px;
      }
    }
  }
}
.work_div{
  margin-top:15px;
  border-bottom:1px solid #ddd;
  padding-bottom:20px;
  p:nth-child(1){
    color:#222;
    font-size:16px;
    font-weight:500;
  }
  p:nth-child(2){
    color:#777;
    font-size:14px;
    margin-top:6px;
  }
  p:nth-child(3){
    color:#787878;
    font-size:14px;
    margin-top:6px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  div{
    margin-top:10px;
    p{
      display:flex;
      align-items: center;
      justify-content: center;
      background:#f5f5f5;
      border-radius:5px;
    }
    span{
      width:100%;
      display: inline-block;
      margin-top:5px;
      color:#222;
      height:50px;
      line-height:50px;
      padding-left:10px;
      font-size:14px;
    }
  }
}
#posterHtml{
position: relative;
width: 750px;
height: 1334px;
}
.qrcode{
  width:194px;
  height:200px;
  margin-bottom:210px;
  position: absolute;
  left:50%;
  bottom:0px;
  margin-left:-97px;
}
.posterInfo{
  margin-bottom:40px;
  position: absolute;
  left:50%;
  top:50%;
  width:560px;
  height:180px;
  margin-left:-280px;
  margin-top:-50px;
  >p{
    color:#000;
    font-weight: bold;
    font-size:50px;
    text-align:center;
  }
  div{
    margin-top:45px;
    width:100%;
    height:100%;
    background: #F5F5F5;
    padding:23px 0 39px 40px;
    p{
      color: #666;
      font-size:26px;
      font-weight: 500;
      height:35px;
      line-height:35px;
    }
  }
}
</style>