<template>
    <div class="main-content sys-user-manage">
        <div class="btn-area">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="输入学生姓名"
            size="small"
            style="min-width:120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
                <span class="item-lable">申请状态：</span>
                <el-select
                    v-model="listParams.teacherApproval"
                    placeholder="请选择"
                    size="small"
                >
                    <el-option
                    v-for="item in teacherApprovalList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    ></el-option>
                </el-select>
            </div>
        <div class="select-condition-item">
          <span class="item-lable">提交时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <el-button type="primary" size="small" icon="el-icon-search"
          @click="()=>(listParams.pageNumber=1,studentRefundListFn())"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="tableList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
      >
       <el-table-column
          type="index"
          label="序号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="学生姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createName"
          label="提交人"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{scope.row.createUserName}}（{{scope.row.createName}}）</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="提交时间"
          align="center"
        ></el-table-column>
        <el-table-column
          label="申请状态"
          align="center"
          :formatter="teacherApprovalFor"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-show="scope.row.teacherApproval==0"
              @click="refundDetail(scope.row,1)"
              >审核</el-button
            >
             <el-popover
    placement="top-start"
    title="驳回原因"
    width="200"
    trigger="hover"
    :content="scope.row.teacherApprovalReason">

    <el-button
    slot="reference"
              type="text"
              size="small"
               v-show="scope.row.teacherApproval==2"
            
              >驳回原因</el-button
            >
  </el-popover>

             <el-button
              type="text"
              size="small"
               v-show="scope.row.teacherApproval==1||scope.row.teacherApproval==2"
              @click="refundDetail(scope.row,2)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="changeSizeChange"
        @current-change="changeCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      title="退费详情"
      :visible.sync="refundSync"
      width="880px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content studentInfo">
          <h2>学员信息</h2>
          <ul class="studentUl">
            <li><span>学生姓名：</span> {{studentDetailList.name?studentDetailList.name:'暂无数据'}}</li>
            <li><span>性别：</span> {{studentSex(studentDetailList.sex?studentDetailList.sex:'暂无数据')}}</li>
            <li><span>年级：</span> {{studentDetailList.sectionName?studentDetailList.sectionName:'暂无数据'}}</li>
            <li><span>出生日期：</span> {{studentDetailList.birthday?studentDetailList.birthday:'暂无数据'}}</li>
            <li><span>星座：</span> {{studentDetailList.constellationName?studentDetailList.constellationName:'暂无数据'}}</li>
          </ul>
          <div class="orderDiv">
            <!-- <h2>订单信息</h2> -->
            <ul v-for="(item, indexs) in orderdDetail" :key="indexs" style="margin-top:20px;">
              <li style="font-size:16px;color:#000;"><h2>订单信息{{indexs+1}}</h2></li>
              <li><span>产品名称：</span>{{item.productName}}</li>
              <li><span>总课时：</span>{{item.classHour?item.classHour:item.zdyClassHour}}课时</li>
              <li><span>总金额：</span> {{item.totalPrice?item.totalPrice:item.zdyActualPrice}}元</li>
              <li><span>下单时间：</span> {{item.createTime}}</li>
              <li><span>订单编号：</span> {{item.orderNo}}</li>
              <ul v-for="(items, indexss) in item.xmtStudentRefundInfoChildList" :key="indexss">
                <li><span>课程级别：</span> {{courseLevelFn(items.courseLevel)}}</li>
                <li><span>已上课时数：</span> {{items.countUsedClassHour?items.countUsedClassHour:0}}</li>
                <li><span>课时单价：</span> {{items.classSinglePrice}}元</li>
                <li><span>课消金额：</span> {{classMoney(items.countUsedClassHour,items.classSinglePrice)}}元</li>
              </ul>
            </ul>
            <div class="infoDiv">
              <p style="margin-left:150px;color:red;font-size:16px;">总计交费金额：{{orderMoney}}元</p>
              <p ><span>实际消耗金额：</span> {{getRealityUsePrice}}元</p>
              <p><span>应退款金额：</span> {{getRefundAmount}}元</p>
              <p><span>实际退费金额：</span> {{getRealityRefundAmount}}元</p>
              <p><span>备注：</span> {{getBackReason}}</p>
              <p><span>收款姓名：</span> {{getBankUserName}}</p>
              <p><span>收款账号：</span> {{getBankAccount}}</p>
              <p><span>开户支行：</span> {{getBankAddress}}</p>
              <p><span>联系方式：</span> {{getBankUserPhone}}</p>
              <p><span>提交人：</span> {{getCreateName}}</p>
              <p><span>提交时间：</span> {{getCreateTime}}</p>
            </div>
          </div>
      </div>
       <span slot="footer" class="dialog-footer" v-show="isTeacherApproval==0">
         <el-button
          type="primary"
          size="small"
          @click="studentRefundUpdate(1)"
          >同 意</el-button
        >
        <el-button @click="studentRefundUpdate(2)" size="small">驳 回</el-button>
      </span>
    </el-dialog>
    <refundDialog ref="refundDialog" :visibleNum="visibleNum" :visibleId="visibleId" :page="2" @close="Visible=false" :visibleStudentId="visibleStudentId" v-if="Visible" :visible="Visible"></refundDialog>
    </div>
</template>
<script>
import dayjs from "dayjs";
import refundDialog from '../../finance/dialog/refund-dialog.vue'
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  followTags,
  fileUpload,
  reg_Num,
} from "@/api/public";
import {
  studentRefundList,
  studentRefundInfo,
  studentRefundUpdate
} from "@/api/administration/tubeTeachBusiness/studentManage.js";
import { studentInfo } from "@/api/sales/saleManage";
    export default{
        components:{
          refundDialog
        },
        data(){
            return{
              visibleId:'',
              visibleStudentId:'',
              Visible:false,
              isTeacherApproval:"",
              orderMoney:0,
              getRealityUsePrice:"",//实际消耗金额
              getRefundAmount:"",//应退款金额
              getRealityRefundAmount:"",//实际退费金额
              getBackReason:"",//备注
              getBankUserName:"",//收款人姓名
              getBankAccount:"",//收款人账号
              getBankAddress:"",//开户支行
              getCreateName:"",//提交人
              getCreateTime:"",//提交时间
              getBankUserPhone:"",
              studentDetailList:{},
              visibleNum:'',
                getId:"",
                refundSync:false,
                tableList:[],
                total:0,
                orderdDetail:[],
                timePickerValue:"",
                listParams:{
                  name:"",
                  pageNumber:1,
                  pageSize:10,
                  teacherApproval:"",
                  searchBeginTime:null,
                  searchEndTime:null,
                  dataAuthority:'dept', 
                   ifTeacher:'N'
                },
                teacherApprovalList:[
                  {value:'',label:"全部"},
                  {value:'0',label:"待审核"},
                  {value:'1',label:"已通过"},
                  {value:'2',label:"已拒绝"}
                ],
                pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick(picker) {
                        const end = new Date(
                            dayjs()
                            .add(1, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        const start = new Date(
                            dayjs().format("YYYY-MM-DD") + " 00:00:00"
                        );
                        picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "昨天",
                        onClick(picker) {
                        const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
                        const start = new Date(
                            dayjs()
                            .subtract(1, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近一周",
                        onClick(picker) {
                        const end = new Date(
                            dayjs()
                            .add(1, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        const start = new Date(
                            dayjs()
                            .subtract(7, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                        const end = new Date(
                            dayjs()
                            .add(1, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        const start = new Date(
                            dayjs()
                            .subtract(30, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                        const end = new Date(
                            dayjs()
                            .add(1, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        const start = new Date(
                            dayjs()
                            .subtract(90, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        picker.$emit("pick", [start, end]);
                        }
                    }
                ]
            },
            }
        },
        methods:{
          classMoney(countUsedClassHour,classSinglePrice){
            return Math.ceil(Number(countUsedClassHour))*Number(classSinglePrice)
          },
          studentRefundUpdate(type){
            let orderIds=''
            if(this.orderdDetail.length){
              for(var i=0;i<this.orderdDetail.length;i++){
                  orderIds+=this.orderdDetail[i].id+','
              }
              if (orderIds.length > 0) {
                orderIds = orderIds.substr(0, orderIds.length - 1);
              }
            }
            if(type==1){
              this.$confirm("确定要退费吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                closeOnClickModal: false,
                type: "warning",
              })
                .then(() => {
                  studentRefundUpdate({id:this.getId,teacherApproval:1,orderIds:orderIds}).then((res) => {
                    if (res.code == 0) {
                      this.msgSuccess("操作成功！");
                      this.studentRefundListFn();
                      this.refundSync=false;
                    }
                  });
                })
                .catch(() => {});
            }else{
              this.$prompt("请输入驳回原因", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPattern: /\S/,
                inputErrorMessage: "驳回原因不能为空！",
                closeOnClickModal: false
              })
                .then( ({ value }) => {
                    studentRefundUpdate({
                      id: this.getId,
                      teacherApproval: 2,
                      orderIds:orderIds,
                      teacherApprovalReason: value
                    }).then(res => {
                      if (res.code == 0) {
                        this.msgSuccess("操作成功！");
                        this.studentRefundListFn();
                        this.refundSync=false;
                      }
                    });
                })
                .catch(() => {});
            }
          
          },
          studentSex(sex){
            if(sex==1){
              return '男'
            }else if(sex==2){
              return '女'
            }else {
              return ''
            }
          },
          courseLevelFn(item){
            if(item==1){
              return '初级课程'
            }else if(item==2){
              return '中级课程'
            }else if(item==3){
              return '高级课程'
            }else if(item==4){
              return '特级课程'
            }else {
              return '正常课程'
            }
          },
          studentRefundListFn(){
            studentRefundList(this.listParams).then(res => {
                this.tableList=res.rows;
                this.total=res.total;
            })
          },
          
          refundDetail(row,num){
              if(row.type!=1){
                this.refundSync=true;
                this.getId=row.id;
                studentInfo(row.studentId).then(res => {
                  this.studentDetailList = res.rows;
                });
                studentRefundInfo(row.orderIds).then(res => {
                    this.orderdDetail=res.rows.xmtStudentRefundInfoEntity;
                    this.getRealityUsePrice=res.rows.realityUsePrice;
                    this.getRefundAmount=res.rows.refundAmount;
                    this.getBackReason=res.rows.backReason;
                    this.getBankUserName=res.rows.bankUserName;
                    this.getBankAccount=res.rows.bankAccount;
                    this.getBankAddress=res.rows.bankAddress;
                    this.getBankUserPhone=res.rows.bankUserPhone;
                    this.getCreateName=res.rows.createUserName?res.rows.createUserName+'（'+res.rows.createName+'）':"";
                    this.getCreateTime=res.rows.createTime;
                    this.isTeacherApproval=res.rows.teacherApproval;
                    this.getRealityRefundAmount=res.rows.realityRefundAmount;
                    this.orderMoney=0;
                    for(let i=0;i<res.rows.xmtStudentRefundInfoEntity.length;i++){
                      if(res.rows.xmtStudentRefundInfoEntity[i].totalPrice){
                        this.orderMoney+=Math.ceil(Number(res.rows.xmtStudentRefundInfoEntity[i].totalPrice));
                      }else{
                        this.orderMoney+=Math.ceil(Number(res.rows.xmtStudentRefundInfoEntity[i].zdyActualPrice));
                      }
                    }
                })
              }else{
                this.Visible=true;
                this.visibleId=row.id;
                this.visibleNum=num;
              }
          },
          changeSizeChange(pageSize){
            this.listParams.pageSize = pageSize;
            this.studentRefundListFn();
          },
          changeCurrentChange(currentPage){
            this.listParams.pageNumber = currentPage;
            this.studentRefundListFn();
          },
          teacherApprovalFor(row){
             switch (row.teacherApproval) {
                case '1':
                  return "已通过";
                case '2':
                  return "已拒绝";
                case '0':
                  return "待审核";
              }
          },
          datePickerChange(){
            if (!this.timePickerValue) {
                  this.listParams.searchBeginTime = null;
                  this.listParams.searchEndTime = null;
                  return;
                }
                this.listParams.searchBeginTime = this.timePickerValue[0];
                this.listParams.searchEndTime = this.timePickerValue[1];
            }
        },
        mounted(){
          this.studentRefundListFn();
        }
    }
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .studentInfo{
     h2{
       font-size:16px;
       color:#000;
     }
     .studentUl{
       display: flex;
       margin-top:15px;
       li{
         flex:1;
       }
       span{
         color:#303133;
         font-size:16px;
         font-weight: 500;
       }
     }
     .orderDiv{
        margin-top:25px;
       span{
         font-weight: 600;
         color:#303133;
         font-size:16px;
       }
        ul{
          margin-top:15px;
          li{
            padding:5px 0;
          }
        }
     }
  }
  .infoDiv{
    p{
      padding:5px 0;
    }
  }
}
</style>