<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area">
      <div class="select-condition-item">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">未分配</el-menu-item>
          <el-menu-item index="2">已分配</el-menu-item>
        </el-menu>
      </div>
    </div>
    <div class="btn-area select-condition" v-show="activeIndex==1">
      <div class="left">
        <div class="select-condition-item">
          <div class="select-condition-item">
            <span class="item-lable">关键字：</span>
            <el-input
              v-model="listParams.keyword"
              placeholder="姓名/联系方式"
              size="small"
              style="width:120px"
            ></el-input>
          </div>
          <div class="select-condition-item">
            <span class="item-lable">年级：</span>
            <el-select
              v-model="listParams.sectionId"
              placeholder="请选择"
              style="width:120px"
              size="small"
            >
              <el-option
                v-for="item in sectionIdList"
                :key="item.macroId"
                :label="item.name"
                :value="item.macroId"
              ></el-option>
            </el-select>
          </div>
          <div class="select-condition-item" v-show="checkKey==2">
            <span class="item-lable">所属教务：</span>
            <el-select
              v-model="listParams.teachManagerId"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in teachManagerIdList"
                :key="item.userId"
                :label="`${item.username}（${item.name}）`"
                :value="item.userId"
              ></el-option>
            </el-select>
          </div>
          <div class="select-condition-item" v-show="checkKey==2">
            <span class="item-lable">最近跟进时间：</span>
            <el-date-picker
              size="small"
              v-model="timePickerValue"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              @change="datePickerChange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </div>
        </div>
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="searchFn()"
            >搜索</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="importExcel"
            icon="el-icon-printer"
            >导出excel</el-button
          >
        </div>
      </div>
    </div>
    <div style="display:flex;margin:8px 0;"  v-show="type == 2">
        <el-button type="success" @click="levelTab(1)" size="mini" class="levelBtn"  :class="{levelActive:levelNum==1}">在读（{{countParams.studying}}）</el-button>
        <el-button type="success" @click="levelTab(2)" size="mini" class="levelBtn" :class="{levelActive:levelNum==2}">停课（{{countParams.suspend}}）</el-button>
        <el-button type="success" @click="levelTab(3)" size="mini" class="levelBtn" :class="{levelActive:levelNum==3}">退费（{{countParams.refund}}）</el-button>
        <el-button type="success" @click="levelTab(4)" size="mini" class="levelBtn" :class="{levelActive:levelNum==4}">结课（{{countParams.endClass}}）</el-button>
      </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="studentList"
        style="width: 100%; margin-bottom: 20px"
        border
        height="100%"
        tooltip-effect="dark"
        ref="studentListTable"
        @selection-change="handleSelectionChange"
        @row-click="studentItemClick"
      >
        <el-table-column prop="name" label="">
          <template slot-scope="scope">
            <div style="display:flex;padding:4px 0;">
              <div style="width:50%;">
                <img
                  :src="scope.row.teachManagerPhoto"
                  alt=""
                  style="width:100px;height:100px;margin:0 20px"
                />
                <div class="scopeDiv" style="display: inline-block;margin-top:12px;">
                  <p
                    style="cursor: pointer;font-size:16px;color:#333;font-weight: bold;"
                    @click="studentDetail(scope.row.id)"
                  >
                    {{ scope.row.name
                    }}
                    <span><img src="../../../assets/image/dengji.png" v-for="index in scope.row.levelId" :key="index" style="width:15px;height:12px;margin-left:4px;"/></span>
                    <span class="bq" v-show="(scope.row.onlineStatus!=''&&scope.row.onlineStatus!=null)&&(scope.row.onlineStatus=='Online'||scope.row.onlineStatus=='PushOnline')">
                        <img :src="require('@/assets/image/手机.png')" alt="" >
                        <span class="bqfont">家长APP在线</span>
                      </span>
                      <span class="bqlx" v-show="(scope.row.onlineStatus!=''&&scope.row.onlineStatus!=null)&&scope.row.onlineStatus=='Offline'">
                        <img :src="require('@/assets/image/手机-未开.png')" alt="" >
                        <span>家长APP离线</span>
                      </span>
                  </p>
                  <p style="margin-top:12px;">
                    <span style="color:#333;border-right:1px solid #DCDFE6;padding-right:0px;">{{scope.row.sex==1?'男':scope.row.sex==2?'女':'未填写'}}</span>
                    <span style="color:#333;border-right:1px solid #DCDFE6;padding:0 6px;">{{ scope.row.birthday?scope.row.birthday:"未填写" }}</span>
                    <span style="color:#333;border-right:1px solid #DCDFE6;padding:0 6px;">{{ scope.row.schoolName?scope.row.schoolName:"未填写" }}</span>
                    <span style="color:#333;border-right:1px solid #DCDFE6;padding:0 6px;">{{ getStudentState(scope.row.studentState) }}</span>
                    <span style="color:#333;padding:0 6px;">{{ scope.row.sectionName }}</span>
                </p>
                <p>
                  <span style="color:#909399;">必考科目：</span>
                  <span style="color:#333;">{{ mustTest(scope.row) }}</span>
                </p>
                <p >
                  <span style="color:#909399;">分配时间：</span>
                  <span style="color:#333;">{{ scope.row.allocateTime }}</span>
                </p>
                <p >
                  <span style="color:#909399;">添加时间：</span>
                  <span style="color:#333;">{{ scope.row.createTime }}</span>
                </p>
                 <p v-show="type == 2">
                    <span style="color:#909399;">归属教务：</span>
                    <span style="color:#333;">{{scope.row.teachManagerName?scope.row.teachManagerName:""}}（{{scope.row.teachManagerRealName?scope.row.teachManagerRealName:""}}）</span>
                  </p>
                </div>
              </div>
              <div
                style="display:flex;flex-direction: column;justify-content: center;width:35%;"
              >
                <!-- <p v-show="type == 1">
                  <span style="font-weight: 600;">必考科目：</span> {{mustTest(scope.row)}}
                </p> -->
                <p v-show="type == 1" style="margin-top:15px;">
                  <span style="color:#909399;">订单数量：</span>
                  <span style="margin:0 5px;"
                    >{{ scope.row.yuyueke }}个(试听课)</span
                  ><span style="margin:0 5px;"
                    >{{ scope.row.yiduiyi }}个(一对一)</span
                  ><span style="margin:0 5px;"
                    >{{ scope.row.yiduiduo }}个(一对多)</span
                  >
                </p>
                <p v-show="type == 2">
                  <span style="color:#909399;">跟进时间：</span>
                  {{ scope.row.followUpCreateTime }}
                </p>
                <p v-show="type == 2" style="margin-top:15px;">
                  <span style="color:#909399;">跟进内容：</span>
                  <span
                    style=" display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 3;
                      overflow: hidden;
                      color:#000;cursor:pointer
                    "
                    @click="seeFollow(scope.row)"
                    v-html="scope.row.followContent"
                    >{{ scope.row.followContent }}</span
                  >
                </p>
              </div>
              <div style="width:10%;" class="scopeDiv3">
                <el-button
                  type="text"
                  size="small"
                  style="display:block"
                  v-show="type == 1"
                  @click.stop="dealAgain(scope.row)"
                  >学员分配</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  style="display:block"
                  v-show="type == 2"
                  @click.stop="dealCourse(scope.row)"
                  >配置课程</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  style="display:block"
                  v-show="type == 2"
                  @click.stop="daalArrange(scope.row)"
                  >课程安排</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :visible.sync="studentVisible"
      :close-on-click-modal="false"
      width="1200px"
      center
    >
      <div class="dialog-content">
        <el-button type="success" @click="enableClick()" style="float: right;margin-left:10px;" size="small">{{studentListDetail.status==1?'禁用':'启用'}}</el-button>
        <el-button type="success" @click="daalEdit" style="float: right;" size="small">查看</el-button>
        <dl class="student_dl">
          <dt>
            <el-upload
              :action="fileUpload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              accept="image/jpeg, image/png/"
            >
              <img
                v-if="updateImg.teachManagerPhoto"
                :src="updateImg.teachManagerPhoto"
                style="width: 88px; height: 88px; "
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            
          </dt>
          <dd style="margin-top: 16p;margin-left:20px;">
            <span style="font-weight: bold; font-size: 20px;color:#000;">{{
              studentListDetail.name
            }}<span
              style="
                display: inline-block;
                padding: 4px 10px;
                border: 1px solid #3EC279;
                background: #fff;
                color:#3EC279;
                font-size:12px;
                border-radius: 15px;
                margin-left:12px;
              "
              >{{ studentStateFn(studentListDetail.studentState) }}</span
            ></span>
            <p style="margin-top:19px; font-size: 14px;color:#010101;"
              >账号：{{ studentListDetail.account }}</p
            >
          </dd>
        </dl>
        <p
              style="padding: 6px 0; color: red"
              v-show="updateImg.photoFlag ==0"
            >
              还未上传头像，点击头像上传头像吧~
            </p>
        <div class="info_div1" style="margin-top: 35px">
          <p>基本信息</p>
          <ul>
            <li>
              <p>联系电话</p>
              <p>{{ studentListDetail.phoneNo?studentListDetail.phoneNo:"~" }}</p>
            </li>
            <li>
              <p>性别</p>
              <p>{{studentListDetail.sex==1?"男":studentListDetail.sex==2?'女':'~'}}</p>
            </li>
            <li>
              <p>年级</p>
              <p>{{ studentListDetail.sectionName?studentListDetail.sectionName:"~" }}</p>
            </li>
            <li>
              <p>出生日期</p>
              <p>{{ studentListDetail.birthday?studentListDetail.birthday:"~" }}</p>
            </li>
            <li>
              <p>所在学校</p>
              <p>{{ studentListDetail.schoolName?studentListDetail.schoolName:"~" }}</p>
            </li>
            <li>
              <p
                >家庭地址</p
              >
              <p>{{ studentListDetail.provinceName?studentListDetail.provinceName:"~"
                }}{{ studentListDetail.cityName?studentListDetail.cityName:"~"
                }}{{ studentListDetail.countyName?studentListDetail.countyName:"~" }}</p>
            </li>
            <li>
              <p>孩子性格</p>
              <p>{{ studentListDetail.childNature?studentListDetail.childNature:"~" }}</p>
            </li>
          </ul>
        </div>
        <div class="info_div1">
          <p>家长信息</p>
          <ul>
            <li>
              <p
                >学生母亲</p>
              <p>{{ studentListDetail.childMotherName?studentListDetail.childMotherName:"~"
                }}{{ studentListDetail.childMotherPhone?studentListDetail.childMotherPhone:"~" }}</p>
            </li>
            <li>
              <p
                >学生父亲</p>
              <p>{{ studentListDetail.childFatherName?studentListDetail.childFatherName:"~"
                }}{{ studentListDetail.childFatherPhone?studentListDetail.childFatherPhone :"~" }}</p>
            </li>
            <li>
              <p>微信/QQ</p>
              <p>{{ studentListDetail.parentWechat?studentListDetail.parentWechat:"~" }}</p>
            </li>
          </ul>
        </div>
        <div class="info_div2">
          <p>必考科目</p>
          <ul>
            <li>
              <p>{{ mustTest(studentListDetail) }}</p>
            </li>
          </ul>
        </div>
        <div class="info_div2">
          <p>所上科目</p>
          <ul>
            <li><p>{{ mustSubjects(studentListDetail) }}</p></li>
          </ul>
        </div>
        <div class="info_div1" v-show="studentListDetail.referralstudent">
          <p>介绍人信息</p>
          <ul>
            <li>
              <p
                >老生姓名</p
              >
              <p>{{
                  studentListDetail.referralstudent
                    ? studentListDetail.referralstudent
                    : "暂无介绍人"
                }}</p>
            </li>
            <li>
              <p
                >联系方式</p
              >
              <p>{{
                  studentListDetail.referralphone
                    ? studentListDetail.referralphone
                    : "暂无联系方式"
                }}</p>
            </li>
          </ul>
        </div>
        <div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
           <el-tab-pane label="工作记录" name="record">
          <el-table
            size="small"
            :data="needList"
            style="width: 100%; margin-top: 40px"
            row-key="id"
            border
            ref="userListTable"
            tooltip-effect="dark"
            @selection-change="handleNeedChange"
            @row-click="needItemClick"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="添加时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="subjectId"
              label="科目"
               :formatter="dealSubjectId"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="orderNo"
              label="科目成绩"
              align="center"
            >
            <template slot-scope="scope">
                {{scope.row.subjectScore}}~{{scope.row.subjectAllScore}}
              </template>
            </el-table-column>
            <el-table-column
              prop="teachTitleName"
              label="讲师职称"
              align="center"
              :formatter="dealTeachTitleName"
            ></el-table-column>
            <el-table-column  prop="teachingAgeId" label="讲师教龄" align="center" :formatter="teachingAgeIdd"></el-table-column>
          </el-table>
           <div class="page-area">
              <el-pagination
                @size-change="handleNeedSizeChange"
                @current-change="handleNeedCurrentChange"
                :current-page="xmtStudentNeeds.pageNumber"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="xmtStudentNeeds.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="needTotal"
                background
              ></el-pagination>
          </div>
        </el-tab-pane>
            <el-tab-pane label="跟进记录" name="followUp">
              <table class="self_table mT20">
                <thead>
                  <tr>
                    <td>序号</td>
                    <td>跟进记录</td>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, index) in followUpList">
                    <tr :key="item.id">
                      <td rowspan="2">{{ index + 1 }}</td>
                      <td class="follow_content">
                        <div>
                          <span>跟进内容：</span>
                          <span v-html="item.followContent" @click="showImg($event)"></span>
                        </div>
                      </td>
                    </tr>
                    <tr :key="index">
                      <td class="follow_info">
                        <span>跟进人：{{ item.saleName }}</span>
                        <span class="mL20"
                          >跟进时间：{{ item.createTime }}</span
                        >
                        <span class="mL20"
                          >下次跟进时间：{{
                            item.nextFollowupTime
                              ? item.nextFollowupTime
                              : "暂未设置"
                          }}</span
                        >
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </el-tab-pane>
            <el-tab-pane label="学习报告" name="studyReport">
          <el-button type="primary" size="small" @click="addStudyReport()">新增学习报告</el-button>
          <el-table
            size="small"
            :data="studyReportList"
            style="width: 100%; margin-top: 40px"
            row-key="id"
            border
            ref="userListTable"
            tooltip-effect="dark"
          
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="添加时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop=""
              label="科目"
              align="center">
              <template slot-scope="scope">
                <span>{{reportSubject(scope.row.subjectIds)}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="teachTitleName"
              label="报告状态"
              align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.reportStatus==1?'已发布':scope.row.reportStatus==0?'未发布':''}}</span>
                </template>
            </el-table-column>
            <el-table-column
              prop="teachTitleName"
              label="家长查看状态"
              align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.parentStatus==1?'已查看':scope.row.parentStatus==0?'未查看':''}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="seeStudyReport(scope.row)"
                  >查看</el-button
                >
                <el-button
                  type="text"
                  size="small"
                   @click="editStudyReport(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="updateStudyReport(scope.row)"
                  v-show="scope.row.reportStatus==0"
                  >发送给家长</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="removeStudyReport(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-area">
            <el-pagination
              @size-change="handlestudySizeChange"
              @current-change="handlestudyCurrentChange"
              :current-page="studyParams.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="studyParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="studyTotal"
              background
            ></el-pagination>
          </div>
        </el-tab-pane>
            <el-tab-pane label="订单" name="order">
              <el-switch
                v-model="switchValue"
                @change="switchChange()"
                active-color="#1890ff"
                inactive-color="#1890ff"
                active-text="订单记录"
                style="margin-bottom:20px;"
                inactive-text="充值记录">
              </el-switch>
              <el-table
                v-show="switchValue==true"
                size="small"
                :data="orderList"
                style="width: 100%; margin-bottom: 20px"
                row-key="id"
                border
                tooltip-effect="dark"
              >
                <el-table-column
                  prop="orderNo"
                  label="订单号"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="orderType"
                  :formatter="formatterOrderType"
                  label="产品类型"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="productName"
                  label="产品名称"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="courseTypeId"
                  label="总价"
                  :formatter="formatterTotalPrice"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="surplusPrice"
                  label="剩余费用"
                  align="center"
                ></el-table-column>
                 <el-table-column
                  prop="zdyActualPrice"
                  label="实际支付"
                  align="center"
                  :formatter="formatterZdyActualPrice"
                ></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="下单时间"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="orderSourceName"
                  label="下单人"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{scope.row.orderSourceName}}（{{scope.row.orderSourceRealName}}）</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :formatter="formatterOrderStatus"
                  label="订单状态"
                  align="center"
                ></el-table-column>
                <el-table-column label="操作" align="center" width="150px">
                  <template slot-scope="scope">
                    <el-button type="text" size="small" @click.stop="seeOrderClick(scope.row)">查看详情</el-button>
                     <el-button
                      type="text"
                      size="small"
                      v-show="(scope.row.orderStatus == 4||scope.row.orderStatus == 5)&&scope.row.orderType==1"
                      @click.stop="balanceTurn(scope.row)"
                      >余额转充值</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <p v-show="switchValue==true" style="float: right; font-weight: 600; font-size: 16px" >
                试听课剩余总金额：{{ surplusMoney }}
              </p>
              <el-table
            v-show="switchValue==false"
            size="small"
            :data="rechargeList"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            ref="rechargeListTable"
            tooltip-effect="dark"
            @selection-change="handleOrderChange"
            @row-click="rechargeItemClick"
          >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column
              prop="totalPrice"
              label="缴费金额"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="zdyGiveClassHour"
              label="赠送课时"
              align="center"
            >
              <template slot-scope="scope">
                {{scope.row.zdyGiveClassHour?scope.row.zdyGiveClassHour:0}}
              </template>
            </el-table-column>
            <el-table-column
            :formatter="formatterMoneyType"
              prop="surplusPrice"
              label="收款类型"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="充值时间"
              align="center"
            ></el-table-column>
            <el-table-column
             :formatter="formatterOrderStatus"
              prop="createTime"
              label="审核状态"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center" width="150px">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click.stop="seeOrderClick(scope.row)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <p v-show="switchValue==false" style="float: right; font-weight: 600; font-size: 16px" >
            总计缴费金额：{{rechargeParams.total}}元，剩余金额{{rechargeParams.surplusMoney}}元，剩余赠送课时{{rechargeParams.classHours}}课时
          </p>
            </el-tab-pane>
            <el-tab-pane label="学生课耗" name="lessonCost">
            <el-tabs v-model="classRecord" @tab-click="classRecordClick">
              <el-tab-pane label="一对一" name="1">
                <div class="btn-area select-condition">
                    <div class="left">
                      <div class="select-condition-item">
                        <span class="item-lable">学科：</span>
                        <el-select
                          v-model="findListParams.subjectId"
                          placeholder="请选择"
                          size="small"
                        >
                          <el-option
                            v-for="item in  onesubjectIdList"
                            :key="item.macroId"
                            :label="item.name"
                            :value="item.macroId"
                          ></el-option>
                        </el-select>
                      </div>    
                      <div class="select-condition-item">
                        <span class="item-lable">上课状态：</span>
                          <el-select
                            v-model="findListParams.attendClassStatus"
                            placeholder="请选择"
                            size="small"
                          >
                            <el-option label="全部" value=""></el-option>
                            <el-option label="未上课" value="0"></el-option>
                            <el-option label="已上课" value="1"></el-option>
                            <el-option label="上课中" value="2"></el-option>
                          </el-select>
                      </div>
                      <div class="select-condition-item">
                        <span class="item-lable">课程状态：</span>
                        <el-select
                          v-model="findListParams.abnormalStatus"
                          placeholder="请选择"
                          size="small"
                        >
                          <el-option label="全部" value=""></el-option>
                          <el-option label="正常" value="0"></el-option>
                          <el-option label="异常" value="1"></el-option>
                        </el-select>
                      </div>
                      <div class="select-condition-item">
                        <span class="item-lable">日期范围：</span>
                        <el-date-picker
                          size="small"
                          v-model="timePickerOne"
                          type="daterange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :picker-options="pickerOptions"
                          @change="onePickerChange"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          :default-time="['00:00:00', '23:59:59']"
                        ></el-date-picker>
                      </div>
                      <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                         @click="()=>(findListParams.pageNumber=1,findProductItemPage())"
                        >搜索</el-button
                      >
                       <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                        @click="importExcelOne"
                        >导出excel</el-button
                      >
                    </div>
                  </div>
                  <el-table
                  size="small"
                  :data="oneToOneData"
                  style="width: 100%; margin-bottom: 20px"
                  row-key="id"
                  border
                  tooltip-effect="dark"
                >
                  <el-table-column
                    type="index"
                    prop="orderNo"
                    label="序号"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="beginTime"
                    label="上课时间"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="productName"
                    label="产品名称"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.productName">{{scope.row.productName}}</span>
                      <span v-else>充值订单</span>
                  </template>
                  </el-table-column>
                  <el-table-column
                    prop="title"
                    label="课程标题"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="courseLevel"
                    :formatter="courseLevelFormatter"
                    label="课程级别"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="usedClassHour"
                    label="课时"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="teacherRealName"
                    label="授课老师"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop=""
                    label="上课状态"
                    :formatter="formatterAttendClassStatus"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop=""
                    label="补课时"
                    align="center"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.classHourType == 7" style="color:red;">是</span>
                      <span v-if="scope.row.classHourType != 7" >否</span>
                    </template>
                  </el-table-column>
                </el-table>
                 <p class="countMap_p">
                  <span>已排{{countMap.yipaike}}课时</span>
                  <span>已上{{countMap.yishangke}}课时</span>
                  <span>上课中{{countMap.shangkezhong}}课时</span>
                  <span>未上{{countMap.weishangke}}课时</span>
                </p>
                <div class="page-area">
                  <el-pagination
                    @size-change="oneToOneSizeChange"
                    @current-change="oneToOneCurrentChange"
                    :current-page="findListParams.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="findListParams.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total1"
                    background
                  ></el-pagination>
                </div>
              </el-tab-pane>
              <el-tab-pane label="一对多" name="2">
                <div class="btn-area select-condition">
                    <div class="left">
                      <div class="select-condition-item">
                        <span class="item-lable">关键字：</span>
                        <el-input
                          v-model="oneToMoreParams.roomName"
                          placeholder="请输入班级名称"
                          size="small"
                        ></el-input>
                      </div>
                      <div class="select-condition-item">
                        <span class="item-lable">上课状态：</span>
                        <el-select
                          v-model="oneToMoreParams.attendClassStatus"
                          placeholder="请选择"
                          size="small"
                        >
                          <el-option label="全部" value=""></el-option>
                          <el-option label="未上课" value="0"></el-option>
                          <el-option label="已上课" value="1"></el-option>
                          <el-option label="上课中" value="2"></el-option>
                        </el-select>
                      </div>
                      <div class="select-condition-item">
                        <span class="item-lable">课程状态：</span>
                        <el-select
                          v-model="oneToMoreParams.abnormalStatus"
                          placeholder="请选择"
                          size="small"
                        >
                          <el-option label="全部" value=""></el-option>
                          <el-option label="正常" value="0"></el-option>
                          <el-option label="异常" value="1"></el-option>
                        </el-select>
                      </div>
                      <div class="select-condition-item">
                        <span class="item-lable">日期范围：</span>
                        <el-date-picker
                          size="small"
                          v-model="timePickerMore"
                          type="daterange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :picker-options="pickerOptions"
                          @change="morePickerChange"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          :default-time="['00:00:00', '23:59:59']"
                        ></el-date-picker>
                      </div>
                      <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                         @click="()=>(oneToMoreParams.pageNumber=1,findMoreProductItemPage())"
                        >搜索</el-button
                      >
                       <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                        @click="importExcelMore"
                        >导出excel</el-button
                      >
                    </div>
                  </div>
                  <el-table
                  size="small"
                  :data="oneToMoreData"
                  style="width: 100%; margin-bottom: 20px"
                  row-key="id"
                  border
                  tooltip-effect="dark"
                >
                  <el-table-column
                    type="index"
                    prop="orderNo"
                    label="序号"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="roomName"
                    label="班级名称"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="teacherRealName"
                    label="授课老师"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="title"
                    label="课程标题"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="beginTime"
                    label="上课时间"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="orderSourceName"
                    label="上课状态"
                    align="center"
                    :formatter="formatterAttendClassStatus"
                  ></el-table-column>
                  <el-table-column
                    prop="usedClassHour"
                    label="课时数"
                    align="center"
                  ></el-table-column>
                   <el-table-column
                    prop=""
                    label="课程状态"
                    align="center"
                  ></el-table-column>
                </el-table>
                <div class="page-area">
                  <el-pagination
                    @size-change="oneToMoreSizeChange"
                    @current-change="oneToMoreCurrentChange"
                    :current-page="oneToMoreParams.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="oneToMoreParams.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total2"
                    background
                  ></el-pagination>
                </div>
              </el-tab-pane>
              <el-tab-pane label="取消课" name="3">
                <div class="btn-area select-condition">
                    <div class="left">
                      <div class="select-condition-item">
                        <span class="item-lable">取消原因：</span>
                        <el-select
                          v-model="delParams.delCause"
                          placeholder="请选择"
                          size="small"
                        >
                          <el-option
                            v-for="item in delCauseList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </div>    
                      <div class="select-condition-item">
                        <span class="item-lable">日期范围：</span>
                        <el-date-picker
                          size="small"
                          v-model="timePickerDel"
                          type="daterange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :picker-options="pickerOptions"
                          @change="delPickerChange"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          :default-time="['00:00:00', '23:59:59']"
                        ></el-date-picker>
                      </div>
                      <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                        @click="()=>(delParams.pageNumber=1,findDelProductItemPage())"
                        >搜索</el-button
                      >
                      <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                        @click="importExcelDel"
                        >导出excel</el-button
                      >
                    </div>
                  </div>
                  <el-table
                  size="small"
                  :data="delData"
                  style="width: 100%; margin-bottom: 20px"
                  row-key="id"
                  border
                  tooltip-effect="dark"
                >
                  <el-table-column
                    type="index"
                    label="序号"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="beginTime"
                    label="上课时间"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="productName"
                    label="产品名称"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.productName">{{scope.row.productName}}</span>
                      <span v-else>充值订单</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="title"
                    label="课程标题"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    label="课程级别"
                    align="center"
                    :formatter="courseLevelFormatter"
                  ></el-table-column>
                  <el-table-column
                    prop="usedClassHour"
                    label="课时"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="teacherRealName"
                    label="授课老师"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    label="取消原因"
                    align="center"
                    :formatter="formatterDelCause"
                  ></el-table-column>
                </el-table>
                <div class="page-area">
                  <el-pagination 
                    @size-change="delSizeChange"
                    @current-change="delCurrentChange"
                    :current-page="delParams.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="delParams.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total3"
                    background
                  ></el-pagination>
                </div>
              </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="教务分配"
      :visible.sync="apportionVisible"
      :close-on-click-modal="false"
      width="500px"
      center
    >
      <div class="dialog-content">
        <div class="item_select">
          <span>请选择教务：</span>
          <el-select
            v-model="saleId"
            placeholder="请选择"
            size="small"
            @change="saleFn()"
          >
            <el-option
              v-for="item in saleList"
              :key="item.userId"
               :label="`${item.username}（${item.name}）`"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="apportionVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="confirmApportion()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="配置课程"
      :visible.sync="courseVisible"
      :close-on-click-modal="false"
      width="1200px"
      center
    >
      <div class="dialog-content">
        <div class="detail_type">学生概况</div>
        <div class="courseContent">
          <p><span>学生姓名：</span><span>{{courseList.name}}</span></p>
          <p><span>联系方式：</span><span>{{courseList.contact ? courseList.contact : '暂无'}}</span></p>
          <p><span>年级：</span><span>{{courseList.sectionName}}</span></p>
          <p><span>性别：</span><span>{{courseList.sex==1?'男':courseList.sex==2?'女':''}}</span></p>
          <p><span>必考科目：</span><span>{{mustTest(courseList)}}</span></p>
        </div>
        <div class="subjectList_div">
          <div class="detail_type">选择科目</div>
          <ul>
            <li v-for="(item, index) in subjectIdList" :key="index">
              <el-button
                size="small"
                type="primary"
                @click="addCourseList(item.macroId, item.name)"
                >{{ item.name }}</el-button
              >
            </li>
          </ul>
          <el-table :data="courseData" style="width: 100%">
            <el-table-column
              prop="subjectId"
              label="学科"
              :formatter="dealSubjectId"
            >
            </el-table-column>
            <el-table-column prop="name" label="课程级别">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.courseLevel"
                  placeholder="请选择"
                  @focus="subjectRow(scope.row)"
                  filterable 
                  allow-create
                >
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="unit" label="课时单价">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.classSinglePrice"
                  placeholder="请输入内容"
                  maxlength="4"
                  @blur="
                    classSingBlur(scope.$index, scope.row.classSinglePrice)
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click.stop="dealDelete(scope.row,scope.$index)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div
            style="display:flex;align-items: center;justify-content: center;margin-top:30px;"
          >
            <el-button size="small" @click="courseVisible = false"
              >取 消</el-button
            >
            <el-button type="primary" @click="save()" size="small"
              >确 定</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="课程安排"
      :visible.sync="arrangePopup"
      :close-on-click-modal="false"
      width="1200px"
      center
    >
      <div class="dialog-content">
        <div class="content_div1">
          <div class="detail_type">学生概况</div>
          <div class="courseContent">
            <p><span>学生姓名：</span><span>{{studentDetailList.name}}</span></p>
            <p><span>联系方式：</span><span>{{studentDetailList.contact}}</span></p>
            <p><span>年级：</span><span>{{studentDetailList.sectionName}}</span></p>
            <p><span>性别：</span><span>{{studentDetailList.sex==1?'男':studentDetailList.sex==2?'女':''}}</span></p>
            <p><span>必考科目：</span><span>{{mustTest(studentDetailList)}}</span></p>
          </div>
        </div>
        <div class="content_div3">
          <p style="font-size:16px;color:#000;font-weight:bold;">课程需求</p>
          <el-table
            size="small"
            :data="teacherList"
            style="width: 100%; margin-top: 20px"
            row-key="userId"
            border
            tooltip-effect="dark"
          >
            <el-table-column
              prop="createTime"
              label="添加时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="subjectName"
              label="科目"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="subjectScore"
              label="科目成绩"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="productName"
              label="讲师职称"
              align="center"
              :formatter="teachTitleIdFn2"
            ></el-table-column>
            <el-table-column  prop="teachingAgeName" :formatter="teachingAgeIdFn2" label="讲师教龄" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="demandInfo(scope.row.id)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="page-area">
          <el-pagination
            @size-change="demandSizeChange"
            @current-change="demandCurrentChange"
            :current-page="configteacherParams.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="configteacherParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="demandTotal"
            background
          ></el-pagination>
        </div>
        <div class="content_div4">
              <div style="display:flex;">
                  <el-button type="primary" size="small" @click="singleTime('')">单次排课</el-button>
                   <el-button type="primary" size="small" @click="batchTime">批量排课</el-button>
              </div>
              <div>
                <div class="weekDiv" style="display:flex;align-items:center;justify-content:center;">
                     <p id="last-week" @click="lastWeek()">&lt;&lt;</p>
                     <p>{{weekTitle}}</p>
                     <p id="next-week" @click="nextWeek()">>></p>
                </div>
                <div style="display:flex;margin-top:30px;">
                  <div class="right_div" style="flex:1;">
                      <table id="monitor" > 
                        <thead id="monitor_thead">
                            <tr>
                          <td style="line-height:40px;"></td>
                          <td v-for="(item, index) in weekList" :key="index" style="line-height:40px;">
                            {{ item.value }}
                          </td>
                        </tr>
                          </thead>
                          <tbody class="monitor_tbody">
                        <tr v-for="(item, index) in tableList" :key="index"  >
                          <td style="width: 80px;line-height:39px;height: 39px;">
                            {{ dataTimeArray[index].time }}
                          </td>
                          <td
                            class="course_td"
                            style="height:39px !important;"
                            v-for="(items, indexs) in item"
                            :key="indexs"
                            :class="{
                              cursor_pointer: !items.pastTime && items.canUse,
                            }"
                             @click.stop="singleTime(items)"
                             @mouseover="mouseOver($event,items)"
                            @mouseleave="mouseLeave($event,items)"
                          >
                            <p v-show="items.id" class="course_opt"  :style="timeClasss(items)">
                              <i
                                class="el-icon-edit-outline"
                                @click.stop="courseEdit(items)"
                                style="
                                  font-size: 25px;
                                  color: #fff;
                                  margin-right: 10px;
                                "
                              ></i>
                              <i
                                  class="el-icon-delete"
                                  @click.stop="courseEditRemove(items.id)"
                                  style="
                                    font-size: 25px;
                                    color: #fff;
                                    margin-left: 10px;
                                  "
                                ></i>
                            </p>
                            <div v-show="items.name" :style="timeClass(items)"
                            >
                              <span
                                >{{ items.subjectName }} {{ items.name }}<br>{{ items.time }}</span
                              >
                            </div>
                            <div
                              class="course_can_use"
                              v-show="!items.pastTime && items.canUse"
                            >
                              <span>+</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                          <!-- <tbody class="monitor_tbody">
                              <tr v-for="(item,index) in tableList" :key='index'>
                                <td style="width: 80px;line-height:40px;height: 40px;">
                                {{dataTimeArray[index].time }}
                              </td>
                              <td class="course_td" v-for="(items,indexs) in item" :key='indexs' 
                                 @mouseover="mouseOver($event)"
                                  @mouseleave="mouseLeave($event)"
                              >
                              <p  v-show="items.id" style="display:flex;align-items: center;justify-content: center;" >
                                <i class="el-icon-edit-outline" @click="courseEdit(items)" style="font-size:25px;color:#fff;margin-right:10px;"></i>
                               <i class="el-icon-delete" @click.stop="courseEditRemove(items.id)"  style="font-size:25px;color:#fff;margin-left:10px;"></i>
                               </p>
                               {{items.subjectId}} {{items.name}}</br>{{items.time}}
                              </td>
                            </tr>
                          </tbody> -->
                      </table>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="singleParams.title"
      :visible.sync="singlePopup"
      :close-on-click-modal="false"
      width="460px"
      center
       custom-class="add_class"
    >
       <div class="dialog-content">
           <el-form
          :model="singleRuleForm"
          status-icon
          :rules="singlePopupRules"
          ref="singleRuleForm"
          label-width="100px"
          size="small"
        >
           <el-col :span="20">
              <el-form-item label="学科" prop="subjectId">
                <el-select
                  v-model="singleRuleForm.subjectId"
                  placeholder="请选择"
                  size="small"
                  @change="selectChanged"
                >
                  <el-option
                    v-for="item in singSubjectIdList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="20" v-show="singleRuleForm.classHourType!=7">
            <el-form-item label="产品类型" prop="classHourStatus">
              <el-radio-group v-model="classHourStatus" @change="classHourStatusChange()">
                <el-radio label="1">一对一充值</el-radio>
                <el-radio label="2">一对一</el-radio>
              </el-radio-group>
              
            </el-form-item>
          </el-col>
            <el-col :span="20" v-show="singleRuleForm.classHourType!=7">
              <el-form-item label="收费类型" prop="classHourType">
                  <el-select @change="classHourTypeChange" v-model="singleRuleForm.classHourType" placeholder="请选择">
                    <el-option
                      v-for="item in classHourTypeList"
                      :key="item.value"
                      :label="item.label"
                       v-show="classHourStatus==item.show"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <p v-show="singleRuleForm.classHourType == 3">
                    剩余金额：{{ accountBalance }}
                  </p>
                  <p v-show="singleRuleForm.classHourType == 5">
                    剩余课时：{{ remainingClassHours }}
                  </p>
              </el-form-item>
            </el-col>
            <el-col :span="20" v-show="classHourStatus==2">
              <el-form-item label="产品名称" prop="productId">
                <el-select
                  v-model="singleRuleForm.productId"
                  placeholder="请选择"
                  size="small"
                  value-key="id"
                  @change="surplusNumFn"
                >
                  <el-option
                    v-for="item in buyProductList"
                    :key="item.id"
                    :label="item.productName"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <p v-show="singleRuleForm.classHourType==1">剩余金额：{{surplusNum}}</p>
                 <p v-show="singleRuleForm.classHourType==2">剩余课时：{{surplusNum}}</p>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="上课模式" prop="classMode">
                <el-radio-group
                  v-model="singleRuleForm.classMode"
                  @change="classModeChange()"
                >
                  <el-radio label="1">线上</el-radio>
                  <el-radio label="2">线下</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="课程标题" prop="title">
                  <el-input v-model="singleRuleForm.title" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="20">
              <el-form-item label="上课时间" prop="beginTime">
                 <el-date-picker
                  v-model="singleRuleForm.beginTime"
                  type="datetime"
                  placeholder="选择日期"
                  format="yyyy-MM-dd HH:mm"
                  @change="changeBeginTime"
                  :picker-options="singleRuleForm.classMode=='1'?beforePickerOptions:offlineBeforePickerOptions"
                  value-format="yyyy-MM-dd HH:mm"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="课时数" prop="usedClassHour">
                  <el-input-number @change="classHourChange" v-model="singleRuleForm.usedClassHour" :max='Number(4)' :min='Number(1)' placeholder="请输入内容"></el-input-number>
              </el-form-item>
            </el-col>
            
            <el-col :span="20">
              <el-form-item label="讲师" prop="teacherId">
                  <el-input @click.native="getTeacherId()" readonly v-model="newteacherName" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20" v-show="singleRuleForm.classMode==2">
              <el-form-item label="上课地址" prop="detailAddress">
                  <el-input @click.native="getDetailAddress()" readonly v-model="singleRuleForm.classAddress" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20" v-show="singleRuleForm.classMode == 1">
            <el-form-item label="视频模式" prop="videoMode">
              <el-select
                v-model="singleRuleForm.videoMode"
                placeholder="请选择"
                size="small"
                @change="videoModeChanged"
              >
                <el-option
                  v-for="item in videoModeList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
            <el-col :span="20">
              <el-form-item label="首次课设置" prop="isFirstClass">
                <el-switch v-model="singleRuleForm.isFirstClass"
                  active-value="1"
                  inactive-value="0">
              </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="20">
            <el-form-item label="监课展示" prop="isPrisonClass">
              <el-switch :disabled="!hasCdnMenu"
                @click.native="handleAuthTip('hasCdnMenu', hasCdnMenu)"
                :title="!hasCdnMenu ? '请联系管理员开同此功能' : ''" v-model="singleRuleForm.isPrisonClass"
                  active-value="1"
                  inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="回放录制" prop="isPlayback">
              <el-switch :disabled="!hasRecordMenu"
                @click.native="handleAuthTip('hasRecordMenu', hasRecordMenu)"
                :title="!hasRecordMenu ? '请联系管理员开同此功能' : ''" v-model="singleRuleForm.isPlayback"
                  active-value="1"
                  inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-show="singleRuleForm.classHourType==7">
            <el-form-item label="讲师课酬" prop="ifClassRemuneration">
              <el-radio-group v-model="singleRuleForm.ifClassRemuneration">
                <el-radio label="0">计算</el-radio>
                <el-radio label="1">不计算</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form>
       </div>
       <div class="price_tip">
        <div class="class_fee">本堂课流量费用：<span>{{singleRuleForm.expense}}</span>元 </div>
        <div class="account_fee">账户余额：{{organTotalBalance}}元</div>
      </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="singlePopup = false" size="small"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="sureSave('singleRuleForm')"
            size="small"
            >确 定</el-button
          >
        </span>
    </el-dialog>
     <el-dialog
      title="上课地址"
      :visible.sync="detailAddressPopup"
      :close-on-click-modal="false"
      width="800px"
      center
    >
       <div class="dialog-content">
         <div class="btn-area">
            <div class="right">
              <el-button-group>
                <el-button type="default" size="small" @click="addAddress">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-xinzeng" />
                  </svg>
                  <span>新增</span>
                </el-button>
                <el-button type="default" size="small" @click="editAddress">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-edit" />
                  </svg>
                  <span>编辑</span>
                </el-button>
                <el-button type="default" size="small" @click="removeAddress">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-trash" />
                  </svg>
                  <span>删除</span>
                </el-button>
              </el-button-group>
            </div>
          </div>
           <div class="table-area">
      <el-table 
        size="small"
        :data="addressListData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        tooltip-effect="dark"
        ref="addressListData"
        @selection-change="addressListDataChange"
        @row-click="addressListDataItemClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column  
          prop="name"
          label="地址"
          align="center"
        >
          <template slot-scope="scope">
          <span v-show="scope.row.isDefault==1" style="padding:5px 8px;background:red;color:#fff;border-radius:4px;">默认</span>  <span>{{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.countyName}}{{scope.row.detailAddress}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChangeAddress"
        @current-change="handleCurrentChangeAddress"
        :current-page="classAddressData.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="classAddressData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalData"
        background
      ></el-pagination>
    </div>
       </div>
       <span slot="footer" class="dialog-footer">
        <el-button @click="detailAddressPopup = false" size="small">取 消</el-button>
        <el-button  
          type="primary"
          @click="clickAddressSure()"
          size="small"
          >确 定</el-button
        >
      </span>
      </el-dialog>
      <el-dialog
      :title="addAddressParams.title"
      :visible.sync="addAddressPopup"
      :close-on-click-modal="false"
      width="800px"
      center
    >
       <div class="dialog-content">
          <el-form
          :model="saveAddressForm"
          status-icon
          :rules="saveAddressRules"
          ref="saveAddressForm"
          label-width="100px"
          size="small"
        >
            <el-form-item label="所在地区">
            <el-row type="flex">
              <el-col :span="8">
                <el-select
                  v-model="saveAddressForm.provinceId"
                  placeholder="请选择"
                  size="small"
                  @change="addressProvinceChange"
                >
                  <el-option
                    v-for="item in addressProvinceList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveAddressForm.cityId"
                  placeholder="请选择"
                  size="small"
                  @change="addressCityChange"
                >
                  <el-option
                    v-for="item in addressCityList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveAddressForm.countyId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in addressCountyList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
           <el-form-item label="详细地址">
             <el-col :span="12">
                <el-input
                  v-model="saveAddressForm.detailAddress"
                ></el-input>
             </el-col>
           </el-form-item>
            <el-form-item label="默认地址" prop="isDefault">
              <el-radio-group v-model="saveAddressForm.isDefault">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
        </el-form>
       </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="addAddressPopup = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmAddressSave('saveAddressForm')"
          size="small"
          >确 定</el-button
        >
      </span>
      </el-dialog>
      <el-dialog
      title="选择讲师"
      :visible.sync="selectTeacherPopup"
      :close-on-click-modal="false"
      width="800px"
      center
    >
       <div class="dialog-content">
            <div class="table-area mT10">
            <el-table
              size="small"
              :data="matchingTeacherList"
              style="width: 100%;margin-bottom: 20px;"
              border
              height="400px"
              tooltip-effect="dark"
              :row-key="getRowKeys"
              ref="resTeacherListTable"
              @selection-change="handleTeacherSelectionChange"
              @row-click="rowTeacherItemClick"
            >
            <el-table-column type="selection" width="55" align="center" />
              <!-- <el-table-column
                type="selection"
                width="55"
                :reserve-selection="true"
                align="center"
              ></el-table-column> -->
              <el-table-column
                prop="polymerizationKeywords"
                label="课程级别"
                align="center"
                :formatter="courseLevelFormatter"
              ></el-table-column>
              <el-table-column
                prop="subjectName"
                label="学科"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="teacherRealname"
                label="讲师"
                align="center"
              ></el-table-column>
              <!-- <el-table-column
                prop="createTime"
                label="期望薪资"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.hopeMinFee }}~{{ scope.row.hopeMaxFee }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="teachFee"
                label="讲师课酬"
                align="center"
              ></el-table-column> -->
              <!-- <el-table-column
                prop="classSinglePrice"
                label="课时单价"
                align="center"
              ></el-table-column> -->
            </el-table>
          </div>
           <div class="page-area">
            <el-pagination
              @size-change="handleTeacherNewSizeChange"
              @current-change="handleTeacherNewCurrentChange"
              :current-page="teacherParams.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="teacherParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="teacherTotalnew"
              background
            ></el-pagination>
          </div>
       </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="selectTeacherPopup = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" size="small"  @click="sureTeacher()"
            >确 定</el-button
          >
        </span>
     </el-dialog>
     <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center>
      <p v-show="centerType=='student'" style="padding:7px 0;">该时间段"{{centerStudentName}}"学生已有课程安排</p>
      <p v-show="centerType=='teacher'" style="padding:7px 0;">该时间段"{{centerTeacherName}}"老师已有课程安排</p>
      <p style="padding:7px 0;">上课时间：{{centerBeginTime}}</p>
       <p style="padding: 7px 0" v-show="centerTrue">机构：{{ centerOrganName }}</p>
      <p style="padding:7px 0;">上课时长：{{centerUsedClassHour}}小时</p>
      <p style="padding:7px 0;">辅导老师：{{centerTeacherName}}老师</p>
      <p style="padding:7px 0;">上课学生：{{centerStudentName}}学生</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
     <el-dialog
        title="新增课程"
        :visible.sync="batchTimePopup"
        :close-on-click-modal="false"
        width="1300px"
        center
      >
       <div class="dialog-content">
           <el-form
            :model="batchTimeForm"
            status-icon
            ref="batchTimeForm"
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col :span="6">
              <el-form-item label="排课日期" prop="">
                <el-date-picker
                  size="small"
                  v-model="batchPickerValue"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                :picker-options="expireTimeOptionBefore"
                  @change="batchPickerChange"
                  value-format="yyyy-MM-dd"
                 :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            </el-row>
             <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="星期选择" prop="">
                <el-checkbox-group
                  v-model="checkedWeeks"
                  @change="handleCheckedWeeksChange"
                >
                  <el-checkbox
                    v-for="city in weeks"
                    :label="city.id"
                    :key="city.id"
                    >{{ city.name }}</el-checkbox
                  >
                </el-checkbox-group>
                <el-checkbox
                  :indeterminate="isWeekIndeterminate"
                  v-model="checkWeekAll"
                  @change="weeksCheckAllChange"
                  >全选</el-checkbox
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item label="产品类型" prop="classHourType">
                 <el-radio-group v-model="classHourStatusMore" @change="classMoreChange()">
                <el-radio label="1">一对一充值</el-radio>
                <el-radio label="2">一对一</el-radio>
              </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row> 
            <el-row :gutter="20">
              <el-col :span="10">
                <el-form-item label="收费类型" prop="classHourType">
                  <el-select @change="batchHourTypeChange" v-model="batchTimeForm.classHourType" placeholder="请选择" >
                    <el-option
                      v-for="item in classHourTypeList"
                      :key="item.value"
                      :label="item.label"
                      
                       v-show="classHourStatusMore==item.show"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <p v-show="batchTimeForm.classHourType == 3">
                    剩余金额：{{ accountBalance }}
                  </p>
                  <p v-show="batchTimeForm.classHourType == 5">
                    剩余课时：{{ remainingClassHours }}
                  </p>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="classHourStatusMore==2">
              <el-col :span="10">
              <el-form-item label="产品名称" prop="sex">
                <el-select
                  v-model="newProductId"
                  placeholder="请选择"
                  size="small"
                  value-key="id"
                  @change="surplusNumFn2"
                >
                  <el-option
                    v-for="item in batchProductList"
                    :key="item.id"
                    :label="item.productName"
                    :value="item"
                  ></el-option>
                </el-select>
                <p v-show="batchTimeForm.classHourType == 1">
                剩余金额：{{ surplusNum2 }}
              </p>
              <p v-show="batchTimeForm.classHourType == 2">
                剩余课时：{{ surplusNum2 }}
              </p>
              </el-form-item>
            </el-col>
            </el-row>
            <el-row :gutter="20">
            <el-col :span="10">
            <el-form-item label="视频模式" prop="videoMode">
              <el-select
                v-model="batchTimeForm.videoMode"
                placeholder="请选择"
                size="small"
                @change="videoModeChanged"
              >
                <el-option
                  v-for="item in videoModeList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          </el-row>
           </el-form>
            <el-button type="primary" @click="createCourse" >生成课程</el-button>
           <el-table
                v-show="courseShow==true"
                size="small"
                :data="courseDate"
                style="width: 100%; margin-bottom: 20px;margin-top:20px;"
                row-key="id"
                border
                tooltip-effect="dark"
                 highlight-current-row
              >
                <el-table-column
                  prop="date"
                  label="日期"
                  align="center"
                  width="110px"
                ></el-table-column>
                <el-table-column
                  prop="orderType"
                  label="星期"
                  width="100px"
                  :formatter="dealIsDay"
                  align="center"
                >
                <!-- <template slot-scope="scope">
                    <el-input v-model="scope.row.week" clearable size="mini" placeholder="输入课时" />
                </template> -->
                </el-table-column>
                <el-table-column
                  prop="productName"
                  label="科目"
                  align="center"
                >
                 <template slot="header">
                    <el-select
                      v-model="tableSubjectId"
                      placeholder="可选科目"
                      size="small"
                      @change="tableChange()"
                    >
                      <el-option
                        v-for="item in singSubjectIdList"
                        :key="item.macroId"
                        :label="item.name"
                        :value="item.macroId"
                      ></el-option>
                    </el-select>
                  </template>
                  <template slot-scope="scope">
                       <el-select
                      v-model="scope.row.subjectId"
                      placeholder="可选科目"
                      size="small"
                    >
                      <el-option
                        v-for="item in singSubjectIdList"
                        :key="item.macroId"
                        :label="item.name"
                        :value="item.macroId"
                      ></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="beginTime"
                  label="上课时间"
                  align="center"
                >
                   <template slot="header">
                         <el-time-select
                          placeholder="起始时间"
                          v-model="pickerData"
                          :picker-options="{
                            start: '06:00',
                            step: '00:05',
                            end: '23:59'
                          }"
                            @change="datetimeChange()"
                          >
                        </el-time-select>
                   </template>
                   <template slot-scope="scope">
                      <el-time-select
                        placeholder="起始时间"
                        v-model="scope.row.time"
                        :picker-options="{
                          start: '06:00',
                          step: '00:05',
                          end: '23:59'
                        }"
                        >
                      </el-time-select>
                   </template>
                </el-table-column>
                <el-table-column
                  label="课时"
                  align="center"
                  visEdit="true"
                   width="150px"
                >
                <template slot="header">
                      <el-input-number v-model="usedClassHour" @change="usedClassHourChange()" @input.native="usedClassHourInput($event)" :max="4"  :min="1" clearable size="mini" placeholder="输入课时" />
                  </template>
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.usedClassHour" clearable size="mini" :max="4"  :min="1" placeholder="输入课时" />
                </template>
                </el-table-column>
                <el-table-column label="课程标题" align="center" visEdit="true">
            <template slot="header">
              <el-input
                v-model="usedTitle"
                @input="usedTitleInput()"
                clearable
                size="mini"
                placeholder="输入课程标题"
              />
            </template>
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.title"
                clearable
                size="mini"
                placeholder="输入课程标题"
              />
            </template>
          </el-table-column>
                <el-table-column
                  prop="teacherName"
                  label="讲师"
                  align="center"
                >
                <template slot="header">
                  <p @click="scopeTeacher">请选择讲师</p>
                  </template>
                 <template slot-scope="scope">
                   <span>{{scope.row.teacherName}}</span>
                </template>
                </el-table-column>
                <el-table-column
                  prop="classAddress"
                  label="上课地址"
                  align="center"
                >
                 <template slot="header">
                  <p @click="scopeAddress">请选择上课地址</p>
                  </template>
                 <template slot-scope="scope">
                    <span v-show="scope.row.classMode">{{scope.row.classAddress}}</span>
                </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="200px">
                  <template slot-scope="scope" >
                    <div style="display:flex;">
                        <div style="width: 60%;text-align:left;">
                        <el-switch
                    v-model="scope.row.isFirstClass"
                    active-text="首次课"
                  >
                  </el-switch>
                  <el-switch
                    style="display: block"
                    v-model="scope.row.classMode"
                    active-text="线下课"
                    @change="moreClassMode"
                    >
                  </el-switch>
                  <el-switch v-model="scope.row.isPrisonClass"
                      active-text="是否监课" active-value="1" inactive-value="0">
                  </el-switch>
                  <el-switch v-model="scope.row.isPlayback"
                      active-text="是否回放" active-value="1" inactive-value="0">
                  </el-switch>
                    </div>
                   <div style="width: 40%;display:flex;align-items: center;justify-content: center;">
                     <i class="el-icon-delete" @click="deleteTableRow(scope)" style="margin-top:10px;font-size:30px;color:red;"></i>
                   </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
       </div>
       <div class="price_tip">
        <div class="class_fee">本堂课流量费用：<span>{{singleRuleForm.expense}}</span>元 </div>
        <div class="account_fee">账户余额：{{organTotalBalance}}元</div>
      </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="batchTimePopup = false" size="small">取 消</el-button>
        <el-button type="primary"  size="small" @click="sure()" :loading="batchLoading">确 定</el-button>
      </span>
       </el-dialog>
       
    <el-dialog
      title="请选择删除原因"
      :visible.sync="removeClassVisible"
      :close-on-click-modal="false"
      width="400px"
      center
    > 
      <div >
        <div class="item_select" style="padding:30px 0;">
            <el-radio v-model="removeClassRadio" label="1">学生原因</el-radio>
            <el-radio v-model="removeClassRadio" label="2">老师原因</el-radio>
            <el-radio v-model="removeClassRadio" label="3">其他原因</el-radio>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="removeClassVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary"  size="small" @click="sureRemoveClass()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="查看学员"
      :visible.sync="seeDialogVisible"
      :close-on-click-modal="false"
      width="1000px"
      center
    >
      <div class="dialog-content">
        <div class="info_div1"  >
          <p style="font-size:16px;color:#000;font-weight: bold;">学生信息</p>
          <ul>
            <li>
              <p>学生姓名</p>
              <p>{{ seeRuleForm.name?seeRuleForm.name:"~" }}</p>
            </li>
            <li>
              <p>联系电话</p>
              <p>{{ seeRuleForm.phoneNo?seeRuleForm.phoneNo:"~" }}</p>
            </li>
            <li>
              <p>性 别</p>
              <p>{{seeRuleForm.sex==1?"男":seeRuleForm.sex==2?'女':'~'}}</p>
            </li>
            <li>
              <p>年 级</p>
              <p>{{seeRuleForm.sectionName?seeRuleForm.sectionName:'~'}}</p>
            </li>
            <li>
              <p>出生日期</p>
              <p>{{seeRuleForm.birthday?seeRuleForm.birthday:'~'}}</p>
            </li>
             <li>
              <p>星 座</p>
              <p>{{seeConstellationId(seeRuleForm.constellationId)}}</p>
            </li>
          </ul>
          <ul>
            <li>
              <p>家庭住址</p>
              <p>{{ seeRuleForm.provinceName?seeRuleForm.provinceName:'~' }}
                {{ seeRuleForm.cityName?seeRuleForm.cityName:'~'
              }}{{ seeRuleForm.countyName?seeRuleForm.countyName:'~' }}</p>
            </li>
             <li>
              <p>所在学校</p>
              <p>{{seeRuleForm.schoolName?seeRuleForm.schoolName:'~'}}</p>
            </li>
            <li>
              <p>所在区域</p>
              <p>{{seeRuleForm.schoolReach?seeRuleForm.schoolReach:'~'}}</p>
            </li>
            <li>
              <p>孩子性格</p>
              <p>{{seeRuleForm.childNature?seeRuleForm.childNature:'~'}}</p>
            </li>
            <li>
              <p>来源渠道</p>
              <p>{{sourceTypeFn(seeRuleForm.sourceType)}}</p>
            </li>
            <li>
              <p></p>
              <p></p>
            </li>
          </ul>
        </div>
        <div class="info_div1">
          <p style="font-size:16px;color:#000;font-weight: bold;margin-top:50px;">家长信息</p>
          <ul>
            <li>
              <p>母亲姓名</p>
              <p>{{ seeRuleForm.childMotherName?seeRuleForm.childMotherName:"~" }}</p>
            </li>
            <li>
              <p>母亲电话</p>
              <p>{{ seeRuleForm.childMotherPhone?seeRuleForm.childMotherPhone:"~" }}</p>
            </li>
            <li>
              <p>父亲姓名</p>
              <p>{{ seeRuleForm.childFatherName?seeRuleForm.childFatherName:"~" }}</p>
            </li>
            <li>
              <p>父亲电话</p>
              <p>{{ seeRuleForm.childFatherPhone?seeRuleForm.childFatherPhone:"~" }}</p>
            </li>
            <li>
              <p>微信/QQ</p>
              <p>{{ seeRuleForm.parentWechat?seeRuleForm.parentWechat:"~" }}</p>
            </li>
          </ul>
        </div>
         <p style="font-size:16px;color:#000;font-weight: bold;margin-top:50px;">空闲时间</p>
         <el-row :gutter="20" class="row_detail"  align="bottom">
           <div class="class_title">
              <span
                @click="classTimeTyp = 1"
                :class="{ active: classTimeTyp == 1 }"
                >首次上课时间</span
              >
              <span
                @click="classTimeTyp = 2"
                :class="{ active: classTimeTyp == 2 }"
                class="mL20"
                >正式上课时间</span
              >
            </div>
            <div class="table_area" v-show="classTimeTyp == 1">
              <table class="self_table">
                <thead>
                  <tr>
                    <td>周一</td>
                    <td>周二</td>
                    <td>周三</td>
                    <td>周四</td>
                    <td>周五</td>
                    <td>周六</td>
                    <td>周日</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in firstClassTimeList"
                    :key="index"
                  >
                    <td v-for="(subItem, subIndex) in item" :key="subIndex">
                      <el-checkbox v-model="subItem.status" disabled>{{
                        subItem.name
                      }}</el-checkbox>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="class_tip">
                <span>上午：8:00-12:00</span>
                <span>下午：12:00-18:00</span>
                <span>晚上：18:00-22:00</span>
              </div>
              <div class="label_info">
                <span>上课时间备注：</span>
                <span>{{
                  seeRuleForm.firstClassTimeRemark
                    ? seeRuleForm.firstClassTimeRemark
                    : "暂无数据"
                }}</span>
              </div>
            </div>
            <div class="table_area" v-show="classTimeTyp == 2">
              <table class="self_table">
                <thead>
                  <tr>
                    <td>周一</td>
                    <td>周二</td>
                    <td>周三</td>
                    <td>周四</td>
                    <td>周五</td>
                    <td>周六</td>
                    <td>周日</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in formalClassTimeList"
                    :key="index"
                  >
                    <td v-for="(subItem, subIndex) in item" :key="subIndex">
                      <el-checkbox v-model="subItem.status" disabled>{{
                        subItem.name
                      }}</el-checkbox>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="class_tip">
                <span>上午：8:00-12:00</span>
                <span>下午：12:00-18:00</span>
                <span>晚上：18:00-22:00</span>
              </div>
              <div class="label_info">
                <span>上课时间备注：</span>
                <span>{{
                  seeRuleForm.formalClassTimeRemark
                    ? seeRuleForm.formalClassTimeRemark
                    : "暂无数据"
                }}</span>
              </div>
            </div>
         </el-row>
         <p style="font-size:16px;color:#000;font-weight: bold;padding-bottom:26px;margin-top:50px;">课程信息</p>
        <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
           <el-col :span="20">
            <span class="studentSmallTitle">选择必考科目：</span>
            <template v-for="item in subjectList_info">
            <el-checkbox
              v-model="item.select"
              v-show="item.value == 'sys.teach.subject.base'"
              class="mR10"
              :key="item.macroId"
              disabled
              >{{ item.name }}</el-checkbox
            >
          </template>
          </el-col>
        </el-row>
        <div class="classInfo"  v-for="item in subjectList_info" :key="item.macroId" v-show="item.status">
            <p>
              <span style="font-size:16px;color:#1890FF;font-weight:bold;">{{ item.name }}</span>
              <el-button size="small">发送给老师</el-button>
            </p>
            <div>
              <span class="studentSmallTitle">设置讲师要求</span>
              <p>
                <span>性别偏好：{{judgeSex(item.sexNeedId)}}</span>
                <span>讲师教龄：{{judgeAgeNeed(item.ageNeedId)}}</span>
                <span>讲课速度：{{getTeachSpeed(item)}}</span>
                <span>风格偏好：{{judgeStyleNeed(item.styleNeedId)}}</span>
              </p>
            </div>
            <div style="min-height:56px;background: #F3F9FF;border-radius: 3px;display:flex;align-items:center;">
              <span style="margin-left:25px;">学生成绩：</span>
              <ul>
                <li v-for="(items,indexs) in item.xmtStudentAcademicRecordList" :key="indexs"  style="display:flex;align-items:center">
                  <span style="width: 15%">{{ items.examinationTime?items.examinationTime.substring(0,11):'' }}</span>
                  <span  style="width:15%;">{{judgeExamination(items.examinationType)}}</span>
                  <span style="width:10%;">{{items.subjectScore?items.subjectScore:''}}/{{items.subjectAllScore?items.subjectAllScore:''}}</span>
                  <span style="flex:1;" class="remarksSpan">{{items.remarks}}</span>
                  <el-button style="width:15%;" type="text" @click="seeTestPaper(items)">试卷预览</el-button>
                </li>
              </ul>
            </div>
        </div>
         <p class="studentTitle" v-show="seeRuleForm.referralstudent">介绍人信息</p>
         <el-row :gutter="20" class="row_detail" type="flex" align="bottom" v-show="seeRuleForm.referralstudent">
          <el-col :span="8">
            <span>老生姓名：</span>
            <span>{{ seeRuleForm.referralstudent?seeRuleForm.referralstudent:"" }}</span>
          </el-col>
          <el-col :span="8">
            <span>联系方式：</span>
            <span>{{ seeRuleForm.referralphone?seeRuleForm.referralphone:"" }}</span>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog width="600px" center :visible.sync="seeImageShow">
      <div class="dialog-content" style="display: flex;flex-direction: column;justify-content:center;align-items: center;padding-bottom:20px;">
         <el-image 
          style="width: 200px; height: 200px;"
          :src="srcUrl" 
          :preview-src-list="srcList">
        </el-image>
        <p style="margin-top:15px;">(默认显示第一张,可点击预览大图)</p>
      </div>
    </el-dialog>
    <el-dialog
      title="需求详情"
      :visible.sync="demandDialog"
      width="800px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div>
          <el-row :gutter="20" class="row_details" type="flex" align="bottom">
            <el-col :span="8">
              <span>学科：</span>
              <span>{{dealSubjectIdd(demandParams)}}</span>
            </el-col>
            <el-col :span="8">
              <span>科目成绩：</span>
              <span>{{demandParams.subjectScore ? demandParams.subjectScore : '暂无数据'}}/{{demandParams.subjectAllScore ? demandParams.subjectAllScore : '暂无数据'}}</span>
            </el-col>
            <el-col :span="8">
              <span>讲师职称：</span>
              <span>{{getTeachTitleId(demandParams)}}</span>
            </el-col> 
          </el-row>
          <el-row :gutter="20" class="row_details" type="flex" align="bottom">
            <el-col :span="8">
              <span>讲师教龄：</span>
              <span>{{getTeachingAgeId(demandParams)}}</span>
            </el-col>
            <el-col :span="8">
              <span>性格偏好：</span>
              <span>{{getSexNeedId(demandParams)}}</span>
            </el-col>
             <el-col :span="8">
              <span>风格偏好：</span>
              <span>{{getStyleNeedId(demandParams)}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_details" type="flex" align="bottom">
            <el-col :span="8">
              <span>讲课速度：</span>
              <span>{{getTeachSpeed(demandParams)}}</span>
            </el-col>
            <el-col :span="8">
              <span>年龄偏好：</span>
              <span>{{getAgeNeedId(demandParams)}}</span>
            </el-col>
             <el-col :span="8">
              <span>排课次数：</span>
              <span>{{getClassTimes(demandParams)}}</span>
            </el-col>
          </el-row>
        
          <el-row :gutter="20" class="row_details" type="flex" align="bottom">
            <el-col :span="8">
              <span>优先排课时间：</span>
              <span>{{getPriorTimeOne(demandParams)}} {{getPriorTimeTwo(demandParams)}} {{getPriorTimeThree(demandParams)}}</span>
            </el-col>
            <el-col :span="8">
              <span>优先排课日期：</span>
              <span>{{getPriorDateOne(demandParams)}} {{getPriorDateTwo(demandParams)}} {{getPriorDateThree(demandParams)}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_details" type="flex" align="bottom">
            <el-col :span="8">
              <span>上课形式：</span>
              <span>{{getClassForm(demandParams)}}</span>
            </el-col>
            <el-col :span="8">
              <span>学生情况描述：</span>
              <span>{{demandParams.studentDescribe ? demandParams.studentDescribe : '暂无数据'}}</span>
            </el-col>
            <el-col :span="8">
              <span>备注：</span>
              <span>{{demandParams.remark ? demandParams.remark : "暂无数据"}}</span>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" >
        <el-button
          type="primary"
          @click="demandDialog=false"
          size="small"
          >返 回</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="学习报告预览"
      :visible.sync="seeStudyPopup"
      width="700px"
      :close-on-click-modal="false"
      center
      @close='closeDialog'
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="student_info">
          <dl>
            <dt><img  style="width: 88px; height: 88px;border-radius:50%; " :src="updateImg.teachManagerPhoto"> </dt>
            <dd>
              <p>{{studentListDetail.name}}</p>
              <p>{{ studentListDetail.sectionName?studentListDetail.sectionName:"~" }}</p>
              <p style="margin-top:10px;">{{startTime?startTime.substring(0,11):''}}~{{endTime?endTime.substring(0,11):''}}</p>
            </dd>
          </dl>
          <p> <el-button type="primary">学习报告</el-button></p>
        </div>
       <div class="census_div">
            <p>上课时长对比</p>
            <div style="margin-top:20px;">
            <div class="infoDiv2" style="margin-left:25px;">
              <span  v-for="(item,index) in subjectData" :class="{reportActive:reportNum==index}" v-show="item.flag" @click="comparisonClassClick(item.macroId,index,item,1)" :id="'nav' + index" v-hClick :key="index" type="success" plain size="small" >{{item.name}}</span>
            </div>
              <div id="comparisonClassHoursCharts" ref="comparisonClassHoursCharts" style="width: 600px;height:400px;"></div>
            </div>
        </div>
        <div class="census_div">
            <p>课堂反馈</p>
            <div class="infoDiv2" style="margin-left:25px;margin-top:30px;">
              <span @click="comparisonClassClick(item.macroId,index,item,2)"  v-for="(item,index) in subjectData"  :class="{reportActive:reportNum3==index}" v-show="item.flag"  :id="'feedback' + index" :key="index"  type="success" plain size="small" >{{item.name}}</span>
            </div>
            <p style="font-size:15px;color:#666;font-weight:600;margin-left:25px;margin-top:30px;">课堂评价</p>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">学生在课堂听课的认真程度</p>
            
            <div id="feedbackCharts" ref="feedbackCharts" style="width: 600px;height:400px;"></div>
            <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">上次课作业的完成程度</p>
             <div id="teaFinishCharts" ref="teaFinishCharts" style="width: 600px;height:400px;"></div>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">学生与老师的互动效果</p>
             <div id="teaInteractionCharts" ref="teaInteractionCharts" style="width: 600px;height:420px;"></div>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">学生对本堂课内容的掌握程度</p>
             <div id="teaUnderstandCharts" ref="teaUnderstandCharts" style="width: 600px;height:400px;"></div>
              <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">作业情况</p>
             <div id="homeWorkDownloadCharts" ref="homeWorkDownloadCharts" style="width: 600px;height:400px;"></div>
        </div>
        <div class="census_div">
            <p>上课情况</p>
           <div class="infoDiv2" style="margin-left:25px;margin-top:30px;">
              <span  v-for="(item,index) in subjectData" @click="comparisonClassClick(item.macroId,index,item,3)" :class="{reportActive:reportNum4==index}" v-show="item.flag"  :id="'cancelClass' + index"  :key="index"  type="success" plain size="small" >{{item.name}}</span>
            </div>
          <div id="cancellationOfClassCharts" ref="cancellationOfClassCharts" style="width: 600px;height:400px;"></div>
        </div>
        <div class="census_div">
            <p>后续上课计划</p>
           <div class="infoDiv2" style="margin-top:20px;">
              <span  v-for="(item,index) in subjectData" @click="stuComparisonClick(item.macroId,index)" :class="{reportActive:reportNum2==index}" v-show="item.flag"  :key="index"  type="success" plain size="small" >{{item.name}}</span>
            </div>
        </div>
        <div v-for="(item,index) in subjectData" :key="index" v-show="reportNum2==index"  style="margin-top:30px;">
            <p style="font-size:16px;">课时数：{{subjectData[index].stuComparisonClassHours}}</p>   
            <p style="font-size:16px;margin-top:10px;">计划内容：{{subjectData[index].plan}}</p>   
        </div>
         <div class="census_div">
             <p>给家长的建议</p>
             <p style="margin-top:10px;min-height:100px;">{{parentProposal}}</p>
         </div>
         <div class="census_div">
             <p>给学生的建议</p>
             <p style="margin-top:10px;min-height:100px;">{{stuProposal}}</p>
         </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="addStudyParams.title"
      :visible.sync="addStudyPopup"
      width="700px"
      @close='closeDialog'
      :close-on-click-modal="false"
      center
    
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <el-form
          :model="studyRuleForm"
          status-icon
          :rules="studyRules"
          ref="studyRuleForm"
          label-width="120px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="开始上课时间" prop="name">
                <el-date-picker
                  v-model="startTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                   @change="startTimeChange()"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="结束上课时间" prop="name">
                <el-date-picker
                  v-model="endTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                   @change="endTimeChange()"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="选择报告的科目" prop="name">
                <el-select v-model="reportValue" multiple  @change="reportChangeSubject()" @blur="reportBlur()" placeholder="请选择">
                  <el-option
                    v-for="item in subjectidList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="info-title2"> <el-checkbox v-model="comparisonClassHours"></el-checkbox><span style="margin-left:10px;">上课时长对比</span><span style="color:red;">（必填项）</span></div>
          <div style="margin-top:10px">
            <div class="infoDiv2" style="margin-left:25px;">
              <span  v-for="(item,index) in subjectData" :class="{reportActive:reportNum==index}" v-show="item.flag" @click="comparisonClassClick(item.macroId,index,item,1)" :id="'nav' + index" :ref="'nav' + index" v-hClick="{index:index,macroId:item.macroId,flag:item.flag}" :key="index" type="success" plain size="small" >{{item.name}}</span>
            </div>
              <div v-for="(item,index) in subjectData" :key="index" v-show="reportNum==index" style="margin-top:30px;">
                  <el-form-item label="平均上课时长" prop="name">
                    <input  class="el-input__inner" style="width:80px;" @blur="averageClassHoursInput(index)" @input="averageClassChange(item.classHours,index)"  v-model="subjectData[index].classHours">   课时
                  </el-form-item>
              </div>
              <div id="comparisonClassHoursChart" ref="comparisonClassHoursChart" style="width: 600px;height:400px;"></div>
            </div>
            <div class="info-title2"><el-checkbox v-model="classroomFeedback"></el-checkbox><span style="margin-left:10px;">课堂反馈</span></div>
            <div class="infoDiv2" style="margin-left:25px;margin-top:10px;">
              <span @click="comparisonClassClick(item.macroId,index,item,2)"  v-for="(item,index) in subjectData"  :class="{reportActive:reportNum3==index}" v-show="item.flag"  :id="'feedback' + index" :key="index"  v-hClick="{index:index,macroId:item.macroId,flag:item.flag}" type="success" plain size="small" >{{item.name}}</span>
            </div>
             <p style="font-size:15px;color:#666;font-weight:600;margin-left:25px;margin-top:30px;">课堂评价</p>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">学生在课堂听课的认真程度</p>
             <div id="feedbackChart" ref="feedbackChart" style="width: 600px;height:400px;"></div>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">上次课作业的完成程度</p>
             <div id="teaFinishChart" ref="teaFinishChart" style="width: 600px;height:400px;"></div>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">学生与老师的互动效果</p>
             <div id="teaInteractionChart" ref="teaInteractionChart" style="width: 600px;height:400px;"></div>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">学生对本堂课内容的掌握程度</p>
             <div id="teaUnderstandChart" ref="teaUnderstandChart" style="width: 600px;height:400px;"></div>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">作业情况</p>
             <div id="homeWorkDownloadChart" ref="homeWorkDownloadChart" style="width: 600px;height:400px;"></div>
           <div class="info-title2"><el-checkbox v-model="cancellationOfClass"></el-checkbox><span style="margin-left:10px;">取消课情况</span></div>
            <div class="infoDiv2" style="margin-left:25px;margin-top:10px;">
              <span  v-for="(item,index) in subjectData" @click="comparisonClassClick(item.macroId,index,item,3)" :class="{reportActive:reportNum4==index}" v-show="item.flag"  :id="'cancelClass' + index"  :key="index"  type="success" plain size="small" >{{item.name}}</span>
            </div>
            <div id="cancellationOfClassChart" ref="cancellationOfClassChart" style="width: 600px;height:400px;"></div>
            <div class="info-title2"><el-checkbox v-model="followUpClassPlan"></el-checkbox><span style="margin-left:10px;">后续上课计划</span><span style="color:red;">（必填项）</span></div>
            <div class="infoDiv2" style="margin-left:25px;margin-top:10px;">
              <span  v-for="(item,index) in subjectData" @click="stuComparisonClick(item.macroId,index)" :class="{reportActive:reportNum2==index}" v-show="item.flag"  :key="index"  type="success" plain size="small" >{{item.name}}</span>
            </div>
            <div v-for="(item,index) in subjectData" :key="index" v-show="reportNum2==index"  style="margin-top:30px;">
                <el-row :gutter="20" >
                <el-col :span="20">
                  <el-form-item label="课时数" prop="name">
                    <input class="el-input__inner" style="width:90px;" maxlength="3" v-model="subjectData[index].stuComparisonClassHours">  课时
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20" >
                <el-col :span="20">
                  <el-form-item label="计划内容" prop="name">
                    <input
                    :rows="3"
                    class="el-input__inner"
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="subjectData[index].plan">
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div class="info-title2"><el-checkbox v-model="adviceToParents"></el-checkbox><span style="margin-left:10px;">给家长的建议</span></div>
                  <el-input
                    type="textarea"
                    show-word-limit
                    :rows="3"
                    maxlength="500"
                    placeholder="请输入内容"
                    v-model="parentProposal">
                </el-input>
          <div class="info-title2"><el-checkbox v-model="suggestionsForParticipants"></el-checkbox><span style="margin-left:10px;">给学员的建议</span></div>
                  <el-input
                    type="textarea"
                    show-word-limit
                    :rows="3"
                    maxlength="500"
                    placeholder="请输入内容"
                    v-model="stuProposal">
                </el-input>
          
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addStudyPopup = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="saveReport('studyRuleForm')"
          size="small"
          :disabled="isSaveDisable"
          >确 定</el-button
        >
         
      </span>
    </el-dialog>
    <div class="imgDolg" v-show="imgPreview.show" @click.stop="imgPreview.show = false">
      <i class="el-icon-close" id="imgDolgClose" @click.stop="imgPreview.show = false"></i>
      <img @click.stop="imgPreview.show = true" :src="imgPreview.img" />
    </div>
    <followCont ref="followCont" :followDialog="followDialog" :followId="followId" @change_followStatus="change_followStatus" ></followCont>
    <orderInfo ref="orderInfo" :orderInfoStatus="orderInfoStatus"  :orderInfoParams="orderInfoParams" @CB_orderInfoShow="CB_orderInfoShow"></orderInfo>
  </div>
</template>
<script>
import orderInfo from '../../finance/dialog/orderInfo.vue';
import followCont from '../expertTeachBusiness/studentFile/followCont.vue'
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  followTags,
  constellation,//星座
} from "@/api/public";
import {
  unDistribute,
  alreadyDistribute,
  listNoPage,
  updateTeachManagerId,
  configureCourseList,
  configureCourseSave,
  configureCourseRemove,
  removeByStudentId,
  selectStudentState,
  studentStudyReportList,
  studentStudyReportSave,
  studentStudyReportRemove,
  studentStudyReportUpdate,
  studentStudyReportInfo,
  getChartData,
  alreadyDistributeCount,
  sendStudyReport,getProductByStudentIdForBatch,balanceRecharge,setStudentStatus
} from "@/api/administration/tubeTeachBusiness/studentManage.js";
import { studentInfo,studentDelete,studentUpdate,infoByParentCodeAndValue } from "@/api/sales/saleManage";
import { orderList, followUpList,orderDetail} from "@/api/sales/myCustomer";
import { systemAreaList } from "@/api/system/systemManage";
import { findCourseItem,
  configteacherPlan,
  xmtProductItemRemove,
  xmtProductItemUpdate,
  getProductByStudentId,
  getResiduePrice,
  studentClassAddressList,//上课地址列表
  studentClassAddressSave,//上课地址列表新增
  studentClassAddressUpdate,//上课地址列表修改
  studentClassAddressRemove,//上课地址列表删除
  studentClassAddressInfo,//上课地址列表详情
  checkDefault,
  findProductItemPage,
  findMoreProductItemPage,
  findDelProductItemPage,
  batchCancel,
  xmtStudentNeedsList,
  getDetailByStudentId,
  checkTeacherClassTime,//老师是否被占用
  checkStudentClassTime,//学生是否被占用
  xmtProductItemSave,//新增排课接口
  xmtProductItemInfo,
  xmtProductItembatchSave,
  xmtProductItemDelete,
  batchCheckTeacherClassTime,//批量排课钱判断老师是否被占用
  batchCheckStudentClassTime,
  teacherFollowupRecordList,
  checkTeacherOrganClassTime,
  batchCheckTeacherOrganClassTime,
  saveOneToOne,
  batchSaveOneToOne,
  getOtoPriceByStudentId,
  deleteMakeUpTimeForClass,
  makeUpTimeForClass
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
function formatNumber(n) {
    return n.toString().length > 1 ? n : '0' + n;
}
const rich = {
  b: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  n: {
    fontSize: 14,
    // lineHeight: 20
  }
}

const makeMultiLine = (str, charQty) => {
  const strs = str.split('')
  const len = strs.length
  if (len < charQty + 1) {
    return str
  }
  let result = ''
  strs.forEach((_, index) => {
    result += _
    if ((index + 1) % charQty === 0 && index < len - 1) {
      result += '\n'
    }
  })
  return result
}
const cNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
const getYearWeek = function (a, b, c) {
  //a = d = 当前日期
  //b = 6 - w = 当前周的还有几天过完(不算今天)
  //a + b 的和在除以7 就是当天是当前月份的第几周
  var date = new Date(a, parseInt(b) - 1, c),
    w = date.getDay(),
    d = date.getDate();
  return Math.ceil((d + 6 - w) / 7);
};
const getWeek = {
    run: function (year) {
        var days = getWeek.getDate(year || new Date().getFullYear())
        var weeks = {};

        for (var i = 0; i < days.length; i++) {
            var weeksKeyLen = Object.keys(weeks).length;
            var daySplit = days[i].split('_');

            if (weeks[weeksKeyLen] == undefined) {
                weeks[weeksKeyLen + 1] = [daySplit[0]]
            } else {
                if (daySplit[1] == '1') {
                    weeks[weeksKeyLen + 1] = [daySplit[0]]
                } else {
                    weeks[weeksKeyLen].push(daySplit[0])
                }
            }
         }
        
         return weeks
     },
     getDate: function (year) {
         var dates = []
         for (var i = 1; i <= 12; i++) {
             for (var j = 1; j <= new Date(year, i, 0).getDate(); j++) {
                  dates.push(year + '-' + formatNumber(i) + '-' + formatNumber(j) + '_' + new Date([year, i, j].join('-')).getDay())
             }
         }

         return dates
     }
}
const weekOptions = [
  { name: "星期一", id: "1" },
  { name: "星期二", id: "2" },
  { name: "星期三", id: "3" },
  { name: "星期四", id: "4" },
  { name: "星期五", id: "5" },
  { name: "星期六", id: "6" },
  { name: "星期日", id: "0" },
];
 
var addDate;
var setDate;
var currentFirstDate;
var getYear;
import dayjs from "dayjs";
import { getFlowFeeByMode, getOrganBalance } from "@/api/system/systemManage";
import { mapState } from 'vuex';
export default {
  directives:{
    hClick:{
     inserted(el, binging){
         el.id == 'nav0' ? el.click() : null 
         el.id == 'cancelClass0' ? el.click() : null 
      }
    }
  },
  components: {
    followCont,
    orderInfo
  },
  data() {
    let check_productIdOne = (rule, value, callback) => {
 
      if (this.classHourStatus ==2) {
        if (!value) {
          callback(new Error("产品不能为空！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    }
    return {
      orderInfoStatus:false,
      orderInfoParams:{
        id:"",
        studentId:""
      },
      accountBalance:0,
      remainingClassHours:0,
      classHourStatus:'1',
      weekList:[],
      curWeekFirstDay: dayjs()
        .add(-(dayjs().day() == 0 ? 7 : dayjs().day()) + 1, "day")
        .format("YYYY-MM-DD"),
        weekItemArr: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      courseShow:false,
      subjectList_info: [],
      parentId:"0",
      provinceList:[],
      saveDialogVisible:false,
      seeDialogVisible:false,
      constellationList:[],
      studyParams:{
        pageNumber: 1,
        pageSize: 10,
        studentId:''
      },
      studyTotal:0,
      subjectData:[],
      assignedData:[],
      examinationTypeList:[
        {
          macroId:"1",
          name:"模拟考试"
        },
        {
          macroId:"2",
          name:"期中"
        },
        {
          macroId:"3",
          name:"期末"
        },
        {
          macroId:"4",
          name:"月考"
        },
        {
          macroId:"5",
          name:"随堂练"
        },
      ],
      batchLoading:false,
      switchValue:true,
      demandDialog:false,
      demandParams:{},
      srcUrl: '',
      imgPreview:{
        img:"",
        show:false
      },
      fileUpload,
      classTimeTyp:1,
      seeImageShow:false,
      srcList: [],
      batchProductId:"",
      seeRuleForm: {
        name: "",
        phoneNo: "",
        sex: "1",
        sectionId: "",
        birthday: "",
        provinceId: "",
        cityId: "",
        countyId: "",
        schoolName: "",
        sourceType: "",
        areaId: "",
        constellationId: "",
        childNature: "",
        childMotherName: "",
        childMotherPhone: "",
        childFatherName: "",
        childFatherPhone: "",
        parentWechat: "",
        firstClassTime: "",
        firstClassTimeRemark: "",
        formalClassTime: "",
        formalClassTimeRemark: "",
        qitaxueke:0,
        yuwen: 0,
        shuxue: 0,
        yingyu: 0,
        lishi: 0,
        zhengzhi: 0,
        dili: 0,
        wuli: 0,
        huaxue: 0,
        shengwu: 0,
        xmtStudentSubjectRelationEntityList: []
      },
      rechargeParams:{
        total:0,
        surplusMoney:0,
        classHours:0
      },
      surplusMoney:0,
      saveDisabled:false,
      newsinglePrice:"",
      getSectionId:"",
      editId:"",
      checkedGh:[],
      studentState:"",
      singlePopup:false,
      singleParams:{
        title:"新增课程",
        type:"add"
      },
      newProductId:"",
      surplusNum:0,
      buyProductList:[],
      batchProductList:[],
      singlePopupRules:{
         productId: [
          { validator: check_productIdOne, trigger: "blur" }
          // { required: true, message: "产品不能为空", trigger: "blur" },
        ],
           subjectId: [
            { required: true, message: "学科不能为空", trigger: "blur" },
          ],
          title: [
            { required: true, message: "课程标题不能为空", trigger: "blur" },
          ],
          usedClassHour:[
            { required: true, message: "课时数不能为空", trigger: "blur" },
          ],
          beginTime:[
              { required: true, message: "上课时间不能为空", trigger: "blur" },
          ],
          teacherId:[
              { required: true, message: "讲师不能为空", trigger: "change" },
          ],
      },
      centerTrue:false,
      centerOrganName :"",
      studyReportList:[],
      videoModeList: [
        {
          name: "普通模式",
          type: 1,
        },
        {
          name: "高级模式",
          type: 2,
        },
      ],
      oneToOneSingleClassPrice: 0, // 一对一单价
      organTotalBalance: "--", // 机构剩余金额
      singleRuleForm:{
          subjectId:"",//学科
          classMode:"1",//上课模式
          title:"",//课程标题
          classHourType:this.classHourStatus==2?"1":"3",//收费类型
          usedClassHour:"2",//课时数
          beginTime:'',//上课时间
          isFirstClass:"0",//首次课设置
          isPrisonClass:"0",
          isPlayback:"0",
          teacherId:"",//讲师
          productId:"",
          classAddress:"",
          totalPrice:"",//总金额
          usePrice:"",//这节课使用的金额
          videoMode: 1, // 1 普通模式  2 高级模式
          expense: "--", // 消费金额
      },
      saveAddressForm:{
        isDefault:"0",
        provinceId:"",
        cityId:"",
        countyId:"",
        detailAddress:""
      },
      saveAddressRules:{},
      addressCityList:[],
      addressCountyList:[],
      newdetailAddress:[],
      batchPickerValue:"",
      usedClassHour:"2",
      usedTitle:"",

      surplusNum2:0,
      courseDate:[],
      pickerData:"",

      tableSubjectId:"",
       batchTimeForm:{
          beginTime:"",
          stopTime:"",
          classHourType:"",
      },
      checkedWeeks:[],
      followDialog:false,
      followId:"",
      week:[],
      selectTeacherPopup:false,
      teacherType:1,
      centerBeginTime:"",
      centerTitle:"",
      batchTimePopup:false,
      centerUsedClassHour:"",
       centerStudentName:"",
      centerTeacherName:"",
      matchingTeacherList:[],
      teacherTotalnew:0,
      centerDialogVisible:false,
      centerType:"",
     
      singSubjectIdList:[ 
        {
          macroId: "0",
          name: "全部",
        }
      ],
      surplusNum:"",
      outData:{},
      addAddressPopup:false,
      addressProvinceList:[],
       parentId: "0",
       beforePickerOptions:  {
            // 限制时间
            selectableRange: ['06:00:00 -23:59:59'],
           // 限制日期
            disabledDate: this.disabledDateTime
        },
      offlineBeforePickerOptions: {
        selectableRange: ["06:00:00 -23:59:59"],
        disabledDate: false,
        format: "HH:mm",
      },
      addAddressParams:{
          title:"添加地址",
          type:"add"
      },
       classHourTypeList:[
        {
          value: "1",
          label: "正常课时",
          show:"2"
        },
        {
          value: "2",
          label: "赠送课时",
          show:"2"
        },
        {
          value: "3",
          label: "收费课程",
          show:"1"
        },
        {
          value: "5",
          label: "赠送课时",
          show:"1"
        },
      ],
      weeks:weekOptions,
      isWeekIndeterminate:false,
      checkWeekAll:false,
      newteacherName:"",
      getSubjectId:"",
      getSinglePrice:0,
      surplusNum:0,
      teacherParams:{
        status:1,
        label:2,
        studentId:'',
        subjectId:'',
        pageNumber: 1,
        pageSize: 10
        
      },
      firstClassTimeList: [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ],
     formalClassTimeList: [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ],
       expireTimeOptionBefore:{
         disabledDate (date) {
            return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
         }
      },
      reportNum:0,
      reportNum2:0,
      reportNum3:0,
      reportNum4:0,
      stuProposal:'',
      parentProposal:'',
      reportNum:0,
      reportNum2:0,
      reportNum3:0,
      reportNum4:0,

      checkKey:'1',
      weekTitle:"",
      dataArray2:[],
      dataWeek:[],
      tableList:[],
      arrangePopup:false,
      idd:"",
      studentDetailList:[],
      teacherList:[],
      detailAddressPopup:false,
      addressType:1,
      addressListData:[],
      totalData:0,
      classRecord:"1",
      timePickerOne:"",
      oneToOneData:[],
      oneToMoreData:[],
      delData:[],
      total3:0,
      total2:0,
      total1:0,
      timePickerMore:"",
      studentInfoDetail:{},
      orderSrc:[],
      studentListDetail:{},
      orderDetails:{},
      timePickerDel:"",
      needList:[],
      needTotal:0,
      subjectidList:[],
      xmtStudentNeeds:{
        studentId:"",
        pageNumber: 1,
        pageSize: 10
      },
      delCauseList:[
        {
          value: "",
          label: "全部"
        },
        {
          value: "1",
          label: "学生原因"
        },
        {
          value: "2",
          label: "老师原因"
        },
        {
          value: "3",
          label: "其他原因"
        },
      ],
      delParams:{
          dataAuthority:"dept",
          delCause:'',
          pageNumber: 1,
          pageSize: 10,
          searchBeginDate:null,
          searchEndDate:null,
          studentId:"",
      },
      timePickerValue:"",
      oneToMoreParams:{
          dataAuthority:"dept",
          roomName: "",
          pageNumber: 1,
          pageSize: 10,
          searchBeginDate: null,
          searchEndDate: null,
          studentId: "",
          attendClassStatus: "1",
          abnormalStatus: "",
      },
      countMap:{},
      cancellationOfClassArray:[],
      sourceTypeList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "地推活动",
        },
        {
          value: "2",
          label: "网络活动",
        },
        {
          value: "3",
          label: "美团活动",
        },
        {
          value: "4",
          label: "A级客户",
        },
        {
          value: "5",
          label: "家长转介绍",
        },
      ],
      onesubjectIdList:[
        {
          macroId: "",
          name: "全部"
        }
      ],
       updateImg:{
        photo:"",
        id:"",
      },
      removeClassVisible:false,
      getRemoveId:"",
      removeClassRadio:"",
      demandTotal:0,
      followUpParamsTotal:0,
      followUpTotal:0,
      infoOrderType:"",
      countParams:{
        suspend:0,//暂停课
        studying:0,//在读
        refund:0,//退费
        endClass:0,
      },
      findListParams:{
          orderType: "1",
          dataAuthority: "dept",
          name: "",
          subjectId: "",
          attendClassStatus: "1",
          abnormalStatus: "",
          classMode: "",
          pageNumber: 1,
          pageSize: 10,
          searchBeginDate: null,
          searchEndDate: null,
      },
      classAddressData:{
        studentId:"",
        pageNumber:1,
        pageSize:10
      },
      classHourStatusMore:'1',
      followupRecord:{
        pageNumber: 1,
        pageSize: 10,
        studentId:""
      },
      configteacherParams: {
        status: 1,
        studentId: "",
        pageNumber: 1,
        pageSize: 10
      },
      seeStudyPopup:false,
       addStudyParams:{
        type:'add',
        title:"新增学习报告"
      },
      addStudyPopup:false,
       studyRuleForm:{
        XmtStudentFollowUpClassPlanList:[],//后续上课计划
        XmtStudentComparisonClassHoursList:[],//上课时长对比
        studentId:'',

      },
      startTime:null,
      endTime:null,
      isSaveDisable:false,
      reportValue:"",
      studyRules:{},
      comparisonClassHours:false,//上课时长对比状态
      classroomFeedback:false,//课堂反馈状态
      cancellationOfClass:false,//取消课状态  上课情况
      followUpClassPlan:false,//后续上课状态
      adviceToParents:false,//家长的建议  
      suggestionsForParticipants:false,//学员的建议
      saveDisabled:false,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs()
                  .subtract(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(7, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(30, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(90, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },

       dataTimeArray: [
        {
          time: "6:00-7:00",
          min: 6,
          max: 7,
        },
        {
          time: "7:00-8:00",
          min: 7,
          max: 8,
        },
        {
          time: "8:00-9:00",
          min: 8,
          max: 9,
        },
        {
          time: "9:00-10:00",
          min: 9,
          max: 10,
        },
        {
          time: "10:00-11:00",
          min: 10,
          max: 11,
        },
        {
          time: "11:00-12:00",
          min: 11,
          max: 12,
        },
        {
          time: "12:00-13:00",
          min: 12,
          max: 13,
        },
        {
          time: "13:00-14:00",
          min: 13,
          max: 14,
        },
        {
          time: "14:00-15:00",
          min: 14,
          max: 15,
        },
        {
          time: "15:00-16:00",
          min: 15,
          max: 16,
        },
        {
          time: "16:00-17:00",
          min: 16,
          max: 17,
        },
        {
          time: "17:00-18:00",
          min: 17,
          max: 18,
        },
        {
          time: "18:00-19:00",
          min: 18,
          max: 19,
        },
        {
          time: "19:00-20:00",
          min: 19,
          max: 20,
        },
        {
          time: "20:00-21:00",
          min: 20,
          max: 21,
        },
        {
          time: "21:00-22:00",
          min: 21,
          max: 22,
        },
        {
          time: "22:00-23:00",
          min: 22,
          max: 23,
        },
        {
          time: "23:00-24:00",
          min: 23,
          max: 24,
        },
      ],
      seeFollowData:[],
      studentId: "",
      orderList: [],
      followUpList: [],
      activeName: "record",
      type: 1,
      activeIndex: "1",
      teachManagerIdList:[],
      listParams: {
        keyword: "",
        sectionId: "0",
        dataAuthority: "dept",
        pageNumber: 1,
        pageSize: 10,
        teachManagerId:""
      },
      studentList: [],
      checkRowId: [],
      total: 0,
      apportionVisible: false,
      saleList: [],
      saleId: "",
      username: "",
      sectionIdList: [
        {
          macroId: "0",
          name: "全部"
        }
      ],
      levelNum:1,
      studentVisible: false,
      studentListDetail: {},
      followTagList: [],
      courseVisible: false,
      courseList: {},
      subjectIdList: [],
      courseData: [],
      courseDataLength: 0,
      options: [
        {
          id: 1,
          label: "初级课程"
        },
        {
          id: 2,
          label: "中级课程"
        },
        {
          id: 3,
          label: "高级课程"
        },
        {
          id: 4,
          label: "特级课程"
        }
      ],
      rechargeList:[],
      value: "",
      unit: "",
      newStudentId: "",
      teacherSelectionHandle:[],
      studyTimes:{
        searchBeginTime:"",
        searchEndTime:"",
      },
      studentStateTotal:[1,5],
    };
  },
  created() {
    this.unDistribute();
  },
  async mounted() {
    await this.getSectionIdList();
    await this.getFollowTagList();
    await this.getSubjectList();
    this.classSubjectList();
    this.getConstellationList();
    this.findProductItemPage();
  },
  watch: {
        // studentPopup: function (val,oldVla) {
        //   if (this.$refs['saveRuleForm'] !== undefined) {
        //     this.$refs["saveRuleForm"].resetFields();
        //   }
        // },
        singlePopup: function (val,oldVla) {
          if (this.$refs['singleRuleForm'] !== undefined) {
            this.$refs["singleRuleForm"].resetFields();
          }
        },
        courseDate: {
      handler() {
        clearTimeout(this._computeOneToMoreFeeTimer);
        this._computeOneToMoreFeeTimer = setTimeout(() => {
          this.computeClassExpense();
        }, 200);
      },
      deep: true,
    },
        // orderDialogVisible: function (val,oldVla) {
        //   if (this.$refs['saveRuleFormOrder'] !== undefined) {
        //     this.$refs["saveRuleFormOrder"].resetFields();
        //   }
        // }
      },
  computed: {
    ...mapState('mainData', ['hasCdnMenu', 'hasRecordMenu'])
  },
  methods: {
    // 监课展示和回放录制权限提示
    handleAuthTip(_, flag){
      if(!flag){
        this.$message.warning('请联系管理员开同此功能！')
      }
    },
    levelTab(type){
      this.levelNum=type;
      if(type==1){
        this.studentStateTotal=[1,5]
      }else if(type==2){
        this.studentStateTotal=[6,2]
      }else if(type==3){
        this.studentStateTotal=[3]
      }else if(type==4){
        this.studentStateTotal=[4]
      }
      this.alreadyDistribute();
    },
    classHourTypeChange(){
      this.$set(this.singleRuleForm,'productId','')  
      this.surplusNum=0;
    },

    seeSex(sex){
      if(sex==1){
        return '男'
      }else if(sex==2){
        return '女'
      }else{
        return '暂无数据'
      }
    },
    getConstellationList(){
      getSelectList({
        parentCode: constellation
      }).then(res => {
        if (res.code == 0) {
          this.constellationList = res.rows;
        }
      });
    },
    showImg(e){
      if (e.target.tagName == 'IMG') {
        this.imgPreview.img = e.target.src
        this.imgPreview.show = true
      }
    },
    seeConstellationId(constellationId){
        for(var i=0;i<this.constellationList.length;i++){
          if(this.constellationList[i].macroId==constellationId){
            return this.constellationList[i].name;
          }
        }
    },
    CB_orderInfoShow(){
      this.orderInfoStatus=false;
    },
  seeTestPaper(data){
    if(data.testPapers){
      this.srcList=data.testPapers.split(',');
      this.srcUrl=this.srcList[0];
    }
    this.seeImageShow=true;
  },
  balanceTurn(row){
      this.$confirm("确定余额转充值吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          balanceRecharge({id:row.id}).then((res) => {
            if (res.code == 0&&res.msg=='操作成功！') {
              this.msgSuccess("操作成功！");
              this.orderListFn();
            }else{
              this.msgWarn(res.rows);
            }
          });
        })
        .catch(() => {});
    },
  enableClick(){
    let data={
      id:this.studentListDetail.id,
      status:this.studentListDetail.status==0?1:0,
    }
    
    setStudentStatus(data).then((res) => {
      if (res.code == 0) {
        this.msgSuccess("操作成功！");
        this.studentVisible=false;
        this.alreadyDistribute();
        this.alreadyDistributeCountFn();
      }else{
        this.msgWarn(res.rows);
      }
    });
  },
   async daalEdit(){
      this.seeDialogVisible =true;
      await this.getProvinceList();
      await studentInfo(this.studentId).then(res => {
        if (res.code == 0) {
          this.seeRuleForm = {
            name: res.rows.name,
            sectionName:res.rows.sectionName,
            phoneNo: res.rows.phoneNo,
            sex: String(res.rows.sex),
            sectionId: res.rows.sectionId,
            birthday: res.rows.birthday,
            provinceId: res.rows.provinceId,
            cityId: res.rows.cityId,
            countyId: res.rows.countyId,
            schoolName: res.rows.schoolName,
            sourceType: res.rows.sourceType==0?'':String(res.rows.sourceType),
            // areaId: this.saveRuleForm.areaId,
            constellationId: res.rows.constellationId,
            childNature: res.rows.childNature,
            childMotherName: res.rows.childMotherName,
            childMotherPhone: res.rows.childMotherPhone,
            childFatherName: res.rows.childFatherName,
            childFatherPhone: res.rows.childFatherPhone,
            referralphone:res.rows.referralphone,
            referralstudent:res.rows.referralstudent,
            parentWechat: res.rows.parentWechat,
            firstClassTime: res.rows.firstClassTime,
            firstClassTimeRemark: res.rows.firstClassTimeRemark,
            formalClassTime: res.rows.formalClassTime,
            formalClassTimeRemark: res.rows.formalClassTimeRemark,
            qitaxueke:res.rows.qitaxueke,
            yuwen: res.rows.yuwen,
            shuxue: res.rows.shuxue,
            yingyu: res.rows.yingyu,
            lishi: res.rows.lishi,
            zhengzhi: res.rows.zhengzhi,
            dili: res.rows.dili,
            wuli: res.rows.wuli,
            huaxue: res.rows.huaxue,
            shengwu: res.rows.shengwu,
            xmtStudentSubjectRelationEntityList: [],
            id: res.rows.id,
            schoolReach:res.rows.schoolReach,
            provinceName:res.rows.provinceName,
            cityName:res.rows.cityName,
            countyName:res.rows.countyName,
            no:res.rows.no,
          };
          this.initClassTime_info();
          for (let i = 0, len = this.firstClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.firstClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (
                res.rows.firstClassTime &&
                res.rows.firstClassTime.includes(
                  this.firstClassTimeList[i][j].key
                )
              ) {
                this.firstClassTimeList[i][j].status = true;
              }
            }
          }
          for (let i = 0, len = this.formalClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.formalClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (
                res.rows.formalClassTime &&
                res.rows.formalClassTime.includes(
                  this.formalClassTimeList[i][j].key
                )
              ) {
                this.formalClassTimeList[i][j].status = true;
              }
            }
          }
          for (let i = 0, len = this.subjectList_info.length; i < len; i++) {
            this.subjectList_info[i].status = false;
            this.subjectList_info[i].select = false;
            this.subjectList_info[i].subjectScore = "";
            this.subjectList_info[i].subjectAllScore = "";
            this.subjectList_info[i].sexNeedId = "";
            this.subjectList_info[i].styleNeedId = "";
            this.subjectList_info[i].speedNeedId = "";
            this.subjectList_info[i].ageNeedId = "";
            this.subjectList_info[i].content = "";
            if (
              (this.subjectList_info[i].name == "其他学科" && res.rows.qitaxueke == 1) ||
              (this.subjectList_info[i].name == "语文" && res.rows.yuwen == 1) ||
              (this.subjectList_info[i].name == "数学" && res.rows.shuxue == 1) ||
              (this.subjectList_info[i].name == "英语" && res.rows.yingyu == 1) ||
              (this.subjectList_info[i].name == "历史" && res.rows.lishi == 1) ||
              (this.subjectList_info[i].name == "政治" && res.rows.zhengzhi == 1) ||
              (this.subjectList_info[i].name == "地理" && res.rows.dili == 1) ||
              (this.subjectList_info[i].name == "物理" && res.rows.wuli == 1) ||
              (this.subjectList_info[i].name == "化学" && res.rows.huaxue == 1) ||
              (this.subjectList_info[i].name == "生物" && res.rows.shengwu == 1)
            ) {
              this.subjectList_info[i].select = true;
            }
          }
          for (let i = 0, len = this.subjectList_info.length; i < len; i++) {
         
            for ( 
              let j = 0,
                lenJ = res.rows.xmtStudentSubjectRelationEntityList.length;
              j < lenJ;
              j++
            ) {
              if (
                this.subjectList_info[i].macroId ==
                res.rows.xmtStudentSubjectRelationEntityList[j].subjectId
              ) {
                this.subjectList_info[i].xmtStudentAcademicRecordList =
                  res.rows.xmtStudentSubjectRelationEntityList[
                    j
                  ].xmtStudentAcademicRecordList;
                this.subjectList_info[i].status = true;
                this.subjectList_info[i].subjectScore =
                  res.rows.xmtStudentSubjectRelationEntityList[j].subjectScore;
                this.subjectList_info[i].subjectAllScore =
                  res.rows.xmtStudentSubjectRelationEntityList[
                    j
                  ].subjectAllScore;
                this.subjectList_info[i].sexNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].sexNeedId;
                this.subjectList_info[i].styleNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].styleNeedId;
                this.subjectList_info[i].speedNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].speedNeedId;
                this.subjectList_info[i].ageNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].ageNeedId;
                this.subjectList_info[i].content =
                  res.rows.xmtStudentSubjectRelationEntityList[j].content;
                   this.subjectList_info[i].id =
                  res.rows.xmtStudentSubjectRelationEntityList[j].id;
              }
            }
          }
          console.log(this.subjectList_info,'subjectList_info//////////')
        }
      });
    },
    initClassTime_info() {
      this.formalClassTimeList = [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ];
      this.firstClassTimeList = [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ]
    },
     studentStateFn(studentState) {
      if (studentState == 0) {
        return "新生";
      } else if (studentState == 1) {
        return "在读";
      } else if (studentState == 2) {
        return "暂停课";
      } else if (studentState == 3) {
        return "退费";
      } else if (studentState == 4) {
        return "结课";
      }
    },
    seeFollow(row){
      this.followDialog=true;
      this.followId = row.id;
    },
     rechargeItemClick(row){
      this.$refs.rechargeListTable.toggleRowSelection(row);
    },
    handleOrderChange(val) {
      this.valLength = val.length;
      this.studentIdInfo = val[0].studentId;
      this.getOrderStatus = [];
      this.selectOrderList = [];
      this.orderChangeId = "";
      if (val.length > 0) {
        for (var i = 0; i < val.length; i++) {
          this.selectOrderList.push(val[i].id);
          this.orderChangeId += val[i].id + ",";
          this.getOrderStatus.push(val[i].orderStatus);
        }
        if (this.orderChangeId.length > 0) {
          this.orderChangeId = this.orderChangeId.substr(
            0,
            this.orderChangeId.length - 1
          );
        }
        this.saveOrderForm.orderIds = this.selectOrderList.toString();
      }
      // this.checkedGh2 = val.id;
      //       if (val.length > 1) {
      //         this.$refs.orderListTable.clearSelection();
      //         this.$refs.orderListTable.toggleRowSelection(val.pop());
      //       }
    },
    classHourStatusChange(){
      if(this.classHourStatus==1){
        this.singleRuleForm.classHourType='3';
      }else{
        this.singleRuleForm.classHourType='1';
      }
    },
    
    getProvinceList() {
      return systemAreaList({
        parentId: this.parentId
      }).then(res => {
        if (res.code == 0) {
          this.provinceList = res.rows;
        }
      });
    },
    singleTime(items){
      studentInfo(this.idd).then((res) => {
        this.accountBalance=res.rows.accountBalance;
        this.remainingClassHours=res.rows.remainingClassHours;
      });
      this.classHourStatus='1'
      let beginTime = "";
      if (items) {
        beginTime = items.cellTime ? items.cellTime : "";
        // 过去的时间段和已经占用的时间段不处理
        if (items.pastTime || !items.canUse) {
          return;
        }
      }
      this.singlePopup=true;
      this.singleParams.title='新增课程';
      this.getOrganBalance();
      this.getSingleClassFee();
      this.singleParams.type="add";
       this.singleRuleForm={
          subjectId:"",//学科
          classMode:"1",//上课模式
          title:"",//课程标题
          classHourType:this.classHourStatus==2?"1":"3",//收费类型
          usedClassHour:"2",//课时数
          beginTime,//上课时间
          isFirstClass:"0",//首次课设置
          teacherId:"",//讲师
          productId:"",
          classAddress:"",
          totalPrice:"",//总金额
          usePrice:"",//这节课使用的金额
          sectionId:this.getSectionId,
          videoMode: 1,
          expense: "--",
      },
      this.newProductId='';
      this.surplusNum=0;
      this.newteacherName="";
      this.teacherParams.subjectId="";
      this.productListFn();
    },
    reportSubject(subjectid){
      let subjectData = subjectid?subjectid.split(','):[];
      let str="";
      for(var i=0;i<this.subjectIdList.length;i++){
        for(var k=0;k<subjectData.length;k++){
          if(this.subjectIdList[i].macroId==subjectData[k]){
            str+=this.subjectIdList[i].name+'，'
          }
        }
      }
      return str.substring(0,str.length-1)
    },
    addStudyReport(){
      this.comparisonClassHours=false;
      this.classroomFeedback=false;
      this.cancellationOfClass=false;
      this.followUpClassPlan=false;
      this.adviceToParents=false;
      this.suggestionsForParticipants=false;
      this.startTime=null;
      this.endTime=null;
      this.reportValue="";
      this.reportNum=0;
      this.reportNum2=0;
      this.reportNum3=0;
      this.reportNum4=0;
      this.studyRuleForm.id="";
      this.teaEarnest=[];
      this.teaFinish=[];
      this.interactionName=[];
      this.interactionData=[];
      this.teaUnderstand=[];
      this.workHome=[];
      for(var i=0;i<this.subjectidList.length;i++){
        this.subjectidList[i].flag=false;
        this.subjectidList[i].plan="";
        this.subjectidList[i].classHours="";
        this.subjectidList[i].comparisonOne="";
        this.subjectidList[i].stuComparisonClassHours="";
      }
      for(var i=0;i<this.subjectData.length;i++){
        this.subjectData[i].flag=false;
        this.subjectData[i].plan="";
        this.subjectData[i].classHours="";
        this.subjectData[i].comparisonOne="";
        this.subjectData[i].stuComparisonClassHours="";
      }
      this.parentProposal="";
      this.stuProposal="";
      this.addStudyPopup = true;
        this.$nextTick(() => {
        this.drawChart();
        this.drawChart2();
      })
      this.addStudyParams={
        type:'add',
        title:"新增学习报告"
      }
    },
     change_followStatus(){
      this.followDialog=false;
    },
    seeOrderClick(row){
      this.orderInfoStatus=true;
      this.orderInfoParams={
        id:row.id,
        studentId:row.studentId
      }
    },
    classMoreChange(){
      if(this.classHourStatusMore==1){
        this.batchTimeForm.classHourType='3';
      }else{
        this.batchTimeForm.classHourType='1';
      }
    },
     handleClickItem(){
      this.$nextTick(()=>{
			let domImageMask = document.querySelector(".el-image-viewer__mask");
			 if (!domImageMask) {
		 	  return;
		 	}
			domImageMask.addEventListener("click", () => {
			  	 document.querySelector(".el-image-viewer__close").click();
			});
		})
    },
    datePickerChange() {
      if (!this.timePickerValue) {
        this.listParams.createBeginTime = null;
        this.listParams.createEndTime = null;
        return;
      }
      this.listParams.createBeginTime = this.timePickerValue[0];
      this.listParams.createEndTime = this.timePickerValue[1];
    },
    startTimeChange(){
      this.studyTimes.searchBeginTime = this.startTime+' 00:00:00'
    },
    endTimeChange(){
       this.studyTimes.searchEndTime = this.endTime+' 23:59:59'
    },
    formatterMoneyType(item){
      switch(item.moneyType){
        case 1: 
          return '全款'
        case 2: 
          return '定金'
        case 3: 
          return '尾款'
        case 4: 
          return '特殊订单'
        case 5: 
          return '余额转充值'
        default:
          return ''
      }
    },
    surplusNumFn(val){
      var obj=this.buyProductList;
      var hostValue = {}
      obj.forEach((item, index) => {
        const deviceId = item.id
        if (!hostValue[deviceId]) {
          hostValue[deviceId] = {
            item
          }
        }
      })
      this.outData = hostValue[val].item;
      this.singleRuleForm.productId=this.outData.id;
      if(this.outData.zdySinglePrice!=null){//订单金额
        this.getSinglePrice=this.outData.zdySinglePrice;
      }else{
        this.getSinglePrice=this.outData.singlePrice;
      }
      getResiduePrice({classHourType:this.singleRuleForm.classHourType,orderId:this.outData.orderId,studentId:this.idd,totalPrice:this.outData.totalPrice?this.outData.totalPrice:Number(this.outData.zdyActualPrice)}).then(res => {
          if(res.code==0){
            this.surplusNum=res.rows;
             this.singleRuleForm.totalPrice=res.rows;
          }
      });
    },
     usedClassHourChange() {
      console.log(this.usedClassHour)
      for (var i = 0; i < this.courseDate.length; i++) {
        this.courseDate[i].usedClassHour = this.usedClassHour;
      }
    },
    timeClass(item){
      if(item.time){
        var minuteTop=Number(item.time.substring(3,5)),
            spacing=39.9/60,
            start=new Date(item.cellTime.split(' ')[0]+" "+item.time.split('-')[0]),
            end=new Date(item.cellTime.split(' ')[0]+" "+item.time.split('-')[1]),
            m='',
            h=''
            if (start && end) {
              var subTime = Math.floor((end.getTime() - start.getTime()) / 1000);
              var cal=Math.floor(subTime/60);//分钟
              }
        if(minuteTop!=0){
          return {
            'top':spacing*minuteTop+'px',
            'position':'absolute',
            'z-index':'99999',
            'background':'#1890FF',
            'width':'100%',
            'font-size':'12px',
            'color':'#fff',
            'height':Number(cal)*spacing+'px'
          }
        }else{
          return {
            'top':'0px',
            'position':'absolute',
            'z-index':'99999',
            'background':'#1890FF',
            'width':'100%',
            'font-size':'12px',
            'color':'#fff',
            'height':Number(cal)*spacing+'px'
          }
        }
      }
    },
    timeClasss(item){
      console.log(item.time,'time...')
        if(item.time){
        var minuteTop=item.time?Number(item.time.substring(3,5)):0,
            spacing=39.9/60,
            start=new Date(item.cellTime.split(' ')[0]+" "+item.time.split('-')[0]),
            end=new Date(item.cellTime.split(' ')[0]+" "+item.time.split('-')[1]),
            m='',
            h=''
            if (start && end) {
              var subTime = Math.floor((end.getTime() - start.getTime()) / 1000);
              var cal=Math.floor(subTime/60);//分钟
              }
        if(minuteTop!=0){
          return {
            'top':spacing*minuteTop+'px',
            'position':'absolute',
            // 'z-index':'999999',
            'width':'100%',
            'font-size':'12px',
            'color':'#fff',
            'height':Number(cal)*spacing+'px'
          }
        }else{
          return {
            'top':'0px',
            'position':'absolute',
            // 'z-index':'999999',
            'width':'100%',
            'font-size':'12px',
            'color':'#fff',
            'height':Number(cal)*spacing+'px'
          }
        }
        }
    },
    selectChanged(value){
      this.teacherParams.subjectId=value;
          if(value!=this.getSubjectId){
             this.newteacherName=''
             this.singleRuleForm.teacherId='';
          }
      for(var i=0;i<this.singSubjectIdList.length;i++){
              if(this.singSubjectIdList[i].macroId==value){
                this.singleRuleForm.title = this.getSectionName + this.singSubjectIdList[i].name;
              }
            }
    },
     removeStudyReport(row){
       this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          studentStudyReportRemove(row.id).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.studentStudyReportFn();
            }
          });
        })
        .catch(() => {});
    },
    updateStudyReport(row){
      this.$confirm("确定要发布吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sendStudyReport({id:row.id,studentId:row.studentId,searchBeginTime:row.searchBeginTime,searchEndTime:row.searchEndTime}).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.studentStudyReportFn();
            }
          });
        })
        .catch(() => {});
    },
    studentStudyReportFn(){
      this.studyParams.studentId=this.getstudentid;
      studentStudyReportList(this.studyParams).then((res) => {
        this.studyReportList=res.rows;
        this.studyTotal=res.total;
      });
    },
     //获取该学生购买过的产品
    productListFn(){
      getProductByStudentId(this.idd).then(res => {
        this.buyProductList=res.rows;
      });
    },
    batchProductListFn(){
      getProductByStudentIdForBatch({studentId:this.idd}).then((res) => {
        this.batchProductList = res.rows;
      });
    },
    switchChange(){
      if(this.switchValue==true){
        this.orderListFn();
      }else{
        this.rechargeOrderList();
      }
    },
    rechargeOrderList() {
        orderList({ studentId: this.getstudentid, dataAuthority: "dept",orderType:5 }).then(
          (res) => {
            if (res.code == 0) {
              this.rechargeList = res.rows;
            }
          }
        );
        getOtoPriceByStudentId({
          studentId: this.getstudentid,
          dataAuthority: "dept",
          orderType: 5,
        }).then((res) => {
          if (res.code == 0) {
            this.rechargeParams.total = res.rows?res.rows:0;
          }
        });
     
    },
    /* 视频模式改变 */
    videoModeChanged() {
      this.getSingleClassFee();
    },
    /* 课时数变化 */
    classHourChange() {
      this.computeClassExpense();
    },
    /* 获取当前机构余额 */
    getOrganBalance() {
      this.organTotalBalance = "--";
      getOrganBalance().then((res) => {
        console.log("getOrganBalance: ", res);
        if (res.flag == "error") {
          return this.$message.error(res.msg);
        }
        this.organTotalBalance = res.rows.accountBalance
          ? res.rows.accountBalance
          : 0;
      });
    },
    /* 获取特定上课模式下单节课的费用 */
    getSingleClassFee() {
      this.oneToOneSingleClassPrice = 0;
      this.singleRuleForm.expense = "--";
      const classMode = 1;
      let videoMode = 1;
      if (this.batchTimePopup) {
        // 批量排课
        videoMode = this.batchTimeForm.videoMode;
      } else if (this.singlePopup) {
        // 单次排课
        videoMode = this.singleRuleForm.videoMode;
      }
      if (videoMode) {
        getFlowFeeByMode({
          classMode,
          videoMode,
        }).then((res) => {
          console.log("getOrganBalance: ", res);
          if (res.flag == "error") {
            return this.$message.error(res.msg);
          }
          this.oneToOneSingleClassPrice = res.rows.flowFee;
          this.computeClassExpense();
        });
      }
    },
    /* 计算本次排课消耗金额 */
    computeClassExpense() {
      let classPirce = -1;
      this.singleRuleForm.expense = "--";
      // 单次排课处理
      if (this.singlePopup) {
        // this.singleRuleForm.classMode  1 线上
        if( this.singleRuleForm.classMode == 2){
          this.singleRuleForm.expense = 0
          return
        }
        const { usedClassHour } = this.singleRuleForm;
        if(this.oneToOneSingleClassPrice != 0){
          classPirce = this.oneToOneSingleClassPrice * usedClassHour;
        }
        if (classPirce == -1) {
          this.singleRuleForm.expense = "--";
        } else {
          this.singleRuleForm.expense = classPirce;
        }
        // 批量排课处理
      } else if (this.batchTimePopup) {
        console.log(111);
        if (this.courseDate.length > 0) {
          // isIncludeOnlineClass 是否包含有线上课，默认为false
          let totalHours = 0,
            isIncludeOnlineClass = false;
          for (let i = 0, len = this.courseDate.length; i < len; i++) {
            const { usedClassHour, classMode } = this.courseDate[i];
            if (!usedClassHour) {
              totalHours = 0;
              break;
            }
            if (!classMode) {
              // classMode true 线下课，流量费为0， false 线上课 有流量费
              isIncludeOnlineClass = true;
              totalHours += usedClassHour;
            }
          }
          // 1，如果总课时不为0说明，每个item 课时数都填写了， 2，如果总课时数为0，并且每个item 都为线下课时， 这两种情况显示消耗流量数
          if (totalHours || (!totalHours && !isIncludeOnlineClass)) {
            classPirce = this.oneToOneSingleClassPrice * totalHours;
          }
          if (classPirce == -1) {
            this.singleRuleForm.expense = "--";
          } else {
            this.singleRuleForm.expense = classPirce;
          }
        }
      }
    },
    sureSave(formName){
        this.$refs[formName].validate(valid => {
           if (valid) {
             if (this.saveDisabled) {
            return;
          }
          this.saveDisabled = true;
          if (this.singleRuleForm.expense == "--") {
            this.saveDisabled = false;
            return this.$message.warning("流量费用不正确！");
          }
          if (this.organTotalBalance < this.singleRuleForm.expense) {
            this.saveDisabled = false;
            return this.$message.warning("流量费用不能大于账户余额！");
          }
             if (this.getSinglePrice2) {
            //正常课程除外
            this.singleRuleForm.usePrice =
              Number(this.getSinglePrice2) *
              Number(this.singleRuleForm.usedClassHour);
          } else {//正常课程
             this.singleRuleForm.usePrice =
              Number(this.getSinglePrice) *
              Number(this.singleRuleForm.usedClassHour);
          }
                this.singleRuleForm.studentId=this.idd;
                // this.singleRuleForm.teachFee=Number(this.singleRuleForm.usedClassHour)*Number(this.singleRuleForm.teachFee)
                if (this.singleRuleForm.classHourType == 1) {//正常课时 
                if(this.singleParams.type=="add"){
                  if (this.singleRuleForm.usePrice > this.surplusNum) {
                    this.msgWarn("该费用已不足！");
                    this.saveDisabled = false;
                    return false;
                  }
                }else if(this.singleParams.type=="edit"){
                  if (this.singleRuleForm.usePrice > this.beforeDate) {
                    this.msgWarn("该费用已不足！");
                    this.saveDisabled = false;
                    return false;
                  }
                }
            
          } else if(this.singleRuleForm.classHourType == 2){
            if (this.singleParams.type == "add") {
              if (this.singleRuleForm.usedClassHour > this.surplusNum) {
                  this.msgWarn("该赠送课时已不足！");
                  this.saveDisabled = false;
                  return false;
                }else if(this.singleParams.type=="edit"){
                  if (this.singleRuleForm.usedClassHour > this.beforeDate) {
                    this.msgWarn("该赠送课时已不足！");
                    this.saveDisabled = false;
                    return false;
                  }
                }
            }
          }
                if(dayjs(this.singleRuleForm.beginTime).add(1, 'minute').isBefore(dayjs())&&this.singleRuleForm.classMode=='1'){
                  this.msgWarn('排课不能再到当前时间之前的时间！请重新选择时间')
                  this.saveDisabled = false;
                  return
                }
                if(this.singleParams.type=='add'){
                   checkTeacherClassTime(this.singleRuleForm).then(res => {
                   if(res.msg=='操作成功！'){
                     checkStudentClassTime(this.singleRuleForm).then(res => {
                        if(res.msg=='操作成功！'){
                           checkTeacherOrganClassTime(this.singleRuleForm).then((res) => {
                             if(res.msg=='操作成功！'){
                               if(this.classHourStatus==2){
                                 xmtProductItemSave(this.singleRuleForm).then(res => {
                                  if(res.code==0&&res.msg == "操作成功！"){
                                    this.msgSuccess('添加成功！')
                                    this.singlePopup=false;
                                    this.saveDisabled = false;
                                    this.findCourseItemFnn();
                                  }else {
                                        this.saveDisabled = false;
                                        this.msgWarn(res.rows);
                                        return false;
                                      }
                                })
                               }else{
                                 saveOneToOne(this.singleRuleForm).then(res => {
                                  if(res.code==0&&res.msg == "操作成功！"){
                                    this.msgSuccess('添加成功！')
                                    this.singlePopup=false;
                                    this.saveDisabled = false;
                                    this.findCourseItemFnn();
                                  }else {
                                        this.saveDisabled = false;
                                        this.msgWarn(res.rows);
                                        return false;
                                      }
                                })
                               }
                                
                             }else{
                                this.centerType = "teacher";
                                this.centerStudentName = res.rows.studentName;
                                this.centerTeacherName = res.rows.teacherName;
                                this.centerBeginTime = res.rows.beginTime;
                                this.centerTitle = res.rows.title;
                                this.centerUsedClassHour = res.rows.usedClassHour;
                                this.centerOrganName = res.rows.organName;
                                this.centerTrue=true;
                                this.centerDialogVisible = true;
                                this.saveDisabled = false;
                             }
                           })
                           
                        }else{
                         //学生被占用
                         this.centerType='student'
                          this.centerStudentName=res.rows.studentName;
                          this.centerTeacherName=res.rows.teacherName;
                          this.centerBeginTime=res.rows.beginTime;
                          this.centerTitle=res.rows.title;
                          this.centerUsedClassHour=res.rows.usedClassHour;
                          this.centerTrue=false;
                          this.centerDialogVisible=true;
                          this.saveDisabled = false;
                        }
                     })
                   }else{
                       //老师被占用
                       this.centerType='teacher'
                       this.centerStudentName=res.rows.studentName;
                       this.centerTeacherName=res.rows.teacherName;
                       this.centerBeginTime=res.rows.beginTime;
                       this.centerTitle=res.rows.title;
                       this.centerUsedClassHour=res.rows.usedClassHour;
                       this.centerTrue=false;
                       this.centerDialogVisible=true;
                       this.saveDisabled = false;
                   }
                 })
                }else if(this.singleParams.type=='edit'){
                    //delete this.singleRuleForm.id;
                    if(this.singleRuleForm.classHourType==7){
                      this.singleRuleForm.id = this.editId;
                      this.singleRuleForm.supplementItemId = this.editId;
                    }else{
                      this.singleRuleForm.id = this.editId;
                    }
                    this.singleRuleForm.sectionId=this.getSectionId;
                    if(this.editTime==this.singleRuleForm.beginTime&&this.editUsedClassHour==this.singleRuleForm.usedClassHour){//没修改时间  只删除保存
                     if(this.classHourStatus==2){//一对一
                  xmtProductItemDelete(this.editId).then((res) => {
                    delete this.singleRuleForm.id;
                    xmtProductItemSave(this.singleRuleForm).then((res) => {
                    if (res.code == 0 && res.msg == "操作成功！") {
                      this.msgSuccess("添加成功！");
                      this.singlePopup = false;
                      this.saveDisabled = false;
                      this.findCourseItemFnn();
                    } else {
                      this.saveDisabled = false;
                      this.msgWarn(res.rows);
                      return false;
                    }
                  });
                  })
                    
                  }else if(this.singleRuleForm.classHourType==7){
                    deleteMakeUpTimeForClass({id:this.editId}).then((res) => {
                      delete this.singleRuleForm.id;
                      makeUpTimeForClass(this.singleRuleForm).then((res) => {
                      if (res.code == 0 && res.msg == "操作成功！") {
                        this.msgSuccess("添加成功！");
                        this.singlePopup = false;
                        this.saveDisabled = false;
                        this.findCourseItemFnn();
                      } else {
                        this.saveDisabled = false;
                        this.msgWarn(res.rows);
                        return false;
                      }
                    });
                    })
                  }
                  else{
                    xmtProductItemDelete(this.editId).then((res) => {
                      delete this.singleRuleForm.id;
                      saveOneToOne(this.singleRuleForm).then((res) => {
                    if (res.code == 0 && res.msg == "操作成功！") {
                      this.msgSuccess("添加成功！");
                      this.singlePopup = false;
                      this.saveDisabled = false;
                      this.findCourseItemFnn();
                    } else {
                      this.saveDisabled = false;
                      this.msgWarn(res.rows);
                      return false;
                    }
                  });
                    })
                  }
                    }else{
                         checkTeacherClassTime(this.singleRuleForm).then(res => {
                   if(res.msg=='操作成功！'){
                     checkStudentClassTime(this.singleRuleForm).then(res => {
                        if(res.msg=='操作成功！'){
                           checkTeacherOrganClassTime(this.singleRuleForm).then((res) => {
                             if(res.msg=='操作成功！'){
                              if(this.singleRuleForm.classHourType==7){
                                deleteMakeUpTimeForClass({id:this.editId}).then((res) => {
                                  delete this.singleRuleForm.id;
                                  makeUpTimeForClass(this.singleRuleForm).then(
                                  (res) => {
                                    if (
                                      res.code == 0 &&
                                      res.msg == "操作成功！"
                                    ) {
                                      this.msgSuccess("添加成功！");
                                      this.singlePopup = false;
                                      this.saveDisabled = false;
                                      this.findCourseItemFnn();
                                    } else {
                                      this.saveDisabled = false;
                                      this.msgWarn(res.rows);
                                      return false;
                                    }
                                  }
                                );
                                })
                              }else{
                                xmtProductItemDelete(this.editId).then((res) => {
                                  delete this.singleRuleForm.id;
                                 if(this.classHourStatus==2){
                                   xmtProductItemSave(this.singleRuleForm).then((res) => {
                                  if (res.code == 0&&res.msg=='操作成功！') {
                                    this.msgSuccess("添加成功！");
                                    this.singlePopup = false;
                                     this.saveDisabled = false;
                                    this.findCourseItemFnn();
                                  }else{
                                     this.saveDisabled = false;
                                  this.msgWarn(res.rows);
                                  return false;
                                }
                                });
                                 }else{
                                   saveOneToOne(this.singleRuleForm).then((res) => {
                                  if (res.code == 0&&res.msg=='操作成功！') {
                                    this.msgSuccess("添加成功！");
                                    this.singlePopup = false;
                                     this.saveDisabled = false;
                                    this.findCourseItemFnn();
                                  }else{
                                     if(res.rows=='当前学生剩余金额不足，请核实后重新排课'||res.rows=='该学生赠送课时不足，请核对后重新排课'){
                                        this.saveDisabled = false;
                                        this.singlePopup = false;
                                        this.msgWarn(res.rows);
                                        this.findCourseItemFnn();
                                        return false;
                                      }else{
                                        this.saveDisabled = false;
                                        this.msgWarn(res.rows);
                                        return false;
                                      }
                                }
                                });
                                 }
                              });
                              }
                             }else{
                                this.centerType = "teacher";
                                this.centerStudentName = res.rows.studentName;
                                this.centerTeacherName = res.rows.teacherName;
                                this.centerBeginTime = res.rows.beginTime;
                                this.centerTitle = res.rows.title;
                                this.centerUsedClassHour = res.rows.usedClassHour;
                                this.centerTrue=false;
                                this.centerDialogVisible = true;
                                this.saveDisabled = false;
                             }
                           })
                                 
                        }else{
                         //学生被占用
                          this.centerType='student'
                          this.centerStudentName=res.rows.studentName;
                          this.centerTeacherName=res.rows.teacherName;
                          this.centerBeginTime=res.rows.beginTime;
                          this.centerTitle=res.rows.title;
                          this.centerUsedClassHour=res.rows.usedClassHour;
                          this.centerTrue=false;
                          this.centerDialogVisible=true;
                          this.saveDisabled = false;
                        }
                     })
                   }else{
                       //老师被占用
                       this.centerType='teacher'
                       this.centerStudentName=res.rows.studentName;
                       this.centerTeacherName=res.rows.teacherName;
                       this.centerBeginTime=res.rows.beginTime;
                       this.centerTitle=res.rows.title;
                       this.centerUsedClassHour=res.rows.usedClassHour;
                      this.centerTrue=false;
                       this.centerDialogVisible=true;
                       this.saveDisabled = false;
                   }
                 })  
        
                    }
                }
           }
         })
    },
    mustSubjects(row) {
      if (row.productItemList != undefined) {
        var map = {},dest = [];
    　　for(var i = 0; i < row.productItemList.length; i++){
    　　　　var ai = row.productItemList[i];
    　　　　if(!map[ai.subjectName]){
    　　　　　　dest.push({
    　　　　　　　　subjectName: ai.subjectName,
                  //  teacherRealName: ai.teacherRealName,
    　　　　　　　　usedClassHour: ai.usedClassHour
    　　　　　　});
    　　　　　　map[ai.subjectName] = ai;
    　　　　}else{
    　　　　　　for(var j = 0; j < dest.length; j++){
    　　　　　　　　var dj = dest[j];
    　　　　　　　　if(dj.subjectName == ai.subjectName){
    　　　　　　　　　　dj.usedClassHour=(parseFloat(dj.usedClassHour) + parseFloat(ai.usedClassHour)).toString();
    　　　　　　　　　　break;
    　　　　　　　　}
    　　　　　　}
    　　　　}
    　　};
        let str='';
        for(var k=0;k<dest.length;k++){
          str+=dest[k].subjectName+"（"+dest[k].usedClassHour+"课时）"+"、"
        }
        return str.substring(0,str.length-1);
      } 
    },
     demandInfo(id){
      this.demandDialog=true;
         getDetailByStudentId(id).then(res => {
           this.demandParams=res.rows;
      });
    },
    handleSizeChangeAddress(){},
    handleCurrentChangeAddress(){},
    addressListDataChange(val){
       this.selectData = val;
       this.newdetailAddress = val[0];
      if (val.length > 1) {
        this.$refs.addressListData.clearSelection();
        this.$refs.addressListData.toggleRowSelection(val.pop());
      }
    },
    addressListDataItemClick(row){
      this.$refs.addressListData.toggleRowSelection(row);
    },
    handlestudySizeChange(pageSize){
      this.studyParams.pageSize = pageSize;
      this.studentStudyReportFn();
    },
    handlestudyCurrentChange(currentPage){
      this.studyParams.pageSize = pageSize;
      this.studentStudyReportFn();
    },
    async  comparisonClassClick(item,index,items,num){
     this.reportNum=index;
     this.reportNum2=index;
    this.reportNum3=index;
    this.reportNum4=index;
    this.averageClassHoursId=index;
      let data={
        studentId:this.getstudentid,
        subjectId:item,
        searchBeginTime:this.startTime+' 00:00:00',
        searchEndTime:this.endTime+' 23:59:59',
      }
    await  getChartData(data).then((res) => {
        if(res.code==0){
          if(res.rows.teaEarnest.length){
            this.teaEarnest=[];
            for(var i=0;i<res.rows.teaEarnest.length;i++){
              if(res.rows.teaEarnest[i].teaEarnestName=='能够跟上老师的节奏'){
                this.teaEarnest[0]=res.rows.teaEarnest[i].count
              }
              if(res.rows.teaEarnest[i].teaEarnestName=='正常水平'){
                this.teaEarnest[1]=res.rows.teaEarnest[i].count
              }
              if(res.rows.teaEarnest[i].teaEarnestName=='走神情况严重'){
                this.teaEarnest[2]=res.rows.teaEarnest[i].count
              }
              if(res.rows.teaEarnest[i].teaEarnestName=='注意力集中时间较短'){
                this.teaEarnest[3]=res.rows.teaEarnest[i].count
              }
              if(res.rows.teaEarnest[i].teaEarnestName=='全神贯注'){
                this.teaEarnest[4]=res.rows.teaEarnest[i].count
              }
            }
          }else{
            this.teaEarnest=[];
          }
          if(res.rows.teaUnderstand.length){
            this.teaUnderstand=[];
            for(var i=0;i<res.rows.teaUnderstand.length;i++){
              if(res.rows.teaUnderstand[i].teaUnderstandName=='完全掌握，能够举一反三'){
                this.teaUnderstand[0]=res.rows.teaUnderstand[i].count
              }
              if(res.rows.teaUnderstand[i].teaUnderstandName=='理解层面，可以熟练做题'){
                this.teaUnderstand[1]=res.rows.teaUnderstand[i].count
              }
              if(res.rows.teaUnderstand[i].teaUnderstandName=='记忆层面，只记住知识点'){
                this.teaUnderstand[2]=res.rows.teaUnderstand[i].count
              }
            }
          }else{
            this.teaUnderstand=[];
          }
          if(res.rows.teaInteraction.length){
            this.interactionName=[];
            this.interactionData=[];
            for(var i=0;i<res.rows.teaInteraction.length;i++){
              this.interactionName.push(res.rows.teaInteraction[i].teaInteractionName);
              this.interactionData.push(res.rows.teaInteraction[i].count)
            }
          }else{
            this.interactionName=[];
            this.interactionData=[];
          }
          if(res.rows.teaFinish.length){
            this.teaFinish=[];
            for(var i=0;i<res.rows.teaFinish.length;i++){
              this.teaFinish.push({value:res.rows.teaFinish[i].count,name:res.rows.teaFinish[i].teaFinishName})
            }
          }else{
            this.teaFinish=[];
          }
              this.cancellationOfClassArray=[]; 
              this.cancellationOfClassArray[0]=res.rows.cancellationOfClass[0]?res.rows.cancellationOfClass[0].planClassHour?res.rows.cancellationOfClass[0].planClassHour:0:0;
              this.cancellationOfClassArray[1]=res.rows.cancellationOfClass[0]?res.rows.cancellationOfClass[0].actualClassHour?res.rows.cancellationOfClass[0].actualClassHour:0:0;
              this.cancellationOfClassArray[2]=res.rows.cancellationOfClass[0]?res.rows.cancellationOfClass[0].cancelClassHour?res.rows.cancellationOfClass[0].cancelClassHour:0:0;
              this.workHome=[];
              this.workHome[0]=res.rows.homeWorkDownload[0]?res.rows.homeWorkDownload[0].downloadCount?res.rows.homeWorkDownload[0].downloadCount:0:0;
              this.workHome[1]=res.rows.homeWorkDownload[0]?res.rows.homeWorkDownload[0].uploadCount?res.rows.homeWorkDownload[0].uploadCount:0:0;
              this.subjectData[index].comparisonOne=res.rows.comparisonClassHours[0]?res.rows.comparisonClassHours[0].classHourType:0;
      }
      });
      
      if(this.seeStudyPopup==true){
         this.drawChart3()
         this.drawChart4()
      }else{
        this.drawChart()
        this.drawChart2()
      }
    },
    closeDialog(){

    },
      reportBlur(){
      this.$nextTick(() => {
      this.$refs.nav0[0].click(); 
})
      
    },
    reportChangeSubject(){
        this.subjectData=[]
      for(var i=0;i<this.subjectidList.length;i++){
        this.subjectidList[i].flag=false;
       for(var j=0;j<this.reportValue.length;j++){
         if(this.subjectidList[i].macroId==this.reportValue[j]){
           this.subjectidList[i].flag=true;
           if(this.subjectidList[i].flag=true){
           this.subjectData.push(this.subjectidList[i])
         }
         }
       }
      }
    },
    drawChart3(){
      let myFeedbacks = this.$echarts.init(this.$refs.feedbackCharts);
      let teaFinishCharts = this.$echarts.init(this.$refs.teaFinishCharts);
      let teaInteractionCharts= this.$echarts.init(this.$refs.teaInteractionCharts);
      let teaUnderstandCharts=this.$echarts.init(this.$refs.teaUnderstandCharts);
      let cancellationOfClassCharts=this.$echarts.init(this.$refs.cancellationOfClassCharts);
      let homeWorkDownloadCharts=this.$echarts.init(this.$refs.homeWorkDownloadCharts);
      let optionFeedbacks={
        title: {
          text: ''
        },
        legend: {
          data: ['学生在课堂听课的认真程度']
        },
        label: {
                show: true,
                formatter: function (params) {
                  return params.value!=undefined?params.value:0;
                }
              },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: '能够跟上老师的节奏', max: 10 },
            { name: '正常水平', max: 10 },
            { name: '走神状态严重', max: 10 },
            { name: '注意力集中时间较短', max: 10 },
            { name: '全神贯注', max: 10 },
          ]
        },
        series: [
          {
            name: '',
            type: 'radar',
             data: [
              {
                value: this.teaEarnest,
                name: '',
                 areaStyle: {
                color: this.$echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                  {
                    color: 'rgba(255, 145, 124, 0.1)',
                    offset: 0
                  },
                  {
                    color: 'rgba(255, 145, 124, 0.9)',
                    offset: 1
                  }
                ])
              }
              },
            ]
          }
        ]
      }
      let teaFinishOptions = {
        title: {
          text: '',
          subtext: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
       
        series: [
          {
            name: '',
            type: 'pie',
            radius: '50%',
            data:this.teaFinish,
            itemStyle: {
                normal: {
                color: function(params) {
                        //自定义颜色
                        var colorList = [
                        '#FF8800', '#0066FF', '#E38EFF','#FFFF00','#99FF00','#993300'
                        ];
                        return colorList[params.dataIndex]
                    }
                }
            }
           
          }
        ]
      };
      let teaInteractionOptions={
       tooltip: {trigger: 'axis'},
        xAxis: {
          type: 'category',
          data: this.interactionName,
          axisLabel: {
            formatter: function (val) {
              let str = makeMultiLine(val, 5) // 每 5 个字符一行
              return `{n|${str}}` // 使用 rich 中的 n 来设置样式
            },
            rich,
            interval: 0 // 显示所有的 x 轴上的文字不隐藏
          }
        },
        yAxis: {
          type: 'value'
        },
         label: {
                show: true,
                formatter: function (params) {
                  return params.value;
                }
              },
        series: [
          {
            data: this.interactionData,
             barWidth : 60,//柱图宽度
            type: 'bar',
             itemStyle: {
            normal: {
　　　　　　　　//这里是重点
                color: function(params) {
                	//注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                    var colorList = ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83', '#ca8622'];
                    return colorList[params.dataIndex]
                }
            }
        }

          }
        ]
      }
      let teaUnderstandOptions={
        title: {
          text: ''
        },
        legend: {
          data: ['']
        },
        label: {
                show: true,
                formatter: function (params) {
                  return params.value!=undefined?params.value:0;
                }
              },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: '完全掌握，能够举一反三', max: 10 },
            { name: '理解层面，可以熟练做题', max: 10 },
            { name: '记忆层面，只记住知识点', max: 10 },
          ]
        },
        series: [
          {
            name: '',
            type: 'radar',
            data: [
              {
                value: this.teaUnderstand,
                name: '',
                 areaStyle: {
                color: this.$echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                  {
                    color: 'rgba(255, 145, 124, 0.1)',
                    offset: 0
                  },
                  {
                    color: 'rgba(255, 145, 124, 0.9)',
                    offset: 1
                  }
                ])
              }
              },
            ]
            // data: [
            //   {
            //     value: this.teaUnderstand,
            //     name: ''
            //   },
            // ],
            // itemStyle: {
            //     normal: {
            //     color: function(params) {
                       
            //             var colorList = [
            //             '#749f83', '#2f4554', '#c23531','#ca8622','#99FF00','#993300'
            //             ];
            //             return colorList[params.dataIndex]
            //         }
            //     }
            // }
          }
        ]
      }
      let cancellationOptions={
        tooltip: {trigger: 'axis'},
        legend: {
          data: [""]
        },
        xAxis: {
          type: 'category',
          data: ["计划课时", "实际上课课时","取消课课时"]
        },
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barWidth : 60,//柱图宽度
            data: [
              {
                value:this.cancellationOfClassArray[0],
                itemStyle: {
                  color: '#00BBFF'
                }
              },
              {
                value:this.cancellationOfClassArray[1],
                itemStyle: {
                  color: '#FF88C2'
                }
              },
              {
                value:this.cancellationOfClassArray[2],
                itemStyle: {
                  color: '#c23531'
                }
              }
             ],
            itemStyle: {
              normal: {
                color: 'rgba(51,129,255,1)',
                 
                lineStyle: {
                  color: 'rgba(51,129,255,.8)',
                  width: 10
                }
              }
            },
           
          },
        ]
      }
      let homeWorkOptions={
        tooltip: {trigger: 'axis'},
        legend: {
          data: [""]
        },
        xAxis: {
          type: 'category',
          data: ["下载次数", "提交次数"]
        },
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barWidth : 60,//柱图宽度
            data: [
              {
                value:this.workHome[0],
                itemStyle: {
                  color: '#00BBFF'
                }
              },
              {
                value:this.workHome[1],
                itemStyle: {
                  color: '#FF88C2'
                }
              },
             ],
            itemStyle: {
              normal: {
                color: 'rgba(51,129,255,1)',
                 
                lineStyle: {
                  color: 'rgba(51,129,255,.8)',
                  width: 10
                }
              }
            },
           
          },
        ]
      }
      myFeedbacks.setOption(optionFeedbacks);
      teaFinishCharts.setOption(teaFinishOptions);
      teaInteractionCharts.setOption(teaInteractionOptions);
      teaUnderstandCharts.setOption(teaUnderstandOptions);
      cancellationOfClassCharts.setOption(cancellationOptions);
      homeWorkDownloadCharts.setOption(homeWorkOptions);
    },
    drawChart() {
     
      let myChart = this.$echarts.init(this.$refs.comparisonClassHoursChart);
      let option={
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }},
        legend: {
          data: [""]
        },
        xAxis: {
          type: 'category',
          data: ["你的平均上课时长", "其他学生的平均上课时长"]
        },
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barWidth : 60,//柱图宽度
            data: [{
                value: this.subjectData.length?this.subjectData[this.reportNum].comparisonOne?this.subjectData[this.reportNum].comparisonOne:0:0,
                itemStyle: {
                  color: '#00BBFF'
                }
              },
              {
                value: this.subjectData.length?this.subjectData[this.reportNum].classHours?this.subjectData[this.reportNum].classHours:0:0,
                itemStyle: {
                  color: '#FF88C2'
                }
              }
            ],
            itemStyle: {
              normal: {
                color: 'rgba(51,129,255,1)',
                 
                lineStyle: {
                  color: 'rgba(51,129,255,.8)',
                  width: 10
                }
              }
            },
            
          },
        ]
      }
      myChart.setOption(option);
    },
    drawChart2(){
      let myFeedback = this.$echarts.init(this.$refs.feedbackChart);
      let teaFinishChart = this.$echarts.init(this.$refs.teaFinishChart);
      let teaInteractionChart= this.$echarts.init(this.$refs.teaInteractionChart);
      let teaUnderstandChart=this.$echarts.init(this.$refs.teaUnderstandChart);
      let cancellationOfClassChart=this.$echarts.init(this.$refs.cancellationOfClassChart);
      let homeWorkDownloadChart=this.$echarts.init(this.$refs.homeWorkDownloadChart);
      let optionFeedback={
        title: {
          text: ''
        },
        legend: {
          data: ['']
        },
        label: {
                show: true,
                formatter: function (params) {
                  return params.value!=undefined?params.value:0;
                }
              },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: '能够跟上老师的节奏', max: 10 },
            { name: '正常水平', max: 10 },
            { name: '走神状态严重', max: 10 },
            { name: '注意力集中时间较短', max: 10 },
            { name: '全神贯注', max: 10 },
          ]
        },
        series: [
          {
            name: '',
            type: 'radar',
             data: [
              {
                value: this.teaEarnest,
                name: '',
                 areaStyle: {
                color: this.$echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                  {
                    color: 'rgba(255, 145, 124, 0.1)',
                    offset: 0
                  },
                  {
                    color: 'rgba(255, 145, 124, 0.9)',
                    offset: 1
                  }
                ])
              }
              },
            ]
          }
        ]
      }
      let teaFinishOption = {
        title: {
          text: '',
          subtext: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
       
        series: [
          {
            name: '',
            type: 'pie',
            radius: '50%',
            data:this.teaFinish,
            itemStyle: {
                normal: {
                color: function(params) {
                        //自定义颜色
                        var colorList = [
                        '#FF8800', '#0066FF', '#E38EFF','#FFFF00','#99FF00','#993300'
                        ];
                        return colorList[params.dataIndex]
                    }
                }
            }
           
          }
        ]
      };
      let teaInteractionOption={
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }},
        xAxis: {
          type: 'category',
          data: this.interactionName,
          axisLabel: {
            formatter: function (val) {
              let str = makeMultiLine(val, 5) // 每 5 个字符一行
              return `{n|${str}}` // 使用 rich 中的 n 来设置样式
            },
            rich,
            interval: 0 // 显示所有的 x 轴上的文字不隐藏
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.interactionData,
            type: 'bar',
            barWidth : 60,//柱图宽度
             itemStyle: {
            normal: {
　　　　　　　　//这里是重点
                color: function(params) {
                	//注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                    var colorList = ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83', '#ca8622'];
                    return colorList[params.dataIndex]
                }
            }
        }

          }
        ]
      }
      let teaUnderstandOption={
        title: {
          text: ''
        },
        legend: {
          data: ['']
        },
        label: {
                show: true,
                formatter: function (params) {
                  return params.value!=undefined?params.value:0;
                }
              },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: '完全掌握，能够举一反三', max: 10 },
            { name: '理解层面，可以熟练做题', max: 10 },
            { name: '记忆层面，只记住知识点', max: 10 },
          ]
        },
        series: [
          {
            name: '',
            type: 'radar',
            data: [
              {
                value: this.teaUnderstand,
                name: '',
                 areaStyle: {
                color: this.$echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                  {
                    color: 'rgba(255, 145, 124, 0.1)',
                    offset: 0
                  },
                  {
                    color: 'rgba(255, 145, 124, 0.9)',
                    offset: 1
                  }
                ])
              }
              },
            ]
          }
        ]
      }
      let cancellationOption={
        tooltip: {trigger: 'axis'},
        legend: {
          data: [""]
        },
        xAxis: {
          type: 'category',
          data: ["计划课时", "实际上课课时","取消课课时"]
        },
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barWidth : 60,//柱图宽度
            data: [
              {
                value:this.cancellationOfClassArray[0],
                itemStyle: {
                  color: '#00BBFF'
                }
              },
              {
                value:this.cancellationOfClassArray[1],
                itemStyle: {
                  color: '#FF88C2'
                }
              },
              {
                value:this.cancellationOfClassArray[2],
                itemStyle: {
                  color: '#c23531'
                }
              }
             ],
            itemStyle: {
              normal: {
                color: 'rgba(51,129,255,1)',
                 
                lineStyle: {
                  color: 'rgba(51,129,255,.8)',
                  width: 10
                }
              }
            },
           
          },
        ]
      }
      let homeWorkOption={
        tooltip: {trigger: 'axis'},
        legend: {
          data: [""]
        },
        xAxis: {
          type: 'category',
          data: ["下载次数", "提交次数"]
        },
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barWidth : 60,//柱图宽度
            data: [
              {
                value:this.workHome[0],
                itemStyle: {
                  color: '#00BBFF'
                }
              },
              {
                value:this.workHome[1],
                itemStyle: {
                  color: '#FF88C2'
                }
              },
             ],
            itemStyle: {
              normal: {
                color: 'rgba(51,129,255,1)',
                 
                lineStyle: {
                  color: 'rgba(51,129,255,.8)',
                  width: 10
                }
              }
            },
           
          },
        ]
      }
      myFeedback.setOption(optionFeedback);
      teaFinishChart.setOption(teaFinishOption);
      teaInteractionChart.setOption(teaInteractionOption);
      teaUnderstandChart.setOption(teaUnderstandOption);
      cancellationOfClassChart.setOption(cancellationOption);
      homeWorkDownloadChart.setOption(homeWorkOption);
    },
    sendParent(){
        var data={
            id:this.studyRuleForm.id,
            studentId:this.getstudentid,
            searchBeginTime:this.startTime,
            searchEndTime:this.endTime,
         }
         sendStudyReport(data).then((res) => {
            if(res.code==0){
              this.msgSuccess("发送成功！");
              this.addStudyPopup = false;
              this.studentStudyReportFn();
              this.startTime="";
              this.endTime="";
              this.reportValue="";
              this.parentProposal="";
              this.stuProposal="";
              for(var i=0;i<this.subjectidList.length;i++){
                this.subjectidList[i].flag=false;
                this.subjectidList[i].stuComparisonClassHours='';
                this.subjectidList[i].classHours='';
                this.subjectidList[i].plan='';
              }
              for(var i=0;i<this.subjectData.length;i++){
                this.subjectData[i].flag=false;
                this.subjectData[i].stuComparisonClassHours='';
                this.subjectData[i].classHours='';
                this.subjectData[i].plan='';
          }
            }
          });
//       var  data={
//         id:this.studyRuleForm.id,
//         XmtStudentFollowUpClassPlanList:this.studyRuleForm.XmtStudentFollowUpClassPlanList,
//         XmtStudentComparisonClassHoursList:this.studyRuleForm.XmtStudentComparisonClassHoursList,
//         studentId:this.getstudentid,
//         searchBeginTime:this.startTime,
//         searchEndTime:this.endTime,
//         reportStatus:1,//状态
//         parentStatus:0,//查看
//         comparisonClassHours:this.comparisonClassHours?1:0,//上课时长对比状态
//         classroomFeedback:this.classroomFeedback?1:0,//课堂反馈状态
//         wrongQuestionBookCollection:0,//错题本收录状态
//         cancellationOfClass:this.cancellationOfClass?1:0,//取消课状态
//         followUpClassPlan:this.followUpClassPlan?1:0,//后续上课计划状态
//         adviceToParents:this.adviceToParents?1:0,//给家长建议的状态
//         parents:this.parentProposal,//给家长的建议
//         suggestionsForParticipants:this.suggestionsForParticipants?1:0,//给学生的建议的状态
//         participants:this.stuProposal,//给学生的建议
//         subjectIds:this.reportValue.join(',')
//       }
//       studentStudyReportUpdate(data).then((res) => {
//         if(res.code==0){
//           this.msgSuccess("发送成功！");
//           this.addStudyPopup = false;
//           this.studentStudyReportFn();
//           this.startTime="";
//           this.endTime="";
//           this.reportValue="";
//           this.parentProposal="";
//           this.stuProposal="";
//           for(var i=0;i<this.subjectidList.length;i++){
//             this.subjectidList[i].flag=false;
//             this.subjectidList[i].stuComparisonClassHours='';
//             this.subjectidList[i].classHours='';
//             this.subjectidList[i].plan='';
//           }
//           for(var i=0;i<this.subjectData.length;i++){
//             this.subjectData[i].flag=false;
//             this.subjectData[i].stuComparisonClassHours='';
//             this.subjectData[i].classHours='';
//             this.subjectData[i].plan='';
//           }
//         }
//       });
    },
    addAddress(){
        this.addAddressPopup=true;
        this.saveAddressForm.provinceId = "";
        this.saveAddressForm.cityId = "";
        this.saveAddressForm.countyId = "";
        this.saveAddressForm.detailAddress = "";
        this.addAddressParams={
          title:"添加地址",
          type:"add"
        }
        this.address1();
    },
    editAddress(){
      
    },
    removeAddress(){},
    address1(){
        return systemAreaList({
        parentId: this.parentId
      }).then(res => {
        if (res.code == 0) {
          this.addressProvinceList = res.rows;
        }
      });
    },
   async  editStudyReport(row){
     this.subjectData=[];
      this.reportNum=0;
      this.reportNum2=0;
      this.reportNum3=0;
      this.reportNum4=0;
      this.addStudyPopup=true;
      this.startTime=row.searchBeginTime.substring(0,10);
      this.endTime=row.searchEndTime.substring(0,10);
      this.studyRuleForm.id=row.id;
      this.addStudyParams={
        type:'edit',
        title:"编辑学习报告"
      }
    await  studentStudyReportInfo(row.id).then((res) => {
        if(res.code==0){
          let rowData=res.rows;
          this.reportValue=rowData.subjectIds.split(',').map(Number);
          // this.startTime=rowData.searchBeginTime;
          // this.endTime=rowData.searchEndTime;
          this.parentProposal=rowData.parents;
          this.stuProposal=rowData.participants;
          rowData.comparisonClassHours=rowData.comparisonClassHours==1?true:false;
          rowData.classroomFeedback=rowData.classroomFeedback==1?true:false;
          rowData.cancellationOfClass=rowData.cancellationOfClass==1?true:false;
           rowData.followUpClassPlan=rowData.followUpClassPlan==1?true:false;
          rowData.adviceToParents=rowData.adviceToParents==1?true:false;
          rowData.suggestionsForParticipants=rowData.suggestionsForParticipants==1?true:false;
           this.subjectData=[]
          for(var i=0;i<this.subjectidList.length;i++){
            for(var k=0;k<rowData.xmtStudentComparisonClassHoursList.length;k++){
              if(this.subjectidList[i].macroId==rowData.xmtStudentComparisonClassHoursList[k].subjectId){
                 this.subjectidList[i].flag=true;
                this.subjectidList[i].stuComparisonClassHours=rowData.xmtStudentFollowUpClassPlanList[k].classHour;
                this.subjectidList[i].plan = rowData.xmtStudentFollowUpClassPlanList[k].plan;
                this.subjectidList[i].classHours=Number(rowData.xmtStudentComparisonClassHoursList[k].classHour);
                if(this.subjectidList[i].flag=true){
           this.subjectData.push(this.subjectidList[i])
         }
             }
            }
        
          }
        }
      });
      this.drawChart();
    },
    async  seeStudyReport(row){
      this.subjectData=[];
      this.reportNum=0;
      this.reportNum2=0;
      this.reportNum3=0;
      this.reportNum4=0;
      this.seeStudyPopup=true;
      this.startTime=row.searchBeginTime.substring(0,10);
      this.endTime=row.searchEndTime.substring(0,10);
      await  studentStudyReportInfo(row.id).then((res) => {
        if(res.code==0){
          let rowData=res.rows;
          this.reportValue=rowData.subjectIds.split(',').map(Number);
          // this.startTime=rowData.searchBeginTime;
          // this.endTime=rowData.searchEndTime;
          this.parentProposal=rowData.parents;
          this.stuProposal=rowData.participants;
          rowData.comparisonClassHours=rowData.comparisonClassHours==1?true:false;
          rowData.classroomFeedback=rowData.classroomFeedback==1?true:false;
          rowData.cancellationOfClass=rowData.cancellationOfClass==1?true:false;
           rowData.followUpClassPlan=rowData.followUpClassPlan==1?true:false;
          rowData.adviceToParents=rowData.adviceToParents==1?true:false;
          rowData.suggestionsForParticipants=rowData.suggestionsForParticipants==1?true:false;
         for(var i=0;i<this.subjectidList.length;i++){
            for(var k=0;k<rowData.xmtStudentComparisonClassHoursList.length;k++){
              if(this.subjectidList[i].macroId==rowData.xmtStudentComparisonClassHoursList[k].subjectId){
                 this.subjectidList[i].flag=true;
                this.subjectidList[i].stuComparisonClassHours=rowData.xmtStudentFollowUpClassPlanList[k].classHour;
                this.subjectidList[i].plan = rowData.xmtStudentFollowUpClassPlanList[k].plan;
                this.subjectidList[i].classHours=Number(rowData.xmtStudentComparisonClassHoursList[k].classHour);
                if(this.subjectidList[i].flag=true){
           this.subjectData.push(this.subjectidList[i])
         }
             }
            }
          }
        }
      });
      this.drawChart4();
    },
    drawChart4() {
      let myChart = this.$echarts.init(this.$refs.comparisonClassHoursCharts);
      let option={
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }},
        legend: {
          data: [""]
        },
        xAxis: {
          type: 'category',
          data: ["你的平均上课时长", "其他学生的平均上课时长"]
        },
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barWidth : 60,//柱图宽度
            data: [{
                value: this.subjectData[this.reportNum].comparisonOne,
                itemStyle: {
                  color: '#00BBFF'
                }
              },
              {
                value: this.subjectData[this.reportNum].classHours,
                itemStyle: {
                  color: '#FF88C2'
                }
              }
            ],
            itemStyle: {
              normal: {
                color: 'rgba(51,129,255,1)',
                 
                lineStyle: {
                  color: 'rgba(51,129,255,.8)',
                  width: 10
                }
              }
            },
            
          },
        ]
      }
      myChart.setOption(option);
    },
    averageClassHoursInput(index){
       this.drawChart()
    },
    //批量排课
    batchTime(){
      this.courseShow=false;
      this.batchTimePopup=true;
      this.batchPickerValue="";
      this.checkedWeeks=[];
      this.batchTimeForm.classHourType="";
      this.newProductId="";
      this.surplusNum2=0;
      this.tableSubjectId="";
      this.pickerData="";
      this.usedClassHour="";
      this.usedTitle="";
      this.courseDate=[];
      this.batchTimeForm.videoMode = 1;
      this.batchTimeForm.expense = ''
      this.getOrganBalance();
      this.getSingleClassFee();
      studentInfo(this.idd).then((res) => {
        this.accountBalance=res.rows.accountBalance;
        this.remainingClassHours=res.rows.remainingClassHours;
      });
      // this.productListFn();
      this.batchProductListFn()
    },
     disabledDateTime(time) {
        return time.getTime() < Date.now() - 8.64e7  
   },
   saveReport(){
      this.studyRuleForm.XmtStudentComparisonClassHoursList=[];
      this.studyRuleForm.XmtStudentFollowUpClassPlanList=[];
      for(var i=0;i<this.subjectData.length;i++){
      if(this.subjectData[i].stuComparisonClassHours==""||this.subjectData[i].classHours==""){
          this.msgWarn("有必填项未填！");
          return false;
        }
        if(this.subjectData[i].classHours){
          this.studyRuleForm.XmtStudentComparisonClassHoursList.push({
          subjectId:this.subjectData[i].macroId,
          classHour:this.subjectData[i].classHours
        })
           this.studyRuleForm.XmtStudentFollowUpClassPlanList.push({
          subjectId:this.subjectData[i].macroId,
          classHour:this.subjectData[i].stuComparisonClassHours,
          plan:this.subjectData[i].plan
        })
        }
      }
      
     if(this.addStudyParams.title=='新增学习报告'){
       var data={
        XmtStudentFollowUpClassPlanList:this.studyRuleForm.XmtStudentFollowUpClassPlanList,
        XmtStudentComparisonClassHoursList:this.studyRuleForm.XmtStudentComparisonClassHoursList,
        studentId:this.getstudentid,
        searchBeginTime:this.startTime,
        searchEndTime:this.endTime,
        reportStatus:0,//状态
        parentStatus:0,//查看
        comparisonClassHours:this.comparisonClassHours?1:0,//上课时长对比状态
        classroomFeedback:this.classroomFeedback?1:0,//课堂反馈状态
        wrongQuestionBookCollection:0,//错题本收录状态
        cancellationOfClass:this.cancellationOfClass?1:0,//取消课状态
        followUpClassPlan:this.followUpClassPlan?1:0,//后续上课计划状态
        adviceToParents:this.adviceToParents?1:0,//给家长建议的状态
        parents:this.parentProposal,//给家长的建议
        suggestionsForParticipants:this.suggestionsForParticipants?1:0,//给学生的建议的状态
        participants:this.stuProposal,//给学生的建议
        subjectIds:this.reportValue.join(',')
      }
        studentStudyReportSave(data).then((res) => {
        if(res.code==0){
          this.msgSuccess("操作成功！");
          this.addStudyPopup = false;
          this.studentStudyReportFn();
          this.startTime="";
          this.endTime="";
          this.reportValue="";
          this.parentProposal="";
          this.stuProposal="";
          for(var i=0;i<this.subjectidList.length;i++){
            this.subjectidList[i].flag=false;
            this.subjectidList[i].stuComparisonClassHours='';
            this.subjectidList[i].classHours='';
            this.subjectidList[i].plan='';
          }
          for(var i=0;i<this.subjectData.length;i++){
            this.subjectData[i].flag=false;
            this.subjectData[i].stuComparisonClassHours='';
            this.subjectData[i].classHours='';
            this.subjectData[i].plan='';
          }
        }
      });
     }else{
      var data={
        id:this.studyRuleForm.id,
        XmtStudentFollowUpClassPlanList:this.studyRuleForm.XmtStudentFollowUpClassPlanList,
        XmtStudentComparisonClassHoursList:this.studyRuleForm.XmtStudentComparisonClassHoursList,
        studentId:this.getstudentid,
        searchBeginTime:this.startTime,
        searchEndTime:this.endTime,
        reportStatus:0,//状态
        parentStatus:0,//查看
        comparisonClassHours:this.comparisonClassHours?1:0,//上课时长对比状态
        classroomFeedback:this.classroomFeedback?1:0,//课堂反馈状态
        wrongQuestionBookCollection:0,//错题本收录状态
        cancellationOfClass:this.cancellationOfClass?1:0,//取消课状态
        followUpClassPlan:this.followUpClassPlan?1:0,//后续上课计划状态
        adviceToParents:this.adviceToParents?1:0,//给家长建议的状态
        parents:this.parentProposal,//给家长的建议
        suggestionsForParticipants:this.suggestionsForParticipants?1:0,//给学生的建议的状态
        participants:this.stuProposal,//给学生的建议
        subjectIds:this.reportValue.join(',')
      }
        studentStudyReportUpdate(data).then((res) => {
        if(res.code==0){
          this.msgSuccess("操作成功！");
          this.addStudyPopup = false;
          this.studentStudyReportFn();
          this.startTime="";
          this.endTime="";
          this.reportValue="";
          this.parentProposal="";
          this.stuProposal="";
          for(var i=0;i<this.subjectidList.length;i++){
            this.subjectidList[i].flag=false;
            this.subjectidList[i].stuComparisonClassHours='';
            this.subjectidList[i].classHours='';
            this.subjectidList[i].plan='';
          }
          for(var i=0;i<this.subjectData.length;i++){
            this.subjectData[i].flag=false;
            this.subjectData[i].stuComparisonClassHours='';
            this.subjectData[i].classHours='';
            this.subjectData[i].plan='';
          }
        }
      });
     }
      
    },
    classModeChange(){
      if(this.singleRuleForm.classMode==1){
        this.singleRuleForm.classAddress=="";
      }
      this.computeClassExpense()
      this.classAddressData.pageSize=1000;
      studentClassAddressList(this.classAddressData).then((res) => {
        if(res.rows){
          for(var i=0;i<res.rows.length;i++){
            if(res.rows[i].isDefault==1){
               this.singleRuleForm.classAddress =
                res.rows[i].provinceName +
                res.rows[i].cityName +
                res.rows[i].countyName +
                res.rows[i].detailAddress;
            }
          }
        }
      });
    },
     moreClassMode(){
       this.classAddressData.pageSize=1000;
      studentClassAddressList(this.classAddressData).then((res) => {
        if(res.rows){
          for(var i=0;i<res.rows.length;i++){
            if(res.rows[i].isDefault==1){
               for (var k = 0; k < this.courseDate.length; k++) {
                this.courseDate[k].classAddress = res.rows[i].provinceName +
                res.rows[i].cityName +
                res.rows[i].countyName +
                res.rows[i].detailAddress;
        }
            }
          }
        }
      });
    },
    scopeTeacher(){
        this.selectTeacherPopup=true;
        this.teacherType=2;
        this.teacherParams.subjectId=this.tableSubjectId;
        this.configteacherPlanFn();
    },
    usedTitleInput(){
      for (var i = 0; i < this.courseDate.length; i++) {
        this.courseDate[i].title = this.usedTitle;
      }
    },
    usedClassHourInput(){
      for(var i=0;i<this.courseDate.length;i++){
        this.courseDate[i].usedClassHour=this.usedClassHour;
      }
    },
    scopeAddress(){
      this.detailAddressPopup=true;
      this.addressType=2;
      this.studentClassAddressList()
    },
    deleteTableRow(row){
      this.courseDate.splice(row.$index,1)
    },
    datetimeChange(){
      for(var i=0;i<this.courseDate.length;i++){
      this.courseDate[i].time=this.pickerData;
      this.courseDate[i].beginTime=this.courseDate[i].date+' '+this.pickerData;
    }
    // this.$set(this.courseDate)
    },
    teachTitleIdFn2(row){
      if(row.teachTitleId==""||row.teachTitleId==0){
        return '均可'
      }
      else if(row.teachTitleId==59){
        return '初级讲师'
      }else if(row.teachTitleId==60){
        return '中级讲师'
      }else if(row.teachTitleId==61){
        return '高级讲师'
      }else if(row.teachTitleId==62){
        return '特级讲师'
      }
    },
     teachingAgeIdFn2(row){
      if(row.teachingAgeId==""||row.teachingAgeId==0){
        return '均可'
      }else if(row.teachingAgeId==27){
        return '5年以下'
      }else if(row.teachingAgeId==28){
        return '5-10'
      }else if(row.teachingAgeId==29){
        return '10-15'
      }else if(row.teachingAgeId==227){
        return '15-20以上'
      }
    },
    defaultValue(){
      let  d = new Date();
      return `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()} `+"06:00:00"
    },
    tableChange(val){
      for(var i=0;i<this.courseDate.length;i++){
        this.courseDate[i].subjectId=this.tableSubjectId;
      }
      for(var i=0;i<this.singSubjectIdList.length;i++){
        if(this.singSubjectIdList[i].macroId==this.tableSubjectId){
          this.usedTitle=this.getSectionName+this.singSubjectIdList[i].name;
          for (var k = 0; k < this.courseDate.length; k++) {
            this.courseDate[k].title = this.getSectionName+this.singSubjectIdList[i].name;
          }
        }
      }
    },

    createCourse(){
      if(this.batchPickerValue==''){
          this.msgWarn("请选择排课日期");
          return;
      }else if(this.weeks.length==0){
          this.msgWarn("请选择星期");
          return;
      }else if(this.batchTimeForm.classHourType==''){
          this.msgWarn("请选择收费类型");
          return;
      }else if (this.newProductId == ""&&this.classHourStatusMore==2) {
        this.msgWarn("请选择产品名称");
        return;
      }
       this.courseShow=true;
      this.courseDate=[]
      this.getWeek(this.batchTimeForm.beginTime,this.batchTimeForm.stopTime,this.checkedWeeks)
    },
         getWeek (begin, end, weekNum){
          console.log(begin, end, weekNum)
    var dateArr = new Array();
    var stimeArr = begin.split("-");//=>["2018", "01", "01"]
    var etimeArr = end.split("-");//=>["2018", "01", "30"]
 
    var stoday = new Date();
    stoday.setUTCFullYear(stimeArr[0], stimeArr[1]-1, stimeArr[2]);
    var etoday = new Date();
    etoday.setUTCFullYear(etimeArr[0], etimeArr[1]-1, etimeArr[2]);
 
    var unixDb = stoday.getTime();//开始时间的毫秒数
    var unixDe = etoday.getTime();//结束时间的毫秒数
 
    for (var k = unixDb; k <= unixDe;) {
       let needJudgeDate = this.msToDate(parseInt(k)).withoutTime;
       //不加这个if判断直接push的话就是已知时间段内的所有日期
        for(var i = 0; i < weekNum.length; i++) {
          if (new Date(needJudgeDate).getDay() == weekNum[i]) {
              dateArr.push(needJudgeDate);
            }
        }
        k = k + 24*60*60*1000;
    }
    
    for (var j = 0; j < dateArr.length; j++) {
        this.courseDate.push({
          date: dateArr[j],
          productId: this.batchProductId,
          totalPrice: this.batchTotalPrice,
          singlePrice: this.getSinglePrice2,
          classHourType: this.batchClassHourType,
          subjectId: this.tableSubjectId,
          time: this.pickerData,
          usedClassHour: this.usedClassHour,
          title: '',
          teacherName: '',
          teacherId: '',
          configteacherPlanId: '',
          classAddress: '',
          isPrisonClass:'0',
          isPlayback:'0',
          isFirstClass: false,
          classMode: false,
        });
      }
    return dateArr;
},
 msToDate (msec) {
   let datetime = new Date(msec);
   let year = datetime.getFullYear();
   let month = datetime.getMonth();
   let date = datetime.getDate();
   let hour = datetime.getHours();
   let minute = datetime.getMinutes();
   let second = datetime.getSeconds();
 
   let result1 = year +
                '-' +
                ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
                '-' +
                ((date + 1) < 10 ? '0' + date : date) +
                ' ' +
                ((hour + 1) < 10 ? '0' + hour : hour) +
                ':' +
                ((minute + 1) < 10 ? '0' + minute : minute) +
                ':' +
                ((second + 1) < 10 ? '0' + second : second);
 
   let result2 = year +
                '-' +
                ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
                '-' +
                ((date + 1) < 11 ? '0' + date : date);
 
   let result = {
       hasTime: result1,
       withoutTime: result2
   };
   return result;
},
 stuComparisonClick(macroId,index){
      this.reportNum2=index;
    },
     averageClassChange(val,index){
      this.$set(this.subjectData[index],'classHours',val)
    },
    surplusNumFn2(val){
      this.batchProductId = val.id;//产品Id
      if (val.zdySinglePrice != null) {//自定义
        this.getSinglePrice = val.zdySinglePrice;
      } else {
        this.getSinglePrice = val.singlePrice;
      }
      getResiduePrice({
        orderId: val.orderId,
        studentId: this.idd,
        classHourType: this.batchTimeForm.classHourType,
      }).then((res) => {
        if (res.code == 0) {
          this.surplusNum2 = res.rows;
          this.batchTotalPrice = res.rows;
        }
      });
    },
    sourceTypeFn(row) {
      let type;
      for (let i = 0, len = this.sourceTypeList.length; i < len; i++) {
        if (row == this.sourceTypeList[i].value) {
          type = this.sourceTypeList[i].label;
          break;
        }
      }
      return type;
    },
    dealIsDay(row){
      var day=new Date(row.date).getDay();
      if(day==1){
        return '星期一'
      }else if(day==2){
        return '星期二'
      }else if(day==3){
        return '星期三'
      }else if(day==4){
        return '星期四'
      }else if(day==5){
        return '星期五'
      }else if(day==6){
        return '星期六'
      }else if(day==0){
        return '星期日'
      }
    },
    weekCheckAllChange(val) {
      let checked = cityOptions.map((item) => {
        return item.id;
      });
      this.checkedCities = val ? checked : [];
      this.isIndeterminate = false;
    },
    weeksCheckAllChange(val){
      let checked = weekOptions.map((item) => {
        return item.id;
      });
      this.checkedWeeks = val ? checked : [];
      this.isWeekIndeterminate = false;
    },
    beforeAvatarUpload(file) {
      let types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
        return false;
      }else{
      }
    },
      averageClassChange(val,index){
      this.$set(this.subjectData[index],'classHours',val)
    },
    dealTeachTitleName(row){
      if(row.teachTitleName){
        return row.teachTitleName;
      }else{
        return '均可';
      }
    },
    formatterZdyActualPrice(row){
      //9.28 没有实际支付  就显示总价字段
      if(row.zdyActualPrice){
        return row.zdyActualPrice;
      }else{
        if (row.zdyActualPrice) {
        return row.zdyActualPrice;
      } else {
        return row.totalPrice;
      }
      }
    },
     handleAvatarSuccess(res, file){
      studentUpdate({id:this.studentId,teachManagerPhoto:res[0].url,photoFlag:1}).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
           this.studentInfo(this.studentId);
        }
      });
        this.updateImg.teachManagerPhoto = res[0].url;
    },
    batchPickerChange(){
      if (!this.batchPickerValue) {
        this.batchTimeForm.beginTime = null;
        this.batchTimeForm.stopTime = null;
        return;
      }
      this.batchTimeForm.beginTime = this.batchPickerValue[0];
      this.batchTimeForm.stopTime = this.batchPickerValue[1];
    },
    async addressProvinceChange(){
      await this.addressCityListFn();
      this.saveAddressForm.cityId = this.addressCityList[0].areaId;
      this.addressCityChange();
    },
    async addressCityChange() {
      await this.addressCountyListFn();
      this.saveAddressForm.countyId = this.addressCountyList[0].areaId;
    },
     addressCityListFn() {
      return systemAreaList({
        parentId: this.saveAddressForm.provinceId
      }).then(res => {
        if (res.code == 0) {
          this.addressCityList = res.rows;
        }
      });
    },
    addressCountyListFn(){
        return systemAreaList({
        parentId: this.saveAddressForm.cityId
      }).then(res => {
        if (res.code == 0) {
          this.addressCountyList = res.rows;
        }
      });
    },
    changeBeginTime(){
      if (this.singleRuleForm.beginTime === null) {
       
        return;
      }
      this.singleRuleForm.beginTime = dayjs(
        this.singleRuleForm.beginTime
      ).format("YYYY-MM-DD HH:mm");
      let  time=this.singleRuleForm.beginTime.substring(0,10);
      let  weekArray = new Array("日", "一", "二", "三", "四", "五", "六");
      let week = weekArray[new Date(time).getDay()];
      if(week=='一'){
        this.singleRuleForm.week=1;
      }else if(week=='二'){
        this.singleRuleForm.week=2;
      }else if(week=='三'){
        this.singleRuleForm.week=3;
      }else if(week=='四'){
        this.singleRuleForm.week=4;
      }else if(week=='五'){
        this.singleRuleForm.week=5;
      }else if(week=='六'){
        this.singleRuleForm.week=6;
      }else if(week=='日'){
        this.singleRuleForm.week=0;
      }
    },
     dealSubjectIdd(row) {
      var subjectlist = "";
      if (row.subjectId == 185) {
        subjectlist = "全学科";
      } else if (row.subjectId == 23) {
        subjectlist = "语文";
      } else if (row.subjectId == 24) {
        subjectlist = "数学";
      } else if (row.subjectId == 25) {
        subjectlist = "英语";
      } else if (row.subjectId == 101) {
        subjectlist = "物理";
      } else if (row.subjectId == 102) {
        subjectlist = "化学";
      } else if (row.subjectId == 99) {
        subjectlist = "政治";
      } else if (row.subjectId == 100) {
        subjectlist = "生物";
      } else if (row.subjectId == 87) {
        subjectlist = "地理";
      } else if (row.subjectId == 88) {
        subjectlist = "历史";
      } else if (row.subjectId == 103) {
        subjectlist = "科学";
      } else if (row.subjectId == 112) {
        subjectlist = "国学";
      } else if (row.subjectId == 113) {
        subjectlist = "书法";
      } else if (row.subjectId == 114) {
        subjectlist = "吉他";
      } else if (row.subjectId == 115) {
        subjectlist = "绘画";
      } else if (row.subjectId == 116) {
        subjectlist = "德语";
      } else if (row.subjectId == 117) {
        subjectlist = "法语";
      } else if (row.subjectId == 118) {
        subjectlist = "俄语";
      } else if (row.subjectId == 119) {
        subjectlist = "西班牙语";
      } else if (row.subjectId == 183) {
        subjectlist = "其他小语种";
      } else if (row.subjectId == 184) {
        subjectlist = "其他艺术课";
      }
      return subjectlist;
    },
    seeSex(sex){
      if(sex==1){
        return '男'
      }else if(sex==2){
        return '女'
      }else{
        return '暂无数据'
      }
    },
    getClassTimes(row){
      if(row.classTimes==''||row.classTimes==0){
        return '每周一次'
      }else if(row.classTimes==1){
        return '每周两次'
      }else if(row.classTimes==2){
        return '每周三次'
      }else if(row.classTimes==3){
        return '每周四次'
      }else if(row.classTimes==4){
        return '每周五次'
      }else if(row.classTimes==5){
        return '每周六次'
      }else if(row.classTimes==6){
        return '每天一次'
      }
    },
    getClassForm(row){
      if(row.classForm==''||row.classForm==0){
        return '均可'
      }else if(row.classForm==1){
        return '线下'
      }else if(row.classForm==2){
        return '线上'
      }
    },
    getPriorTimeOne(row){
      if(row.priorTimeOne==''||row.priorTimeOne==0){
        return '均可'
      }else if(row.priorTimeOne==1){
        return '凌晨'
      }else if(row.priorTimeOne==2){
        return '上午'
      }else if(row.priorTimeOne==3){
        return '下午'
      }else if(row.priorTimeOne==4){
        return '晚上'
      }
    },
    getPriorTimeTwo(row){
      if(row.priorTimeTwo==''||row.priorTimeTwo==0){
        return '均可'
      }else if(row.priorTimeTwo==1){
        return '凌晨'
      }else if(row.priorTimeTwo==2){
        return '上午'
      }else if(row.priorTimeTwo==3){
        return '下午'
      }else if(row.priorTimeTwo==4){
        return '晚上'
      }
    },
    getPriorTimeThree(row){
      if(row.priorTimeThree==''||row.priorTimeThree==0){
        return '均可'
      }else if(row.priorTimeThree==1){
        return '凌晨'
      }else if(row.priorTimeThree==2){
        return '上午'
      }else if(row.priorTimeThree==3){
        return '下午'
      }else if(row.priorTimeThree==4){
        return '晚上'
      }
    },
    getPriorDateOne(row){
      if(row.priorDateOne==1){
        return '周一'
      }else if(row.priorDateOne==2){
        return '周二'
      }else if(row.priorDateOne==3){
        return '周三'
      }else if(row.priorDateOne==4){
        return '周四'
      }else if(row.priorDateOne==5){
        return '周五'
      }else if(row.priorDateOne==6){
        return '周六'
      }else if(row.priorDateOne==7){
        return '周日'
      }
    },
     getPriorDateTwo(row){
      if(row.priorDateTwo==1){
        return '周一'
      }else if(row.priorDateTwo==2){
        return '周二'
      }else if(row.priorDateTwo==3){
        return '周三'
      }else if(row.priorDateTwo==4){
        return '周四'
      }else if(row.priorDateTwo==5){
        return '周五'
      }else if(row.priorDateTwo==6){
        return '周六'
      }else if(row.priorDateTwo==7){
        return '周日'
      }
    },
     getTeachSpeed(row){
      if(row.speedNeedId==''||row.speedNeedId==0){
        return '均可'
      }else if(row.speedNeedId==13){
        return '正常'
      }else if(row.speedNeedId==12){
        return '快'
      }else if(row.speedNeedId==14){
        return '慢'
      }
    },
    getPriorDateThree(row){
      if(row.priorDateThree==1){
        return '周一'
      }else if(row.priorDateThree==2){
        return '周二'
      }else if(row.priorDateThree==3){
        return '周三'
      }else if(row.priorDateThree==4){
        return '周四'
      }else if(row.priorDateThree==5){
        return '周五'
      }else if(row.priorDateThree==6){
        return '周六'
      }else if(row.priorDateThree==7){
        return '周日'
      }
    },
     getTeachTitleId(row){
      if(row.teachTitleId==''||row.teachTitleId==0){
        return '均可'
      }else if(row.teachTitleId==59){
        return '初级讲师'
      }else if(row.teachTitleId==60){
        return '中级讲师'
      }else if(row.teachTitleId==61){
        return '高级讲师'
      }else if(row.teachTitleId==62){
        return '特级讲师'
      }
    },
     getAgeNeedId(row){
      if(row.ageNeedId==''||row.ageNeedId==0){
        return '均可'
      }else if(row.ageNeedId==49){
        return '25~35'
      }else if(row.ageNeedId==79){
        return '35~45'
      }else if(row.ageNeedId==80){
        return '45以上'
      }
    },
    getTeachingAgeId(row){
      if(row.teachingAgeId==''||row.teachingAgeId==0){
        return '均可'
      }else if(row.teachingAgeId==27){
        return '5年以下'
      }else if(row.teachingAgeId==28){
        return '5-10'
      }else if(row.teachingAgeId==29){
        return '10-15'
      }else if(row.teachingAgeId==227){
        return '15-20以上'
      }
    },
    getStyleNeedId(row){
      if(row.styleNeedId==''||row.styleNeedId==0){
        return '均可'
      }else if(row.styleNeedId==31){
        return '严厉型'
      }else if(row.styleNeedId==32){
        return '幽默风趣型'
      }else if(row.styleNeedId==228){
        return '亲善知心型'
      }
    },
    getSexNeedId(row){
      if(row.sexNeedId==''||row.sexNeedId==0){
        return '均可'
      }else if(row.sexNeedId==1){
        return '男老师'
      }else if(row.sexNeedId==2){
        return '女老师'
      }
    },
     classHourTypeChange() {
      this.$set(this.singleRuleForm, "productId", "");
      this.surplusNum = 0;
    },
    batchHourTypeChange(){
      this.$set(this.batchTimeForm, "productId", "");
      this.batchClassHourType =  this.batchTimeForm.classHourType;
      this.surplusNum2 = 0;
      for (var i = 0; i < this.courseDate.length; i++) {
        this.courseDate[i].classHourType = this.batchClassHourType;
      }
    },
    sure(){
      this.batchLoading = true;
    for(var i=0;i<this.courseDate.length;i++){
        if (
            dayjs(this.courseDate[i].beginTime)
              .add(1, "minute")
              .isBefore(dayjs())
          ) {
            this.msgWarn("排课不能再到当前时间之前的时间！请重新选择时间");
            return;
          }
      }
     if(this.courseDate.length==0){
        this.msgWarn("课程不能为空！");
        this.batchLoading = false;
        return;
      }
      for(var i=0;i<this.courseDate.length;i++){
        if(this.courseDate[i].subjectId==""||this.courseDate[i].time==""||this.courseDate[i].usedClassHour==""||this.courseDate[i].title==""||this.courseDate[i].teacherName==""){
          this.msgWarn("必填项不能为空！");
          this.batchLoading = false;
          return;
        }
      }
      let courseAll = 0;
      for (var k = 0; k < this.courseDate.length; k++) {
        //  this.courseDate[k].teachFee=Number(this.courseDate[k].teachFee)*Number(this.courseDate[k].usedClassHour)
        if (this.getSinglePrice2 ) {
            //正常课程除外
            this.courseDate[k].usePrice =
              Number(this.getSinglePrice2) *
              Number(this.courseDate[k].usedClassHour);
          } else {
            this.courseDate[k].usePrice =
              Number(this.getSinglePrice) *
              Number(this.courseDate[k].usedClassHour);
          }
          var allUsePrice=0;
          var allUsedClassHour=0;
          if (this.batchTimeForm.classHourType == '1') {
            for(var i=0;i<this.courseDate.length;i++){
                allUsePrice+=this.courseDate[i].usePrice
            }
            if (allUsePrice> this.surplusNum2) {
              this.batchLoading = false;
              this.msgWarn("该费用已不足！");
              return false;
            }
          } else if(this.batchTimeForm.classHourType == "2"){
            for(var i=0;i<this.courseDate.length;i++){
                allUsedClassHour+=this.courseDate[i].usedClassHour
            }
            if (allUsedClassHour > this.surplusNum2) {
              this.batchLoading = false;
              this.msgWarn("该赠送课时已不足！");
              return false;
            }
          }
       
      }
          
          if (this.singleRuleForm.expense == "--") {
            this.batchLoading = false;
            return this.$message.warning("流量费用不正确！");
          }
          if (this.organTotalBalance < this.singleRuleForm.expense) {
            this.batchLoading = false;
            return this.$message.warning("流量费用不能大于账户余额！");
          }
      batchCheckTeacherClassTime(this.courseDate).then(res => {
      if(res.msg=='操作成功！'){
        batchCheckStudentClassTime(this.courseDate).then(res => {
            if(res.msg=='操作成功！'){
              batchCheckTeacherOrganClassTime(this.courseDate).then((res) => {
                if(res.msg=='操作成功！'){
                   for(var i=0;i<this.courseDate.length;i++){
              this.courseDate[i].studentId=this.idd;
              this.courseDate[i].sectionId=this.getSectionId;
            //  this.courseDate[i].usePrice=Number(this.getSinglePrice2)*Number(this.usedClassHour);
              this.courseDate[i].week=new Date(this.courseDate[i].date).getDay();
              if(this.courseDate[i].classMode==true){
                  this.courseDate[i].classMode='1'
              }else{
                this.courseDate[i].classMode='2'
              }
              if(this.courseDate[i].isFirstClass==true){
                  this.courseDate[i].isFirstClass='1'
              }else{
                this.courseDate[i].isFirstClass='0'
              }
            }
              if(this.classHourStatusMore==2){
                    xmtProductItembatchSave(this.courseDate).then((res) => {
                    if (res.code == 0&&res.msg=='操作成功！') {
                      this.batchTimePopup = false;
                      this.findCourseItemFnn();
                      this.batchLoading=false;
                    }else{
                      this.msgWarn(res.rows);
                      this.batchLoading=false;
                    }
                  });
                  }else{
                    batchSaveOneToOne(this.courseDate).then((res) => {
                    if (res.code == 0&&res.msg=='操作成功！') {
                      this.batchTimePopup = false;
                      this.batchLoading = false;
                      this.findCourseItemFnn();
                    }else{
                      this.msgWarn(res.rows);
                      this.batchLoading=false;
                    }
                  });
                  }
                }else{
                    //老师被占用
                    this.centerType = "teacher";
                    this.centerStudentName = res.rows.organStudentName;
                    this.centerTeacherName = res.rows.teacherName;
                    this.centerBeginTime = res.rows.beginTime;
                    this.centerTitle = res.rows.title;
                    this.centerUsedClassHour = res.rows.usedClassHour;
                    this.centerOrganName = res.rows.organName;
                    this.centerTrue=true;
                    this.centerDialogVisible = true;
                    this.batchLoading=false;
                }
              })
               
            }else{
              //学生被占用
              this.centerType='student'
              this.centerStudentName=res.rows.studentName;
              this.centerTeacherName=res.rows.teacherName;
              this.centerBeginTime=res.rows.beginTime;
              this.centerTitle=res.rows.title;
              this.centerUsedClassHour=res.rows.usedClassHour;
               this.centerTrue=false;
              this.centerDialogVisible=true;
              this.batchLoading=false;
            }
        });
      }else{
        //老师被占用
        this.centerType='teacher'
        this.centerStudentName=res.rows.studentName;
        this.centerTeacherName=res.rows.teacherName;
        this.centerBeginTime=res.rows.beginTime;
        this.centerTitle=res.rows.title;
        this.centerUsedClassHour=res.rows.usedClassHour;
         this.centerTrue=false;
        this.centerDialogVisible=true;
        this.batchLoading=false;
      }
  });

  // for(var i=0;i<this.courseDate.length;i++){
  //    this.courseDate[i].studentId=this.idd;
  //    this.courseDate[i].usePrice=Number(this.getSinglePrice2)*Number(this.usedClassHour);
  //    this.courseDate[i].week=new Date(this.courseDate[i].date).getDay();
  //    if(this.courseDate[i].classMode==true){
  //       this.courseDate[i].classMode='1'
  //    }else{
  //      this.courseDate[i].classMode='2'
  //    }
  //    if(this.courseDate[i].isFirstClass==true){
  //       this.courseDate[i].isFirstClass='1'
  //    }else{
  //      this.courseDate[i].isFirstClass='0'
  //    }

  // }
  //   xmtProductItembatchSave(this.courseDate).then(res => {
  //       if(res.code==0){
  //         this.batchTimePopup=false;
  //         this.findCourseItemFnn();
  //       }
  //   });
},
    clickAddressSure(){
       this.detailAddressPopup=false;
      if(this.addressType==1){
      this.singleRuleForm.classAddress=this.newdetailAddress.provinceName+this.newdetailAddress.cityName+this.newdetailAddress.countyName+this.newdetailAddress.detailAddress;
      }else{
        for(var i=0;i<this.courseDate.length;i++){
          this.courseDate[i].classAddress=this.newdetailAddress.provinceName+this.newdetailAddress.cityName+this.newdetailAddress.countyName+this.newdetailAddress.detailAddress;
        }
      }
    },
    onePickerChange(){
      if (!this.timePickerOne) {
        this.findListParams.searchBeginDate = null;
        this.findListParams.searchEndDate = null;
        return;
      }
      this.findListParams.searchBeginDate = this.timePickerOne[0];
      this.findListParams.searchEndDate = this.timePickerOne[1];
    },
    oneToOneSizeChange(pageSize){
      this.findListParams.pageSize = pageSize;
      this.findProductItemPage();
    },
    oneToOneCurrentChange(currentPage){
      this.findListParams.pageNumber = currentPage;
      this.findProductItemPage();
    },
     oneToMoreSizeChange(pageSize){
      this.findMoreProductItemPage.pageSize = pageSize;
      this.findMoreProductItemPage();
    },
    oneToMoreCurrentChange(currentPage){
       this.findMoreProductItemPage.pageNumber = currentPage;
       this.findMoreProductItemPage();
    },
     formatterAttendClassStatus(row){
        switch (row.attendClassStatus) {
            case 0:
              return  "未上课";
            case 1:
              return "已上课";
            case 2:
              return "上课中";
            default:
                return "";
        }
    },
     findProductItemPage(){
      findProductItemPage(this.findListParams).then(res => {
          this.oneToOneData=res.rows;
          this.countMap=res.countMap;
          this.total1=res.total;
      })
    },
    importExcelOne(){
      let jsonData = [], str = `上课时间,产品名称,课程标题,课程级别,课时,授课老师,上课状态\n`
      for(let i = 0, len = this.oneToOneData.length; i < len; i++){
        jsonData.push({
          'beginTime': this.oneToOneData[i].beginTime,
          'productName': this.oneToOneData[i].productName,
          'title': this.oneToOneData[i].title,
          'courseLevel': this.courseLevelFormatter(this.oneToOneData[i]),
          'usedClassHour': this.oneToOneData[i].usedClassHour,
          'teacherName': this.oneToOneData[i].teacherRealName,
          'attendClassStatus': this.formatterAttendClassStatus(this.oneToOneData[i]),
        })
      }
      // 增加\t为了不让表格显示科学计数法或者其他格式
      for(let i = 0 ; i < jsonData.length ; i++ ){
          for(const key in jsonData[i]){
              str+=`${jsonData[i][key] + '\t'},`;     
          }
          str+='\n';
      }
      console.log(str)
      // encodeURIComponent解决中文乱码
      const uri = 'data:text/xls;charset=utf-8,\ufeff' + encodeURIComponent(str);
      // 通过创建a标签实现
      const link = document.createElement("a");
      link.href = uri;
      // 对下载的文件命名
      link.download =  "学生课耗一对一列表.xls";
      link.click();
    },

    classRecordClick(tab){
       this.findListParams.studentId=this.studentId;
        this.oneToMoreParams.studentId=this.studentId;
        this.delParams.studentId=this.studentId;
        if(this.classRecord==1){
          this.findProductItemPage();
        }else if(this.classRecord==2){
          this.findMoreProductItemPage();
        }else if(this.classRecord==3){
          this.findDelProductItemPage();
        }
    },
    findDelProductItemPage(){
       findDelProductItemPage(this.delParams).then(res => {
                this.delData=res.rows;
                this.total3=res.total;
            })
    },
     teachingAgeIdd(row){
        var str='';
        if(row.teachingAgeId==27){
            str='5年以下'
        }else if(row.teachingAgeId==28){
            str='5-10'
        }else if(row.teachingAgeId==29){
            str='10-15'
        }else if(row.teachingAgeId==227){
            str='15-20以上'
        }else{
          str='均可'
        }
        return str;
    },
    delPickerChange(){
      if (!this.timePickerDel) {
        this.delParams.searchBeginDate = null;
        this.delParams.searchEndDate = null;
        return;
      }
      this.delParams.searchBeginDate = this.timePickerDel[0];
      this.delParams.searchEndDate = this.timePickerDel[1];
    },
    importExcelDel(){
      let jsonData = [], str = `上课时间,产品名称,课程标题,课程级别,课时,授课老师,取消原因\n`
      for(let i = 0, len = this.delData.length; i < len; i++){
        jsonData.push({
          'beginTime': this.delData[i].beginTime,
          'productName': this.delData[i].productName,
          'title': this.delData[i].title,
          'courseLevel': this.courseLevelFormatter(this.delData[i]),
          'usedClassHour':this.delData[i].usedClassHour,
          'teacherName': this.delData[i].teacherRealName,
          'delCause': this.formatterDelCause(this.delData[i]),
        })
      }
      // 增加\t为了不让表格显示科学计数法或者其他格式
      for(let i = 0 ; i < jsonData.length ; i++ ){
          for(const key in jsonData[i]){
              str+=`${jsonData[i][key] + '\t'},`;
          }
          str+='\n';
      }
      console.log(str)
      // encodeURIComponent解决中文乱码
      const uri = 'data:text/xls;charset=utf-8,\ufeff' + encodeURIComponent(str);
      // 通过创建a标签实现
      const link = document.createElement("a");
      link.href = uri;
      // 对下载的文件命名
      link.download =  "学生课耗取消课列表.xls";
      link.click();
    },
     formatterDelCause(row){
        switch (row.delCause) {
            case 1:
              return "学生";
            case 2:
              return "老师";
            case 3:
              return "其他";
            default:
                return "";
        }
    },
    sureTeacher(){
      this.selectTeacherPopup=false;
      if(this.teacherType==1){
        this.getSinglePrice2=this.teacherSelectionHandle[0].classSinglePrice;
        this.newteacherName=this.teacherSelectionHandle[0].teacherRealname;
        this.singleRuleForm.teacherId=this.teacherSelectionHandle[0].teacherId;
        this.singleRuleForm.configteacherPlanId=this.teacherSelectionHandle[0].id;
         this.singleRuleForm.teachFee =
          this.teacherSelectionHandle[0].teachFee;
      }else{
        //批量选择老师
        for(var i=0;i<this.courseDate.length;i++){
          this.courseDate[i].teacherName=this.teacherSelectionHandle[0].teacherRealname;
          this.courseDate[i].teacherId=this.teacherSelectionHandle[0].teacherId;
          this.courseDate[i].configteacherPlanId=this.teacherSelectionHandle[0].id;
           this.courseDate[i].teachFee =
          this.teacherSelectionHandle[0].teachFee;
        }
      }
    },
    getRowKeys(row) {
      return row.id;
    },
    handleTeacherSelectionChange(val){
      this.teacherSelectionHandle=val;
      this.checkedGh = val.id;
      if (val.length > 1) {
        this.$refs.resTeacherListTable.clearSelection();
        this.$refs.resTeacherListTable.toggleRowSelection(val.pop());
      }
    },
    rowTeacherItemClick(row){
      this.$refs.resTeacherListTable.toggleRowSelection(row);
    },
    
    handleTeacherNewSizeChange(){},
    handleTeacherNewCurrentChange(){},
    morePickerChange(){
      if (!this.timePickerMore) {
        this.oneToMoreParams.searchBeginDate = null;
        this.oneToMoreParams.searchEndDate = null;
        return;
      }
      this.oneToMoreParams.searchBeginDate = this.timePickerMore[0];
      this.oneToMoreParams.searchEndDate = this.timePickerMore[1];
    },
    delSizeChange(pageSize){
      this.delParams.pageSize = pageSize;
      this.findDelProductItemPage();
    },
    delCurrentChange(currentPage){
      this.delParams.pageNumber = currentPage;
       this.findDelProductItemPage();
    },
    demandSizeChange(pageSize){
      this.configteacherParams.pageSize = pageSize;
      this.configteacherPlanDemand();
    },
    demandCurrentChange(currentPage){
      this.configteacherParams.pageNumber = currentPage;
       this.configteacherPlanDemand();
    },
     findMoreProductItemPage(){
      findMoreProductItemPage(this.oneToMoreParams).then(res => {
          this.oneToMoreData=res.rows;
          this.total2=res.total;
      })
    },
    judgeSex(item){
      if(item==1){
        return '男老师'
      }else if(item==2){
        return '女老师'
      }else{
        return '均可'
      }
    },
    judgeAgeNeed(item){
      if(item==49){
        return '25~35'
      }else if(item==79){
        return '35~45'
      }else if(item==80){
        return '45以上'
      }else{
        return '均可'
      }
    },
    judgeStyleNeed(item){
      if (item == 272) {
        return "理智型教学风格";
      } else if (item == 273) {
        return "情感型教学风格";
      } else if (item == 274) {
        return "自然型教学风格";
      }else if (item == 275) {
        return "幽默型教学风格";
      }else if (item == 276) {
        return "技巧型教学风格";
      }
       else {
        return "均可";
      }
    },
    judgeExamination(item){
      if(item==1){
        return '模拟考试'
      }else if(item==2){
        return '期中'
      }else if(item==3){
        return '期末'
      }else if(item==4){
        return '月考'
      }else if(item==5){
        return '随堂练'
      }
    },
    sureRemoveClass() {
      if (this.removeClassRadio == "") {
        this.msgWarn("请选择删除原因！");
        return;
      }
      let data = {
        id: this.getRemoveId,
        delFlag: 0,
        delCause: this.removeClassRadio,
      };
      xmtProductItemUpdate(data).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.findCourseItemFnn();
          this.removeClassVisible = false;
          this.removeClassRadio = "";
        }
      });
    },
    importExcelMore(){
      let jsonData = [], str = `班级名称,授课老师,课程标题,上课时间,上课状态,课时数,课程状态\n`
      for(let i = 0, len = this.oneToMoreData.length; i < len; i++){
        jsonData.push({
          'roomName': this.oneToMoreData[i].roomName,
          'teacherName': this.oneToMoreData[i].teacherRealName,
          'title': this.oneToMoreData[i].title,
          'beginTime':  this.oneToMoreData[i].beginTime,
          'orderSourceName':this.formatterAttendClassStatus(this.oneToMoreData[i]),
          'usedClassHour': this.oneToMoreData[i].usedClassHour,
        })
      }
      // 增加\t为了不让表格显示科学计数法或者其他格式
      for(let i = 0 ; i < jsonData.length ; i++ ){
          for(const key in jsonData[i]){
              str+=`${jsonData[i][key] + '\t'},`;     
          }
          str+='\n';
      }
      console.log(str)
      // encodeURIComponent解决中文乱码
      const uri = 'data:text/xls;charset=utf-8,\ufeff' + encodeURIComponent(str);
      // 通过创建a标签实现
      const link = document.createElement("a");
      link.href = uri;
      // 对下载的文件命名
      link.download =  "学生课耗一对多列表.xls";
      link.click();
    },
     courseLevelFormatter(row){
      var str='';
      if(row.courseLevel==1){
          str='初级课程'
      }else if(row.courseLevel==2){
          str='中级课程'
      }else if(row.courseLevel==3){
          str='高级课程'
      }else if(row.courseLevel==4){
          str='特级课程'
      }else if(row.courseLevel==0){
        str='正常课程'
      }
      return str;
    },
    confirmAddressSave(formName){
        this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveAddressForm.studentId=this.idd;
              checkDefault(this.idd).then(res => {
                if(res.code==0){
                  if(res.msg=='无默认'){
                     studentClassAddressSave(this.saveAddressForm).then(res => {
                        if(res.code==0){
                          this.addAddressPopup=false;
                          this.studentClassAddressList();
                        }
                      });
                  }else{
                      if(this.saveAddressForm.isDefault==1){
                          this.msgWarn("已有默认地址！");
                      return false;
                      }else{
                        studentClassAddressSave(this.saveAddressForm).then(res => {
                        if(res.code==0){
                          this.addAddressPopup=false;
                          this.studentClassAddressList();
                        }
                      });
                      }
                  }
                }
              });
            }
         })
    },
    handleNeedChange(){

    },
    needItemClick(){

    },
    getDetailAddress(){
      this.detailAddressPopup=true;
      this.addressType=1;
      this.studentClassAddressList();
    },
    studentClassAddressList(){
      studentClassAddressList(this.classAddressData).then(res => {
          this.addressListData=res.rows;
          this.totalData=res.total;
        });
    },
    getTeacherId(){
      if(this.teacherParams.subjectId==null||this.teacherParams.subjectId==""){
          this.msgWarn("请先选择学科！");
          return false;
      }
        this.selectTeacherPopup=true;
        this.teacherType=1;
        this.configteacherPlanFn()
    },
    configteacherPlanFn(){
      configteacherPlan(this.teacherParams).then(res => {
          this.matchingTeacherList=res.rows;
          this.teacherTotalnew=res.total;
      });
    },
    lastWeek(){
      this.dataArray2 = [];
      this.dataWeek = [];
      this.curWeekFirstDay = dayjs(this.curWeekFirstDay)
        .add(-7, "day")
        .format("YYYY-MM-DD");
      this.getWeekFn();
      this.findCourseItemFnn();
    },
    nextWeek(){
      this.dataArray2 = [];
      this.dataWeek = [];
      this.curWeekFirstDay = dayjs(this.curWeekFirstDay)
        .add(7, "day")
        .format("YYYY-MM-DD");
      this.getWeekFn();
      this.findCourseItemFnn();
    },
    addCourseList(macroId, name) {
      this.courseData.push({
        studentId: this.newStudentId,
        subjectId: macroId
      });
    },
    dealDelete(val,index) {
      if(val.id){
         configureCourseRemove([val.id]).then(res => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.configureCourseListFn();
          // this.courseVisible = false;
        }
      });
      }else{
        this.courseData.splice(index,1);
      }
    },
    subjectRow(row, index) {
      this.options=[
        {
          id: 1,
          label: "初级课程"
        },
        {
          id: 2,
          label: "中级课程"
        },
        {
          id: 3,
          label: "高级课程"
        },
        {
          id: 4,
          label: "特级课程"
        }
      ]
      console.log(row,'row....')
    },
    classSingBlur(index, val) {
      
      // this.courseData[index].courseLevel = 1;
    },
    save() {
      if (this.courseDataLength > 0) {
        for (var i = 0; i < this.courseData.length - 1; i++) {
            for (var j = i + 1; j < this.courseData.length; j++) {
              console.log(this.courseData[i],'1111')
              console.log(this.courseData[j],'2222')
              if (this.courseData[i].courseLevel === this.courseData[j].courseLevel&&this.courseData[i].subjectId === this.courseData[j].subjectId) {
                this.msgWarn("同一个课程级别不能设置多个！");
                return false
              }
            }
          }
        for(var i=0;i<this.courseData.length;i++){
          if(this.courseData[i].classSinglePrice==undefined||this.courseData[i].courseLevel==undefined){
            this.msgWarn("课程级别和课时单价不能为空！");
            return false;
          }
        }
      
        removeByStudentId(this.newStudentId).then(res => {
          if (res.code == 0) {
            configureCourseSave(this.courseData).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.courseVisible = false;
              }
            });
          }
        });
      } else {
        for (var i = 0; i < this.courseData.length - 1; i++) {
            for (var j = i + 1; j < this.courseData.length; j++) {
              if (this.courseData[i].courseLevel === this.courseData[j].courseLevel) {
                this.msgWarn("同一个课程级别不能设置多个！");
                return false
              }
            }
          }
         for(var i=0;i<this.courseData.length;i++){
         console.log(this.courseData[i].classSinglePrice,this.courseData[i].courseLevel)
         if(this.courseData[i].classSinglePrice==undefined||this.courseData[i].courseLevel==undefined){
           this.msgWarn("课程级别和课时单价不能为空！");
           return false;
         }
       }
        configureCourseSave(this.courseData).then(res => {
          if (res.code == 0) {
            this.msgSuccess("操作成功！");
            this.courseVisible = false;
          }
        });
      }
    },
    dealSubjectId(row) {
      var subjectlist=''
      if (row.subjectId == 185) {
        subjectlist = "全学科";
      } else if (row.subjectId == 23) {
        subjectlist = "语文";
      } else if (row.subjectId == 24) {
        subjectlist = "数学";
      } else if (row.subjectId == 25) {
        subjectlist = "英语";
      } else if (row.subjectId == 101) {
        subjectlist = "物理";
      } else if (row.subjectId == 102) {
        subjectlist = "化学";
      } else if (row.subjectId == 99) {
        subjectlist = "政治";
      } else if (row.subjectId == 100) {
        subjectlist = "生物";
      } else if (row.subjectId == 87) {
        subjectlist = "地理";
      } else if (row.subjectId == 88) {
        subjectlist = "历史";
      } else if (row.subjectId == 103) {
        subjectlist = "科学";
      } else if (row.subjectId == 112) {
        subjectlist = "国学";
      } else if (row.subjectId == 113) {
        subjectlist = "书法";
      } else if (row.subjectId == 114) {
        subjectlist = "吉他";
      } else if (row.subjectId == 115) {
        subjectlist = "绘画";
      } else if (row.subjectId == 116) {
        subjectlist = "德语";
      } else if (row.subjectId == 117) {
        subjectlist = "法语";
      } else if (row.subjectId == 118) {
        subjectlist = "俄语";
      } else if (row.subjectId == 119) {
        subjectlist = "西班牙语";
      } else if (row.subjectId == 183) {
        subjectlist = "其他小语种";
      } else if (row.subjectId == 184) {
        subjectlist = "其他艺术课";
      }
      return subjectlist;
    },
    //获取学科
    getSubjectList() {
      return getSelectList({
        parentCode: subjectCode
      }).then(res => {
        if (res.code == 0) {
          res.rows.splice(0,1);
          this.subjectIdList = res.rows;
          this.singSubjectIdList=res.rows;
          this.onesubjectIdList=res.rows;
           this.subjectidList = res.rows;
        }
      });
    },
    classSubjectList(){
      return infoByParentCodeAndValue({
        parentCode: subjectCode
      }).then(res => {
        if (res.code == 0) {
          for (let i = 0, len = res.rows.length; i < len; i++) {
            res.rows[i].status = false;
            res.rows[i].select = false;
            res.rows[i].subjectScore = "";
            res.rows[i].subjectAllScore = "";
            res.rows[i].sexNeedId = "";
            res.rows[i].styleNeedId = "";
            res.rows[i].speedNeedId = "";
            res.rows[i].ageNeedId = "";
            res.rows[i].content = "";
          }
          // this.subjectList = res.rows;
          this.subjectList_info = JSON.parse(JSON.stringify(res.rows));
        }
      });
    },
    unDistribute() {
      let data = {
        keyword: this.listParams.keyword,
        sectionId:
          this.listParams.sectionId == "0" ? "" : this.listParams.sectionId, //年级
        dataAuthority: "dept",
        pageNumber: this.listParams.pageNumber,
        pageSize: this.listParams.pageSize
      };
      unDistribute(data).then(res => {
        if (res.rows) {
          this.type = 1;
          this.studentList = res.rows;
          this.total = res.total;
        }
      });
    },
    //已分配
    alreadyDistribute() {
      let data = {
        keyword: this.listParams.keyword,
        sectionId:
          this.listParams.sectionId == "0" ? "" : this.listParams.sectionId, //年级
        dataAuthority: "dept",
        pageNumber: this.listParams.pageNumber,
        pageSize: this.listParams.pageSize,
        teachManagerId:this.listParams.teachManagerId,
        createEndTime:this.listParams.createEndTime,
        createBeginTime:this.listParams.createBeginTime,
        studentState:this.studentStateTotal,
      };
      alreadyDistribute(data).then(res => {
        if (res.rows) {
          console.log(res.row, "已分配数据。。。");
          this.type = 2;
          this.studentList = res.rows;
          this.total = res.total;
        }
      });
    },
  async  importExcel(){
            this.assignedData=[];
            let str = `学生姓名,归属教务,联系电话,年级\n`
            let data = {
              keyword: this.listParams.keyword,
              sectionId:
                this.listParams.sectionId == "0" ? "" : this.listParams.sectionId, //年级
              dataAuthority: "dept",
              pageNumber: this.listParams.pageNumber,
              pageSize: 10000,
              teachManagerId:this.listParams.teachManagerId,
              createEndTime:this.listParams.createEndTime,
              createBeginTime:this.listParams.createBeginTime,
            };
          await  alreadyDistribute(data).then((res) => {
              for(let i = 0, len = res.rows.length; i < len; i++){
                this.assignedData.push({
                  'studentName': res.rows[i].name?res.rows[i].name:'',
                  'teachManagerRealName': res.rows[i].teachManagerRealName?res.rows[i].teachManagerRealName:'',
                  'phoneNo': res.rows[i].phoneNo?res.rows[i].phoneNo:'',
                  'sectionName':res.rows[i].sectionName?res.rows[i].sectionName:'',
                })
              }
            });
            // 增加\t为了不让表格显示科学计数法或者其他格式
            for(let i = 0 ; i < this.assignedData.length ; i++ ){
                for(const key in this.assignedData[i]){
                    str+=`${this.assignedData[i][key] + '\t'},`;     
                }
                str+='\n';
            }
            // encodeURIComponent解决中文乱码
            const uri = 'data:text/xls;charset=utf-8,\ufeff' + encodeURIComponent(str);
            // 通过创建a标签实现
            const link = document.createElement("a");
            link.href = uri;
            // 对下载的文件命名
            link.download =  "已分配学生列表.xls";
            link.click();
    },
    getFollowTagList() {
      return getSelectList({
        parentCode: followTags
      }).then(res => {
        if (res.code == 0) {
          for (let i = 0, len = res.rows.length; i < len; i++) {
            res.rows[i].select = false;
          }
          this.followTagList = res.rows;
        }
      });
    },
    formatterOrderType(row) {
      switch (row.orderType) {
        case 1:
          return "一对一";
        case 2:
        case 5:
          return "一对多";
        case 3:
          return "试听课";
      }
    },
    formatterOrderStatus(row) {
      switch (row.orderStatus) {
        case 1:
          return "待财务审核";
        case 2:
          return "财务审核拒绝";
        case 3:
          return "教务主管分配中";
        case 4:
          return "待排课";
        case 5:
          return "排课中";
        case 6:
          return "已退款";
        case 7:
          return "订单已完成";
        case 8:
          return "订单已作废";
        case 9:
          return "退款中";
        case 10:
          return "已转为充值";
      }
    },
     handleCheckedWeeksChange(value){
       let checkedCount = value.length;
      this.checkWeekAll = checkedCount === this.weeks.length;
      this.isWeekIndeterminate =
        checkedCount > 0 && checkedCount < this.weeks.length;
    },
    formatterTotalPrice(row) {
      if (row.zdyTotalPrice) {
        return row.zdyTotalPrice;
      } else {
        return row.totalPrice;
      }
    },
    dealTags(value) {
      if (value) {
        let arr = value.split(","),
          tags = [];
        for (let i = 0, len = this.followTagList.length; i < len; i++) {
          if (arr.includes(String(this.followTagList[i].macroId))) {
            tags.push(this.followTagList[i].name);
          }
        }
        return tags.join("，") ? tags.join("，") : "暂无";
      }
      return "暂无";
    },
    dealRemove(type){
        this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          studentDelete([this.studentId]).then(res => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.studentVisible=false;
              if(type==1){
                this.unDistribute();
              }else{
                this.alreadyDistribute();
              }
            }
          });
        })
        .catch(() => {});
    },
    //配置课程
    dealCourse(value) {
      this.courseVisible = true;
      this.newStudentId = value.id;
      studentInfo(value.id).then(res => {
        if (res.rows) {
          this.courseList = res.rows;
        }
      });
      this.configureCourseListFn();
    },
    configureCourseListFn() {
      var data = {
        studentId: this.newStudentId,
        pageNumber: 1,
        pageSize: 1000
      };
      configureCourseList(data).then(res => {
        if (res.rows) {
          this.courseDataLength = res.rows.length;
          this.courseData = res.rows;
        }
      });
    },
    //课程安排
    daalArrange(row) {  
      this.arrangePopup = true; 
      this.idd=row.id;
       this.teacherParams.studentId=row.id;
      this.classAddressData.studentId=row.id;
       this.getSectionName=row.sectionName?row.sectionName:'';
      this.weekTitle="";
      this.curWeekFirstDay= dayjs()
        .add(-(dayjs().day() == 0 ? 7 : dayjs().day()) + 1, "day")
        .format("YYYY-MM-DD");
      this.freeWeekFirstDay=dayjs()
        .add(-(dayjs().day() == 0 ? 7 : dayjs().day()) + 1, "day")
        .format("YYYY-MM-DD");
      studentInfo(row.id).then(res => {
        this.accountBalance=res.rows.accountBalance;
        this.remainingClassHours=res.rows.remainingClassHours;
        this.studentDetailList = res.rows;
        this.getSectionId =res.rows.sectionId;
      });
       this.configteacherParams.studentId = row.id;
       this.configteacherPlanDemand();
      
      this.$nextTick(()=>{
        this.dataArray2=[];
        this.dataWeek=[];
        this.getWeekFn();
        this.findCourseItemFnn();
      })
    },
    configteacherPlanDemand(){
       xmtStudentNeedsList(this.configteacherParams).then(res => {
        this.teacherList = res.rows;
        this.demandTotal=res.total;
      });
    },
  findCourseItemFnn(){
        let year = dayjs(this.curWeekFirstDay).year(),
        month = dayjs(this.curWeekFirstDay).month(),
        date = dayjs(this.curWeekFirstDay).date();
      this.weekTitle =
        year +
        "年" +
        (month + 1) +
        "月第" +
        cNum[getYearWeek(year, month, date)] +
        "周";
      let list = [];
      for (var k = 6; k < 24; k++) {
        list.push([]);
        for (var q = 0; q < 7; q++) {
          // 单元格时间段的开始时间
          let cellTime = dayjs(this.curWeekFirstDay)
            .add(q, "day")
            .add(k, "hour")
            .format("YYYY-MM-DD HH:mm");
          // 时间是否是当前时间之前，是的话不能点击选择， 小时加1按照每格1小时算的
          let isPastTime = dayjs().isAfter(
            dayjs(this.curWeekFirstDay)
              .add(q, "day")
              .add(k + 1, "hour")
          );
          list[k - 6][q] = {
            check: false,
            name: "",
            pastTime: isPastTime,
            canUse: true,
            cellTime,
          };
        }
      }
      this.tableList = list;
     
      let searchBeginDate =
        dayjs(this.curWeekFirstDay).add(0, "day").format("YYYY-MM-DD") +
        " 00:00:00";
      let searchEndDate =
        dayjs(this.curWeekFirstDay).add(6, "day").format("YYYY-MM-DD") +
        " 23:59:59";
      findCourseItem({
        studentId: this.configteacherParams.studentId,
        searchBeginDate,
        searchEndDate,
      }).then((res) => {
        if (res.code == 0) {
          let listProductItemTeachManager =
            res.rows.listProductItemTeachManager;
          listProductItemTeachManager.forEach((item) => {
            // day取值 0 1 2 3 4 5 6 对应周一至周日
            let day =
              (dayjs(item.beginTime).day() == 0
                ? 7
                : dayjs(item.beginTime).day()) - 1;
            // hour代表y坐标因为小时轴从6开始-6便于计算
            let hour = dayjs(item.beginTime).hour() - 6;
            this.tableList[hour][day].canUse = false;
            this.tableList[hour][day].check = true;
            this.tableList[hour][day].name = item.teacherRealName;
            this.tableList[hour][day].id = item.id;
            this.tableList[hour][day].attendClassStatus = item.attendClassStatus;
            this.tableList[hour][day].time = `${dayjs(item.beginTime).format(
              "HH:mm"
            )}-${dayjs(item.beginTime)
              .add(item.usedClassHour, "hour")
              .format("HH:mm")}`;
            this.tableList[hour][day].usedClassHour = item.usedClassHour;
            // 如果课时大于1，则设置下边的几个时间段也不能被点击处理，不足1个小时的课程按照一个小时处理
            let len = item.usedClassHour;
            if (dayjs(item.beginTime).minute() != "00") {
              len++;
            }
            for (let i = 1; i < len; i++) {
              try {
                this.tableList[hour + i][day].canUse = false;
              } catch (err) {
                console.log(err);
              }
            }
            this.tableList[hour][day].subjectName = item.subjectName;
          });
        }
      });
    },
     mouseOver(e,item){
      if(item.attendClassStatus==0){
        e.currentTarget.firstElementChild.className = "td_active";
      }
    },
    mouseLeave(e){
      e.currentTarget.firstElementChild.className='none'
    },
    getWeekFn(){  
        this.weekList = [];
        for (let i = 0; i < 7; i++) {
          this.weekList.push({
            value: `${dayjs(this.curWeekFirstDay)
              .add(i, "day")
              .format("MM-DD")}(${this.weekItemArr[i]})`,
          });
        }
},
 courseEditRemove(id,type){
  this.removeClassRadio = "";
  this.removeClassVisible = true;
  this.getRemoveId = id;
//       xmtProductItemUpdate({id:id,delCause:type}).then(res => {
//            if (res.code == 0) {
//               this.courseRemove(id);
//             }
//        });

    },
     async courseEdit(items){
      this.singlePopup=true;
      this.singleParams.title='编辑课程';
      this.singleParams.type="edit";
    this.getOrganBalance();
      await   getProductByStudentId(this.idd).then(res => {
        this.buyProductList=res.rows;
      });
       studentInfo(this.idd).then((res) => {
        this.accountBalance=res.rows.accountBalance;
        this.remainingClassHours=res.rows.remainingClassHours;
      });
      xmtProductItemInfo(items.id).then(res => {
            this.editId=res.rows.id;
            this.getSubjectId=res.rows.subjectId;
            this.getTeacher=res.rows.teacherId;
            this.teacherParams.subjectId=res.rows.subjectId;
            this.editTime = res.rows.beginTime;
            this.editUsedClassHour = res.rows.usedClassHour;
            this.singleRuleForm={
              id:res.rows.id,
              subjectId: res.rows.subjectId,
              title: res.rows.title,
              beginTime: res.rows.beginTime,
              usedClassHour: res.rows.usedClassHour,
              isFirstClass:String(res.rows.isFirstClass),
              teacherId:res.rows.teacherId,
              classroomId:res.rows.classroomId,
              classMode:String(res.rows.classMode),
              classHourType:this.classHourTypeFn(String(res.rows.classHourType)) ,
              classAddress:res.rows.classAddress,
              productId:res.rows.productId,
              teachFee:res.rows.teachFee,
              isPrisonClass:String(res.rows.isPrisonClass),
              isPlayback:String(res.rows.isPlayback),
              ifClassRemuneration:String(res.rows.ifClassRemuneration),
              videoMode: res.rows.videoMode,
               expense: "--",
            }
            if(res.rows.classHourType==1||res.rows.classHourType==2){
              this.classHourStatus='2'
            }else{
              this.classHourStatus='1'
            }
            this.newProductId=res.rows.productId;
            this.newteacherName=res.rows.teacherName;
            if(this.classHourStatus==2){
              this.surplusNumFn(res.rows.productId);
            }
            this.getSingleClassFee();
      });
    },
    classHourTypeFn(type){
      if(type==3||type==4){
        return String(3);
      }else{
        return type;
      }
    },
    courseRemove(id){
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          xmtProductItemRemove([id]).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.findCourseItemFnn();
            }
          });
        })
        .catch(() => {});
    },
    handleClick(tab, event) {
      if (tab.index == 0) {
      } else if (tab.index == 1) {
        this.followUpListFn();
      } else if (tab.index == 2) {
        this.studentStudyReportFn();
      } else if (tab.index == 3) {
        this.orderListFn();
      } else if (tab.index == 4) {
        this.findProductItemPage();
      }
    },
    studentDetail(id) {
      this.rechargeList=[]
      this.switchValue=true;
      this.classRecord='1'
      this.studentId = id;
      this.getstudentid=id;
      this.studentVisible = true;
      this.xmtStudentNeeds.studentId=id;  
      this.activeName='record';
      this.findListParams.subjectId=""
      this.findListParams.attendClassStatus="1"
      this.findListParams.abnormalStatus=""
      this.timePickerOne=""
      this.oneToMoreParams.roomName=""
      this.oneToMoreParams.attendClassStatus="1"
      this.oneToMoreParams.abnormalStatus=""
      this.timePickerMore=""
      this.delParams.delCause=""
      this.timePickerDel=""
      this.findListParams.studentId=this.studentId;
      this.oneToMoreParams.studentId=this.studentId;
      this.delParams.studentId=this.studentId;
      this.studentInfo(id);
      this.demandFn(id);
      this.selectStudentStateFn(id);
    },
    selectStudentStateFn(id){
      selectStudentState({studentId:id}).then(res => {
        if (res.code==0) {
          if(res.rows==0){
            this.studentState='新生'
          }else if(res.rows==1){
            this.studentState='在读'
          }else if(res.rows==2){

            this.studentState='暂停课'
          }else if(res.rows==3){
            this.studentState='退费'
          }else if(res.rows==4){
            this.studentState='结课'
          }
        }
      });
    },
    getStudentState(row){
      if(row==0){
        return '新生'
      }else if(row==1){
        return '在读'
      }else if(row==2){
        return '暂停课'
      }else if(row==3){
        return '退费'
      }else if(row==4){
        return '结课'
      }
    },
    handleNeedSizeChange(pageSize){
      this.xmtStudentNeeds.pageSize=pageSize;
        this.demandFn();
    },
    handleNeedCurrentChange(currentPage){
      this.xmtStudentNeeds.pageNumber=currentPage;
        this.demandFn();
    },
    demandFn(id) {
      xmtStudentNeedsList(this.xmtStudentNeeds).then(res => {
          this.needList=res.rows;
           this.needTotal=res.total;
      });
    },
    studentInfo(id) {
      //获取详情
      studentInfo(id).then(res => {
        if (res.rows) {
          this.studentListDetail = res.rows;
          this.updateImg.teachManagerPhoto=res.rows.teachManagerPhoto;
          this.updateImg.photoFlag = res.rows.photoFlag
          this.rechargeParams.surplusMoney=res.rows.accountBalance?res.rows.accountBalance:0;
          this.rechargeParams.classHours=res.rows.remainingClassHours?res.rows.remainingClassHours:0;
        }
      });
    },
    orderListFn() {
      orderList({ studentId: this.studentId,dataAuthority:'dept' }).then(res => {
        if (res.code == 0) {
          this.orderList = res.rows;
        }
      });
      getOtoPriceByStudentId({
          studentId: this.getstudentid,
          dataAuthority: "dept",
          orderType: 1,
        }).then((res) => {
          if (res.code == 0) {
            this.surplusMoney = res.rows;
          }
        });
    },
    followUpListFn() {
      this.followupRecord.studentId=Number(this.studentId);
      teacherFollowupRecordList(this.followupRecord).then(res => {
         this.followUpList = res.rows;
        for(var i=0;i< this.followUpList.length;i++){
             if( this.followUpList.length){
                this.followUpList[i].followContent= this.followUpList[i].followContent.replace(/<img/g, "<img style='max-width:200px;height:auto;'");
          }
           }
        //  this.followUpList[i].followContent= this.followUpList[i].followContent.replace(/<img/g, "<img style='max-width:200px;height:auto;'");
      });
    },
    //选择教务确定
    confirmApportion() {
      this.apportionVisible = false;
      let data = {
        id: this.getstudentId,
        teachManagerId: this.saleId,
        teachManagerName: this.username
      };
      studentUpdate({id:this.getstudentId,otoAllocateIsNew:1}).then(res => {
        if (res.code == 0) {
           updateTeachManagerId(data).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.unDistribute();
              }
            });
        }
      });
    },
    searchFn() {
      this.listParams.pageNumber=1;
      if (this.type == 1) {
        
        this.unDistribute();
      } else {
        this.alreadyDistribute();
        this.alreadyDistributeCountFn()
      }
    },
    saleFn() {
      for (var i = 0; i < this.saleList.length; i++) {
        if (this.saleId == this.saleList[i].userId) {
          this.username = this.saleList[i].username;
        }
      }
    },
    //获取年级
    getSectionIdList() {
      getSelectList({
        parentCode: sectionCode
      }).then(res => {
        if (res.code == 0) {
          this.sectionIdList = res.rows;
          this.sectionIdList.unshift({
            macroId: "0",
            name: "全部"
          });
        }
      });
    },
    //分配
    dealAgain(row) {
      let data = {
        roleSign: "studentAdmin",
        status: 1,
        roleSignAdmin:"studentAdminAdmin"
      };

      listNoPage(data).then(res => {
        this.saleList = res;
      });
      this.apportionVisible = true;
      this.getstudentId = row.id;
    },
    handleSelectionChange(val) {
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.checkRowId.push(val[i].id);
        }
      }
    },
    studentItemClick(row) {
      this.$refs.studentListTable.toggleRowSelection(row);
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      if (this.type == 1) {
        this.unDistribute();
      } else {
        this.alreadyDistribute();
      }
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      if (this.type == 1) {
        this.unDistribute();
      } else {
        this.alreadyDistribute();
      }
    },
    handleSelect(key, keyPath) {
      this.checkKey=key;
      if (key == 1) {
      this.listParams={
        keyword: "",
        sectionId: "0",
        dataAuthority: "dept",
        pageNumber: 1,
        pageSize: 10,
        teachManagerId:""
      };
        this.unDistribute();
      } else if (key == 2) {
        //已分配
        this.levelNum=1;
        this.studentStateTotal=[1,5]
        this.listParams={
          keyword: "",
          sectionId: "0",
          dataAuthority: "dept",
          pageNumber: 1,
          pageSize: 10,
          teachManagerId:""
        };
        let data = {
          roleSign: "studentAdmin",
          status: 1
        };
        listNoPage(data).then(res => {
          this.teachManagerIdList = res;
          this.teachManagerIdList.unshift({
            userId: "",
            name:"全部",
            username: "全部"
          });
        });
        this.alreadyDistribute();
        this.alreadyDistributeCountFn();
      }
    },
    alreadyDistributeCountFn(){
      var data = {
        keyword: this.listParams.keyword,
        sectionId:
          this.listParams.sectionId == "0" ? "" : this.listParams.sectionId, //年级
        dataAuthority: "dept",
        pageNumber: this.listParams.pageNumber,
        pageSize: this.listParams.pageSize,
        teachManagerId:this.listParams.teachManagerId,
        createEndTime:this.listParams.createEndTime,
        createBeginTime:this.listParams.createBeginTime,
      };
      alreadyDistributeCount(data).then((res) => {
        if(res.code==0){
          this.countParams=res.rows;
        }
      });
    },
    mustTest(row){
      var str='';
       if(row.qitaxueke==1){
        str+='其他学科，'
      }
      if(row.yuwen==1){
        str+='语文，'
      }
      if(row.shuxue==1){
        str+='数学，'
      }
      if(row.yingyu==1){
        str+='英语，'
      }
      if(row.wuli==1){
        str+='物理，'
      }
      if(row.lishi==1){
        str+='历史，'
      }
       if(row.zhengzhi==1){
        str+='政治，'
      }
      if(row.huaxue==1){
        str+='化学，'
      }
       if(row.dili==1){
        str+='地理，'
      }
      if(row.shengwu==1){
        str+='生物，'
      }
      return str.substring(0,str.length-1);
    },
  },

};
</script>
<style scoped lang="scss">
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 80px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title2 {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-align: left;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .class_title {
    height: 30px;
    line-height: 30px;
    text-indent: 12px;
  }
  .table_area {
    margin-left: 20px;
    padding: 10px 4px;
    .class_tip {
      height: 40px;
      line-height: 40px;
      text-indent: 4px;
      span {
        margin-right: 20px;
      }
    }
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .self_input {
    padding-bottom: 4px;
    color: #606266;
    text-align: center;
    width: 90%;
    overflow: hidden;
    outline: none;
    border: none;
    border-bottom: 1px solid #c0c4cc;
  }
  .self_input:focus {
    border-bottom: 1px solid #1890ff;
  }
  .self_table {
    width: 100%;
    border: 1px solid #ebeef5;
    border-collapse: collapse;
    border-spacing: 0px;
    tr {
      border: 1px solid #ebeef5;
      td {
        border: 1px solid #ebeef5;
        text-align: center;
        vertical-align: middle;
        height: 40px;
      }
    }
  }
  .required_subject {
    height: auto;
    
    margin-bottom: 10px;
  }
  .select_subject {
    .select_subject_item {
      .item_subject_title {
        font-weight: 900;
        padding-left: 32px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
      }
      .item_score {
        display: flex;
        /deep/ .el-input {
          width: 40%;
        }
        > span {
          width: 20px;
          text-align: center;
        }
      }
    }
  }
  .open_tip {
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  .save_apply_tip {
    margin-bottom: 20px;
  }
  .save_apply {
    .save_apply_input {
      width: 80px;
    }
  }
  .scopeDiv div {
    margin-left: 20px;
    display:flex;
   
  }
  .scopeDiv3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .student_dl {
    display: flex;
  }
  .student_div h2 {
    margin-top: 30px;
  }
  .student_div span {
    margin-right: 40px;
    margin-top: 20px;
    display: inline-block;
  }
  .self_table {
    width: 100%;
    border: 1px solid #ebeef5;
    border-collapse: collapse;
    border-spacing: 0px;
    tr {
      border: 1px solid #ebeef5;
      td {
        padding: 10px;
        border: 1px solid #ebeef5;
        text-align: center;
        vertical-align: middle;
        height: 40px;
      }
      .follow_content {
        text-align: left;
        > div:nth-of-type(1) {
          display: flex;
          // height: 80px;
          overflow-y: auto;
          span{
            display: inline-block;
          }
          span:nth-child(1){
            width:100px;
          }
          span:nth-child(2){
            flex:1;
          }
        }
        > div:nth-of-type(2) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .follow_info {
        text-align: right;
        font-size: 14px;
        color: #333;
      }
    }
  }
  .courseContent {
    display: flex;
    margin-top: 20px;
  }
  .courseContent p {
    flex: 1;
    span:nth-child(1){
      color:#909399;
      font-size:12px;
    }
    span:nth-child(2){
      color:#000;
      font-size:14px;
    }
  }
  .subjectList_div {
    margin-top: 30px;
  }
  .subjectList_div ul {
    display: flex;
    flex-wrap: wrap;
  }
  .subjectList_div ul li {
    text-align: center;
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    margin-top: 10px;
  }
   .content_div1 div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    margin-top: 20px;
  }
   .content_div2 h2 {
    margin-top: 20px;
  }
  .content_div2 ul {
    display: flex;
    margin-top: 20px;
  }
  .content_div2 ul li {
    margin-right: 50px;
    font-size: 16px;
  }
  .content_div2 ul li span:nth-child(2) {
    color: red;
    margin-left: 15px;
  }
  .content_div3 {
    margin-top: 20px;
  }
  .content_div4 {
    margin-top: 40px;
  }
   .weekDiv{
    margin-top:20px;
    p{
      font-size:22px;
      // font-weight: 800;
      cursor:pointer;
    }
    p:nth-child(2){
      font-size:22px;
      padding:0 10px;
    }
  }
  table tr td { border:1px solid #DCDFE6; }
  table {width: 1000px; min-height: 40px; line-height: 40px; text-align: center; border-collapse: collapse;}  
  .course_td{
      width: 80px;
      height: 40px;
      line-height:20px;
      position:relative;
      vertical-align: middle;
      // overflow: hidden;
  }
  .course_td > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .course_td p{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .cursor_pointer {
    cursor: pointer;
  }
  .course_can_use {
      font-size: 25px;
      color: #1890FF;
      margin-top: -8px;
    }
    .free_course_can_use{
      color: #666666;
      margin-top: -8px;
    }
  .td_active{
         position: absolute;
    // top: 0 !important;
    left: 0;
    width: 100%;
    height: 100%;
    z-index:999999;
    background: rgba(0, 0, 0, 0.6) !important;
      }
      .detail_type {
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      color: #000;
    }
    .row_detail{
      padding: 6px 0;
    }
    .row_detail2{
      span{
        font-weight: bold;
        color:#ff3f3f;
      }
    }
    .studentTitle{
      font-size:18px;
      font-weight: 700;
      padding: 15px 0;
    }
    .studentSmallTitle{
      font-size:14px;
      font-weight: 700;
    }
    .classInfo{
      >p{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      >div:nth-child(2){
        display: flex;
        
        align-items: center;
        justify-content: space-between;
        padding:15px 0;
        p{
          flex: 1;
          padding-left:20px;
          display: flex;
          span{
            flex:1;
          }
        }
      }
       >div:nth-child(3){
         display: flex;
        justify-content: space-between;
        padding:15px 0;
         ul{
           flex:1;
           padding-left:20px;
          li{
            display: flex;
            .remarksSpan{
              overflow : hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;      /* 可以显示的行数，超出部分用...表示*/
              -webkit-box-orient: vertical;
            }
          }
         }
       }
      .studentSmallTitle{
        width:12%;
        text-align: right;
      }
    }
    .active {
      color: #1890ff;
    }
    .class_title {
      height: 30px;
      line-height: 30px;
      text-indent: 12px;
      > span {
        cursor: pointer;
      }
    }
    /deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
      background-color: #1890ff;
      border-color: #1890ff;
    }
    /deep/ .el-checkbox.is-disabled.is-checked .el-checkbox__label {
      color: #1890ff;
    }
      .imgDolg {
          width: 100vw;
          height: 100vh;
          position: fixed;
          z-index: 9999;
          background-color: rgba(0,0,0, 0.7);
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          #imgDolgClose {
            position: fixed;
            top: 35px;
            cursor: pointer;
            right: 7%;
            font-size: 50px;
            color: white;
          }
          img{
            width: 40%;
            display: block;
          }
        }
        .row_details{
          padding: 12px 0;
        }
        .info_div1,
        .info_div2 {
          // display: flex;
          margin-top: 15px;
        }
        .info_div1 >p,
        .info_div2 >p {
          font-size: 14px;
          font-weight: bold;
        }
        .info_div1 ul ,.info_div2 ul{
          margin-top:20px;
          display:flex;
        }
        .info_div1 ul li {
    width: 25%;
    // padding-bottom: 20px;
    p:nth-child(1){
      color:#909399;
      font-size:12px;
    }
    p:nth-child(2){
      color:#000;
      font-size:14px;
      margin-top:15px;
    }
  }
  .info_div2 ul li{
     p:nth-child(1){
      color:#000000;
      font-size:14px;
    }
  }

}
.countMap_p{
  float:right;
  span{
    padding:0 8px;
  }
}
.student_info{
    // width:80%;
    margin-top:20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height:140px;
        padding:10px 20px;
        background: #fff;
        box-shadow: 0 0 5px #ccc;
        dl{
          display:flex;
          align-items: center;
          dd{
            margin-left:15px;
            p:nth-child(1){
              font-size:16px;
              font-weight:600;
            }
            p:nth-child(2){
              margin-top:10px;
            }
          }
        }

}
.census_div{
  margin-top:40px;
  >p:nth-child(1){
    text-align:center;
    font-size:16px;
    font-weight: 600;
  }
}
  .infoDiv2 span{
      padding:3px 10px;
      border-radius:3px;
      text-align:center;
      margin-right:20px;
      color:#1890ff;
      background:#e8f4ff;
      border:1px solid #1890ff;
  }
  .reportActive{
    background:#1890ff !important;
    color:#fff !important;
  }
  .levelBtn{
  display:inline-block;
  margin-right:10px;
  background:#EFEFEF;
  color:#333;
  border:1px solid #EFEFEF;
}
.levelActive{
  color:#fff;
  background:#3EC279;
  border:1px solid #3EC279;
}

  .bq{
      padding:3px 8px;
      border-radius: 3px;
      font-size:12px;
      color:#EE783D;
      border:1px solid #EE7031;
      background: #FFF4EE;
      margin-left:6px;
      img{
        vertical-align: middle;
        display: inline-block;
        margin-top:-5px;
      }
      span{
        display: inline-block;
      }
  }
  .bqlx{
      padding:3px 8px;
      border-radius: 3px;
      font-size:12px;
      color:#B2B8C0;
      border:1px solid #D9DDE3;
      background: #F5F7FA;
      margin-left:6px;
      img{
        vertical-align: middle;
        display: inline-block;
        margin-top:-5px;
      }
      span{
        display: inline-block;
      }
  }
  /deep/ .add_class .el-dialog__body {
  padding-bottom: 10px;
}
.price_tip {
  margin-top: 10px;
  display: flex;
  line-height: 24px;
  padding-left: 28px;
  .account_fee {
    margin-left: 20px;
  }
}
</style>
