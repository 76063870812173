<template>
    <div class="main-content sys-role-manage">
    <div class="btn-area" style="display:flex; justify-content: space-between;">
      <div class="select-condition-item" >
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">一对一课程</el-menu-item>
          <!-- <el-menu-item index="2">一对多课程</el-menu-item> -->
        </el-menu>
      </div>
      <div style="margin-left:30px;">
             <el-time-select
              v-show="handleKey==1"
              placeholder="起始时间"
              v-model="startTime"
              style="width:120px;"
              @change="startChange()"
              :picker-options="{
                start: '06:00',
                step: '00:30',
                end: '23:30'
              }">
            </el-time-select>
            <el-time-select
              placeholder="结束时间"
              v-show="handleKey==1"
              v-model="endTime"
              style="width:120px;margin:0 15px;"
              @blur="endChange()"
              :picker-options="{
                start: '06:00',
                step: '00:30',
                end: '23:30',
                minTime: startTime
              }">
            </el-time-select>
            <el-select
            v-model="params.teachManagerId"
            placeholder="请选择"
            size="small"
            v-show="handleKey==1"
            style="width:190px;"
            @change="teachManagerChange()"
          >
            <el-option
              v-for="item in saleList"
              :key="item.userId"
               :label="`${item.username}（${item.name}）`"
              :value="item.userId"
            >
            </el-option>
          </el-select>
          <el-time-select
              v-show="handleKey==2"
              placeholder="起始时间"
              v-model="moreStartTime"
              style="width:120px;"
              :picker-options="{
                start: '06:00',
                step: '00:30',
                end: '23:30'
              }">
            </el-time-select>
            <el-time-select
              v-show="handleKey==2"
              placeholder="结束时间"
              v-model="moreEndTime"
              style="width:120px;margin:0 15px;"
              @blur="moreEndChange()"
              :picker-options="{
                start: '06:00',
                step: '00:30',
                end: '23:30',
                minTime: moreStartTime
              }">
            </el-time-select>
           </div>
    </div>
      <div class="table-area" v-show="handleKey == 1">
       <div class="classNum">
           <p>今日课程总数（{{censusParams.todayTotalCourseCount}}/{{videoTotal}}）</p>
           
           <div><span :class="classType=='all'?'active':''" @click="getSupervisionCourseCountFn('all')">全部（{{censusParams.todayTotalCourseCount}}）</span>
               <span :class="classType=='2'?'active':''" @click="getSupervisionCourseCountFn('2')">进行中（{{censusParams.ongoingCourseCount}}）</span>
               <span :class="classType=='3'?'active':''" @click="getSupervisionCourseCountFn('3')">老师或学生未进入（{{censusParams.notIntoCourseCount}}）</span>
               <span :class="classType=='1'?'active':''" @click="getSupervisionCourseCountFn('1')">课程结束（{{censusParams.endCourseCount}}）</span>
           </div>
       </div>
       <div class="classList">
           <dl v-for="(item,index) in videoList" :key="index">
               <dt>
                   <p><span :style="item.attendClassStatus==1?'background:#909399':item.attendClassStatus==0?'background:#F89636':''"></span><span>{{attendClassStatusType(item.attendClassStatus)}}</span></p>
                   <a :href="`${$myStore.webPc}`+`${item.roomid}`" target="_blank" v-show="item.attendClassStatus==2"><img src="../../../assets/image/监课.png" style="width: 100%; height: 100%" alt="" v-show="pullUrlFlvFn(item)" ></a>
                   <span v-show="item.attendClassStatus!=2">
                     <img src="../../../assets/image/监课.png" style="width: 100%; height: 100%" alt="" >
                   </span>
                   <flv-video v-show="item.pullUrlFlv&&new Date().getTime()<new Date(item.stopTime).setHours(new Date(item.stopTime).getHours()+1)&&item.attendClassStatus==2" :id="item.id" :pullUrlFlv="item.pullUrlFlv" ></flv-video>
               </dt>
               <dd>
                <h6>{{item.title}}<span style="margin-left:20px;">{{item.teachManagerName}}（{{item.teachManagerRealName}}）</span></h6>
                <p>{{item.beginTime.split(' ')[0]}}&nbsp;&nbsp;&nbsp;{{item.beginTime.slice(11,16)}}-{{item.stopTime.slice(11,16)}}（{{item.usedClassHour}}小时）</p>
                <ul>
                    <li>
                      <p>
                        <img src="../../../assets/image/老师.png" alt="">
                        <span>{{item.teacherRealName}}</span>
                        <!-- <span>{{item.teacherAccount}}</span> -->
                      </p>
                      <span :style="item.teacherOnlineStatus!=1?'background:#909399':''">{{item.teacherOnlineStatus==1?'在线':'未在线'}}</span>
                    </li>
                    <li>
                      <p>
                        <img src="../../../assets/image/学生.png" alt="">
                        <span>{{item.studentName}}</span>
                        <!-- <span>{{item.studentAccount}}</span> -->
                      </p>
                      <span :style="item.studentOnlineStatus!=1?'background:#909399':''">{{item.studentOnlineStatus==1?'在线':'未在线'}}</span>
                      <img @click="seeQrCode(item)"  v-show="item.attendClassStatus==2" src="../../../assets/image/qrcode.png" alt="">
                    </li>
                    <!-- <li><img src="../../../assets/image/旁听.png" alt=""><p>旁听（3）:</p><p>家长APP</p><p>其他（2）</p></li> -->
                </ul>
               </dd>
           </dl>
       </div>
      </div>
      <!-- <div class="page-area" v-show="handleKey == 1">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.pageNumber"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="videoTotal"
          background
        ></el-pagination>
      </div> -->
      <div class="table-area" v-show="handleKey == 2">
        <div class="classNum">
           <p>今日课程总数（{{moreCensusParams.todayTotalCourseCount}}/{{moreVideoTotal}}）</p>
           <div>
               <span :class="moreclassType=='all'?'active':''" @click="getOneToMoreSupervisionCoursePageFn('all')">全部（{{moreCensusParams.todayTotalCourseCount}}）</span>
               <span :class="moreclassType=='2'?'active':''" @click="getOneToMoreSupervisionCoursePageFn('2')">进行中（{{moreCensusParams.ongoingCourseCount}}）</span>
               <span :class="moreclassType=='3'?'active':''" @click="getOneToMoreSupervisionCoursePageFn('3')">老师或学生未进入（{{moreCensusParams.notIntoCourseCount}}）</span>
               <span :class="moreclassType=='1'?'active':''" @click="getOneToMoreSupervisionCoursePageFn('1')">课程结束（{{moreCensusParams.endCourseCount}}）</span>
           </div>
       </div>
       <div class="classList">
           <dl v-for="(item,index) in moreList" :key="index">
               <dt>
                   <p><span :style="item.attendClassStatus==1?'background:#909399':item.attendClassStatus==0?'background:#F89636':''"></span><span>{{attendClassStatusType(item.attendClassStatus)}}</span></p>
                   <!-- <a :href="`${$myStore.webPc}`+`${item.roomid}`" target="_blank" v-show="item.attendClassStatus==2"><img src="../../../assets/image/监课.png" style="width: 100%; height: 100%" alt="" v-show="pullUrlFlvFn(item)" ></a> -->
                   <span v-show="item.attendClassStatus!=2">
                     <img src="../../../assets/image/监课.png" style="width: 100%; height: 100%" alt="" >
                   </span>
                   <flv-video v-show="new Date().getTime()<new Date(item.stopTime).setHours(new Date(item.stopTime).getHours()+1)&&item.attendClassStatus==2" :id="item.id" :pullUrlFlv="item.pullUrlFlv" ></flv-video>
               </dt>
               <dd>
                <h6>{{item.title}}<span style="margin-left:20px;">{{item.teachManagerName}}（{{item.teachManagerRealName}}）</span></h6>
                <p>{{item.beginTime.split(' ')[0]}}&nbsp;&nbsp;&nbsp;{{item.beginTime.slice(11,16)}}-{{item.stopTime.slice(11,16)}}（{{item.usedClassHour}}小时）</p>
                <ul>
                    <li>
                      <p>
                        <img src="../../../assets/image/老师.png" alt="">
                        <span>{{item.teacherRealName}}</span>
                      </p>
                      <span :style="item.teacherOnlineStatus!=1?'background:#909399':''">{{item.teacherOnlineStatus==1?'在线':'未在线'}}</span>
                    </li>
                    <li>
                      <p @click="studentShowFn(item.studentList)" style="cursor:pointer">
                        <img src="../../../assets/image/学生.png" alt="">
                        <span>（{{studentStatusFn(item.studentList)}}/{{item.studentList.length}}）</span>
                      </p>
                      <span :style="item.studentOnlineStatus!=1?'background:#909399':''">{{item.studentOnlineStatus==1?'在线':'未在线'}}</span>
                      <img @click="seeQrCode(item)"   v-show="item.attendClassStatus==2" src="../../../assets/image/qrcode.png" alt="">
                    </li>
                </ul>
               </dd>
           </dl>
       </div>
      </div>
      <el-dialog
      title=""
      :visible.sync="qrcodePopup"
      :close-on-click-modal="false"
      width="470px"
      center
    >
       <div class="dialog-content" style="padding-bottom:15px;">
         <!-- <p style="text-align:center;font-size:16px;">电脑旁听地址：<a target="_blank" :href="`${$myStore.webPc}`+`${roomId}`" style="color:#1890FF;text-decoration:none">{{$myStore.webPc}}{{roomId}}</a></p> -->
         <p style="margin:10px auto;" class="qrcodeDiv">
           <vue-qr ref="Qrcode"
            :text="dataObj.text"
             qid="testQrId"></vue-qr>
              <a :href="exportLink" @click="downloadImg" :download="downloadFilename" style="color:#1890FF;text-decoration:none">下载二维码</a>
        </p>
      </div>
     </el-dialog>
     <el-dialog
      title=""
      :visible.sync="studentNumShow"
      :close-on-click-modal="false"
      width="470px"
      center
    >
      <div>
        <el-table
          :data="studentShowList"
          stripe
          style="width: 100%">
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="60">
          </el-table-column>
          <el-table-column
            prop="name"
            label="学生姓名"
           >
          </el-table-column>
          <el-table-column
            prop="name"
            label="在线状态"
            >
            <template slot-scope="scope">
              <span v-show="scope.row.studentOnlineStatus==1">已上线</span>
              <span v-show="scope.row.studentOnlineStatus==0" style="color:red;">未上线</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
     </el-dialog>
  </div>
</template>
<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  followTags,
  reg_Num,
} from "@/api/public";
import {
  getSupervisionCoursePage,
  getSupervisionCourseCount,
  listNoPage,
  getOneToMoreSupervisionCoursePage,//一对多列表
  getOneToMoreSupervisionCourseCount,//一对多统计
} from "@/api/administration/tubeTeachBusiness/studentManage.js";
import flvVideo from '../expertTeachBusiness/flv-video.vue'
import VueQr from 'vue-qr'
export default {
 
    data(){
        return{
          studentNumShow:false,
          studentShowList:[],
          qrcodePopup:false,
          exportLink:"",
          downloadFilename:"",
          dataObj:{
            text:""
          },
          classType:'2',
          moreclassType:'2',
          startTime: '',
          saleList:[],
          endTime: '',
            activeIndex:'1',
            handleKey:1,
            timePickerValue:'',
            moreVideoTotal:0,
            moreList:[],
            params:{
              pageNumber:1,
              pageSize:1000,
              searchBeginDate:null,
              searchEndDate:null,
              attendClassStatus:"2",
              dataAuthority:"dept",
              teachManagerId:""
            },
            moreParams:{
              pageNumber:1,
              pageSize:1000,
              searchBeginDate:null,
              searchEndDate:null,
              attendClassStatus:"2",
              dataAuthority:"dept",
            },
            countParams:{
              searchBeginDate:null,
              searchEndDate:null,
              dataAuthority:"dept"
            },
             moreCountParams:{
              searchBeginDate:null,
              searchEndDate:null,
              dataAuthority:"dept"
            },
            moreCensusParams:{
              endCourseCount:0,//课程结束
              notIntoCourseCount:0,//老师或学生未进入
              ongoingCourseCount:0,//进行中
              screeningTotalCourseCount:0,//筛选后的总数
              todayTotalCourseCount:0,//今日课程总数
            },
            moreStartTime:'',
            moreEndTime:'',
            videoList:[],
            videoTotal:0,
            censusParams:{
              endCourseCount:0,//课程结束
              notIntoCourseCount:0,//老师或学生未进入
              ongoingCourseCount:0,//进行中
              screeningTotalCourseCount:0,//筛选后的总数
              todayTotalCourseCount:0,//今日课程总数
            },
            saleParams: {
            roleSign: "studentAdmin",
            status: 1,
            roleSignAdmin:"studentAdminAdmin"
          },
        }
    },
    components: {
      flvVideo,
      VueQr
    },
    created(){
      this.getSaleList();
      this.getSupervisionCourseCountFn();
      this.getSupervisionCoursePageFn();
    },
    methods:{
       pullUrlFlvFn(item){
        if(!item.isPrisonClass){//没有开启监课
          return true
        }else{
          var curtime=new Date(item.stopTime);
          var endTime=curtime.setHours(curtime.getHours()+1);
          if(new Date().getTime()>endTime||item.attendClassStatus=='0'){
            return true
          }else{
            return false
          } 
        }
      },
      studentStatusFn(array){
        var num=0;
        for(var i=0;i<array.length;i++){
          if(array[i].studentOnlineStatus==1){
            num+=1;
          }
        }
        return num;
      },
      moreEndChange(time){
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        if (month < 10) {
            month = "0" + month;
        }
        if (day < 10) {
            day = "0" + day;
        }
        var time=[year + "-" + month + "-" + day+" "+this.moreStartTime,year + "-" + month + "-" + day+" "+this.moreEndTime];
        this.moreParams.searchBeginDate=time[0]+':00';
        this.moreParams.searchEndDate=time[1]+':00';
        this.moreParams.attendClassStatus="";
        this.moreclassType=''
         getOneToMoreSupervisionCoursePage(this.moreParams).then((res) => {
            this.moreList=res.rows;
            this.moreVideoTotal=res.total;
        });
      },
    teachManagerChange(){
        if(this.params.teachManagerId!=''){
          this.params.dataAuthority="deptOneUser"
        }else{
          this.params.dataAuthority="dept"
        }
        this.params.pageNumber=1;
         getSupervisionCoursePage(this.params).then((res) => {
         
            this.videoList=res.rows;
            this.videoTotal=res.total;
          
        });
      },
    getSaleList() {
        listNoPage(this.saleParams).then(res => {
          this.saleList = res;
          this.saleList.unshift({
            userId: "",
            name:"全部",
            username: "全部"
          });
        });
      },
      seeQrCode(item){
        this.qrcodePopup=true;
        var url=`${this.webUrl}/html/hls.html?pullUrlHls=`
        if(item.pullUrlHls==null){//原来旁听
          this.dataObj.text=this.$myStore.webMobile+item.roomid;
        }else{
          this.dataObj.text=url+encodeURIComponent(item.pullUrlHls)
        }
      },
      downloadImg(){
        let Qrcode = this.$refs.Qrcode
      this.exportLink = Qrcode.$el.currentSrc
      this.downloadFilename = '二维码'
      },
      startChange(){
        console.log(this.startTime)
      },
      endChange(time){
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        if (month < 10) {
            month = "0" + month;
        }
        if (day < 10) {
            day = "0" + day;
        }
        var time=[year + "-" + month + "-" + day+" "+this.startTime,year + "-" + month + "-" + day+" "+this.endTime];
        this.params.searchBeginDate=time[0]+':00';
        this.params.searchEndDate=time[1]+':00';
        this.params.attendClassStatus="";
        this.classType=''
         getSupervisionCoursePage(this.params).then((res) => {
            this.videoList=res.rows;
            this.videoTotal=res.total;
        });
        // this.getSupervisionCourseCountFn(4);
      },
      handleSizeChange(pageSize){
        this.params.pageSize = pageSize;
      },
      handleCurrentChange(currentPage){
        this.params.pageNumber = currentPage;
      },
      getSupervisionCourseCountFn(num){
          this.startTime='';
          this.endTime='';
          this.params.searchBeginDate=null;
          this.params.searchEndDate=null;
          this.params.teachManagerId=null;
          this.params.dataAuthority='dept';
        this.classType=num?num:'2'
        if(num==3){//学生或者老师未进入
          this.params.attendClassStatus='0';
        }else if(num==1||num==2){
          this.params.attendClassStatus=String(num);
        }else if(num==4||num=='all'){//时间查看
          this.params.attendClassStatus='';
        }
        else{
          this.params.attendClassStatus=String(2);
        }
        getSupervisionCoursePage(this.params).then((res) => {
            if(num==1){//课程结束的数据
             for(var i=0;i<res.rows.length;i++){
               if(res.rows[i].attendClassStatus=='2'){
                 res.rows[i].attendClassStatus='1'
               }
             }
            }
            this.videoList=res.rows;
            this.videoTotal=res.total;
        });
      },
      studentShowFn(array){
       this.studentShowList=array;
        this.studentNumShow=true;
      },
      getSupervisionCoursePageFn(){
        getSupervisionCourseCount(this.countParams).then((res) => {
          if(res.code==0){
            this.censusParams=res.rows[0];
          }
        });
      },
      attendClassStatusType(val){
        if(val==0){
          return '未上课'
        }else if(val==1){
          return '已上课'
        }else if(val==2){
          return '上课中'
        }
      },
      getOneToMoreSupervisionCoursePageFn(num){
          this.moreStartTime='';
          this.moreEndTime='';
          this.moreParams.searchBeginDate=null;
          this.moreParams.searchEndDate=null;
          this.moreclassType=num?num:'2'
          if(num==3){//学生或者老师未进入
            this.moreParams.attendClassStatus='0';
          }else if(num==1||num==2){
            this.moreParams.attendClassStatus=String(num);
          }else if(num==4||num=='all'){//时间查看
            this.moreParams.attendClassStatus='';
          }else{
            this.moreParams.attendClassStatus=String(2);
          }
          getOneToMoreSupervisionCoursePage(this.moreParams).then((res) => {
              if(num==1){//课程结束的数据
              for(var i=0;i<res.rows.length;i++){
                if(res.rows[i].attendClassStatus=='2'){
                  res.rows[i].attendClassStatus='1'
                }
              }
              }
              this.moreList=res.rows;
              this.moreVideoTotal=res.total;
          });
      },
      getOneToMoreSupervisionCourseCountFn(){//一对多统计
        getOneToMoreSupervisionCourseCount(this.moreCountParams).then((res) => {
          if(res.code==0){
            this.moreCensusParams=res.rows[0];
          }
        });
      },
      handleSelect(key){
          this.handleKey=key;
          if(key==1){//一对一
            this.getSupervisionCourseCountFn();
            this.getSupervisionCoursePageFn();
          }else{//一对多
            this.getOneToMoreSupervisionCoursePageFn();
            this.getOneToMoreSupervisionCourseCountFn();
          }
      },
    },
    mounted(){

    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
      
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .classInfo_ul {
    display: flex;
    flex-wrap: wrap;
  }
  .classInfo_ul li {
    width: 25%;
    margin-top: 20px;
    font-size: 16px;
  }
  .subject_ul {
    width: 100%;
    overflow: visible;
    height: 55px;
    line-height: 55px;
  }
  .subject_ul li {
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    float: left;
  }
}
 .subjectActive {
    overflow: hidden;
  }
.classNum{
    width: 100%;
    height:60px;
    // min-width:1220px;
    background: #F5F7FA;
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top:13px;
    p{
        color:#222;
        font-size:16px;
        font-weight: bold;
        margin-left:30px;
    }
    div{
        margin-right:40px;
        span{
            display: inline-block;
            width:auto;
            height:32px;
            line-height: 32px;
            color:#3EC279;
            border-radius: 3px;
            text-align: center;
            font-size:12px;
            margin-left:6px;
            padding:0 12px;
            cursor:pointer;
            border:1px solid #3EC279;
        }
    }
}
.classList{
    margin-top:20px;
    display: flex;
    flex-wrap: wrap;
    flex:1;
    overflow-y: scroll;
    dl{
        width:284px;
        margin:15px;
        dt{
            width:100%;
            height:160px;
            // background: chocolate;
            position: relative;
            p{
                position: absolute;
                top:6px;
                right:6px;
                background-color: rgba(0,0,0,0.5);
                border-radius: 2px;
                color:#fff;
                font-size:12px;
                padding:7px 8px 7px 8px;
                span:nth-child(1){
                    width:8px;
                    height:8px;
                    border-radius: 50%;
                    background: #3EC279;
                    display: inline-block;
                    margin-right:5px;
                }
            }
        }
        dd{
            margin-top:17px;
            h6{
                font-size:14px;
            }
           >p{
                color:#606266;
                font-size: 12px;
                margin-top:12px;
            }
            ul{
                margin-top:15px;
                li{
                  display: flex;
                  align-items: center;
                  margin-top:6px;
                  position: relative;
                  >img{
                    float:right;
                    position: absolute;
                    right:0;
                  }
                }
                li:nth-child(3){
                  p{
                    color:#606266;
                    font-size:12px;
                  }
                }
                li:nth-child(1),li:nth-child(2){
                  display: flex;
                  align-items: center;
                  margin-top:6px;
                  p{
                    display: flex;
                    align-items: center;
                    span{
                      color:#606266;
                      font-size:12px;
                    }
                    img{
                      margin-right:7px;
                    }
                    span:nth-child(2){
                      width:50px;
                    }
                    span:nth-child(3){
                      margin-left:5px;
                    }
                  }
                  >span{
                    padding:4px 12px;
                    border-radius: 2px;
                    background: #3EC279;
                    color:#fff;
                    font-size:12px;
                    margin-left:15px;
                  }
                }
            }
        }
    }
}
.active{
  color:#fff !important;
  background: #3EC279 !important;
}
.qrcodeDiv{
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.qrcodeDiv img{
  margin:0 auto;
}
</style>

