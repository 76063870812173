<template>
    <div>
        <el-dialog
        title="退费"
        center
        width="800px"
        :close-on-click-modal="false"
        :visible="Visible"
        @close="$emit('close')"
    >
        <div class="dialog-contents">
           <p>班级信息</p>
           <ul>
            <li>班级名称：</li>
            <li>产品名称：</li>
            <li>学段：</li>
            <li>满班人数：</li>
           </ul>
           <div class="table-area">
          <el-table
            size="small"
            :data="classData"
            style="width: 100%;margin-top:10px;"
            row-key="roleId"
            border
            tooltip-effect="dark"
            ref="customDataTable"
            @selection-change="handleSelectionChange"
            @row-click="roleItemClick"
        >
        <el-table-column
            prop="sectionName"
            label="课程名称"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="basicPrice"
            label="作业标题"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="address"
            align="center"
            label="布置教师">
        </el-table-column>
         <el-table-column
            prop="createTime"
            label="布置时间"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="上课时间"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="未提交/已提交"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="未点评/已点评"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                >查看</el-button
              >
              <el-button
                type="text"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        </div>
        </div>
    </el-dialog>
    </div>
</template>
<script>

export default {
    props:['Visible'],
    data(){
        return{
            classData:[],
        }
    },
    methods:{
        handleSelectionChange(){

        },
        roleItemClick(){

        }
    }
}
</script>
<style scoped lang="scss">
    .seePrice-div{
      font-size:15px;
      padding-bottom:30px;
      ul{
        margin-top:20px;
        li{
          line-height: 26px;
        }
      }
    }
    .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-contents{
    display: flex;
    flex-direction: column;
    height:100%;
    .btn-div{
      height:50px;
      float:right;
    }
    .table-area{
      flex:1;
      overflow: hidden;
    }
    >p{
        color:#333;
        font-size:16px;
        font-weight: bold;
    }
    >ul{
        display: flex;
        padding:15px 0;
        li{
            margin-right:20px;
        }
    }
  }
 .title_p{
     display: flex;
     align-items: center;

     span{
         display: inline-block;
     }
     span:nth-child(1){
         width:3px;
         height:20px;
         background: #27C580;
         border-radius: 2px;
     }
     span:nth-child(2){
        color:#222;
        font-size:15px;
        margin-left:15px;
        font-weight: 600;

    }
 }
 .student_info{
     padding:20px 0;
     span:nth-child(1){
         font-weight: 600;
         font-size: 15px;
         color:#000;
     }
     span{
         padding:0 5px;
     }
 }
 .total_p{
     font-weight: 600;
     color:#222;
     padding:10px 0;
 }
</style>
