<template>
    <div class="main-content sys-role-manage">
        <div class="btn-area">
            <div class="left">
                <div class="select-condition-item">
                <span class="item-lable">关键字：</span>
                <el-input
                    v-model="listParams.keyword"
                    placeholder="输入学生姓名"
                    size="small"
                    style="min-width:120px; max-width:216px"
                ></el-input>
                </div>
                <div class="select-condition-item">
                    <span class="item-lable">年级：</span>
                    <el-select
                    v-model="listParams.sectionId"
                    placeholder="请选择"
                    size="small"
                    >
                    <el-option
                        v-for="item in sectionList"
                        :key="item.macroId"
                        :label="item.name"
                        :value="item.macroId"
                    ></el-option>
                    </el-select>
                </div>
                <div class="select-condition-item">
                    <span class="item-lable">教务姓名：</span>
                    <el-select
                        v-model="listParams.teachManagerId"
                        placeholder="请选择"
                        size="small"
                    >
                    <el-option
                        v-for="item in saleList"
                        :key="item.userId"
                        :label="`${item.username}（${item.name}）`"
                        :value="item.userId"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="select-condition-item">
                    <span class="item-lable">学生状态：</span>
                    <el-select
                        v-model="listParams.studentState"
                        placeholder="请选择"
                        size="small"
                    >
                    <el-option
                        v-for="item in studentStateList"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type"
                        >
                        </el-option>
                    </el-select>
                </div>
                <el-button type="primary" size="small" icon="el-icon-search"
                @click="()=>(listParams.pageNumber=1,getRemainingClassHoursFn())"
                >搜索</el-button
                >
            </div>
        </div>
        <p class="tongjiTit">
          <span>总计：总金额：{{countMap.totlePrice?countMap.totlePrice:0}}元</span>
          <span>已上课时：{{countMap.usedClassHour?countMap.usedClassHour:0}}课时</span>
          <span>剩余金额：{{countMap.accountBalance?countMap.accountBalance:0}}元</span>
          <span>剩余课时：{{countMap.surplusClassHour?countMap.surplusClassHour:0}}课时</span>
        </p>
        <div class="table-area">
        <el-table
          size="small"
          :data="dataList"
          style="width: 100%; margin-bottom: 20px"
          row-key="userId"
          border
          height="100%"
          tooltip-effect="dark"
        >
          <el-table-column
            prop="name"
            label="学生姓名"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="sectionName"
            label="年级"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="totlePrice"
            label="总金额"
            align="center"
            
          >
            <template slot-scope="scope">
              {{scope.row.totlePrice!=null?scope.row.totlePrice:0}}元
            </template>
          </el-table-column>
          <el-table-column
            prop="usedClassHour"
            label="已上课时"
            align="center"
          > 
            <template slot-scope="scope">
              {{scope.row.usedClassHour!=null?scope.row.usedClassHour:0}}课时
            </template>
          </el-table-column>
          <el-table-column
            prop="accountBalance"
            label="剩余金额"
            align="center"

          > 
            <template slot-scope="scope">
              {{scope.row.accountBalance!=null?scope.row.accountBalance:0}}元
            </template>
          </el-table-column>
          <el-table-column
            label="剩余课时"
            align="center"
            :formatter="dealClassTime"
          >
          </el-table-column>
          <el-table-column
            prop="unitPrice"
            label="单价"
            align="center"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="seeInfo(scope.row)">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column
            label="归属教务"
            align="center"
          >
             <template slot-scope="scope">
            {{scope.row.teachManagerName}}（{{scope.row.teachManagerRealName}}）
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="seeType==2?'时间模式':'阶梯模式'"
      :visible.sync="infoVisible"
      :close-on-click-modal="false"
      :width="seeType==2?'600px':'450px'"
      center
    >
      <div class="dialog-content">
         <div v-show="seeType!=2">
        <div class="seePrice-div">
          <p>基本单价：{{priceInfoParams.basicPrice}}元/课时</p>
        <ul>
          <li v-for="(item,index) in priceInfoParams.listXmtStudentUnitPriceGrandson" :key="index">
            <p>课时范围{{index+1}}：{{item.begin}}至{{item.end}}课时</p>
            <p>课时单价：{{item.unitPrice}}元/课时</p>
          </li>
        </ul>
        </div>
        <p>注：使用阶梯模式的学生按照当前课时范围单价计算剩余课时</p>
      </div>
      <div v-show="seeType==2">
        <div class="table-area">
            <el-table
                size="small"
                :data="customData"
                style="width: 100%;margin-top:10px;"
                row-key="roleId"
                border
                tooltip-effect="dark"
                ref="customDataTable"
            >
            <el-table-column
                type="index"
                label="编号"
                align="center"
                width="60">
            </el-table-column>
            <el-table-column
                prop="basicPrice"
                label="单价"
                align="center"
                >
            </el-table-column>
            <el-table-column
                prop="beginTime"
                label="生效时间"
                align="center"
              >
            </el-table-column>
            <el-table-column
                prop="endTime"
                label="结束时间"
                align="center"
                >
            </el-table-column>
            </el-table>
          </div>
        <p style="margin-top:15px;">注：使用时间模式的学生按照当前时间段单价计算剩余课时</p>
      </div>
      </div>
    </el-dialog>
    </div>
</template>
<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  followTags,
  fileUpload,
  reg_Num,
} from "@/api/public";
  import { studentUnitPriceChildList } from '@/api/finance/finance.js';
  import { financialClassHours,getRemainingClassHours,studentUnitPriceChildInfo } from '@/api/administration/tubeTeachBusiness/studentManage.js'
export default {
    props:[],
    data(){
        return{
          studentStateList: [
            {
              type: -1,
              name: '全部',
            },
            {
              type: 0,
              name: '新生',
            },
            {
              type: 1,
              name: '在读',
            },
            {
              type: 2,
              name: '暂停课',
            },
            {
              type: 3,
              name: '退费',
            },
            {
              type: 4,
              name: '结课',
            },
            {
              type: 5,
              name: '试听',
            },
            {
              type: 6,
              name: '试听未报名',
            },
          ],
            sectionList:[],
            saleList:[],
            dataList:[],
            total:0,
            infoVisible:false,
            seeType:"",
            listParams:{
                keyword:"",
                sectionId:"",
                teachManagerId:"",
                studentState: -1,
                pageNumber:1,
                pageSize:10,
                dataAuthority:"dept"
            },
             params:{
                pageNumber:1,
                pageSize:1000,
                studentId:"",
                type:"2",
                delFlag:"0"
            },
            customData:[],
            priceInfoParams:{},
            countMap:{
              accountBalance:'',
              surplusClassHour:'',
              totlePrice:'',
              usedClassHour:''
            }
        }
    },
    created(){
        this.getSectionIdList();
        this.roleSign();
        this.getRemainingClassHoursFn();
    },
    methods:{
        getRemainingClassHoursFn(){
          getRemainingClassHours({ ...this.listParams, studentState: this.listParams.studentState == -1 ? null : this.listParams.studentState }).then((res) => {
              this.dataList=res.rows;
              this.countMap=res.countMap;
              this.total=res.total;
          });
        },
        dealClassTime(row){
          // type 1 阶梯模式 2 时间模式
          if(row.type == 2 && row.timePrice){
            return (row.accountBalance==null?0:row.accountBalance/row.timePrice).toFixed(2)+'课时'
          }else if(row.type == 1 && row.unitPrice){
            return (row.accountBalance==null?0:row.accountBalance/row.unitPrice).toFixed(2)+'课时'
          }
          return '暂未设置'
        },
        seeInfo(row){
          this.infoVisible=true;
          this.seeType=row.type;
          if(row.type==2){//时间模式
          this.params.studentId=row.id;
           studentUnitPriceChildList(this.params).then(res => {
                this.customData=res.rows;
                // this.customTotal=res.total;
                // var rowsData=res.rows;
                // if(rowsData.length){
                //   this.rowsEndTime=rowsData[0].endTime;
                // }else{
                //   this.rowsEndTime=null;
                // }
            });
          }else{
            studentUnitPriceChildInfo(row.childId).then((res) => {
              this.priceInfoParams=res.rows;
            });
          }
        },
         handleSizeChange(pageSize) {
            this.listParams.pageSize = pageSize;
            this.getRemainingClassHoursFn();
          },
          handleCurrentChange(currentPage) {
            this.listParams.pageNumber = currentPage;
            this.getRemainingClassHoursFn();
          },
        //获取年级
          getSectionIdList() {
              getSelectList({
                  parentCode: sectionCode
              }).then(res => {
                  if (res.code == 0) {
                  this.sectionList = res.rows;
                  this.sectionList.unshift({
                      macroId: "",
                      name: "全部"
                  });
                  }
              });
          },
          roleSign(){
            let data = {
              roleSign: "studentAdmin",
              status: 1,
              roleSignAdmin:"studentAdminAdmin"
            };

            financialClassHours(data).then(res => {
              this.saleList = res;
              this.saleList.unshift({
                    userId: "",
                    username: "全部",
                    name:"全部"
                });
            });
          },
    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .studentInfo{
    h2{
      font-size:16px;
      margin-top:20px;
    }
    ul{
      display: flex;
      flex-wrap:wrap;
      margin-top:10px;
      li{
        width:33.3%;
        padding:5px 0;
      }
    }
  }
  .infoRecord{
    display: flex;
    width:100%;
    span{
      width:25%;
      display: inline-block;
      padding:5px 0;
    }
  }
  .seePrice-div{
      font-size:15px;
      padding-bottom:30px;
      ul{
        margin-top:20px;
        li{
          line-height: 26px;
        }
      }
    }
}
.tongjiTit{
  margin:10px 0;
  span{
    margin-right:8px;
  }
}
</style>