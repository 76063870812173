<template>
    <div>
        <el-dialog
      title="上课地址"
      :visible.sync="showAddressDialog"
      :close-on-click-modal="false"
      width="800px"
      center
      @close="closeDialog"
    >
      <div class="dialog-content">
        <div class="btn-area">
          <div class="right">
            <el-button-group>
              <el-button type="default" size="small" @click="addAddress">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-xinzeng" />
                </svg>
                <span>新增</span>
              </el-button>
              <el-button type="default" size="small" @click="editAddress">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-edit" />
                </svg>
                <span>编辑</span>
              </el-button>
              <el-button type="default" size="small" @click="removeAddress">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-trash" />
                </svg>
                <span>删除</span>
              </el-button>
            </el-button-group>
          </div>
        </div>
        <div class="table-area">
          <el-table
            size="small"
            :data="addressListData"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            tooltip-effect="dark"
            ref="addressListData"
            @selection-change="addressListDataChange"
            @row-click="addressListDataItemClick"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column prop="name" label="地址" align="center">
              <template slot-scope="scope">
                <span
                  v-show="scope.row.isDefault == 1"
                  style="
                    padding: 5px 8px;
                    background: red;
                    color: #fff;
                    border-radius: 4px;
                  "
                  >默认</span
                >
                <span
                  >{{ scope.row.provinceName }}{{ scope.row.cityName
                  }}{{ scope.row.countyName
                  }}{{ scope.row.detailAddress }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="page-area">
          <el-pagination
            @size-change="handleSizeChangeAddress"
            @current-change="handleCurrentChangeAddress"
            :current-page="classAddressData.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="classAddressData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            background
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="clickAddressSure()" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="addAddressParams.title"
      :visible.sync="addAddressPopup"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveAddressForm"
          status-icon
          ref="saveAddressForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="所在地区">
            <el-row type="flex">
              <el-col :span="8">
                <el-select
                  v-model="saveAddressForm.provinceId"
                  placeholder="请选择"
                  size="small"
                  @change="addressProvinceChange"
                >
                  <el-option
                    v-for="item in addressProvinceList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveAddressForm.cityId"
                  placeholder="请选择"
                  size="small"
                  @change="addressCityChange"
                >
                  <el-option
                    v-for="item in addressCityList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveAddressForm.countyId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in addressCountyList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="详细地址">
            <el-col :span="12">
              <el-input v-model="saveAddressForm.detailAddress"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="默认地址" prop="isDefault">
            <el-radio-group v-model="saveAddressForm.isDefault">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addAddressPopup = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="confirmAddressSave('saveAddressForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    </div>
</template>
<script>
import {
    studentClassAddressList, //上课地址列表
  studentClassAddressSave, //上课地址列表新增
  studentClassAddressUpdate, //上课地址列表修改
  studentClassAddressRemove, //上课地址列表删除
  studentClassAddressInfo, //上课地址列表详情
  getProductByStudentId, //该学生所购买过的产品
  checkDefault, //判断是否有默认地址
 } from "@/api/administration/expertTeachBusiness/teachBusiness.js";
 import { systemAreaList,setStudentlevel } from "@/api/system/systemManage";
export default {
    data(){
        return{
            showAddressDialog:false,
            addressListData:[],
            total:0,
            classAddressData: {
                studentId: "",
                pageNumber: 1,
                pageSize: 10,
            },
            selectData:[],
            newdetailAddress:[],
            addAddressPopup:false,
            addAddressParams:{
                title: "添加地址",
                type: "add",
            },
            saveAddressForm: {
                provinceId: "",
                cityId: "",
                countyId: "",
                isDefault: "0",
                detailAddress: "",
            },
            addressProvinceList:[],
            addressCityList:[],
            addressCountyList:[],
            parentId: "0",
            classAddress:""
        }
    },
    props:["addressStatus","addressParams"],
    watch:{
        addressStatus(newValue,oldValue){
            console.log(this.addressParams,'学生信息id...')
            this.classAddressData.studentId=this.addressParams.studentId;
            this.showAddressDialog=newValue;
            this.addressList();

        }
    },
    methods:{
        addressList(){
            studentClassAddressList(this.classAddressData).then((res) => {
                this.addressListData = res.rows;
                this.total = res.total;
            });
        },
        clickAddressSure(){
            if (this.selectData.length <= 0) {
                this.msgWarn("请选择上课地址！");
                return;
            }
            this.closeDialog();
            this.classAddress =
          this.newdetailAddress.provinceName +
          this.newdetailAddress.cityName +
          this.newdetailAddress.countyName +
          this.newdetailAddress.detailAddress;

        },
        handleSizeChangeAddress(pageSize) {
          this.classAddressData.pageSize = pageSize;
          this.addressList();
        },
        handleCurrentChangeAddress(currentPage) {
          this.classAddressData.pageNumber = currentPage;
          this.addressList();
        },
        addressListDataChange(val){
            this.selectData = val;
            this.newdetailAddress = val[0];
            if (val.length > 1) {
                this.$refs.addressListData.clearSelection();
                this.$refs.addressListData.toggleRowSelection(val.pop());
            }
        },
        addressListDataItemClick(row) {
            this.$refs.addressListData.toggleRowSelection(row);
        },
        addAddress(){
            this.addAddressPopup = true;
            this.addAddressParams = {
                title: "添加地址",
                type: "add",
            };
            this.saveAddressForm.provinceId = "";
            this.saveAddressForm.cityId = "";
            this.saveAddressForm.countyId = "";
            this.saveAddressForm.detailAddress = "";
            this.address1();
        },
        address1() {
            return systemAreaList({
                parentId: this.parentId,
            }).then((res) => {
                if (res.code == 0) {
                    this.addressProvinceList = res.rows;
                }
            });
        },
        async addressProvinceChange() {
            await this.addressCityListFn();
            this.saveAddressForm.cityId = this.addressCityList[0].areaId;
            this.addressCityChange();
        },
        addressCityListFn() {
            return systemAreaList({
                parentId: this.saveAddressForm.provinceId,
            }).then((res) => {
                this.addressCityList = res.rows;
            });
        },
        async addressCityChange() {
            await this.addressCountyListFn();
            this.saveAddressForm.countyId = this.addressCountyList[0].areaId;
        },
        addressCountyListFn() {
            return systemAreaList({
                parentId: this.saveAddressForm.cityId,
            }).then((res) => {
                if (res.code == 0) {
                this.addressCountyList = res.rows;
                }
            });
        },
        confirmAddressSave(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                this.saveAddressForm.studentId = this.addressParams.studentId;
                checkDefault(this.addressParams.studentId).then((res) => {
                    if (res.code == 0) {
                    if (this.addAddressParams.type == "add") {
                        if (res.msg == "无默认") {
                        studentClassAddressSave(this.saveAddressForm).then((res) => {
                            if (res.code == 0) {
                                this.addAddressPopup = false;
                                this.addressList();
                            }
                        });
                        } else {
                        if (this.saveAddressForm.isDefault == 1) {
                            this.msgWarn("已有默认地址！");
                            return false;
                        }
                        studentClassAddressSave(this.saveAddressForm).then((res) => {
                            if (res.code == 0) {
                            this.addAddressPopup = false;
                            this.addressList();
                            }
                        });
                        }
                    } else if (this.addAddressParams.type == "edit") {
                        this.saveAddressForm.id = this.selectData[0].id;
                        if (res.msg == "无默认") {
                        studentClassAddressUpdate(this.saveAddressForm).then(
                            (res) => {
                            if (res.code == 0) {
                                this.addAddressPopup = false;
                                this.addressList();
                            }
                            }
                        );
                        } else {
                        if (this.saveAddressForm.id == this.addressParams.defaultId) {
                            studentClassAddressUpdate(this.saveAddressForm).then(
                            (res) => {
                                if (res.code == 0) {
                                this.addAddressPopup = false;
                                this.addressList();
                                }
                            }
                            );
                        } else {
                            if (this.saveAddressForm.isDefault == 1) {
                            this.msgWarn("已有默认地址！");
                            return false;
                            } else {
                            studentClassAddressUpdate(this.saveAddressForm).then(
                                (res) => {
                                if (res.code == 0) {
                                    this.addAddressPopup = false;
                                    this.addressList();
                                }
                                }
                            );
                            }
                        }
                        }
                    }
                    }
                });
                }
            });
            },
        async editAddress(){
            if (this.selectData.length > 1) {
                this.msgWarn("请选择单个地址进行编辑！");
                return;
            }
            if (this.selectData.length == 0) {
                this.msgWarn("请至少选择一个地址！");
                return;
            }
            this.addAddressParams = {
                title: "编辑地址",
                type: "edit",
            };
            this.addAddressPopup = true;
            await this.address1();
            await studentClassAddressInfo(this.selectData[0].id).then((res) => {
                if (res.code == 0) {
                // this.getAddressId = res.rows.id;
                this.saveAddressForm = {
                    provinceId: res.rows.provinceId,
                    cityId: res.rows.cityId,
                    countyId: res.rows.countyId,
                    isDefault: String(res.rows.isDefault),
                    detailAddress: res.rows.detailAddress,
                };
                if (res.rows.provinceId) {
                    this.getEditCityLists();
                }
                if (res.rows.cityId) {
                    this.getEditCountyLists();
                }
                }
            });
        },
        getEditCityLists() {
            return systemAreaList({
                parentId: this.saveAddressForm.provinceId,
            }).then((res) => {
                if (res.code == 0) {
                this.addressCityList = res.rows;
                }
            });
        },
        getEditCountyLists() {
            return systemAreaList({
                parentId: this.saveAddressForm.cityId,
            }).then((res) => {
                if (res.code == 0) {
                this.addressCountyList = res.rows;
                }
            });
        },
        removeAddress(){
            if (this.selectData.length == 0) {
                this.msgWarn("请至少选择一个地址！");
                return;
            }
            this.$confirm("确定要删除吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                studentClassAddressRemove(this.selectData).then((res) => {
                    if (res.code == 0) {
                    this.msgSuccess("操作成功！");
                    this.addressList();
                    }
                });
                })
                .catch(() => {});
        },
        closeDialog(){
            this.$emit('CB_addressStatus',this.classAddress)
        },
    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .center {
    text-align: center;
    min-height: 30px;
  }
  .line {
    height: 0;
    border-bottom: 1px solid #f1f1f1;
    margin-left: 30px;
    margin: 10px 0 10px 30px;
  }
  .save-dialog-content {
    padding-right: 20px;
  }
  .row_detail {
      padding: 10px 0;
    }
}

</style>