<template>
    <div>
        <el-dialog
            title=""
            center
            width="450px"
            :close-on-click-modal="false"
            :visible="studentStatuDialogSon"
            @close="closeDialog"
        >
            <div>
                <p>学生当前的状态：<el-button size="mini" round type="success">{{studentStateFn()}}</el-button></p>
                <div class="box">
                    <p>在读的状态：</p>
                    <el-radio v-model="params.studentState" label="5" border size="small">试听</el-radio>
                    <el-radio v-model="params.studentState" label="1" border size="small">在读</el-radio>
                    <p>停课的状态：</p>
                    <el-radio v-model="params.studentState" label="6" border size="small">试听未报名</el-radio>
                    <el-radio v-model="params.studentState" label="2" border size="small">暂停课</el-radio>
                    <el-radio v-model="params.studentState" label="4" border size="small">结课</el-radio>
                    <p>退费的状态：</p>
                    <el-radio v-model="params.studentState" label="3" border size="small">退费</el-radio>
                </div>
            </div>
            <div
            style="display:flex;align-items: center;justify-content: center;margin-top:30px;" 
          >
            <el-button size="small" 
                @click="cancel"
              >取 消</el-button
            >
            <el-button type="primary"  size="small" @click="sureStatu"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
            title="提示"
            :visible.sync="takeVisible"
            :close-on-click-modal="false"
            width="400px"
            center
            > 
            <div >
                <h2 style="line-height:1.5">学生结课后，会在7天后删除学生的所有回放记录，是否要设置学生状态为结课</h2>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small"
                @click="takeVisible=false"
                >取 消</el-button
                >
                <el-button type="primary"  size="small" @click="setStudentStateFn()"
                >确 定</el-button
                >
            </span>
            </el-dialog>
    </div>
</template>
<script>
import {
  setStudentState
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
export default {
    props:["studentStatuDialog","studentStatuId","studentStateType"],
    data(){
        return{
            studentStatuDialogSon:false,
            takeVisible:false,
            params:{
                id:'',
                studentState:''
            }
        }
    },
     watch:{
      studentStatuDialog(newValue,oldValue){
        this.studentStatuDialogSon=newValue;
        if(newValue==true){
          this.params.studentState="";
          this.params.id=this.studentStatuId;
        }
      },
    },
    mounted(){

    },
    methods:{
        sureStatu(){
            if(this.params.studentState==4){
                this.takeVisible=true;
            }else{
                this.setStudentStateFn();
            }
        },
        setStudentStateFn(){
            setStudentState(this.params).then((res) => {
                if(res.code==0&&res.msg=='操作成功！'){
                    this.msgSuccess("操作成功！");
                    this.takeVisible=false;
                    this.$emit('change_studentStatu')
                }else{
                    this.msgWarn(res.rows);
                }
            })
        },
        studentStateFn(){
            if (this.studentStateType == 0) {
                return "新生";
            } else if (this.studentStateType == 1) {
                return "在读";
            } else if (this.studentStateType == 2) {
                return "暂停课";
            } else if (this.studentStateType == 3) {
                return "退费";
            } else if (this.studentStateType == 4) {
                return "结课";
            }else if (this.studentStateType == 5) {
                return "试听";
            }else if (this.studentStateType == 6) {
                return "试听未报名";
            }
        },
        cancel(){
             this.$emit('change_studentStatu')
        },
        closeDialog(){
          this.$emit('change_studentStatu')
        },
    }
}
</script>
<style lang="scss" scoped>
    .box{
        margin-top:15px;
        >p{
            padding:6px 0;
            margin-top:10px;
        }
    }
</style>
