<template>
  <div>
    <el-dialog
      :title="makeType.title"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      width="400px"
      center
      @close="closeDialog"
      custom-class="add_class"
    >
      <div class="dialog-content">
        <el-form
          :model="saveForm"
          status-icon
          :rules="saveRules"
          ref="saveForm"
          label-width="100px"
          size="small"
        >
          <el-col :span="20">
            <el-form-item label="学科" prop="subjectId">
              <el-select
                v-model="saveForm.subjectId"
                placeholder="请选择"
                size="small"
                @change="selectChanged"
              >
                <el-option
                  v-for="item in subjectIdList"
                  :key="item.macroId"
                  :label="item.name"
                  :value="item.macroId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="问题课程">
              <p>{{ makeInfo.jiesuTime }}</p>
              <p>
                <span>{{ makeInfo.subjectName }}</span
                ><span style="margin-left: 10px">{{
                  makeInfo.teacherRealName
                }}</span>
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="上课模式" prop="classMode">
              <el-radio-group
                v-model="saveForm.classMode"
                @change="classModeChange()"
              >
                <el-radio label="1">线上</el-radio>
                <el-radio label="2">线下</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="课程标题" prop="title">
              <el-input
                v-model="saveForm.title"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="新上课时间" prop="beginTime">
              <el-date-picker
                v-model="saveForm.beginTime"
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                placeholder="选择日期"
                @change="changeBeginTime"
                :picker-options="
                  saveForm.classMode == '1'
                    ? beforePickerOptions
                    : offlineBeforePickerOptions
                "
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="课时数" prop="usedClassHour">
              <el-input-number
                v-model="saveForm.usedClassHour"
                type="number"
                placeholder="请输入内容"
                :max="Number(4)"
                :min="Number(1)"
                @change="classHourChange"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="讲师" prop="teacherId">
              <el-input
                @click.native="getTeacherId()"
                readonly
                v-model="saveTeacherName"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-show="saveForm.classMode == 2">
            <el-form-item label="上课地址" prop="detailAddress">
              <el-input
                @click.native="getDetailAddress()"
                readonly
                v-model="saveForm.classAddress"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-show="saveForm.classMode == 1">
            <el-form-item label="视频模式" prop="videoMode">
              <el-select
                v-model="saveForm.videoMode"
                placeholder="请选择"
                size="small"
                @change="videoModeChanged"
              >
                <el-option
                  v-for="item in videoModeList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="首次课设置" prop="isFirstClass">
              <el-switch
                v-model="saveForm.isFirstClass"
                active-value="1"
                inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="监课展示" prop="isPrisonClass">
              <el-switch
              :disabled="!hasCdnMenu"
              @click.native="handleAuthTip('hasCdnMenu', hasCdnMenu)"
                :title="!hasCdnMenu ? '请联系管理员开同此功能' : ''"
                v-model="saveForm.isPrisonClass"
                active-value="1"
                inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="回放录制" prop="isPlayback">
              <el-switch
              :disabled="!hasRecordMenu"
              @click.native="handleAuthTip('hasRecordMenu', hasRecordMenu)"
                :title="!hasRecordMenu ? '请联系管理员开同此功能' : ''"
                v-model="saveForm.isPlayback"
                active-value="1"
                inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="讲师课酬" prop="ifClassRemuneration">
              <el-radio-group v-model="saveForm.ifClassRemuneration">
                <el-radio label="0">计算</el-radio>
                <el-radio label="1">不计算</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <div class="price_tip">
        <div class="class_fee">
          本堂课流量费用：<span>{{ saveForm.expense }}</span
          >元
        </div>
        <div class="account_fee">账户余额：{{ organTotalBalance }}元</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="small">取 消</el-button>
        <el-button type="primary" @click="saveClass('saveForm')" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="选择讲师"
      :visible.sync="selectTeacherPopup"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <div class="table-area mT10">
          <el-table
            size="small"
            :data="matchingTeacherList"
            style="width: 100%; margin-bottom: 20px"
            border
            height="400px"
            tooltip-effect="dark"
            :row-key="getRowKeys"
            ref="resTeacherListTable"
            @selection-change="handleTeacherSelectionChange"
            @row-click="rowTeacherItemClick"
          >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column
              prop="polymerizationKeywords"
              label="课程级别"
              align="center"
              :formatter="courseLevelFormatter"
            ></el-table-column>
            <el-table-column
              prop="subjectName"
              label="学科"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="teacherRealname"
              label="讲师"
              align="center"
            ></el-table-column>
          </el-table>
        </div>
        <div class="page-area">
          <el-pagination
            @size-change="handleTeacherNewSizeChange"
            @current-change="handleTeacherNewCurrentChange"
            :current-page="teacherParams.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="teacherParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="teacherTotal"
            background
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="selectTeacherPopup = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="sureTeacher()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <p v-show="centerType == 'student'" style="padding: 7px 0">
        该时间段"{{ centerStudentName }}"学生已有课程安排
      </p>
      <p v-show="centerType == 'teacher'" style="padding: 7px 0">
        该时间段"{{ centerTeacherName }}"老师已有课程安排
      </p>
      <p style="padding: 7px 0">上课时间：{{ centerBeginTime }}</p>
      <p style="padding: 7px 0" v-show="centerTrue">
        机构：{{ centerOrganName }}
      </p>
      <p style="padding: 7px 0">上课时长：{{ centerUsedClassHour }}小时</p>
      <p style="padding: 7px 0">辅导老师：{{ centerTeacherName }}老师</p>
      <p style="padding: 7px 0">上课学生：{{ centerStudentName }}学生</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  constellation,
  teachStyle,
  teachYear,
  teachSpeed,
  achievement,
} from "@/api/public";
import dayjs from "dayjs";
import { getFlowFeeByMode, getOrganBalance } from "@/api/system/systemManage";
import {
  configteacherPlan,
  studentClassAddressList,
  makeUpTimeForClass,
  checkTeacherClassTime, //老师是否被占用
  checkStudentClassTime,
  checkTeacherOrganClassTime, //学生是否被占用
  xmtProductItemDelete,
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      showDialog: false,
      videoModeList: [
        {
          name: "普通模式",
          type: 1,
        },
        {
          name: "高级模式",
          type: 2,
        },
      ],
      oneToOneSingleClassPrice: 0, // 一对一单价
      organTotalBalance: "--", // 机构剩余金额
      saveForm: {
        subjectId: "", //学科
        classMode: "1", //上课模式
        title: "", //课程标题
        classHourType: "7", //收费类型
        usedClassHour: "2", //课时数
        beginTime: "", //上课时间
        isFirstClass: "0", //首次课设置
        isPrisonClass: "0",
        isPlayback: "0",
        teacherId: "", //讲师
        productId: "",
        classAddress: "",
        totalPrice: "", //总金额
        usePrice: "", //这节课使用的金额
        ifClassRemuneration: "1",
        supplementItemId: "",
        studentId: "",
        videoMode: 1, // 1 普通模式  2 高级模式
        expense: "--", // 消费金额
      },
      saveRules: {
        // productId: [
        //   { validator: check_productIdOne, trigger: "blur" }
        // ],
        subjectId: [
          { required: true, message: "学科不能为空", trigger: "blur" },
        ],
        title: [
          { required: true, message: "课程标题不能为空", trigger: "blur" },
        ],
        usedClassHour: [
          { required: true, message: "课时数不能为空", trigger: "blur" },
        ],
        beginTime: [
          { required: true, message: "上课时间不能为空", trigger: "blur" },
        ],
        teacherId: [
          { required: true, message: "讲师不能为空", trigger: "change" },
        ],
      },
      subjectIdList: [],
      saveTeacherName: "",
      beforePickerOptions: {
        selectableRange: ["06:00:00 -23:59:59"],
        disabledDate: this.disabledDateTime,
        format: "HH:mm",
      },
      offlineBeforePickerOptions: {
        selectableRange: ["06:00:00 -23:59:59"],
        disabledDate: false,
        format: "HH:mm",
      },
      teacherParams: {
        status: 1,
        label: 2,
        studentId: "",
        subjectId: "",
        pageNumber: 1,
        pageSize: 10,
      },
      selectTeacherPopup: false,
      teacherTotal: 0,
      teacherSelectionHandle: [],
      checkedGh: [],
      matchingTeacherList: [],
      defaultId: "",
      classAddressData: {
        studentId: "",
        pageNumber: 1,
        pageSize: 1000,
      },
      centerType: "",
      centerDialogVisible: false,
      centerStudentName: "",
      centerTeacherName: "",
      centerBeginTime: "",
      centerTitle: "",
      centerUsedClassHour: "",
      centerOrganName: "",
      centerTrue: false,
      lastTime: 0,
      getSectionName: "",
    };
  },
  props: ["makeUpStatus", "makeInfo", "makeType", "addressData"],
  watch: {
    makeUpStatus(newValue, oldValue) {
      this.showDialog = newValue;
      this.teacherParams.studentId = this.makeInfo.studentId;
      this.teacherParams.subjectId = this.makeInfo.subjectId;
      this.classAddressData.studentId = this.makeInfo.studentId;
      this.getSectionName = this.makeInfo.sectionName
        ? this.makeInfo.sectionName
        : "";
      if (newValue) {
        this.getOrganBalance()
        this.getSingleClassFee();
      }
      this.saveForm = {
        sectionId: this.makeInfo.sectionId,
        studentId: this.makeInfo.studentId,
        subjectId: this.makeInfo.subjectId, //学科
        classMode: String(this.makeInfo.classMode), //上课模式
        title: this.makeInfo.title, //课程标题
        classHourType: "7", //收费类型
        usedClassHour: this.makeInfo.usedClassHour, //课时数
        // beginTime: this.makeInfo.beginTime, //上课时间
        isFirstClass: String(this.makeInfo.isFirstClass), //首次课设置
        isPrisonClass: String(this.makeInfo.isPrisonClass),
        isPlayback: String(this.makeInfo.isPlayback),
        teacherId: this.makeInfo.teacherId, //讲师
        productId: this.makeInfo.productId,
        classAddress: this.makeInfo.classAddress,
        totalPrice: this.makeInfo.totalPrice, //总金额
        usePrice: this.makeInfo.usePrice, //这节课使用的金额
        ifClassRemuneration: "1",
        supplementItemId: this.makeInfo.id,
        configteacherPlanId: this.makeInfo.configteacherPlanId,
        videoMode: this.makeInfo.videoMode,
        expense: "--", // 消费金额
      };
      this.saveTeacherName = this.makeInfo.teacherRealName;
    },
    addressData() {
      this.saveForm.classAddress = this.addressData;
    },
  },
  created() {
    this.getSubjectList();
  },
  computed: {
    ...mapState('mainData', ['hasCdnMenu', 'hasRecordMenu'])
  },
  methods: {
    // 监课展示和回放录制权限提示
    handleAuthTip(_, flag){
      if(!flag){
        this.$message.warning('请联系管理员开同此功能！')
      }
    },
    selectChanged(value) {
      this.teacherParams.subjectId = value;
      this.saveTeacherName = "";
      this.saveForm.teacherId = "";
      for (var i = 0; i < this.subjectIdList.length; i++) {
        if (this.subjectIdList[i].macroId == value) {
          this.saveForm.title =
            this.getSectionName + this.subjectIdList[i].name;
        }
      }
    },
    handleTeacherNewSizeChange(pageSize) {
      this.teacherParams.pageSize = pageSize;
      this.configteacherPlanFn();
    },
    handleTeacherNewCurrentChange(currentPage) {
      this.teacherParams.pageNumber = currentPage;
      this.configteacherPlanFn();
    },
    courseLevelFormatter(row) {
      switch (row.courseLevel) {
        case 1:
          return "初级课程";
        case 2:
          return "中级课程";
        case 3:
          return "高级课程";
        case 4:
          return "特级课程";
        case 0:
          return "正常课程";
        default:
          return "";
      }
    },
    getRowKeys(row) {
      return row.id;
    },
    handleTeacherSelectionChange(val) {
      this.teacherSelectionHandle = val;
      this.checkedGh = val.id;
      if (val.length > 1) {
        this.$refs.resTeacherListTable.clearSelection();
        this.$refs.resTeacherListTable.toggleRowSelection(val.pop());
      }
    },
    rowTeacherItemClick(row) {
      this.$refs.resTeacherListTable.toggleRowSelection(row);
    },
    getSubjectList() {
      getSelectList({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          this.subjectIdList = res.rows;
        }
      });
    },
    classModeChange() {
      this.computeClassExpense()
      if (this.saveForm.classMode == 1) {
        this.saveForm.classAddress == "";
      }
      studentClassAddressList(this.classAddressData).then((res) => {
        if (res.rows) {
          for (var i = 0; i < res.rows.length; i++) {
            if (res.rows[i].isDefault == 1) {
              this.defaultId = res.rows[i].id;
              this.saveForm.classAddress =
                res.rows[i].provinceName +
                res.rows[i].cityName +
                res.rows[i].countyName +
                res.rows[i].detailAddress;
            }
          }
        }
      });
    },
    getDetailAddress() {
      this.$emit("selectAddressFn", {
        studentId: this.makeInfo.studentId,
        defaultId: this.defaultId,
      });
    },
    /* 视频模式改变 */
    videoModeChanged() {
      this.getSingleClassFee();
    },
    /* 课时数变化 */
    classHourChange() {
      this.computeClassExpense();
    },
    /* 获取当前机构余额 */
    getOrganBalance() {
      this.organTotalBalance = "--";
      getOrganBalance().then((res) => {
        console.log("getOrganBalance: ", res);
        if (res.flag == "error") {
          return this.$message.error(res.msg);
        }
        this.organTotalBalance = res.rows.accountBalance
          ? res.rows.accountBalance
          : 0;
      });
    },
    /* 获取特定上课模式下单节课的费用 */
    getSingleClassFee() {
      this.oneToOneSingleClassPrice = 0;
      this.saveForm.expense = "--";
      const classMode = 1;
      let videoMode = this.saveForm.videoMode;
      if (videoMode) {
        getFlowFeeByMode({
          classMode,
          videoMode,
        }).then((res) => {
          console.log("getOrganBalance: ", res);
          if (res.flag == "error") {
            return this.$message.error(res.msg);
          }
          this.oneToOneSingleClassPrice = res.rows.flowFee;
          this.computeClassExpense();
        });
      }
    },
    /* 计算本次排课消耗金额 */
    computeClassExpense() {
      let classPirce = -1;
      this.saveForm.expense = "--";
      if (this.saveForm.classMode == 2) {
        this.saveForm.expense = 0;
        return;
      }
      const { usedClassHour } = this.saveForm;
      if (this.oneToOneSingleClassPrice != 0) {
        classPirce = this.oneToOneSingleClassPrice * usedClassHour;
      }
      if (classPirce == -1) {
        this.saveForm.expense = "--";
      } else {
        this.saveForm.expense = classPirce;
      }
    },
    //确定保存
    saveClass(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.saveForm.expense == "--") {
            return this.$message.warning("流量费用不正确！");
          }
          if (this.organTotalBalance < this.saveForm.expense) {
            return this.$message.warning("流量费用不能大于账户余额！");
          }
          this.saveForm.usePrice =
            Number(this.getSinglePrice2) * Number(this.saveForm.usedClassHour);
          if (
            dayjs(this.saveForm.beginTime).add(1, "minute").isBefore(dayjs()) &&
            this.saveForm.classMode == "1"
          ) {
            this.msgWarn("排课不能再到当前时间之前的时间！请重新选择时间");
            return;
          }
          let now = new Date().valueOf();
          if (this.lastTime == 0 || now - this.lastTime > 2000) {
            this.lastTime = now;
            if (this.makeType.type == "add") {
              checkTeacherClassTime(this.saveForm).then((res) => {
                if (res.msg == "操作成功！") {
                  checkStudentClassTime(this.saveForm).then((res) => {
                    if (res.msg == "操作成功！") {
                      checkTeacherOrganClassTime(this.saveForm).then((res) => {
                        if (res.msg == "操作成功！") {
                          makeUpTimeForClass(this.saveForm).then((res) => {
                            if (res.code == 0 && res.msg == "操作成功！") {
                              this.msgSuccess("添加成功！");
                              this.closeDialog();
                              // this.singlePopup = false;
                              // this.saveDisabled = false;
                              // this.findCourseItemFnn();
                            } else {
                              // this.saveDisabled = false;
                              this.msgWarn(res.rows);
                              return false;
                            }
                          });
                        } else {
                          this.centerType = "teacher";
                          this.centerStudentName = res.rows.studentName;
                          this.centerTeacherName = res.rows.teacherRealName;
                          this.centerBeginTime = res.rows.beginTime;
                          this.centerTitle = res.rows.title;
                          this.centerUsedClassHour = res.rows.usedClassHour;
                          this.centerOrganName = res.rows.organName;
                          this.centerTrue = true;
                          this.centerDialogVisible = true;
                        }
                      });
                    } else {
                      //学生被占用
                      this.centerType = "student";
                      this.centerStudentName = res.rows.studentName;
                      this.centerTeacherName = res.rows.teacherRealName;
                      this.centerBeginTime = res.rows.beginTime;
                      this.centerTitle = res.rows.title;
                      this.centerUsedClassHour = res.rows.usedClassHour;
                      this.centerTrue = false;
                      this.centerDialogVisible = true;
                    }
                  });
                } else {
                  //老师被占用
                  this.centerType = "teacher";
                  this.centerStudentName = res.rows.studentName;
                  this.centerTeacherName = res.rows.teacherRealName;
                  this.centerBeginTime = res.rows.beginTime;
                  this.centerTitle = res.rows.title;
                  this.centerUsedClassHour = res.rows.usedClassHour;
                  this.centerTrue = false;
                  this.centerDialogVisible = true;
                }
              });
            } else if (this.makeType.type == "edit") {
            } else {
            }
          }
        } else {
        }
      });
    },
    changeBeginTime() {
      if (this.saveForm.beginTime === null) {
        return;
      }
      this.saveForm.beginTime = dayjs(this.saveForm.beginTime).format(
        "YYYY-MM-DD HH:mm"
      );
      let time = this.saveForm.beginTime.substring(0, 10);
      let weekArray = new Array("日", "一", "二", "三", "四", "五", "六");
      let week = weekArray[new Date(time).getDay()];
      if (week == "一") {
        this.saveForm.week = 1;
      } else if (week == "二") {
        this.saveForm.week = 2;
      } else if (week == "三") {
        this.saveForm.week = 3;
      } else if (week == "四") {
        this.saveForm.week = 4;
      } else if (week == "五") {
        this.saveForm.week = 5;
      } else if (week == "六") {
        this.saveForm.week = 6;
      } else if (week == "日") {
        this.saveForm.week = 0;
      }
    },
    sureTeacher() {
      this.selectTeacherPopup = false;
      this.getSinglePrice2 = this.teacherSelectionHandle[0].classSinglePrice;
      this.saveTeacherName = this.teacherSelectionHandle[0].teacherRealname;
      this.saveForm.teacherId = this.teacherSelectionHandle[0].teacherId;
      this.saveForm.configteacherPlanId = this.teacherSelectionHandle[0].id;
      this.saveForm.teachFee = this.teacherSelectionHandle[0].teachFee;
    },
    getTeacherId() {
      if (
        this.teacherParams.subjectId == null ||
        this.teacherParams.subjectId == ""
      ) {
        this.msgWarn("请先选择学科！");
        return false;
      }
      this.selectTeacherPopup = true;
      this.configteacherPlanFn();
    },

    configteacherPlanFn() {
      configteacherPlan(this.teacherParams).then((res) => {
        this.matchingTeacherList = res.rows;
        this.teacherTotal = res.total;
      });
    },
    closeDialog() {
      this.$emit("CB_makeStatus");
    },
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .center {
    text-align: center;
    min-height: 30px;
  }
  .line {
    height: 0;
    border-bottom: 1px solid #f1f1f1;
    margin-left: 30px;
    margin: 10px 0 10px 30px;
  }
  .save-dialog-content {
    padding-right: 20px;
  }
  .row_detail {
    padding: 10px 0;
  }
}
/deep/ .add_class .el-dialog__body {
  padding-bottom: 10px;
}
.price_tip {
  margin-top: 10px;
  display: flex;
  line-height: 24px;
  .account_fee {
    margin-left: 20px;
  }
}
</style>